// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import "./css/appointmentSlots.css";
import AppointmentSlotsController from "../Controller/AppointmentSlotsController";
import ToggleCalendar from "./ToggleCalendar.web";
import moment from "moment";
import { backwardIcon, forwardIcon, calenderPathIcon } from "../assets";
import { Button } from "@material-ui/core";

const GreenRadio = withStyles({
    root: {
        color: "#949494",
        '&$checked': {
            color: "#80C343",
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);


export const NavButton = ({ label, onClick }: any) => (
    <button
        className="nav-month-btn"
        data-test-id="na"
        onClick={() => onClick()}
    >
        {label}
    </button>
);

class AppointmentSlots extends AppointmentSlotsController {
    render() {
        const { value, timeSlots, unAvailabities } = this.state
        return (
            <div className="App myHeader">
                <div className='header'>
                    <div className='rowHead margin-bottom-28'>
                        <div>
                            <p className='date'>{moment(new Date()).format("DD/MM/YYYY")}</p>
                            <p className='time'>{moment(new Date()).format('LT')}</p>
                        </div>
                        <div className="right">
                            <Button data-test-id="availabilityslotTodayBtn" className="todayBTN" onClick={() => this.jumpToday()}>Today</Button>
                        </div>
                    </div>
                    <div className='rowdate margin-bottom-40 calendorHead'>
                        <div className='mainCalendarHeader' data-test-id="mainCalendarHeader" onClick={() => this.setState({ showCalendar: !this.state.showCalendar })}>
                            {
                                value && moment(value).format('LL')
                            }
                            <img src={calenderPathIcon} />
                        </div>

                        <div data-test-id="headerRightCol" className='right-colm'>
                            <p className='available-text'>Available</p>
                            <p className='available-text'>Unavailable</p>
                        </div>
                    </div>
                </div>
                <div>
                    {this.state.showCalendar ? <ToggleCalendar value={this.state.value} onChangeHandler={this.onChangeHandler} monthClickHandler={this.monthClickHandler} navigationHandler={this.navigationHandler} /> : null}
                </div>
                <div id="nav-months" className='nav-month-arror' style={{ width: '100%' }}>
                    <NavButton
                        label={<img
                            src={backwardIcon}
                            alt="icon"
                        />}
                        onClick={this.onPreviousDayClick}

                    />
                    <NavButton
                        label={<img src={forwardIcon} alt="icon" />}
                        onClick={this.onNextDayClick}
                    />

                </div>

                {
                    timeSlots && timeSlots.data && timeSlots.data.length > 0 && (
                        timeSlots.data.map((item: any, index: number) => {
                            const { attributes } = item;
                            const { from, to } = attributes
                            const { data } = unAvailabities
                            const getDataSlot = data?.find((dataItem: any) => Number(item.id) === dataItem.attributes.time_slot_id)
                            let isChecked: boolean;
                            if (getDataSlot?.attributes?.available === true) {
                                isChecked = getDataSlot.attributes.available
                            } else if (getDataSlot?.attributes?.available === false) {
                                isChecked = getDataSlot.attributes.available
                            } else {
                                isChecked = true
                            }
                            return (
                                <div key={item.id + index} data-test-id="row-cell" className='cell'>
                                    <div >
                                        <p data-test-id="slotTime" className='date'>{`${from.toUpperCase()} - ${to.toUpperCase()}`}</p>
                                    </div>
                                    <div className='right-colm'>
                                        <FormControl component="fieldset">
                                            <RadioGroup className="RadioGroup" aria-label="availability" name="availability" >
                                                <FormControlLabel control={<GreenRadio value="unavailable" checked={!isChecked} onChange={(event) => this.handleAvailability(event, item.id)} />} label="Unavailable" className={`unavailablecheck ${!isChecked && "uncheckunavailability"}`} />
                                                <FormControlLabel control={<GreenRadio value="available" checked={isChecked} onChange={(event) => this.handleAvailability(event, item.id)} />} label="Available" className={`availablecheck ${isChecked && "uncheckavailability"}`} />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>
                            )
                        })
                    )
                }

            </div >
        );
    }
}

export default AppointmentSlots;
// Customizable Area End