// Customizable Area Start
import React from "react";
import "./BackGroundCheckPending.css";
import { closearrow, warning } from "./assets";
import { Grid } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import BackGroudCheckComplete from "./BackGroundCheckComplete.web";
import PaymentFormController from "./PaymentFormController.web";

class BackGroundCheckPending extends PaymentFormController {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            this.state.isBackGroundCheckPendingResolve ?
                <BackGroudCheckComplete
                    navigation={this.props.navigation}
                    id={""}
                    history={undefined}
                    handleChange={this.props.handleChange}
                    handleNext={undefined}
                    errorMessage={""}
                    handleBack={this.props.handleBack} />
                : <>
                    <div>
                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid item lg={8}>
                            <div className="bgTitleheading">
                                <div className="mrTitle">
                                    <img
                                        src={closearrow}
                                        onClick={() => this.props.handleBack()}
                                        className="closeArrowStyle"
                                    />
                                </div>
                                <h3 className="bgheading">Background Check</h3>
                            </div>
                                {/* <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <div>
                                        <img
                                            src={closearrow}
                                            onClick={this.props.handleBack}
                                            className="closeArrowStyle"
                                        />
                                    </div>
                                    <h3 className="bgheading">Background Check</h3>
                                    <p></p>
                                </div> */}
                                <div className="pendingpayment">
                                    <img
                                        src={warning}
                                        className="warning"
                                    />
                                    <h4>Background check is pending completion</h4>
                                    <p>Please login to our partner portal (vetting.com) on the link below to view any outstanding actions to complete your check</p>
                                    <div
                                        className="historyContainer historyContainerfull"
                                        onClick={this.handleVettingDotCom}
                                    >
                                        <p>Vetting.com</p>
                                    </div>
                                    <p>
                                        If you are facing any issues please feel free to give us a call.
                                    </p>
                                </div>
                                <div >
                                    <div
                                        className="flexEnd pt40 btn-static-div"
                                    >
                                        <button
                                            className="btn-transparent mr15"
                                            onClick={this.handleSaveAndExist}
                                        >
                                            Save and Exit
                                        </button>
                                        
                                    </div>
                                </div>

                            </Grid>
                        </Grid>
                    </div>
                </>
        );
    }
}

export default BackGroundCheckPending;
// Customizable Area End
