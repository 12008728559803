import { createRef } from 'react';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Uploader } from './DirectUpload';

const configJSON = require("../src/config");

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  index: number,
  label: string,
  allDocuments: any
  getAllDocuments: any
  link: any
  infolink: any
  infoTitle: any
  linkTitle: any
  // Customizable Area End
}
interface S {
  showProgress: boolean,
  progress: number,
  documentInfo: any,
  isOpen: boolean,
  docUpload: any,
  cbdUpload: any,
  tickNo: boolean,
  tickYes: boolean,
  validateYesorNo: any,
}

interface SS {
  id: any;
}

export default class FileUploadController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  dragRef: any
  apiDocumentUploadCallId: string = "";
  uploadFileUsingSignIdStatus: string = "";
  deleteMediaStatusId: string = "";
  updateDocumentStatusId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.dragRef = createRef()
    this.state = {
      showProgress: false,
      progress: 0,
      tickYes: false,
      tickNo: false,
      validateYesorNo: false,
      docUpload: "",
      cbdUpload: "",
      documentInfo: {
        file: null,
        uploadSuccessFlag: false,
        errorMessage: null,
        signed_id: '',
        fileName: '',
      },
      isOpen: false,
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  checkDocAttributes = (data: any, label: string) => {
    const { url, id, filename } = data
    let documentInfo = { ...this.state.documentInfo }
    documentInfo['file'] = url
    documentInfo['errorMessage'] = null;
    documentInfo['uploadSuccessFlag'] = true;
    documentInfo['signed_id'] = id
    documentInfo['fileName'] = filename
    this.setState({ documentInfo: { ...documentInfo } })
  }

  prepopulatedDocFields = () => {
    const { label, allDocuments } = this.props
    if (allDocuments.attributes.documents[label]) {
      this.checkDocAttributes(allDocuments.attributes.documents[label], label)
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    this.prepopulatedDocFields()
  }

  onDragFileEnter = () => {
    if (this.dragRef && this.dragRef.current) {
      this.dragRef.current.classList.add('dragover');
    }
  };

  removeDragOver = () => {
    if (this.dragRef && this.dragRef.current) {
      this.dragRef.current.classList.remove('dragover');
    }
  };

  handleCallback = (fileupload: any) => {
    this.setState({ showProgress: true, progress: fileupload.progress })
  }
  handleUpload = async (file: any) => {
    const attachmentRes = await file.start()
    return attachmentRes
  }
  updateView = (res: any, selectedFile: any) => {
    let documentUpload = { ...this.state.documentInfo }
    documentUpload['file'] = URL.createObjectURL(selectedFile)
    documentUpload['errorMessage'] = null;
    documentUpload['uploadSuccessFlag'] = true;
    documentUpload['signed_id'] = res.signed_id
    documentUpload['fileName'] = selectedFile.name
    this.setState({ showProgress: false, progress: 0, documentInfo: { ...documentUpload } })
    this.updateDocument(res.signed_id)
  }

  uploadFile = (events: any) => {
    const selectedFile = events.target.files[0];
    let documentUpload = { ...this.state.documentInfo }
    if (selectedFile && selectedFile.size <= 20000000) {
      const upload = new Uploader(selectedFile, { onChangeFile: this.handleCallback })
      if (upload) {
        this.handleUpload(upload).then((res) => {
          this.updateView(res, selectedFile)
        })
      }
    } else {
      documentUpload['file'] = null
      documentUpload['fileName'] = ""
      documentUpload['errorMessage'] = "File size is greater than 20 MB"
      this.setState({ documentInfo: { ...documentUpload } })
    }
  }

  updateDocument = (signId: string) => {
    const body = {
      data: {
        [this.props.label]: signId
      }
    }
    this.runEngineApiCallMethod(configJSON.DocumentUploadApiEndPoint, configJSON.PutMethodType, "updateDocument", body)
  }

  removeUnderscoreAndCapitalize = (string: string) => {
    if (string === "abn") {
      string = string.toUpperCase();
      return string
    } else {
      string = string.replace(/_/g, ' ');
      string = string.charAt(0).toUpperCase() + string.slice(1);
      return string;
    }
  }

  openPreviewModal = () => {
    this.setState({ isOpen: true })
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  }

  onOptionChange = (e:any) => {
    this.setState({docUpload: e.target.value})
  }

  onOptioncbdUpload = (e:any) => {
    this.setState({cbdUpload: e.target.value})
  }  

  handleOnPress = () => {
    this.setState({
      tickYes: true,
      tickNo: false,
      validateYesorNo: false
    });
  }

  handleOnPressNO = () => {
    this.setState({
      tickNo: true,
      validateYesorNo: false,
      tickYes: false
    });

  };

  runEngineApiCallMethod = (url: string, methodType: string, statusId: string, body: any) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if (statusId === 'deleteMedia') {
      this.deleteMediaStatusId = getValidationsMsg.messageId;
    }
    if (statusId === 'updateDocument') {
      this.updateDocumentStatusId = getValidationsMsg.messageId;
    }

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );

    

    if (body) {
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
    }

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (this.deleteMediaStatusId === apiRequestCallId) {
        let documentUpload = { ...this.state.documentInfo }
        documentUpload['file'] = null
        documentUpload['errorMessage'] = null;
        documentUpload['uploadSuccessFlag'] = false;
        documentUpload['signed_id'] = ""
        documentUpload['fileName'] = ""
        this.setState({ documentInfo: { ...documentUpload } })
        this.props.getAllDocuments()
      }
      if (this.updateDocumentStatusId === apiRequestCallId) {
        this.props.getAllDocuments()
      }

    }
    // Customizable Area End
  }

  deleteMedia = () => {
    let signed_id = this.state.documentInfo.signed_id
    let apiURI = `${configJSON.deleteDocument}/${signed_id}`
    this.runEngineApiCallMethod(apiURI, configJSON.DeleteMethodType, "deleteMedia", null)
  }
  // Customizable Area End
}