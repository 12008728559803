// Customizable Area Start
import React from "react";
import "./PaymentThanks.css";
import { closearrow, sucessrobot, warning } from "./assets";
import { Grid } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import BackGroundCheckPending from "./BackGroundCheckPending.web";
import PaymentFormController from "./PaymentFormController.web";

class PaymentThanks extends PaymentFormController {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <>
        {this.state.isBackgroundCheckPending ? (
          <BackGroundCheckPending
            navigation={this.props.navigation}
            id={""}
            history={undefined}
            handleChange={this.props.handleChange}
            handleNext={undefined}
            handleBack={this.props.handleBack}
            errorMessage={""}
          />
        ) : (
          <div>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item lg={8}>
                <div className="bgTitleheading">
                  <div className="mrTitle">
                    <img
                      src={closearrow}
                      onClick={() => this.props.handleBack()}
                      className="closeArrowStyle"
                    />
                  </div>
                  <h3 className="bgheading">Background Check</h3>
                </div>
                <div className="thanksgiving">
                 { this.props.errorMessage?<img src={warning} className="robot" />: <img src={sucessrobot} className="robot" />}
                 <h4>{this.props.errorMessage?this.props.errorMessage:"Thanks for your payment"}</h4>
                </div>
                <div>
                  <div className="flexEnd pt40 btn-static-div">
                    <button
                      className="btn-transparent mr15"
                      onClick={this.handleSaveAndExist}
                    >
                      Save and Exit
                    </button>
                    <button
                      className={
                        this.state.apiLoader
                          ? "btn-green da"
                          : "btn-green-full-opacity da"
                      }
                      disabled={this.state.apiLoader}
                      onClick={this.handleVettingApiCall}
                    >
                      Next
                      <span className="ml56">
                        <NavigateNextIcon />
                      </span>
                    </button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }
}

export default PaymentThanks;
// Customizable Area End
