
// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";


import moment from "moment";

export const requiredError = "This Field is Required";


export const configJSON = require("../../src/config");

export interface Props {
  navigation: any;
  id: string;
  location: any

}

interface S {
  formData: any
  errorObject: any
  value: any
  date: any
  years: any
  months: any
  validationsPassed: any
  openCalendar: boolean
  processingPopUp: boolean,
  sucessPopUp: boolean,
  signUrl: string,
  AppStatusLoader: boolean;
  contract_generated: string;
  showMessage: any;
  signingLoader: boolean;
  manager_signed: boolean;
  isShown: boolean;
  isShownSignature: boolean;
  message: string | null;
}

interface SS {
  id: any;

}

export default class ContractInformationController extends BlockComponent<
  Props,
  S,
  SS
> {


  saveAsDraftAPICallId: string = ""
  savedDetailsId: string = ""
  uploadContractId: string = ""
  getSignofficerId: string = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)

    ];

    this.state = {
      signUrl: "",
      AppStatusLoader: false,
      message: null,
      formData: {
        approvedRateType: "",
        commencementDate: "",
        approvedRateAmount: "",
        numberOfUniforms: "",
        fuelLevy: "",
        adminManual: "",
        changedriverIdtoHrID: false,
      },

      errorObject: {
        commencementDate: "",
        approvedRateType: "",
        approvedRateAmount: "",
        numberOfUniforms: "",
        fuelLevy: "",

      },
      value: "",
      years: [],
      months: [],
      date: new Date(),
      validationsPassed: false,
      openCalendar: false,
      processingPopUp: false,
      sucessPopUp: false,
      contract_generated: "",
      signingLoader: false,
      manager_signed: false,
      isShown: false,
      isShownSignature: false,
      showMessage: ""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }


  async componentDidMount() {
    this.getSavedDataForContract()
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.saveAsDraftAPICallId) {
        this.handleSaveAsDraftResponse(responseJson, errorReponse);
      }
      if (apiRequestCallId === this.savedDetailsId) {
        this.handleSavedResponse(responseJson, errorReponse);
      }
      if (apiRequestCallId === this.uploadContractId) {
        this.handleUploadContractResponse(responseJson, errorReponse);
      }

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (this.getSignofficerId === apiRequestCallId) {
        if (responseJson) {
          this.setState({ AppStatusLoader: false, signingLoader: false })
          window.open(responseJson.signing_url);
        } else {
          console.log(errorResponse);
        }
      }
    }
  }


  handleSaveAsDraftResponse(responseJson: any, errorReponse: any) {
    if (responseJson.errors || responseJson.error) {
      return console.log("note data show");
    }
    if (responseJson.data) {
      this.setState({ contract_generated: responseJson.data.id })
    }

  }

  handleSavedResponse(responseJson: any, errorReponse: any) {
    this.setState({ signingLoader: false })
    if (responseJson.errors || responseJson.error) {
      return;
    }
    if (responseJson.data.length === 0) {
      console.log(responseJson.data)
      this.setState({showMessage: "Please save details first"})
    } else if (responseJson.data) {
      const { attributes } = responseJson.data
      const dataObject =
      {
        approvedRateType: attributes.approved_rate_type,
        commencementDate: attributes.commencements_date,
        approvedRateAmount: attributes.approved_rate_amount,
        numberOfUniforms: attributes.number_of_uniforms,
        fuelLevy: attributes.fuel_levy,
        adminManual: attributes.admin_manual,
        changedriverIdtoHrID: Boolean(attributes.change_driver_to_hr_id)
      }
      this.setState({
        formData: dataObject,
        manager_signed: attributes.manager_signed,
        value: attributes.commencements_date,
        contract_generated: responseJson.data.id
      })

    }

  }

  handleUploadContractResponse(responseJson: any, errorReponse: any) {
    if (responseJson.errors || responseJson.error) {
      return;
    }
    if (responseJson.message) {
      console.log(responseJson.message)
    } else if (responseJson.data) {
      this.setState({ processingPopUp: false, sucessPopUp: true })
    }
  }

  getSavedDataForContract = () => {

    this.setState({ signingLoader: true })
    const { driverId = '' } = this.props.location.state || {}
    const endpoint = `bx_block_profile/driver_contract_details?driver_id=${driverId}`

    const header = {
      "Content-Type": "application/json",
      token: window.localStorage.getItem("token"),
    };

    const getSavedDetailsRequestMsgId = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.savedDetailsId = getSavedDetailsRequestMsgId.messageId;

    getSavedDetailsRequestMsgId.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    getSavedDetailsRequestMsgId.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getSavedDetailsRequestMsgId.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(getSavedDetailsRequestMsgId.id, getSavedDetailsRequestMsgId);

  }

  getSignOfficer = () => {
    this.setState({ signingLoader: true });
    const { driverId = '' } = this.props.location.state || {}
    const endpoint = `bx_block_profile/driver_contract_info/manager_contract_signing_url?driver_id=${driverId}`
    const header = {
      "Content-Type": "application/json",
      token: window.localStorage.getItem("token"),
    };

    const getSignOfficerDetails = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSignofficerId = getSignOfficerDetails.messageId;

    getSignOfficerDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    getSignOfficerDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getSignOfficerDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(getSignOfficerDetails.id, getSignOfficerDetails);

  }

  handleSaveDraft = () => {
    const { formData } = this.state;

    const errorObject: any = {};

    Object.keys(formData).forEach(key => {
      if (!formData[key]) {
        errorObject[key] = requiredError;
      }
    });

    this.setState({ errorObject }, () => {
      delete errorObject.changedriverIdtoHrID;
      if (Object.keys(errorObject).length <= 1) {
        const { formData } = this.state;

        this.setState({message: "Successfully added into draft"});
        setTimeout(() => {
          this.setState({ message: null });
        }, 5000);


        const formDataToSend = new FormData();
        const { driverId = '', name = '' } = this.props.location.state || {};


        formDataToSend.append("driver_contract_info[driver_id]", driverId)
        formDataToSend.append("driver_contract_info[approved_rate_type]", formData.approvedRateType)
        formDataToSend.append("driver_contract_info[approved_rate_amount]", formData.approvedRateAmount)
        formDataToSend.append("driver_contract_info[fuel_levy]", formData.fuelLevy)
        formDataToSend.append("driver_contract_info[admin_manual]", name)

        formDataToSend.append("driver_contract_info[change_driver_to_hr_id]", formData.changedriverIdtoHrID)
        formDataToSend.append("driver_contract_info[number_of_uniforms]", formData.numberOfUniforms)
        formDataToSend.append("driver_contract_info[commencements_date]", formData.commencementDate)

        const header = {
          token: window.localStorage.getItem("token"),
        };


        const getsaveAsDraftRequestMsgId = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.saveAsDraftAPICallId = getsaveAsDraftRequestMsgId.messageId;

        getsaveAsDraftRequestMsgId.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_profile/driver_contract_info`
        );

        getsaveAsDraftRequestMsgId.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        getsaveAsDraftRequestMsgId.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.postMethod
        );

        getsaveAsDraftRequestMsgId.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formDataToSend
        );

        runEngine.sendMessage(getsaveAsDraftRequestMsgId.id, getsaveAsDraftRequestMsgId);

        return true
      }
    });



  }

  handleAplicationsUploadButton = () => {
    const errorObject: any = {};
    const { formData } = this.state;
    Object.keys(formData).forEach(key => {
      if (!formData[key]) {
        errorObject[key] = requiredError;
      }
    });

    this.setState({ errorObject: errorObject }, () => {
      if (Object.keys(errorObject).length <= 1) {
        this.setState({ processingPopUp: true })

        const { formData } = this.state

        const formDataToSend = new FormData();
        const { driverId = '', name = '' } = this.props.location.state || {};

        formDataToSend.append("driver_contract_info[admin_manual]", name)
        formDataToSend.append("driver_contract_info[change_driver_to_hr_id]", formData.changedriverIdtoHrID)
        formDataToSend.append("driver_contract_info[number_of_uniforms]", formData.numberOfUniforms)
        formDataToSend.append("driver_contract_info[commencements_date]", formData.commencementDate)
        formDataToSend.append("driver_contract_info[driver_id]", driverId)
        formDataToSend.append("driver_contract_info[approved_rate_type]", formData.approvedRateType)
        formDataToSend.append("driver_contract_info[approved_rate_amount]", formData.approvedRateAmount)
        formDataToSend.append("driver_contract_info[fuel_levy]", formData.fuelLevy)


        const header = {
          token: window.localStorage.getItem("token"),
        };


        const getUploadRequestMsgId = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.uploadContractId = getUploadRequestMsgId.messageId;

        getUploadRequestMsgId.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_profile/upload_driver_contract`
        );

        getUploadRequestMsgId.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        getUploadRequestMsgId.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.postMethod
        );

        getUploadRequestMsgId.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formDataToSend
        );

        runEngine.sendMessage(getUploadRequestMsgId.id, getUploadRequestMsgId);
        return true

      }
    });

  }

  handleInputFormChange = (e: any) => {
    this.setState({
      errorObject: {
        ...this.state.errorObject,
        [e.target.name]: this.validationCheck(e.target.name, e.target.value),
      },
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });


  };
  handleInputChangeId = (e: any) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.checked,
      },
    });
    this.handleCalendarOpen(false)
  }

  validationCheck = (name: any, value: any) => {
    switch (name) {
      case "approvedRateType":
      case "approvedRateAmount":
      case "numberOfUniforms":
      case "fuelLevy":
        return this.validateForRequired(value)
      default: {
        return "";
      }
    }
  };

  validateForRequired = (value: any) => {
    if (!value || value === "") {
      return requiredError;
    } else {
      return "";
    }

  }

  onChange = (date: any) => {
    const selectedDate = moment(date).format("YYYY-MM-DD")
    this.setState({
      value: selectedDate, date: date,
      formData: { ...this.state.formData, commencementDate: selectedDate }
    });
    this.setState({
      errorObject: {
        ...this.state.errorObject,
        commencementDate: "",
      },

    });

    this.handleCalendarOpen(false)

  };

  handleCalendarOpen = (value: any) => {
    this.setState({ openCalendar: value })
  }
}

// Customizable Area End