import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const Colors = {
  inputLabel: "rgba(31, 31, 34, 0.6)",
  inputTextColor: "rgb(31, 31, 34)",
  borderGrey: "rgba(28, 28, 30, 0.3)",
  borderYellow: "rgb(205, 149, 12)",
  white: "#FFFFFF",
  modalBg: "rgb(243, 243, 243)",
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  handleChange: any;
  handleNext: any;
  handleBack: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  getSellerDetailsMessageId: any;
  token: string;
  validationCheck: boolean;
  questionList: any;
  videoUrl: any;
  showQuestionBank: boolean;
  isLoading: boolean;
  answerList: any;
  videoWatchedOrNot: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class BackgroundCheckController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getVideoUrlID: string = "";
  getQuetionsId: string = "";
  saveOptionsId: string = "";
  videosStatusId: string = "";
  videosStatusUpdateId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      getSellerDetailsMessageId: "",
      token: "",
      validationCheck: false,
      questionList: [],
      videoUrl: {},
      showQuestionBank: false,
      isLoading: false,
      answerList: [],
      videoWatchedOrNot: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId == this.getVideoUrlID) {
        if (responseJson?.data && responseJson?.data?.length) {
          let index = responseJson?.data?.length - 1;
          let vidUrl = responseJson.data[index];
          this.setState({
            videoUrl: vidUrl,
            isLoading: false,
          });
          this.getStatusOfVideo();
        }
      }

      this.handleRecieveFnCallIds(responseJson, apiRequestCallId);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getVideoUrl();
    this.getQuestionList();
  }
  handleRecieveFnCallIds = (responseJson: any, apiRequestCallId: any) => {
    if (apiRequestCallId == this.videosStatusId) {
      this.handleResponseOfVideoStatus(responseJson);
    }
    if (apiRequestCallId == this.videosStatusUpdateId) {
      this.handleUpdateResponseOfVideoStatus(responseJson);
    }
    if (apiRequestCallId == this.getQuetionsId) {
      this.handleQuestionListResponse(responseJson)
    }
    if (apiRequestCallId == this.saveOptionsId) {
      this.handleSaveResponse(responseJson)
    }
  };

  handleResponseOfVideoStatus = (responseJson: any) => {
    if (responseJson?.meta) {
      const status = responseJson?.meta?.message;
      this.setState({
        validationCheck: status,
        videoWatchedOrNot: status,
      });
    } else {
      alert("response not found");
    }
  };

  handleUpdateResponseOfVideoStatus = (responseJson: any) => {
    if (responseJson?.data && responseJson?.data?.length) {
      alert("video status updated")
    } else {
      console.log("not updated")
    }
  };

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  handleSubmitPersonalDetailSecondForm = () => {
    if (!this.state.videoWatchedOrNot) {
      this.handleUpdateStatus();
    }
    this.setState({
      showQuestionBank: true,
    });
  };
  getVideoUrl = () => {
    this.setState({
      isLoading: true,
    });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVideoUrlID = requestMessage.messageId;
    // Set Method Type
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetMethodType
    );
    // Set EndPoints
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getVideoUrlApiEndPoint
    );
    // set Headers
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getQuestionList = () => {
    this.setState({
      isLoading: true,
    });

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQuetionsId = requestMessage.messageId;
    // Set Method Type
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetMethodType
    );
    // Set EndPoints
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getQuestionListApiEndPoint
    );
    // set Headers
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleOption = (e: any, id: number, optionNumber: number) => {

    const { checked } = e.target;
    const updatedQuestionList = [...this.state.questionList];
    if (updatedQuestionList[id]) {
      updatedQuestionList[id].attributes.choices[optionNumber].checked = checked;
      updatedQuestionList[id].isAnswered = checked;
      updatedQuestionList[id].attributes.choices.forEach((option: any, index: any) => {
        if (index !== optionNumber) {
          option.checked = false;
        }
      });
    }

    let _answerList = updatedQuestionList?.map((item: any) => {
      let obj = { qbank_id: "", choices: [] };
      obj.qbank_id = item?.id;
      obj.choices = item?.attributes?.choices.map((option: any) => {
        let optionUpdated = {
          is_selected: option.checked,
          choice_id: option.id,
        };
        return optionUpdated;
      });

      return obj;
    });


    this.setState({
      questionList: updatedQuestionList,
      answerList: _answerList

    })

  };
  saveOptions = () => {
    if (this.validateOptions()) {
      this.handleSaveOptionsApiCall();
    }
  };
  handleQuestionListResponse(responseJson: any) {
    let questoinListupdated = responseJson?.data.map((question: any) => {
      question.isAnswered = true;
      return question;
    });

    this.setState({
      questionList: questoinListupdated,
      isLoading: false,
    });

    let answerList = responseJson?.data.map((item: any) => {
      let obj = { qbank_id: "", choices: [] };
      obj.qbank_id = item?.id;
      obj.choices = item?.attributes?.choices.map((option: any) => {
        let optionUpdated = {
          is_selected: option.checked,
          choice_id: option.id,
        };
        return optionUpdated;
      });

      return obj;
    });
    this.setState({
      answerList,
    });
  }
  validateOptions() {
    let validateQuestionList = this.state.questionList;
    let makeAPICall = true;
    this.state.answerList.map((answers: any, index: any) => {
      let flag = false;
      answers?.choices?.map((choice: any) => {
        if (choice.is_selected) {
          flag = true;
        }
      });
      if (!flag) {
        validateQuestionList[index].isAnswered = false;
        makeAPICall = false;
      }
    });
    this.setState({
      questionList: validateQuestionList,
    });
    return makeAPICall;
  }

  getStatusOfVideo = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };

    const endPoint = `${configJSON.videoStatus}?video_id=${this.state.videoUrl.id}`;

    const requestMessageForVideoStatus = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.videosStatusId = requestMessageForVideoStatus.messageId;

    requestMessageForVideoStatus.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetMethodType
    );
    // Set EndPoints
    requestMessageForVideoStatus.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    // set Headers
    requestMessageForVideoStatus.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    runEngine.sendMessage(
      requestMessageForVideoStatus.id,
      requestMessageForVideoStatus
    );
  };
  handleUpdateStatus = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestBody = {
      video_id: this.state.videoUrl?.id,
      watched_status: true,
    };

    const requestMessageForUpdateVideoStatus = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.videosStatusUpdateId = requestMessageForUpdateVideoStatus.messageId;

    requestMessageForUpdateVideoStatus.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostMethodType
    );

    requestMessageForUpdateVideoStatus.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.videoStatusUpdate
    );

    requestMessageForUpdateVideoStatus.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessageForUpdateVideoStatus.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(
      requestMessageForUpdateVideoStatus.id,
      requestMessageForUpdateVideoStatus
    );
  };
  handleSaveOptionsApiCall() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestBody = {
      responses: this.state.answerList,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveOptionsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveOptionsApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostMethodType
    );

    // pass request parametres
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleSaveResponse(responseJson: any) {
    if (responseJson.data) {
      this.props.handleNext();
    }
  }
  removeValidationCheck = () => {
    this.setState({ validationCheck: true })
  }
  // Customizable Area End
}
