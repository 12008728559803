import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const licensenumberRegex = /[A-Z]\d{4}-\d{4}-\d{4}/;
export const registerationRegex = /^[a-zA-Z0-9]+$/;
export const requiredErr = "This Field is Required";
// Customizable Area End

export const configJSON = require("./config");

interface S {
  // Customizable Area Start
  vehicleDetailForm: number;
  yesTextarea1: boolean;
  yesTextarea2: boolean;
  noTextarea1: boolean;
  noTextarea2: boolean;
  data: any;
  tickYes: boolean;
  tickNo: boolean;
  disqualifiedYes: boolean;
  disqualifiedNo: boolean;
  criminalOffenceYes: boolean;
  criminalOffenceNo: boolean;
  rentAVechile: any;
  vehicleDetailFormBtnResponse: any;
  errors: any;
  validationCheck: boolean;
  selectedDate: any;
  vehcileList: any;
  callType: any;
  createdId: any;
  validateYesorNo: any
  // Customizable Area End
}
export interface Props {
  navigation: any;
  id: string;
  history: any;
  // Customizable Area Start
  handleChange: any;
  handleNext: any;
  handleBack: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

class VehicleformController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  vehicleDetailsApiCallId: any;
  rentAVechile: any;
  vechileListId: any;
  vechileDetailsUpdateApiId: any;
  profileDetailsVehiclesId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      vehicleDetailForm: 0,
      yesTextarea1: false,
      yesTextarea2: false,
      noTextarea1: false,
      noTextarea2: false,
      tickYes: false,
      tickNo: false,
      disqualifiedYes: false,
      disqualifiedNo: false,
      criminalOffenceYes: false,
      criminalOffenceNo: false,
      rentAVechile: [],
      selectedDate: new Date("2014-08-18T21:11:54"),
      data: {
        driver_licence_number: "",
        expiry_date: "",
        registration_number: "",
        registration_expiry_date: "",
        vehicle_model: "",
        vehicle_type: "",
        driving_disquilified: null,
        driving_disquilified_description: "",
        criminal_offence: null,
        criminal_offence_description: "",
      },
      createdId: "",
      errors: {
        driver_licence_number: "",
        expiry_date: "",
        registration_number: "",
        registration_expiry_date: "",
        vehicle_model: "",
        vehicle_type: "",
        driving_disquilified: "",
        criminal_offence: "",
      },
      vehicleDetailFormBtnResponse: "",
      validationCheck: false,
      vehcileList: [
        {
          id: "16",
          type: "vehicle_list",
          attributes: {
            vehicle_type: "type-1",
          },
        },
        {
          id: "1",
          type: "vehicle_list",
          attributes: {
            vehicle_type: "hi",
          },
        },
        {
          id: "25",
          type: "vehicle_list",
          attributes: {
            vehicle_type: "gfg",
          },
        },
        {
          id: "26",
          type: "vehicle_list",
          attributes: {
            vehicle_type: "test",
          },
        },
      ],
      callType: "post",
      validateYesorNo: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.vehicleDetailsApiCallId) {
        this.handleVehicleSubmitFormResponse(responseJson, errorReponse);
      }
      if (apiRequestCallId === this.rentAVechile) {
        this.RentAVechileRes(responseJson, errorReponse);
      }
      if (apiRequestCallId === this.vechileListId) {
        this.handleVehcileListResponse(responseJson);
      }
      if (this.vechileDetailsUpdateApiId == apiRequestCallId) {
        this.handleUpdateApiResponse(responseJson, errorReponse);
      }
      if (this.profileDetailsVehiclesId == apiRequestCallId) {
        this.handleProfileDetailsResponse(responseJson, errorReponse);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.getprofileDataForVehicles();
    this.handlerentAVechile();
    this.handleVehcileList();
  }
  handleDateChange = (date: Date | null) => {
    this.setState({
      selectedDate: date,
    });
  };
  handleOnPress = () => {
    const { data } = this.state
    const ownVehicleData = {
      driver_licence_number: data.driver_licence_number,
      expiry_date: data.expiry_date,
      registration_number: data.registration_number,
      registration_expiry_date: data.registration_expiry_date,
      vehicle_model: data.vehicle_model,
      vehicle_type: data.vehicle_type,
      driving_disquilified: data.driving_disquilified,
      driving_disquilified_description: data.driving_disquilified_description,
      criminal_offence: data.criminal_offence,
      criminal_offence_description: data.criminal_offence_description,
    };

    this.setState({
      vehicleDetailForm: 1,
      tickYes: true,
      tickNo: false,
      validateYesorNo: false,
      data: ownVehicleData
    });
  };
  handleOnPressNO = () => {
    const { data } = this.state

    const rentVehicleData = {
      driver_licence_number: data.driver_licence_number,
      vehicle_type: data.vehicle_type,
      driving_disquilified: data.driving_disquilified,
      driving_disquilified_description: data.driving_disquilified_description,
      criminal_offence: data.criminal_offence,
      criminal_offence_description: data.criminal_offence_description,
    };

    this.setState({
      vehicleDetailForm: 5,
      tickNo: true,
      tickYes: false,
      validationCheck: true,
      validateYesorNo: false,
      data: rentVehicleData

    });

  };
  RentAVechileRes(responseJson: any, errorReponse: any) {
    if (responseJson) {
      this.setState({ rentAVechile: [...responseJson.data] });
    } else alert(errorReponse);
  }
  handlerentAVechile = () => {
    const header = {
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.rentAVechile = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/vehicle_on_rents`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  handleInputchange = (event: any) => {
   
    this.setState({
      errors: {
        ...this.state.errors,
        [event.target.name]: this.validate(event.target.name, event.target.value),
      },
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });

    let error = this.validate(event.target.name, event.target.value)
    if (error.length === 0) {
      this.setState({ validationCheck: true });
    }
    else {
      this.setState({ validationCheck: false });
    }
    
  };
  validate = (name: any, value: any) => {
    switch (name) {
      case "driver_licence_number": {
        return this.validateLicencenumber(value);
      }

      case "expiry_date": {
        return this.validateExpiryDate(value);
      }

      case "registration_number": {
        return this.validateRegistrationNumber(value);
      }

      case "registration_expiry_date": {
        return this.registrationExpiryDate(value);
      }

      case "vehicle_model": {
        return this.makeAndModel(value);
      }

      case "vehicle_type": {
        return this.validateVehcileType(value);
      }
      case "second_liscence_number": {
        return this.validateLicencenumber(value);
      }
      case "second_vehicle_type": {
        return this.validateSecondvehcileType(value);
      }
      case "criminal_offence": {
        return this.validateDefaultYesNo(value);
      }
      case "driving_disquilified": {
        return this.validateDefaultYesNo(value);
      }
      default: {
        return "";
      }
    }
  };
  handleSubmitVehicleDetailForm = (btnResponse: any, formId: any) => {
    this.setState({ vehicleDetailFormBtnResponse: btnResponse });

    if (btnResponse == "save") {
      this.handleSaveAndExitBtn();
    } else {
      this.handleNextBtn(formId);
    }
  };
  validateLicencenumber = (value: any) => {
    if (this.state.vehicleDetailForm != 5) {
      if (!value || value === "") {
        return requiredErr;
      } else if (!value) {
        return "Enter a valid license number.";
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
  validateVehcileType(value: any) {
    if (this.state.vehicleDetailForm != 5) {
      {
        if (!value || value === "") {
          return "This Field is Required";
        } else {
          return "";
        }
      }
    } else {
      return "";
    }
  }
  validateRegistrationNumber = (value: any) => {
    if (this.state.vehicleDetailForm != 5) {
      if (!value || value === "") {
        return requiredErr;
      } else if (!value.match(registerationRegex)) {
        return "Enter a valid registration number.";
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
  makeAndModel = (value: any) => {
    if (this.state.vehicleDetailForm != 5) {
      if (!value || value === "") {
        return requiredErr;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  validateExpiryDate(value: any) {
    const dig = /^\d{4}$/
    if (this.state.vehicleDetailForm != 5) {
      const year = value?.split("-");
      if (!value || value === "") {
        return "This Field is Required";
      } else if (!year[0].match(dig)) {
        return "Year must be four digits";
      }
      else {
        return ""
      }
    } else {
      return "";
    }
  }
  registrationExpiryDate(value: any) {
    const dig = /^\d{4}$/
    if (this.state.vehicleDetailForm != 5) {
      const year = value?.split("-");
      if (!value || value === "") {
        return "This Field is Required";
      }
      else if (!year[0].match(dig)) {
        return "Year must be four digits";
      }
      else {
        return "";
      }
    } else {
      return "";
    }
  }
  validateSecondvehcileType(value: any) {
    if (!value || value === "") {
      return "This Field is Required";
    } else {
      return "";
    }
  }

  validateDefaultYesNo(value: any) {
    if (this.state.vehicleDetailForm !== 5) {
      if (value === null) {
        return "This Field is Required";
      } else {
        return "";
      }
    } else {
      return ""
    }


  }
  handleVehcileList = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.vechileListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.vechileListApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleUpdateFirstForm = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    let requestBody = {};
    if (this.state.tickNo) {
      requestBody = {
        data: {
          own_vehicle: false,
          vehicle_id: this.state.data.vehicle_type ? Number(this.state.data.vehicle_type) : this.state.data.vehicle_type,
          driver_licence_number: this.state.data.driver_licence_number,
          driving_disquilified: this.state.data.driving_disquilified,
          driving_disquilified_description: this.state.data.driving_disquilified_description,
          criminal_offence: this.state.data.criminal_offence,
          criminal_offence_description: this.state.data.criminal_offence_description,
        },
      };

    } else {
      requestBody = {
        data: {
          own_vehicle: true,
          vehicle_id: Number(this.state.data.vehicle_type),
          vehicle_model: this.state.data.vehicle_model,
          driver_licence_number: this.state.data.driver_licence_number,
          expiry_date: this.state.data.expiry_date,
          registration_number: this.state.data.registration_number,
          registration_expiry_date: this.state.data.registration_expiry_date,
          driving_disquilified: this.state.data.driving_disquilified,
          driving_disquilified_description: this.state.data.driving_disquilified_description,
          criminal_offence: this.state.data.criminal_offence,
          criminal_offence_description: this.state.data.criminal_offence_description,
        },
      };
    }

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.vechileDetailsUpdateApiId = getValidationsMsg.messageId;

    let usercreatedId = this.state.createdId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.VehcileDetailsApiEndPoint + "/" + usercreatedId
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PutMethodType
    );

    // pass request parametres
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    return true;
  };
  handlePostApiCall = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    let requestBody = {}

    if (this.state.tickNo) {

      requestBody = {
        data: {
          own_vehicle: false,
          vehicle_id: this.state.data.vehicle_type ? Number(this.state.data.vehicle_type) : this.state.data.vehicle_type,
          driver_licence_number: this.state.data.driver_licence_number,
          driving_disquilified: this.state.data.driving_disquilified,
          driving_disquilified_description: this.state.data
            .driving_disquilified_description,
          criminal_offence: this.state.data.criminal_offence,
          criminal_offence_description: this.state.data
            .criminal_offence_description,
        },
      };

    } else {
      requestBody = {
        data: {
          own_vehicle: true,
          vehicle_id: Number(this.state.data.vehicle_type),

          vehicle_model: this.state.data.vehicle_model,
          driver_licence_number: this.state.data.driver_licence_number,
          expiry_date: this.state.data.expiry_date,
          registration_number: this.state.data.registration_number,
          registration_expiry_date: this.state.data.registration_expiry_date,

          driving_disquilified: this.state.data.driving_disquilified,
          driving_disquilified_description: this.state.data
            .driving_disquilified_description,
          criminal_offence: this.state.data.criminal_offence,
          criminal_offence_description: this.state.data
            .criminal_offence_description,
        },
      };

    }


    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.vehicleDetailsApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.VehcileDetailsApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostMethodType
    );

    // pass request parametres
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    return true;
  };



  getprofileDataForVehicles = () => {
    const headers = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    let userIdInVehicle = localStorage.getItem("userId");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.profileDetailsVehiclesId = requestMessage.messageId;
    // Set Method Type
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetMethodType
    );
    // set Headers
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );

    // Set EndPoints
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileDataApiEndPoint + userIdInVehicle
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleVehicleSubmitFormResponse(responseJson: any, errorReponse: any) {
    const { vehicleDetailForm } = this.state;
    const { handleChange, navigation } = this.props;
    if (responseJson.errors || responseJson.error) {
      return;
    }
    if (this.state.vehicleDetailFormBtnResponse === "next") {
      if (vehicleDetailForm === 1) {
        this.setState({ vehicleDetailForm: 2 });
      } else if (vehicleDetailForm === 5) {
        this.setState({
          vehicleDetailForm: 6,
          createdId: responseJson.data?.id,
          callType: 'put'
        })
      }
      else if (vehicleDetailForm === 2 || vehicleDetailForm === 6) {
        handleChange();
      }
    } else if (this.state.vehicleDetailFormBtnResponse === "save") {
      navigation.navigate("Dashboard");
    }
  }
  handleUpdateApiResponse(responseJson: any, errorReponse: any) {
    if (!responseJson.errors && !responseJson.error) {
      const { vehicleDetailForm } = this.state;
      const { handleChange, navigation } = this.props;
      if (this.state.vehicleDetailFormBtnResponse === "next") {
        if (vehicleDetailForm === 1) {
          this.setState({ vehicleDetailForm: 2 });
        } else if (vehicleDetailForm === 5) {
          this.setState({ vehicleDetailForm: 6 })
        }
        else if (vehicleDetailForm === 2 || vehicleDetailForm === 6) {
          handleChange();
        }
      } else if (this.state.vehicleDetailFormBtnResponse === "save") {
        navigation.navigate("Dashboard");
      }
    } else {
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  handleProfileDetailsResponse(responseJson: any, errorReponse: any) {
    if (!responseJson.errors && !responseJson.error) {
      const typeForAPICall =
        responseJson.data.attributes.vehicle_details.data === null
          ? "post"
          : "put";
      this.setState({
        callType: typeForAPICall,
      });
      if (typeForAPICall == "put") {
        this.setState({
          validationCheck: true,
        });
      }
      if (responseJson.data.attributes.vehicle_details.data !== null) {
        this.updateFormData(responseJson);
      }
    } else {
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  updateFormData(responseJson: any) {

    const statusOfYesCheckBox = responseJson.data.attributes.vehicle_details?.data?.attributes?.own_vehicle
    const statusOfNoCheckBox = !statusOfYesCheckBox

    const driverLicence =
      responseJson.data.attributes.vehicle_details?.data?.attributes
        ?.driver_licence_number;
    const expDate =
      responseJson.data.attributes.vehicle_details?.data?.attributes
        ?.expiry_date;
    const regNumber =
      responseJson.data.attributes.vehicle_details?.data?.attributes
        ?.registration_number;
    const regExpNUmber =
      responseJson.data.attributes.vehicle_details?.data?.attributes
        ?.registration_expiry_date;
    const vehicleModel =
      responseJson.data.attributes.vehicle_details?.data?.attributes
        ?.vehicle_model;
    const vehicleType =
      responseJson.data.attributes.vehicle_details?.data?.attributes
        ?.vehicle_id;
    const driverDisqualified =
      responseJson.data.attributes.vehicle_details?.data?.attributes
        ?.driving_disquilified;
    const driverDisqualifiedDes =
      responseJson.data.attributes.vehicle_details?.data?.attributes
        ?.driving_disquilified_description;
    const criminalOffence =
      responseJson.data.attributes.vehicle_details?.data?.attributes
        ?.criminal_offence;
    const criminalOffenceDes =
      responseJson.data.attributes.vehicle_details?.data?.attributes
        ?.criminal_offence_description;

    if (statusOfYesCheckBox) {
      const ownVehicleData = {
        driver_licence_number: driverLicence,
        expiry_date: expDate,
        registration_number: regNumber,
        registration_expiry_date: regExpNUmber,
        vehicle_model: vehicleModel,
        vehicle_type: vehicleType,
        driving_disquilified: driverDisqualified,
        driving_disquilified_description: driverDisqualifiedDes,
        criminal_offence: criminalOffence,
        criminal_offence_description: criminalOffenceDes,
      };


      this.setState({
        data: ownVehicleData,
        createdId: responseJson.data.attributes.vehicle_details?.data?.id,
        tickYes: statusOfYesCheckBox,
        tickNo: statusOfNoCheckBox,
        disqualifiedYes: driverDisqualified,
        disqualifiedNo: driverDisqualified === null ? driverDisqualified : !driverDisqualified,
        criminalOffenceYes: criminalOffence,
        criminalOffenceNo: criminalOffence === null ? criminalOffence : !criminalOffence,
        vehicleDetailForm: 1,
      });
    }
    else {
      const rentVehicleData = {
        driver_licence_number: driverLicence,
        vehicle_type: vehicleType,

        driving_disquilified: driverDisqualified,
        driving_disquilified_description: driverDisqualifiedDes,
        criminal_offence: criminalOffence,
        criminal_offence_description: criminalOffenceDes,
      };
      this.setState({
        data: rentVehicleData,
        vehicleDetailForm: 5,
        createdId: responseJson.data.attributes.vehicle_details?.data?.id,
        tickYes: statusOfYesCheckBox,
        tickNo: statusOfNoCheckBox,
        disqualifiedYes: driverDisqualified,
        disqualifiedNo: driverDisqualified === null ? driverDisqualified : !driverDisqualified,
        criminalOffenceYes: criminalOffence,
        criminalOffenceNo: criminalOffence === null ? criminalOffence : !criminalOffence,
      });
    }
  }
  handleSaveAndExitBtn() {

    if (this.state.tickYes || this.state.tickNo) {
      this.setState({ validateYesorNo: false })
    }
    else {
      this.setState({ validateYesorNo: true })
      return;
    }

    if (this.state.callType == "post") {
      this.handlePostApiCall();
    } else if (this.state.callType == "put") {
      this.handleUpdateFirstForm();
    }
  }

  handleNextBtn(formId: any) {
    const { data } = this.state;

    const dataYes = {
      driver_licence_number: data.driver_licence_number,
      expiry_date: data.expiry_date,
      registration_expiry_date: data.registration_expiry_date,
      registration_number: data.registration_number,
      vehicle_model: data.vehicle_model,
      vehicle_type: data.vehicle_type
    }

    let testData;

    if (this.state.vehicleDetailForm == 1) {
      testData = dataYes
    } else {
      testData = data
    }

    let validationErrors: any = {};
    for (const [name, value] of Object.entries(testData)) {
      const error = this.validate(name, value);
      if (error && error.length > 0) {
        // @ts-ignore
        validationErrors[name] = error;
      }
    }
    if (Object.keys(validationErrors).length > 0) {
      this.setState({ errors: validationErrors });
      return;
    }

    if (this.state.callType === "post") {
      this.handlePostApiCall();
    } else if (this.state.callType === "put") {
      this.handleUpdateFirstForm();
    }

  }

  handleVehcileListResponse(responseJson: any) {
    if (!responseJson.errors && !responseJson.error) {
      this.setState({
        vehcileList: responseJson.data,
      });
    }
  }

  onClickBackButton = () => {
    if (this.state.vehicleDetailForm === 6 || this.state.vehicleDetailForm === 2) {
      if (this.state.tickYes) {
        this.setState({ vehicleDetailForm: 1 })
      }
      else {
        this.setState({ vehicleDetailForm: 5 })
      }

    } else {
      this.props.handleBack()
    }

  }
  // Customizable Area End
}
export default VehicleformController;
