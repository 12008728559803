// Customizable Area Start
import React from 'react';
import "./css/contractInformation.css";
import ContractInformationController, { Props } from '../Controller/ContractInformationController.web';
import { BackArrowIc, warning, CalendarIcon, Processing, sucess } from '../assets';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Dialog from '@material-ui/core/Dialog';
class ContractInformation extends ContractInformationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderForm = () => {
    const { formData, errorObject } = this.state
    const { state } = this.props.location
    return (
      <div className='formSection'>
        <div className='inputContainer posRelative'>
          <div >
            <label className="labelStyle" htmlFor="commencementDate">
              Commencement date
            </label>
            <span className="colorForError">*</span>
          </div>
          <div className="calendarContainer" onClick={() => this.handleCalendarOpen(true)}>
            <img
              src={CalendarIcon}
              style={{ position: "absolute", top: "1em", right: 0, zIndex: 0 }}
            />
           
            <input
              type="date"
              name="commencementDate"
              id="commencementDate"
              data-testId="commencementDate"
              placeholder="DD/MM/YYYY"
              value={this.state.value}
              onChange={(event) => this.handleInputFormChange(event)}
              readOnly
            />
          </div>
          {this.state.openCalendar && <div id="contractInfoCalendar"><Calendar onChange={this.onChange} value={this.state.date} /></div>}
          {
            errorObject.commencementDate && (
              <div className="errorBlock">
                <img src={warning} alt="" />
                <p className="errorText m10">{errorObject.commencementDate}</p>
              </div>
            )
          }
        </div >

        {/* inputs  approvedRateType */}
        < div className='inputContainer' >
          <div >
            <label className="labelStyle" htmlFor="approvedRateType">
              Approved rate type
            </label>
            <span className="colorForError">*</span>
          </div>
          <input
            className='inputField'
            type="text"
            id="approvedRateType"
            placeholder="Type here..."
            name="approvedRateType"
            data-testId="approvedRateType"
            autoComplete="off"
            value={formData.approvedRateType}
            onChange={event => this.handleInputFormChange(event)}
          />
          {
            errorObject.approvedRateType && (
              <div className="errorBlock">
                <img src={warning} alt="" />
                <p className="errorText m10">{errorObject.approvedRateType}</p>
              </div>
            )
          }
        </div >

        {/* inputs  approvedRateAmount  */}
        < div className='inputContainer' >
          <div >
            <label className="labelStyle" htmlFor="approvedRateAmount">
              Approved rate amount
            </label>
            <span className="colorForError">*</span>
          </div>
          <input
            className='inputField'
            type="number"
            id="approvedRateAmount"
            placeholder="Type here..."
            name="approvedRateAmount"
            data-testId="approvedRateAmount"
            autoComplete="off"
            value={formData.approvedRateAmount}
            onChange={event => this.handleInputFormChange(event)}
          />
          {
            errorObject.approvedRateAmount && (
              <div className="errorBlock">
                <img src={warning} alt="" />
                <p className="errorText m10">{errorObject.approvedRateAmount}</p>
              </div>
            )
          }
        </div >

        {/* inputs Number of uniforms **/}
        < div className='inputContainer' >
          <div >
            <label className="labelStyle" htmlFor="numberOfUniforms">
              Number of uniforms
            </label>
            <span className="colorForError">*</span>
          </div>
          <input
            className='inputField'
            type="number"
            id="numberOfUniforms"
            data-testId="numberOfUniforms"
            placeholder="Type here..."
            name="numberOfUniforms"
            autoComplete="off"
            value={formData.numberOfUniforms}
            onChange={event => this.handleInputFormChange(event)}
          />
          {
            errorObject.numberOfUniforms && (
              <div className="errorBlock">
                <img src={warning} alt="" />
                <p className="errorText m10">{errorObject.numberOfUniforms}</p>
              </div>
            )
          }
        </div >



        {/* inputs  for fuel */}
        < div className='inputContainer' >
          <div >
            <label className="labelStyle" htmlFor="fuelLevy">
              Fuel levy
            </label>
            <span className="colorForError">*</span>
          </div>
          <input
            className='inputField'
            type="text"
            id="fuelLevy"
            placeholder="Type here..."
            name="fuelLevy"
            data-testId="fuelLevy"
            autoComplete="off"
            value={formData.fuelLevy}
            onChange={event => this.handleInputFormChange(event)}
          />
          {
            errorObject.fuelLevy && (
              <div className="errorBlock">
                <img src={warning} alt="" />
                <p className="errorText m10">{errorObject.fuelLevy}</p>
              </div>
            )
          }
        </div >

        {/* inputs   for admin*/}
        < div className='inputContainer' >
          <div >
            <label className="labelStyle" htmlFor="adminManual">
              Please select the relevant Admin Manual <span className="colorForError">*</span>
            </label>
            <div className='adminManualInputContainer'>
              <div className='adminManualInputContainerInner'>
                <input
                  id="first"
                  type="checkbox"
                  name=""
                  autoComplete="off"
                  checked={state?.name === "Code Contract"}
                  disabled
                />
                <label htmlFor="first" className='m10'>Code Admin Manual</label>
              </div>
              <div className='adminManualInputContainerInner'>
                <input
                  type="checkbox"
                  id="second"
                  name=""
                  checked={state?.name === "CBD Contract"}
                  disabled
                />

                <label htmlFor="second" className='m10'>CBD Admin Manual</label>
              </div>
              <div className='adminManualInputContainerInner'>
                <input
                  type="checkbox"
                  id="third"
                  name=""
                  checked={state?.name === "CBD Express Contract"}
                  disabled
                />
                <label htmlFor="third" className='m10'>CBD Express Admin Manual</label>
              </div>
            </div>
          </div >
        </div >


        {/* inputs  driver  */}
        < div className='inputContainer' >
          <div className='changedriverIdtoHrIDContainer'>
            <input
              type="checkbox"
              id="changedriverIdtoHrID"
              name="changedriverIdtoHrID"
              data-testId="changedriverIdtoHrID"
              checked={formData.changedriverIdtoHrID}
              onChange={event => this.handleInputChangeId(event)}
            />
            <label className="m10" htmlFor="changedriverIdtoHrID">
              Change Driver ID to HR Driver ID (Customer Car driver)
            </label>
          </div>
          <div
            className={this.state.contract_generated.length > 0 ? "signOfficer" : "signOfficer_disable"}
          >
            {this.state.signingLoader ?
              <button
                className={"signOfficer_disable"}
                disabled={true}
              >
                Loading......
              </button>

              : 
              
              <div className='showOuter'>
              <button
                className={this.state.contract_generated.length > 0 ? "signOfficer" : "signOfficer_disable"}
                disabled={this.state.contract_generated.length === 0}
                onClick={this.getSignOfficer}>
                Signature of officer
                </button>
                <div className='showMessage'>
                      Please save details first
                    </div>
                </div>
              }

          </div>
        </div >
      </div >)
  }

  render() {
    const { message } = this.state;
    return (
      <div>
        <div className='topSection'>
          <div className='navigationSection'>
            <img
              onClick={() => window.history.back()}
              src={BackArrowIc}
              height={24}
              width={24}
              alt=""
            />
            <h3 className='titleofthePage'>Contract Information</h3>
            <p className="success-message">{message}</p>
          </div>
          <div className='buttonContianers'>
            <button className='saveDraftButton' onClick={this.handleSaveDraft} data-testId="saveAsDraftButton">Save as draft</button>
            {this.state.manager_signed === true ? <button className="uploadContractButton"
              onClick={this.handleAplicationsUploadButton} data-testId="uploadButton">
              Upload Contract
            </button>:
            <div className="buttonTooltip tooltipNew" data-testId="uploadButton">
            Upload Contract <span className="tooltiptext">Please first add signature.</span>
          </div>
          } 
          </div>
        </div>
        {this.renderForm()}
        <Dialog

          open={this.state.processingPopUp}
          onClose={() => { this.setState({ processingPopUp: false, sucessPopUp: true }) }}
        >
          <div className='processingUploadScreen' style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "23em" }}>
            <div>
              Processing
            </div>
            <div>
              <img src={Processing} />
            </div>

          </div>

        </Dialog>
        <Dialog
          open={this.state.sucessPopUp}
          onClose={() => {
            this.setState({ sucessPopUp: false });
            window.history.back()
          }}
        >
          <div className='contractUploadScreen' style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "23em" }}>
            <div>
              Contract Uploaded
            </div>
            <div>
              <img src={sucess} />
            </div>

          </div>
        </Dialog>



      </div>
    );
  }
}

export default ContractInformation;
// Customizable Area End