"use strict";


const baseURL = "__MARKER_FOR_BACKEND_URL_REPLACEMENT";
// const baseURL = "https://driveronboardingsystem-266335-ruby.b266335.dev.eastus.az.svc.builder.cafe";
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.baseURL = baseURL; 
