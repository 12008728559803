// Customizable Area Start
import React from "react";
import CustomformController, { Props } from "./CustomformController";
import { Container, Grid } from "@material-ui/core";
import "./PersonalDetails.css";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { australiaflag, warning, closearrow } from "./assets";
import PersonalDetailsSecondFormWeb from "./PersonalDetailsSecondForm.web";
import { Redirect } from "react-router-dom";



export default class PersonalDetails extends CustomformController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderDetails() {
    const { data, errors, stateList } = this.state
    return (<Grid className="scrollContent">
      <Grid>
        <p className="mt0 mb10 formHeading">Personal Details : </p>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <div>
              <label className="labelHeading" htmlFor="fname">
                First Name
              </label>
              <span className="redSign">*</span>
            </div>
            <input
              type="text"
              id="fname"
              placeholder="Type here..."
              name="first_name"
              data-testid="firstNameInput"
              autoComplete="off"
              value={data.first_name}
              onChange={event => this.handleInputChange(event)}
            />
            {errors.first_name && (
              <div className="da">
                <img src={warning} alt="" />
                <p className="err ml10">{errors.first_name}</p>
              </div>
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            <div>
              <label className="labelHeading" htmlFor="sname">
                Surname
              </label>
              <span className="redSign">*</span>
            </div>
            <input
              type="text"
              id="sname"
              placeholder="Type here..."
              data-testid="lastNameInput"
              name="last_name"
              autoComplete="off"
              value={data.last_name}
              onChange={event => this.handleInputChange(event)}
            />
            {errors.last_name && (
              <div className="da">
                <img src={warning} alt="" />
                <p className="err ml10">{errors.last_name}</p>
              </div>
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            <div>
              <label className="labelHeading" htmlFor="gender">
                Gender
              </label>
              <span className="redSign">*</span>
            </div>
            <select
              name="gender"
              id="gender"
              autoComplete="off"
              data-testid="genderInput"
              value={data.gender}
              onChange={event => this.handleInputChange(event)}
            >
              <option value="" disabled selected>
                Select
              </option>
              <option value="1">Male</option>
              <option value="2">Female</option>
              <option value="3">Others</option>
            </select>
            {errors.gender && (
              <div className="da">
                <img src={warning} alt="" />
                <p className="err ml10">{errors.gender}</p>
              </div>
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            <div>
              <label className="labelHeading" htmlFor="dob">
                Date of birth
              </label>
              <span className="redSign">*</span>
            </div>
            <div className="date">
              <input
                id="dob"
                type="date"
                name="date_of_birth"
                data-testid="dateInput"
                placeholder="DD/MM/YYYY"
                max={this.state.maxYear}
                min={this.state.minYear}
                autoComplete="off"
                value={data.date_of_birth}
                onChange={event => this.handleInputChange(event)}
              />
            </div>
            {errors.date_of_birth && (
              <div className="da">
                <img src={warning} alt="" />
                <p className="err ml10">{errors.date_of_birth}</p>
              </div>
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            <div>
              <label className="labelHeading" htmlFor="address">
                Address
              </label>
              <sup>*</sup>
            </div>
            <input
              type="text"
              id="address"
              data-testid="address"
              placeholder="Type here..."
              name="address"
              autoComplete="off"
              value={data.address}
              onChange={event => this.handleInputChange(event)}
            />
            {errors.address && (
              <div className="da">
                <img src={warning} alt="" />
                <p className="err ml10">{errors.address}</p>
              </div>
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            <div>
              <label className="labelHeading" htmlFor="suburb">
                Suburb
              </label>
              <span className="redSign">*</span>
            </div>
            <input
              type="text"
              id="suburb"
              placeholder="Type here..."
              data-testid="suburb"
              name="suburb"
              autoComplete="off"
              value={data.suburb}
              onChange={event => this.handleInputChange(event)}
            />
            {errors.suburb && (
              <div className="da">
                <img src={warning} alt="" />
                <p className="err ml10">{errors.suburb}</p>
              </div>
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            <div>
              <label className="labelHeading" htmlFor="state">
                State
              </label>
              <span className="redSign">*</span>
            </div>
            <select style={{ marginTop: "0", height: "48px" }}
              name="state"
              id="state"
              data-testid="state"
              autoComplete="off"
              value={data.state}
              onChange={event => this.handleInputChange(event)}
            >
              <option value="" disabled selected>
                Select
              </option>
              {stateList.map((elem: any) => {
                return (
                  <option key={elem.id} value={elem.id}>
                    {elem.attributes.state_name}
                  </option>
                );
              })}
            </select>
            {errors.state && (
              <div className="da">
                <img src={warning} alt="" />
                <p className="err ml10">{errors.state}</p>
              </div>
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            <div>
              <label className="labelHeading" htmlFor="postcode">
                Postcode
              </label>
              <span className="redSign">*</span>
            </div>
            <input
              type="number"
              id="postcode"
              placeholder="Type here..."
              data-testid="postcode"
              name="postcode"
              autoComplete="off"
              value={data.postcode}
              onChange={event => this.handleInputChange(event)}
            />
            {errors.postcode && (
              <div className="da">
                <img src={warning} alt="" />
                <p className="err ml10">{errors.postcode}</p>
              </div>
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            <div>
              <label className="labelHeading" htmlFor="mobile">
                Aus Mobile number
              </label>
              <span className="redSign">*</span>
            </div>
            <section className="dFlex">
              <div className="aus-mob">
                <img
                  src={australiaflag}
                  alt=""
                  className="australiaflag"
                />
              </div>
              <input
                type="number"
                id="mobile"
                placeholder="04xx xxx xxx"
                name="full_phone_number"
                data-testid="mobile"
                autoComplete="off"
                value={data.full_phone_number}
                onChange={event => this.handleInputChange(event)}
              />
            </section>
            {errors.full_phone_number && (
              <div className="da">
                <img src={warning} alt="" />
                <p className="err ml10">
                  {errors.full_phone_number}
                </p>
              </div>
            )}
          </Grid>
          <Grid item sm={6} xs={12} className="emailId">
            <div>
              <label className="labelHeading" htmlFor="email">
                Email
              </label>
              <sup>*</sup>
            </div>
            <input
              type="email"
              id="email"
              disabled
              placeholder="Type here..."
              name="email"
              data-testid="email"
              autoComplete="off"
              value={data.email}
              onChange={event => this.handleInputChange(event)}
            />
            {errors.email && (
              <div className="da">
                <img src={warning} alt="" />
                <p className="err ml10">{errors.email}</p>
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>)
  }
  renderPersonalDetailsForm(userType: string) {
    const decryptedString = userType;

    const {
      validationCheck,
      showPersoanlDetailForm
    } = this.state;

    if (decryptedString === "driver") {
      return (
        <>

          {showPersoanlDetailForm ? (
          <div style={{ maxWidth: "770px", padding: "0px 30px", margin: "0 auto" }}>
            <div className="bgTitleheading">
              <div className="mrTitle">
                <img
                  src={closearrow}
                  onClick={() =>
                    this.setState({
                      showPersoanlDetailForm: false,
                      validationCheck: true
                    })
                  }
                  className="closeArrowStyle"
                />
              </div>
              <h3 className="bgheading">Driver Application</h3>
            </div>
            </div>
          ) : (
            <div style={{ maxWidth: "770px", padding: "0px 30px", margin: "0 auto" }}>
            <div className="bgTitleheading">
              <div className="mrTitle">
                <img
                  src={closearrow}
                  className="closeArrowStyle vHidden"
                />
              </div>
              <h3 className="bgheading">Driver Application</h3>
            </div>
            </div>
          )}
          <section className="bgGrey">
            <Container>
              {showPersoanlDetailForm === false ? (
                <div>
                  {this.renderDetails()}
                  <div className="flexEnd pt40 btn-static-div">
                    <button
                      className="btn-transparent mr15"
                      onClick={() =>
                        this.handleSubmitPersonalDetailFirstForm("save")
                      }
                      data-testid="saveExitId"
                    >
                      Save and Exit
                    </button>
                    <button
                      className={
                        validationCheck === false
                          ? "btn-green da"
                          : "btn-green-full-opacity da"
                      }
                      disabled={validationCheck ? false : true}
                      data-testid="nextId"
                      onClick={() =>
                        this.handleSubmitPersonalDetailFirstForm("next")
                      }
                    >
                      Next
                      <span className="ml56">
                        <NavigateNextIcon />
                      </span>
                    </button>
                  </div>
                </div>
              ) : null}

              {showPersoanlDetailForm === true ? (
                <PersonalDetailsSecondFormWeb
                  handleNext={this.props.handleChange}
                  navigation={this.props.navigation}
                  id={""}
                  history={undefined}
                  handleChange={undefined}
                />
              ) : null}
            </Container>
          </section>
        </>

      );
    } else if (decryptedString === "operation_manager") {
      return <Redirect to="/operation-manager/driver-applications" />;
    } else {
      return <Redirect to="/" />;
    }
  }


  render() {
    return (
      <div>
        {/* ... other JSX or components */}
        {this.renderPersonalDetailsForm(this.state.userType)}
        {/* ... other JSX or components */}
      </div>
    )

  }
}
// Customizable Area End
