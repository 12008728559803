import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    history: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    getSellerDetailsMessageId: any;
    postSellerDetailsMessageId: any;
    sellerID: any;
    selectedServices: number[];
    token: string;
    lat: any;
    long: any;
    validationCheck: boolean;
    activeStep: number;
    data: any
    stateList: any;
    personalDetailSeconddata: any;
    personalDetailSecondErrors: any;
    errors: any;
    businessType: any;
    showPersoanlDetailForm: any;
    stepsLabel: any;
    stepsLabelWithBe: any;
    driverStatus: string

    // Customizable Area End
}
interface SS {
    id: any;
}

export default class StepsContainerController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    profileDetailsForStepperApiID: any;
    token: any;
    userId: any
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            getSellerDetailsMessageId: "",
            postSellerDetailsMessageId: "",
            sellerID: "",
            selectedServices: [],
            token: "",
            lat: 0,
            long: 0,
            validationCheck: false,
            activeStep: 0,
            data: {},
            stateList: [],
            personalDetailSeconddata: {},
            personalDetailSecondErrors: {},
            businessType: {},
            errors: {},
            showPersoanlDetailForm: {},
            stepsLabel: [
                "Personal details",
                "Employment history",
                "Vehicle details",
                "Document check"],
            stepsLabelWithBe: [
                "Personal details",
                "Employment history",
                "Vehicle details",
                "Document check",
                "Background check",
            ],
            driverStatus: ""



        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        this.token = window.localStorage?.getItem('token');
        this.userId = window.localStorage?.getItem('userId');
        if (this.token && this.userId) this.getProfileData();
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let token = message.getData(getName(MessageEnum.SessionResponseToken));
            this.setState({ token: token });
        }

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId == this.profileDetailsForStepperApiID) {
                this.getProfileResponse(responseJson)
            }


        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getToken()
    }

    getProfileResponse = (responseJson: any) => {
        if (responseJson && responseJson.data) {
            let step = responseJson?.data?.attributes?.last_application_step
            let changesStepBar: string[] = [
                "Personal details",
                "Employment history",
                "Vehicle details",
                "Document check",
                "Background check",
            ]
            this.setState({
                activeStep: step == null ? 0 : Number(step),
            })
            if (responseJson?.data?.attributes?.status === "background_checks_required" || responseJson?.data?.attributes?.status === "background_checks_completed") {
                this.setState({ stepsLabel: changesStepBar })
            }


        }
    }

    getToken = () => {
        const msg: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(msg);
    };
    getProfileData() {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.profileDetailsForStepperApiID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GetMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.profileDataApiEndPoint + this.userId
        );
        // set Headers
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }



    // Customizable Area End
}
