// App.js - WEB
import React from "react";
import { View } from "react-native";
import firebase from 'firebase';
import { connect } from 'react-redux';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen.web.tsx";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import QuestionBank from "../../blocks/QuestionBank/src/QuestionBank";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import CfApiIntegrationWithTms from "../../blocks/CfApiIntegrationWithTms/src/CfApiIntegrationWithTms";
import Videos from "../../blocks/videos/src/Videos";
import Customform from "../../blocks/customform/src/Customform";
import Appointments from "../../blocks/appointmentmanagement/src/NewAppointment.web";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import Settings from "../../blocks/Settings5/src/settings/Settings.web";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import ReviewAndApproval from "../../blocks/ReviewAndApproval/src/ReviewAndApproval";
import OutlookIntegration from "../../blocks/OutlookIntegration/src/OutlookIntegration";
import DocusignIntegration from "../../blocks/DocusignIntegration/src/DocusignIntegration";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import LandingPage from "../../blocks/landingpage/src/landing-page/LandingPage";
import CustomisedOrderStatus from "../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus";
import Faq from '../../blocks/landingpage/src/faq/Faq.web';
import BackgroundCheck from '../../blocks/landingpage/src/background-check/BackgroundCheck.web'
import "./App.css"
import StepsContainer from "../../blocks/customform/src/StepsContainer";
import WorkExprience from "../../blocks/customform/src/WorkExperience.web";
import BankDetails from "../../blocks/customform/src/BankDetails.web";

import Header from './Header.web';
import Footer from './Footer.web';
import AppController from "./AppController";
import { setUserData } from "../../framework/src/redux/login/action";
import DashboardHeaderWeb from "../../components/src/DashboardHeader.web";
import TopbarContexProvider from '../../blocks/user-profile-basic/src/TopbarContexProvider.web';
import OperationManager from '../../blocks/ReviewAndApproval/src/OperationManagerDashboard.web'

const routeMap = {
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  QuestionBank: {
    component: QuestionBank,
    path: "/QuestionBank"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  CfApiIntegrationWithTms: {
    component: CfApiIntegrationWithTms,
    path: "/CfApiIntegrationWithTms"
  },
  Videos: {
    component: Videos,
    path: "/Videos"
  },
  Customform: {
    component: Customform,
    path: "/Customform"
  },
  PersonalDetails: {
    component: StepsContainer,
    path: "/driver/driver-details"
  },

  WorkExprience: {
    component: WorkExprience,
    path: '/work-exprience'
  },
  BankDetails: {
    component: BankDetails,
    path: '/bank-details'
  },
  Appointments: {
    component: Appointments,
    path: "/Appointments"
  },
  AddAppointment: {
    component: AddAppointment,
    path: "/AddAppointment"
  },
  Settings5: {
    component: Settings5,
    path: "/Settings5"
  },
  Settings: {
    component: Settings,
    path: "/driver/settings"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  ReviewAndApproval: {
    component: ReviewAndApproval,
    path: "/ReviewAndApproval"
  },
  OutlookIntegration: {
    component: OutlookIntegration,
    path: "/OutlookIntegration"
  },
  DocusignIntegration: {
    component: DocusignIntegration,
    path: "/DocusignIntegration"
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: "/UploadMedia2"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: "/RolesPermissions"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  AdminConsole: {
    component: AdminConsole,
    path: "/AdminConsole"
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: "/ApiIntegration"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Dashboard: {
    component: Dashboard,
    path: "/driver/dashboard"
  },
  EmailNotifications2: {
    component: EmailNotifications2,
    path: "/EmailNotifications2"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  LandingPage: {
    component: LandingPage,
    path: "/"
  },
  CustomisedOrderStatus: {
    component: CustomisedOrderStatus,
    path: "/CustomisedOrderStatus"
  },
  Faq: {
    component: Faq,
    path: "/Faq"
  },
  BackgroundCheck: {
    component: BackgroundCheck,
    path: "/BackgroundCheck"
  },

  LandingPage: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  OperationManager: {
    component: OperationManager,
    path: '/operation-manager'
  },
  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends AppController {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ width: '100vw' }}>
        <TopbarContexProvider>
          {window.location.pathname.includes("Dashboard") ||window.location.pathname.includes("dashboard") ? null : <DashboardHeaderWeb />}

          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </TopbarContexProvider>

      </View >
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => {
      return dispatch(setUserData(data))
    }
  }
};

export default App