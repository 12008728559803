
// Customizable Area Start
import { addDays, subDays } from "date-fns";
import moment from "moment";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Client } from '@microsoft/microsoft-graph-client';
//@ts-ignore
import Cookies from 'js-cookie';


export const configJSON = require("../../src/config");

export interface Props { }
interface S {
    value: Date,
    showCalendar: boolean,
    timeSlots: TimeSlotsInterFace,
    unAvailabities: UnAvailabitySlotInterFace,
    events: NewObject[];
}
interface SS { }
interface TimeSlotsInterFace {
    data?: Array<TimeSlotdataObjKeyInterface>
}
interface TimeSlotdataObjKeyInterface {
    id: string,
    type: string,
    attributes: TimeSlotattributesKeyInterface
}
interface TimeSlotattributesKeyInterface {
    from: string,
    to: string
}
interface UnAvailabitySlotInterFace {
    data?: Array<UnavailavailabilityDataInterface>
}
interface UnavailavailabilityDataInterface {
    id: string,
    type: string,
    attributes: UnAvailabilityAttributesInterface
}
interface UnAvailabilityAttributesInterface {
    id: number,
    driver_id: null,
    service_provider_id: number,
    booked: boolean,
    available: boolean,
    start: Date,
    end: Date,
    slot: string
}
interface NewObject {
    "@odata.etag": string;
    id: string;
    subject: string;
    bodyPreview: string;
    body: {
        contentType: string;
        content: string;
    };
    start: {
        dateTime: string;
        timeZone: string;
    };
    end: {
        dateTime: string;
        timeZone: string;
    };
    location: {
        displayName: string;
        locationType: string;
        uniqueIdType: string;
    };
    organizer: {
        emailAddress: {
            name: string;
            address: string;
        };
    };

}
export default class AppointmentSlotsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    GetAllTimeSlotsStatusId: string = "";
    getOpMangersAvailabilityStatusId: string = ""
    handleUnavailabilitiesStatusId: string = ""
    sessionTimeValue: any = localStorage.getItem('value')
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        this.state = {
            value: this.sessionTimeValue,
            showCalendar: false,
            timeSlots: {},
            unAvailabities: {},
            events: []

        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    async fetchOutLookEvents(accessToken: any, responseJson: any) {
        let getSelectedDate = moment(localStorage.getItem('value')).format('YYYY-MM-DD')

        const client = Client.init({
            authProvider: (done) => {
                done(null, accessToken);
            },
        });
        await client.api('/me/calendarview')
            .header('Prefer', 'outlook.timezone="India Standard Time"')
            .select('subject,body,bodyPreview,organizer,attendees,start,end,location')
            .query({
                startdatetime: `${getSelectedDate}T00:00:00.0000000`,
                enddatetime: `${getSelectedDate}T23:59:00.0000000`
            })
            .orderby("createdDateTime DESC")
            .get().then((response) => {
                if (response?.value?.length > 0) {
                    this.setState({ events: response.value })
                    response.value.map((item: any) => {
                        const startTime = moment(item.start.dateTime).format('HH:mm')
                        const endTime = moment(item.end.dateTime).format('HH:mm')
                        responseJson.data.find((timeSlot: any) => {
                            if (timeSlot.attributes.from === startTime && timeSlot.attributes.to === endTime) {
                                this.handleAvailability(null, timeSlot.id)
                                return true
                            }
                        })
                    })
                }
            }).catch((err) => {
                console.log('error in fetching the events from outlook', err)
            })
    }


    availabilityManager = (event: any) => {
        let availability: boolean
        if (event) {
            availability = event.target.value === 'available' ? true : false
        } else {
            availability = false
        }
        return availability
    }

    handleAvailability = (event: any, id: any) => {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };

        const requestBody = {
            data: {
                time_slot_id: id,
                date: moment(this.state.value).format("DD/MM/YYYY"),
                available: this.availabilityManager(event)
            }
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.handleUnavailabilitiesStatusId = requestMessage.messageId;
        // Set Method Type
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        // Set EndPoints
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.AppointmentApiEndPoint
        );
        // set Headers
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(requestBody)
        )
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    jumpToday = () => {
        const currentTime = new Date();
        this.setState({ value: currentTime })
        this.getOpMangersAvailability(currentTime)
        localStorage.setItem('value', String(currentTime))
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getTimeSlots()
        this.getOpMangersAvailability(null)
    }

    onPreviousDayClick = () => {
        let subDay = moment(this.state.value).subtract(1, 'days').toDate()
        this.setState({ value: subDay })
        const subDayStr = subDay.toString()
        localStorage.setItem('value', subDayStr)
        this.getOpMangersAvailability(subDay)
    }

    onNextDayClick = () => {
        let addDay = moment(this.state.value).add(1, 'days').toDate()
        this.setState({ value: addDay })
        const addDayStr = addDay.toString()
        localStorage.setItem('value', addDayStr)
        this.getOpMangersAvailability(addDay)
    }

    // here write Funtionality
    getTimeSlots = () => {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.GetAllTimeSlotsStatusId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.GetAllTimeSlots}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
    getOpMangersAvailability = (getDate: any) => {
        let token = localStorage.getItem("token")
        let date = getDate ? moment(getDate).format("DD/MM/YYYY") : moment(this.state.value).format("DD/MM/YYYY")
        let managerId = localStorage.getItem("userId")
        if (!token || token.length === 0) {
            return;
        }
        const header = {
            "Content-Type": "application/json",
            token: token,
        };
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getOpMangersAvailabilityStatusId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.AppointmentApiEndPoint}?from=${date}&to=${date}&manager_id=${managerId}`
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
    monthClickHandler = (event: Date) => {
        this.setState({ value: event })
        localStorage.setItem('value', String(event))
    }

    navigationHandler = (event: any) => {
        this.setState({ value: (event.action === 'drillUp' || event.action === 'drillDown') ? event.value : event.activeStartDate });
        localStorage.setItem('value', String(event.value))
    }

    onChangeHandler = (event: Date) => {
        this.setState({ value: event, showCalendar: false });
        localStorage.setItem('value', String(event))
        this.getOpMangersAvailability(event)
    }

    handleReceiveResponse = (responseJson: any, errorResponse: any, setterObj: any) => {
        if (responseJson?.data) {
            this.setState(setterObj)
        } else {
            console.log(errorResponse);
        }
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            let errorResponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (this.GetAllTimeSlotsStatusId === apiRequestCallId) {
                this.handleReceiveResponse(responseJson, errorResponse, { timeSlots: responseJson })
                let outlookToken = Cookies.get("outlook_access_token")
                if (outlookToken) {
                    this.fetchOutLookEvents(outlookToken, responseJson);
                }
            }
            if (this.getOpMangersAvailabilityStatusId === apiRequestCallId) {
                this.handleReceiveResponse(responseJson, errorResponse, { unAvailabities: responseJson })
            }
            if (this.handleUnavailabilitiesStatusId === apiRequestCallId) {
                if (responseJson?.data) {
                    this.getOpMangersAvailability(null)
                } else {
                    console.log(errorResponse);
                }
            }
        }
        // Customizable Area End
    }
    // Customizable Area End
}
