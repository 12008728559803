
// Customizable Area Start
import moment from "moment";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

export const configJSON = require("../../src/config");
export interface Props {
    navigation: any;
    id: string;
    classes: any
}
interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    userDetails: any;
    applicationsLoader: boolean
    statusOfApplication: boolean,
    openSelectTemplate: boolean,
    openrReasonList: boolean,
    selectedOption: string,
    statusReasonList: any,
    statusReasonListId: any,
    opManagersList: any,
    selectedManager: string,
    isShowOpManagerAvailability: boolean,
    isOpenConfirmedSuccessModal: boolean
    isOpenRescheduleConfimationModal: boolean

}
interface SS {
    id: any;
}
export default class ViewApplicationsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    GetDriverVewAppCallId: string = "";
    statusListCallId: string = "";
    PutApprovalCallId: string = "";
    PutRejectCallId: string = "";
    getOpmanagersApiCallId: string = "";
    confirmAssignmentStatusId: string = ""
    requestBackgroundCheckCallId: string = ""
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIRequestMethodMessage),
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage)

        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            userDetails: {},
            applicationsLoader: false,
            statusOfApplication: true,
            openSelectTemplate: false,
            openrReasonList: false,
            selectedOption: "",
            statusReasonList: [],
            statusReasonListId: '',
            opManagersList: [],
            selectedManager: "",
            isShowOpManagerAvailability: false,
            isOpenConfirmedSuccessModal: false,
            isOpenRescheduleConfimationModal: false
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const messageHandlers = {
            [getName(MessageEnum.RestAPIResponceMessage)]: () => {
                const apiRequestCallId = message.getData(
                    getName(MessageEnum.RestAPIResponceDataMessage)
                );

                const responseJson = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                );

                if (this.GetDriverVewAppCallId === apiRequestCallId) {
                    if (responseJson && responseJson.data) {
                        this.setState({
                            userDetails: responseJson.data,
                            applicationsLoader: false
                        })
                    }
                }
                if (this.getOpmanagersApiCallId === apiRequestCallId) {
                    if (responseJson && responseJson.data) {
                        const managersLit = responseJson.data.filter((item: any) => item.name !== ' ')
                        this.setState({ opManagersList: [...managersLit] });
                    }
                }

                this.confirmedAssignmentResponse(apiRequestCallId, responseJson)
                this.rejectApiResponse(apiRequestCallId, responseJson)
                this.approveApiResponse(apiRequestCallId, responseJson)
                this.statusListResponse(apiRequestCallId, responseJson)
                this.RequestBackgroundCheckResponse(apiRequestCallId, responseJson)

            }
        };

        const handler = messageHandlers[message.id];
        if (handler) {
            handler();
        }
        // Customizable Area End
    }

    removeUnderscoreAndCapitalize = (string: string) => {
        if (string === "draft") {
            return "Application draft"
        } else if (string === "contract_signature_req") {
            return "Contract signature required"
        } else {
            string = string.replace(/_/g, ' ');
            string = string.charAt(0).toUpperCase() + string.slice(1);
            return string;
        }
    }

    handleAskToReScheduleClick = () => {
        this.setState({ isOpenRescheduleConfimationModal: true })
    }

    handleCloseConfirmedSuccessModal = () => {
        this.setState({ isOpenConfirmedSuccessModal: false })
        window.location.href = '/operation-manager/driver-applications'
    }

    closeAskToRescheduleConfirmationModal = () => {
        this.setState({ isOpenRescheduleConfimationModal: false })
    }

    handleClose = () => {
        this.setState({ isShowOpManagerAvailability: false })
    }
    handleChange = (event: any) => {
        this.setState({ selectedManager: event.target.value })
    };

    handleCancleAssignment = () => {
        this.setState({ selectedManager: "" })
    }

    handleConfirmAssignment = () => {
        const body = {
            "driver_id": this.state.userDetails?.id,
            "status": "pending_induction",
            "reassigned_to": this.state.selectedManager
        }
        this.commonRunEngineFunction(configJSON.confirmAssignmentEndPoint, configJSON.examplePutAPiMethod, 'confirmAssignment', body)
    }

    commonRunEngineFunction = (urlEndPoint: any, methodType: string, status: any, body: any) => {
        const header = {
            "Content-Type": "application/json",
            token: window.localStorage.getItem("token"),
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const statusMapping = {
            getOpManagers: "getOpmanagersApiCallId",
            confirmAssignment: "confirmAssignmentStatusId"
        };
        //@ts-ignore
        const propertyName = statusMapping[status];
        //@ts-ignore
        this[propertyName] = getValidationsMsg.messageId;


        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            urlEndPoint
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methodType
        );
        if (body) {
            getValidationsMsg.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
        }

        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    checkSchedule = () => {
        this.setState({ isShowOpManagerAvailability: true })
    }

    getOpManagers = () => {
        this.commonRunEngineFunction(configJSON.getOpmanagersApiEndPoints, configJSON.validationApiMethodType, 'getOpManagers', null)
    }

    statusListResponse = (apiRequestCallId: any, responseJson: any) => {
        if (this.statusListCallId === apiRequestCallId) {
            if (responseJson && responseJson.data) {
                this.setState({
                    statusReasonList: responseJson.data
                })
            }
        }
    }
    confirmedAssignmentResponse = (apiRequestCallId: any, responseJson: any) => {
        if (this.confirmAssignmentStatusId === apiRequestCallId) {
            if (responseJson && responseJson.data) {
                this.setState({
                    userDetails: responseJson, isOpenConfirmedSuccessModal: true
                })
            }
        }
    }

    rejectApiResponse = (apiRequestCallId: any, responseJson: any) => {
        if (this.PutRejectCallId === apiRequestCallId) {
            if (responseJson && responseJson.data) {
                const param = new URLSearchParams(window.location.search).get('id')
                this.handleViewDriverApp(param)
                this.setState({
                    userDetails: responseJson.data
                })
            }
        }
    }

    approveApiResponse = (apiRequestCallId: any, responseJson: any) => {
        if (this.PutApprovalCallId === apiRequestCallId) {
            if (responseJson && responseJson.data) {
                const param = new URLSearchParams(window.location.search).get('id')
                this.handleViewDriverApp(param)
                this.setState({
                    userDetails: responseJson.data
                })
            }
        }
    }

    RequestBackgroundCheckResponse = (apiRequestCallId: any, responseJson: any) => {
        if (this.requestBackgroundCheckCallId === apiRequestCallId) {
            if (responseJson && responseJson.data) {
                const param = new URLSearchParams(window.location.search).get('id')
                this.handleViewDriverApp(param)
            }
        }
    }

    async componentDidMount() {
        super.componentDidMount();

        const param = new URLSearchParams(window.location.search).get('id')

        this.handleViewDriverApp(param)
        this.getStatusReasonList();
        this.getOpManagers()
    }

    // here write Funtionality

    handleViewDriverApp = (id: any) => {
        this.setState({ applicationsLoader: true })
        const header = {
            "Content-Type": "application/json",
            token: window.localStorage.getItem("token"),
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.GetDriverVewAppCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.GetViewDriverApp}?id=${id}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    handleChangeStausId = (event: any) => {
        this.setState({ statusReasonListId: event })
    }


    getStatusReasonList = () => {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        }

        const getValidationMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.statusListCallId = getValidationMsg.messageId;

        getValidationMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.GetAllReasons}`
        )

        getValidationMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        getValidationMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethod
        );
        runEngine.sendMessage(getValidationMsg.id, getValidationMsg);
    }

    handleAplicationsReject = () => {
        const userID = new URLSearchParams(window.location.search).get('id')
        const header = {
            "Content-Type": "application/json",
            token: window.localStorage.getItem("token"),
        };

        if (this.state.statusReasonListId === '') {
            return
        }

        const body = {
            driver_id: userID,
            status_reason_id: this.state.statusReasonListId
        }

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.PutRejectCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.RejectApiEndPoints}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.examplePutAPiMethod
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        )
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    handleAplicationsApproval = () => {
        const userID = new URLSearchParams(window.location.search).get('id')
        const header = {
            "Content-Type": "application/json",
            token: window.localStorage.getItem("token"),
        };
        const body = {
            id: userID,
            approved: "approved"
        }

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.PutApprovalCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.ApprovalApiEndPoints}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.examplePutAPiMethod
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        )
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    handleNullChecker = (data: any) => {
        if (data === null) {
            return "--"
        }
        else {
            return data
        }
    }

    handleDateNullChecker = (data: any) => {

        if (data === null) {

            return "--"
        }
        else {

            return moment(data).format("DD/MM/YYYY")
        }
    }

    handleBoolenCheck = (data: any) => {
        if (data) {
            return "YES"
        }
        else if (data === false) {
            return "NO"
        }
        else {
            return "--"
        }
    }

    handleGenderChecker = (data: any) => {
        if (data === 1) {
            return "Male"
        }
        else if (data === 2) {
            return "Female"
        }
        else if (data === 3) {
            return "Others"
        }
    }

    handleAplicationsUpload = () => {
        this.setState({ openSelectTemplate: true })
    }

    handleApplicationReject = () => {
        this.setState({ openrReasonList: true })
    }

    handleTemplateOptionChange = (event: any) => {
        this.setState({ selectedOption: event.target.value })
    };

    handleRequestbackgroundCheck = () => {
        const userID = new URLSearchParams(window.location.search).get('id')
        const header = {
            "Content-Type": "application/json",
            token: window.localStorage.getItem("token"),
        };
        const body = {
            data: {
                driver_id: userID,
                mail_for: "background_check"
            }
        }

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.requestBackgroundCheckCallId = getValidationsMsg.messageId;
        
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.requestBackgroundCheckEndpoints}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.examplePutAPiMethod
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        )
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }


    // Customizable Area End
}
