import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
const configJSON = require("../src/config");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    handleStep: () => void;
    handlePrevStep: () => void;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    allDocuments: any,
    personalInsurance: any,
    personalInsuranceTitle: any,
    infoURL: any,
    infoTitle: any
    saveAndExitStepCount: number,
    submitStepCount: number,
    requiredDoc: any
    // Customizable Area End
}
interface SS {
    id: any;
}
export default class DocumentsCheckController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getAllDocumentsStatusId: string = ""
    getPersonalInsuranceStatusId: string = ""
    submitStatusId: string = ""
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        this.state = {
            allDocuments: {},
            personalInsurance: '',
            personalInsuranceTitle: '',
            infoTitle: '',
            infoURL: '',
            saveAndExitStepCount: 3,
            submitStepCount: 4,
            requiredDoc: []
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    async componentDidMount() {
        super.componentDidMount();
        this.getAllDocuments();
        this.getPersonalInsuranceURL();
    }
    // Customizable Area Start
    stepStoringApiCall = (stepCount: number) => {
        
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };

        const requestBody = {
            data: {
                last_application_step: stepCount
            }
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.submitStatusId = requestMessage.messageId;
        // Set Method Type
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PutMethodType
        );
        // Set EndPoints
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.incrementStepCountDocCheck
        );
        // set Headers
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(requestBody)
        )
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    isDisabled = () => {
        const { requiredDoc, allDocuments } = this.state
        const { attributes } = allDocuments
        let isDisabled = false

        const docuArr = [
            "abn",
            "acn",
            "driver_licence"
        ]

        if (attributes && requiredDoc) {
            isDisabled = docuArr.some((item: any) => attributes.documents[item] === null)
            return isDisabled
        }
        return isDisabled
    }

    getAllDocuments = () => {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllDocumentsStatusId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getAllDocuments}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GetMethodType
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    getPersonalInsuranceURL = () => {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getPersonalInsuranceStatusId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.personalInsurance}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GetMethodType
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    async receive(from: string, message: Message) {
        const operationMap = {
            [this.getAllDocumentsStatusId]: (responseJson:any, errorResponse:any) => {
                responseJson && this.setState({ allDocuments: responseJson.data, requiredDoc: responseJson.data.attributes.required });
                errorResponse && console.log(errorResponse);
            },
            [this.getPersonalInsuranceStatusId]: (responseJson:any) => {
                if (responseJson) {
                    const insuranceData = responseJson.data;
                    this.setState({
                        infoURL: insuranceData[0]?.attributes?.link,
                        infoTitle: insuranceData[0]?.attributes?.name
                    });
                } else {
                    console.log(errorResponse);
                }
            },
            [this.submitStatusId]: (responseJson:any) => {
                responseJson && this.props.navigation.navigate("Dashboard");
                errorResponse && console.log(errorResponse);
            }
        };
    
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    
        const operation = operationMap[apiRequestCallId];
        operation && operation(responseJson, errorResponse);
    }
    
    // Customizable Area End
}
