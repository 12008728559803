import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";


// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config");

export interface Props { }

interface ValidationErrorInterface {
    currentPassword?: string;
    newPassword?: string;
    confirmPassword?: string;
}

interface S {
    // Customizable Area Start
    current_password: string;
    new_password: string;
    confirm_password: string;
    email: string;
    errors: ValidationErrorInterface,
    showCurrentPassword: boolean,
    showNewPassword: boolean,
    showConfirmPassword: boolean,
    name: string;
    stateName: string,
    showSnackBar: boolean,
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SettingsPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    passwordResetApiId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            current_password: "",
            new_password: "",
            confirm_password: "",
            email: "",
            errors: {},
            showCurrentPassword: false,
            showNewPassword: false,
            showConfirmPassword: false,
            name: '',
            stateName: '',
            showSnackBar: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    visibilitySetter = (key: keyof S, password: boolean) => {
        this.setState({ ...this.state, [key]: !password })
    }

    handleCloseSnackBar = () => {
        this.setState({ showSnackBar: false });
    }

    async componentDidMount(): Promise<void> {
        this.getUserData();
    }

    getUserData = async () => {
        let getCurrentUserEmail = localStorage.getItem('email')
        let getCurrentUserDetails: any = localStorage.getItem('current_user')
        let parseGetCurrentUserDetails = JSON.parse(getCurrentUserDetails)
        if (getCurrentUserEmail)
            this.setState({ email: getCurrentUserEmail })
        if (parseGetCurrentUserDetails) this.setState({ name: parseGetCurrentUserDetails.name, stateName: parseGetCurrentUserDetails.state })
    }

    toSentenceCase = (camelCase: string) => {
        if (camelCase) {
            const result = camelCase.replace(/([A-Z])/g, ' $1');
            return result[0] + result.substring(1).toLowerCase();
        }
        return '';
    };

    handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { name, value } = event.target;
        const errorName = name.replace(/(?!^)_(.)/g, (_, char) => char.toUpperCase())
        this.setState({
            ...this.state, [name]: value, errors: { ...this.state.errors, [errorName]: "" }
        });
    };

    validateForm = (): S["errors"] => {
        const {
            current_password,
            new_password,
            confirm_password
        } = this.state;
        const errors: S["errors"] = {};
        const validatePassword = (password: any, fieldName: string) => {
            const fieldNameStr = this.toSentenceCase(fieldName)
            if (!password) {
                errors[fieldName as keyof ValidationErrorInterface] = `${fieldNameStr + " " + configJSON.requiredPasswordMsg}`;
            } else if (fieldName === "newPassword" && !password.match(configJSON.validationRegex)) {
                errors[fieldName as keyof ValidationErrorInterface] = `${fieldNameStr + " " + configJSON.containErrorMsg}`;
            }
        };

        validatePassword(current_password, "currentPassword");
        validatePassword(new_password, "newPassword");

        if (!confirm_password) {
            errors.confirmPassword = `${"confirm password" + " " + configJSON.requiredPasswordMsg}`;
        } else if (confirm_password !== new_password) {
            errors.confirmPassword = "Password does not match";
        }

        return errors;
    };

    handleUpdatePassword = () => {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };

        const requestBody = {
            data: {
                current_password: this.state.current_password,
                new_password: this.state.new_password,
                password_confirmation: this.state.confirm_password
            }
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.passwordResetApiId = requestMessage.messageId;
        // Set Method Type
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.examplePutAPiMethod
        );
        // Set EndPoints
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.changePasswordApiEndPoint
        );
        // set Headers
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(requestBody)
        )
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleSubmit = (e: any) => {
        e.preventDefault();

        const errors = this.validateForm();

        if (Object.keys(errors).length === 0) {
            this.handleUpdatePassword()
        } else {
            this.setState({ errors });
        }
    };

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            let errorResponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (this.passwordResetApiId === apiRequestCallId) {

                if (responseJson && responseJson.errors) {
                    this.setState({ errors: { ...this.state.errors, currentPassword: "Incorrect password" } });
                } else {
                    this.setState({
                        current_password: "",
                        new_password: "",
                        confirm_password: "",
                        errors: {},
                        showSnackBar: true
                    })
                }
            }

        }
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
}