import React from "react";

// Customizable Area Start
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, TextField } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link, Redirect } from "react-router-dom";
import { tick } from "../assets";
// Customizable Area End

import "../ForgotPassword.css";
import ForgotPasswordController from "../ForgotPasswordController";
import { Triangle } from "../../../email-account-login/src/assets";

// Customizable Area Start
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Email is invalid")
    .required("Email is a required field")
});
// Customizable Area End

interface Props {
  // Customizable Area Start
  forgetPasswordState: boolean;
  handleFPassState: () => void;
  loginState: boolean;
  handleLoginState: () => void;
  // Customizable Area End
}

// Customizable Area Start
export const ForgotPasswordForm = ({
  formikprops,
  handleLoginState,
  handleCloseFPass,
  fpNotFoundEmail,
  handleSignupState,
  handleNotFoundClose
}: {
  formikprops: any;
  handleCloseFPass: () => void;
  handleLoginState: () => void;
  fpNotFoundEmail: boolean;
  handleSignupState: () => void;
  handleNotFoundClose: () => void;
}) => {
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values
  } = formikprops;
  return (
    <form>
      <DialogContent>
        {fpNotFoundEmail === false ? <DialogContentText
          style={{
            fontFamily: "Lexend",
            fontWeight: 300,
            fontSize: "16px",
            color: "#303030",
            marginBottom: "24px"

          }}
        >
          Please enter the email address you would like your <br /> password
          reset information sent to
        </DialogContentText>

          : <DialogContentText
            style={{
              fontFamily: "Lexend",
              fontWeight: 300,
              fontSize: "16px",
              color: "#303030",
              marginBottom: "24px"

            }}
          >
            Please enter the email address associated with your account
          </DialogContentText>}

        <label htmlFor="name"
          style={{
            fontFamily: "Lexend",
            fontWeight: 300,
            fontSize: "16px",


          }}
        >Email</label>
        <TextField
          autoFocus
          // margin="dense"
          id="name"
          placeholder="Enter Email"
          type="email"
          name="email"
          fullWidth
          variant="filled"
          data-testid="email-input"
          InputProps={{
            disableUnderline: true
          }}
          inputProps={{
            style: {
              padding: 15,
              fontFamily: "Lexend",
              fontWeight: 300,
              fontSize: "16px",
              border: Boolean(touched.email && errors.email) ? "1px solid red" : "none",
            }
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(touched.email && errors.email)}
          helperText={
            touched.email && Boolean(errors.email) && String(errors.email)
          }
        />
      </DialogContent>
      {fpNotFoundEmail === true &&
        <div style={{
          display: "flex",
          gap: "12px",
          alignItems: "start"
        }}>
          <img src={Triangle}
            style={{
              height: "32px",
              width: "32px"
            }}
          />
          <p className=""
            style={{
              fontFamily: "Lexend",
              fontWeight: 300,
              fontSize: "16px",
              color: "#949494",
              margin: "0 0 20px 0",
              textAlign: "left"

            }}
          >
            {`Sorry we couln’t find an account with this Email.`}
            <br />
            Please
            <Link
              id="link-to-login"
              to="/"
              className="linkColor"
              onClick={() => {
                handleSignupState();
                handleCloseFPass();


              }}
            >
              Sign Up
            </Link>
          </p>
        </div>
      }
      <DialogActions style={{ display: "flex", flexDirection: "column", padding: "0" }}>
        <Button
          onClick={() =>
            handleSubmit()
          }
          variant="contained"
          style={{
            color: "white",
            backgroundColor: "#80c342",
            width: "100%",
            borderRadius: "5px",
            textTransform: "none",
            padding: "20px 0",
            lineHeight: "18px",
            margin: fpNotFoundEmail === false ? "50px 0 0 0" : "0",
            fontFamily: "Lexend",
            fontWeight: 300,
            fontSize: "16px",
          }}
          className="submit-button"
          data-testid="reset-link-btn"
          disabled={values.email === "" || errors.email ? true : false}
        >
          Request resent link
        </Button>
        <Button
          style={{
            marginTop: "7px",
            fontFamily: "Lexend",
            fontWeight: 300,
            fontSize: "16px",
            color: "rgba(0, 0, 0, 0.5)",
            textTransform: "none"

          }}
          onClick={() => {
            handleLoginState();
            handleCloseFPass();
          }}
        >
          Back to login
        </Button>
      </DialogActions>
    </form>
  );
};
// Customizable Area End
export default class Forgetpasswrdmailsent extends ForgotPasswordController {
  constructor(props: Props) {
    // @ts-ignore
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  // Customizable Area End
  componentDidUpdate(prevProps: Readonly<Props>, prevState: any): void {
    // Customizable Area Start
    if (prevState.passToken !== this.state.passToken) {
      this.props.handleFPassState();
      this.handleClickOpen();
    }
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {
      forgetPasswordState,
      handleFPassState,
      handleLoginState,
      handleSignupState,
    } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          data-testid="forgot-password-success"
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              fontWeight: "bold",
              padding: "48px 0 32px 0"
            }}
          >
            <img src={tick} width="50" height="50" />

            <p
              style={{
                cursor: "move",
                textAlign: "center",
                margin: "40px 0 8px 0",
                fontSize: "40px"
              }}
              id="draggable-dialog-title"
              className="ForgetPassHeadingFontBold"
            >
              Password Reset Email Sent
            </p>

            <DialogContent>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  margin: "0"
                }}
                className="TextfontNormal"
              >
                {/* An email has been sent to your entered email
                <br />
                Follow the direction in this email to reset your password.
                <address className="" /> */}
                An email has been sent to your nominated email address.
              </p>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  margin: "0 0 24px 0"
                }}
                className="TextfontNormal"
              >
                Follow the directions in the email to reset your password.
              </p>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={() => this.handleClose()}
                variant="contained"
                style={{
                  borderRadius: "5px",
                  color: "white",
                  backgroundColor: "#80C343",
                  alignSelf: "center",
                  padding: "12px 58px",
                  fontFamily: "Lexend",
                  fontWeight: 400,
                  fontSize: "16px",
                  textTransform: "none",
                }}
              >
                Done
              </Button>
            </DialogActions>
          </Box>
        </Dialog>

        {/* For request For forget pass */}
        <div>
          <Dialog
            open={forgetPasswordState}
            onClose={handleFPassState}
            aria-labelledby="form-dialog-title"
            data-testid="forgot-password-form"
          >
            <Box className="fgSpace">
              {/* <DialogTitle
                id="form-dialog-title"
                style={{
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                  fontSize: "40px"
                }}
              >
                Forgot your password
              </DialogTitle> */}
              {this.state.fpNotFoundEmail === false ? <p
                id="form-dialog-title"
                style={{
                  fontSize: "40px",
                  margin: "0 0 48px 0"
                }}
                className="ForgetPassHeadingFontBold"
              >
                Forgot your password
              </p> :
                <p
                  id="form-dialog-title"
                  style={{
                    fontSize: "40px",
                    margin: "0 0 48px 0",
                    textAlign: "center"
                  }}
                  className="ForgetPassHeadingFontBold"
                >
                  Email Not Found
                </p>
              }
              <Formik
                data-test-id="forgetPasswordForm"
                initialValues={{
                  email: ""
                }}
                // @ts-ignore
                onSubmit={values => this.handleForgotPasswordOTP(values)}
                validationSchema={validationSchema}
              >
                {/* @ts-ignore */}
                {formikprops => (
                  <ForgotPasswordForm
                    formikprops={formikprops}
                    handleLoginState={handleLoginState}
                    handleCloseFPass={handleFPassState}
                    fpNotFoundEmail={this.state.fpNotFoundEmail}
                    handleSignupState={handleSignupState}
                    handleNotFoundClose={this.handleNotFoundClose}
                  />
                )}
              </Formik>
              {/* <button onClick={() => {
                handleLoginState()
                this.handleNotFoundClose()
              }}>Test</button> */}
            </Box>
          </Dialog>
        </div>

      </div>
      // Customizable Area Start
    );
  }
}