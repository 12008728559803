Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.examplePutAPiMethod = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ReviewAndApproval";
exports.labelBodyText = "ReviewAndApproval Body";

exports.btnExampleTitle = "CLICK ME";
exports.GetUserAppEndPoints = "account_block/user_app_status"
exports.GetViewDriverApp = "account_block/view_driver_appl"
exports.GetAllTimeSlots = "account_block/time_slots"
exports.GetAllReasons = "account_block/status_reasons"
exports.AppointmentApiEndPoint = "bx_block_appointment_management/appointments"
exports.ApprovalApiEndPoints = "account_block/op_mgr_approval"
exports.RejectApiEndPoints = "account_block/application_reject"
exports.changePasswordApiEndPoint = "bx_block_profile/passwords";
exports.getAllDocuments = "account_block/documents"
exports.getOpmanagersApiEndPoints = "account_block/op_list"
exports.getAppointmentsAPI = "bx_block_appointment_management/appointments"
exports.checkCalendar = "account_block/check_calendar"
exports.confirmAssignmentEndPoint = "account_block/update_status"


exports.postMethod = "POST"
exports.getMethod = "GET"

exports.requiredPasswordMsg = "is required";
exports.lengthErrorMsg = "should have a minimum length of 8 characters"
exports.containErrorMsg = "must contain 8 characters with 1 uppercase 1 lowercase 1 numeric & 1 special character";
exports.validationRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
exports.StateListDataApiEndPoint = "account_block/states"
exports.vechileListApiEndpoint = "bx_block_profile/vehicles"
exports.updateDriverDetailsEndpoints = "account_block/update_account_details"
exports.requestBackgroundCheckEndpoints = "account_block/send_mail_to_driver"
exports.HearAboutUsApiEndPoint = "account_block/search_froms";
exports.outlook_url_FE = "https://driveronboardingsystem-266335-react.b266335.dev.eastus.az.svc.builder.cafe"
exports.backgroundCheckScreeningApiEndpoint = "bx_block_profile/backgrounds/screening_report_link"
// Customizable Area End