import React from 'react';
import { Image, crossIcon, warning } from "./assets";
import { makeStyles, Typography, Box, Button, LinearProgress, Modal, Backdrop, Fade } from '@material-ui/core';
import FileUploadController, { Props } from './FileUploadController';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { green } from "@material-ui/core/colors";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import './FileUpload.css';


const useStyles: any = makeStyles(() => ({
    root: {
        height: "4px",
        width: '100%',
        "& .MuiLinearProgress-root": {
            height: "8px",
        },

        "& .MuiLinearProgress-colorPrimary": {
            backgroundColor: "#ede9e966 !important",
        },

        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#80C343",
            borderRadius: '8px',
        },

        "& .MuiTypography-body2": {
            fontSize: "18px"
        }
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}));



const LinearProgressWithLabel = (props: any) => {
    const classes = useStyles();
    return (
        <Box className={classes.root} display="flex" alignItems="center">
            <Box style={{ borderRadius: '20px', overflow: 'hidden', }} width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const documentUI = (data: any) => {
    const { showProgress, progress, errorMessage, uploadSuccessFlag, openPreviewModal, deleteMedia, dragRef, onDragFileEnter, removeDragOver, uploadFile } = data
    if (showProgress) {
        return (<div className="DocUploadImageContainer">
            <div className="DocUploadImageContainer1">
                <div style={{ display: "flex", padding: '20px' }}>
                    <img src={Image} className="UpLoadImageIcon" />
                    <div className="fileUploadingContainer">
                        <LinearProgressWithLabel value={progress} />
                    </div>
                </div>
            </div>
        </div>)
    } else if (uploadSuccessFlag) {
        return (
            <div className="DocUploadImageContainer">
                <div className="DocUploadImageContainer1">
                    <div style={{ display: "flex", padding: '15px 20px', justifyContent: 'space-between', }}>
                        <div className='UpLoadImageIcon-box'>
                            <img src={Image} className="UpLoadImageIcon" />
                            <div className="fileUploadingContainer" style={{ gap: '0px' }}>
                                <CheckCircleOutlineIcon style={{ color: "#80C343", fontSize: "33px" }} />
                                <p style={{ color: "#80C343", fontSize: "19px", margin: "0px 0px 0px 4px" }}>Completed</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", gap: "15px" }}>
                            <button className="fileButton" data-test-id="showPreview" onClick={() => openPreviewModal()}>View</button>
                            <Button className="fileButton" data-test-id="delete-media" onClick={deleteMedia} > <img src={crossIcon} style={{ filter: 'opacity(0.5)' }} /></Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <>
                <div
                    ref={dragRef}
                    className="drop-file-input"
                    onDragEnter={onDragFileEnter}
                    onDragLeave={removeDragOver}
                    onDrop={removeDragOver}
                >
                    <div className={`drop-file-input-border ${errorMessage ? "drop-file-input-error-border" : null} `}>
                        <div className="drop-file-input__label">
                            <div className='drop-img'>
                                <img src={Image} alt="" />
                                <div className='drop-file-text'>
                                    <p className='color-black'>Drag your image here, or<a style={{ color: "#80C343", }}> Browse</a></p>
                                    <p className='font-size-10'>file size upto 20 Mb</p>
                                </div>
                            </div>
                            <p className='font-Supports'>Supports JPG, PNG, PDF</p>
                        </div>
                        <input type="file" value="" onChange={(event: any) => uploadFile(event)} accept="application/pdf, image/png, image/jpg, image/jpeg" required />
                    </div>
                </div>
                {errorMessage && (
                    <p className="InputErrTxt" >
                        {errorMessage}
                    </p>
                )}
            </>
        )

    }
}


export const ImagePreview = (props: any) => {
    const classes = useStyles();
    const { isOpen, handleClose, file, fileName } = props
    return (
        <Modal
            className={classes.modal}
            open={isOpen}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={isOpen} timeout={500} >
                {file && fileName?.includes("pdf") ? <iframe src={file} width="80%" height="600px" /> :
                    <img
                        src={file}
                        alt="document"
                        style={{ maxHeight: "90%", maxWidth: "90%" }}
                    />
                }
            </Fade>
        </Modal>
    )
}

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        "&$checked": {
            color: green[600],
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

class FileUpload extends FileUploadController {
    constructor(props: Props) {
        super(props)
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        const { label, link, infolink, infoTitle, linkTitle } = this.props;
        const { showProgress, progress, documentInfo, isOpen } = this.state;
        const { errorMessage, uploadSuccessFlag, file, fileName } = documentInfo;
        const labelInsurance = label === "personal_accident_cover";
        const validAstricks = ["driver_licence", "abn", "acn"].includes(label);
    
        const renderDocumentUI = () => {
            return (
                <>
                    <label className="DocUploadHeading">
                        {this.removeUnderscoreAndCapitalize(label)}
                        {validAstricks && <sup style={{ color: "red" }}>*</sup>}
                    </label>
                    {documentUI({
                        showProgress, progress, errorMessage, uploadSuccessFlag,
                        openPreviewModal: this.openPreviewModal,
                        deleteMedia: this.deleteMedia, dragRef: this.dragRef,
                        onDragFileEnter: this.onDragFileEnter, removeDragOver: this.removeDragOver, uploadFile: this.uploadFile
                    })}
                    {isOpen && <ImagePreview data-test-id="imagePreview" isOpen={isOpen} handleClose={this.handleClose} file={file} fileName={fileName} />}
                </>
            );
        };
    
        const renderInsuranceContent = () => {
            return (
                <>
                    <label className="DocUploadHeading">Do you have Personal Accident insurance?</label>
                    <div className="df twoCheckbox">
                        {["Yes", "No"].map(option => (
                            <div className={`div-checkbox mr15 mb15 ${this.state.docUpload === option ? "selected" : ""}`} key={option}>
                                <FormControlLabel
                                    className={`${option.toLowerCase()}Label`}
                                    id={`formControlLabel${option}`}
                                    control={
                                        <GreenCheckbox
                                            value={option}
                                            checked={this.state.docUpload === option}
                                            onChange={this.onOptionChange}
                                            style={{ padding: "0px 0px", margin: "1px" }}
                                            name={option}
                                        />
                                    }
                                    label={option}
                                />
                            </div>
                        ))}
                    </div>
                    {this.state.validateYesorNo && (
                        <div className="da">
                            <img src={warning} alt="" />
                            <p className="err ml10">please select one</p>
                        </div>
                    )}
                    {this.state.docUpload === 'Yes' && renderDocumentUI()}
                    {this.state.docUpload === 'No' && (
                        <div className="innerBoxselect">
                            <label className="DocUploadHeading">Do you wish to take out cover via CBD’s group Personal Accident policy?</label>
                            <div className="df twoCheckbox">
                                {["Yes", "No"].map(option => (
                                    <div className={`div-checkbox mr15 mb15 ${this.state.cbdUpload === option ? "selected" : ""}`} key={option}>
                                        <FormControlLabel
                                            className={`${option.toLowerCase()}Label`}
                                            id={`formControlLabel${option}`}
                                            control={
                                                <GreenCheckbox
                                                    value={option}
                                                    checked={this.state.cbdUpload === option}
                                                    onChange={this.onOptioncbdUpload}
                                                    style={{ padding: "0px 0px", margin: "1px" }}
                                                    name={option}
                                                />
                                            }
                                            label={option}
                                        />
                                    </div>
                                ))}
                            </div>
                            {this.state.cbdUpload === 'Yes' && (
                                <div className='insuranceUrl'>
                                    <a className='linkInsurance' href={infolink} target="_blank">{infoTitle}</a> 
                                </div>
                            )}
                            {this.state.cbdUpload === 'No' &&  ''}
                        </div>
                    )}
                </>
            );
        };
    
        return (
            <>
                {labelInsurance ? renderInsuranceContent() : renderDocumentUI()}
            </>
        );
    }
    
}

export default FileUpload
