
// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { abnRegex } from "../../../customform/src/CustomformController";



export const configJSON = require("../../src/config");
export const postcodeRegex = /^\d{4}$/;
export const acnRegex = /^\d{6,10}$/;
export const BSBRegex = /^\d{6}$/;
export const phoneNumRegexWorkExp = /^\d\d{9}$/;

export interface Props {
    navigation: any;
    id: string;

}

interface IPersonalDetails {
    full_name: string,
    gender: string,
    date_of_birth: string,
    address: string,
    suburb: string,
    state_name: string,
    postcode: string,
    full_phone_number: string,
    email: string,
    australian_citizen: any,
    business_type: string,
    ABN_number: string,
    ACN_number: string,
    how_do_you_hear_about_us: string,
    search_description: string,

}

interface IBankDetails {
    id: string
    account_name: string;
    BSB_number: string;
    account_number: string;
    bank_name: string;
    GST_registered: any;
    medical_condition: any;
    medical_description: string;
}
interface IVehicleDetails {
    id: string;
    own_vehicle: any;
    driver_licence_number: string;
    registration_number: any;
    vehicle_id: string;
    vehicle_model: any;
    driving_disquilified: any;
    driving_disquilified_description: string;
    criminal_offence: any;
    criminal_offence_description: string;
    registration_expiry_date: any;
    expiry_date: any

}

interface IEmergencyContract {
    id: string;
    name: string;
    relationship: string;
    address: string;
    phone_number: any;
    post_code: string
}

interface IWorkExprience {
    id: string,
    start_date: string,
    end_date: string,
    company_name: string,
    reason_for_leaving: string
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    userDetails: any;
    applicationsLoader: boolean;
    statusOfApplication: boolean;
    openSelectTemplate: boolean;
    selectedOption: string;
    personalDetails: IPersonalDetails;
    bankDetails: IBankDetails;
    emergencyContract: IEmergencyContract;
    vehicleDetails: IVehicleDetails;
    stateList: any,
    vehcileList: any,
    workExprience: any,
    updateLoader: boolean,
    updateMassages: string,
    errors: any,
    hearAboutUsLst: any,
    OtherId: string,
    cbdId: string,
    workExpError: any
}

interface SS {
    id: any;

}

export default class EditDriverDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    GetDriverVewAppCallId: string = "";
    PutApprovalCallId: string = "";
    PutRejectCallId: string = "";
    stateListDataID: string = "";
    vechileListId: string = "";
    hearAboutUsDetailsID: string = "";
    updateDriverDetailsCallId: string = "";
    // fromDate: any;
    // toDate: any;
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIRequestMethodMessage),
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage)

        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            userDetails: {},
            applicationsLoader: false,
            statusOfApplication: true,
            openSelectTemplate: false,
            selectedOption: "",
            updateLoader: false,
            updateMassages: "",
            hearAboutUsLst: [],
            OtherId: "",
            cbdId: "",
            personalDetails: {
                ABN_number: "",
                australian_citizen: "",
                business_type: "",
                date_of_birth: "",
                full_name: "",
                full_phone_number: "",
                state_name: "",
                suburb: "",
                gender: "",
                address: "",
                postcode: "",
                email: "",
                ACN_number: "",
                how_do_you_hear_about_us: "gotu",
                search_description: ""

            },
            bankDetails: {
                account_name: "",
                account_number: "",
                bank_name: "",
                BSB_number: "",
                GST_registered: "",
                medical_condition: "",
                medical_description: "",
                id: ''
            },
            emergencyContract: {
                address: "",
                name: "",
                phone_number: "",
                post_code: "",
                relationship: "",
                id: ""
            },
            vehicleDetails: {
                criminal_offence: "",
                criminal_offence_description: "",
                driver_licence_number: "",
                driving_disquilified: "",
                driving_disquilified_description: "",
                expiry_date: "",
                own_vehicle: "",
                registration_expiry_date: "",
                registration_number: "",
                vehicle_id: "",
                vehicle_model: "",
                id: ""
            },
            stateList: [],
            vehcileList: [],
            workExprience: [{ id: '', company_name: '', start_date: '', end_date: '', reason_for_leaving: '' }],
            errors: {},
            workExpError: [{ start_date: '', end_date: '' }],

        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const messageHandlers = {
            [getName(MessageEnum.RestAPIResponceMessage)]: () => {
                const apiRequestCallId = message.getData(
                    getName(MessageEnum.RestAPIResponceDataMessage)
                );

                const responseJson = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                );


                if (this.vechileListId === apiRequestCallId) {
                    this.handleVehcileListResponse(responseJson)
                }
                this.getProfileResponse(apiRequestCallId, responseJson)
                this.handleStateListDataID(apiRequestCallId, responseJson)
                this.handleUpdateDriverDetailsResponse(apiRequestCallId, responseJson)
                this.handleHearAboutUsDetailsID(apiRequestCallId, responseJson)



            }
        };

        const handler = messageHandlers[message.id];
        if (handler) {
            handler();
        }
        // Customizable Area End
    }



    async componentDidMount() {
        super.componentDidMount();
        // this.handleUserAppStatus()
        const param = new URLSearchParams(window.location.search).get('id')
        this.handleViewDriverApp(param);
        this.getStateListData()
        this.handleVehcileList()
        this.getHearAboutUsList()
    }

    // here write Funtionality



    getProfileResponse = (apiRequestCallId: any, responseJson: any) => {
        if (this.GetDriverVewAppCallId === apiRequestCallId && responseJson && responseJson.data) {
            const { attributes } = responseJson.data;

            const extractPersonalDetails = () => {
                const {
                    full_name, gender, date_of_birth, address, suburb, state_name, postcode,
                    full_phone_number, email, australian_citizen, business_type, ABN_number, ACN_number, search_description, how_do_you_hear_about_us
                } = attributes;



                return {
                    ABN_number,
                    address,
                    full_phone_number,
                    australian_citizen,
                    business_type,
                    date_of_birth,
                    email,
                    full_name,
                    gender,
                    postcode,
                    state_name,
                    suburb,
                    ACN_number,
                    search_description,
                    how_do_you_hear_about_us
                };
            };

            const extractBankDetails = () => {
                const { bank_detail } = attributes;
                if (bank_detail?.data != null) {
                    const { account_name, account_number, bank_name, BSB_number, GST_registered, medical_condition, medical_description } = bank_detail.data.attributes;
                    return {
                        account_name,
                        account_number: account_number.toString(),
                        bank_name,
                        BSB_number,
                        GST_registered,
                        medical_condition,
                        medical_description,
                        id: bank_detail.data.id
                    };
                }
                return {
                    account_name: "",
                    account_number: "",
                    bank_name: "",
                    BSB_number: "",
                    GST_registered: false,
                    medical_condition: false,
                    medical_description: "",
                    id: ""
                };
            };

            const extractEmergencyContact = () => {
                const { emergency_contact } = attributes;
                if (emergency_contact?.data != null) {
                    const { address: emergencyAddress, name, phone_number, post_code, relationship } = emergency_contact.data.attributes;
                    return {
                        address: emergencyAddress,
                        name,
                        phone_number,
                        post_code,
                        relationship,
                        id: emergency_contact.data.id
                    };
                }
                return {
                    address: "",
                    name: "",
                    phone_number: "",
                    post_code: "",
                    relationship: "",
                    id: ""
                };
            };

            const extractVehicleDetails = () => {
                const { vehicle_details } = attributes;
                if (vehicle_details?.data != null) {
                    const {
                        own_vehicle, driver_licence_number, registration_number, vehicle_id, vehicle_model,
                        driving_disquilified, driving_disquilified_description,
                        criminal_offence, criminal_offence_description,
                        registration_expiry_date, expiry_date
                    } = vehicle_details.data.attributes;

                    return {
                        criminal_offence,
                        criminal_offence_description,
                        driver_licence_number: driver_licence_number,
                        driving_disquilified,
                        driving_disquilified_description,
                        expiry_date: expiry_date,
                        own_vehicle,
                        registration_expiry_date: registration_expiry_date,
                        registration_number: registration_number,
                        vehicle_id,
                        vehicle_model,
                        id: vehicle_details.data.id
                    };
                }
                return {
                    criminal_offence: "",
                    criminal_offence_description: "",
                    driver_licence_number: "",
                    driving_disquilified: false,
                    driving_disquilified_description: "",
                    expiry_date: "",
                    own_vehicle: false,
                    registration_expiry_date: "",
                    registration_number: "",
                    vehicle_id: "",
                    vehicle_model: "",
                    id: ""
                };
            };

            const extractCareerExperiences = () => {
                if (attributes.career_experiences.length > 0) {
                    return attributes.career_experiences.map((item: any) => {
                        return {
                            id: item.data.id,
                            start_date: item.data.attributes.start_date,
                            end_date: item.data.attributes.end_date,
                            company_name: item.data.attributes.company_name,
                            reason_for_leaving: item.data.attributes.reason_for_leaving
                        };
                    });
                }
                return [];
            };




            this.setState({
                userDetails: responseJson.data,
                applicationsLoader: false,
                personalDetails: extractPersonalDetails(),
                bankDetails: extractBankDetails(),
                emergencyContract: extractEmergencyContact(),
                vehicleDetails: extractVehicleDetails(),
                workExprience: extractCareerExperiences(),
                workExpError: this.extractCareerExperiencesForError(attributes)
            });
        }
    };

    extractCareerExperiencesForError = (attributes: any) => {
        if (attributes.career_experiences.length > 0) {
            return attributes.career_experiences.map((item: any) => {
                return {
                    start_date: "",
                    end_date: "",
                };
            });
        }
        return [];
    };


    handleUpdateDriverDetailsResponse = (apiRequestCallId: any, responseJson: any) => {
        if (this.updateDriverDetailsCallId === apiRequestCallId) {
            this.setState({ updateLoader: false, updateMassages: responseJson.meta.message })
            if (responseJson.data) {
                const param = new URLSearchParams(window.location.search).get('id')
                this.handleViewDriverApp(param);
            }

        }

    }
    handleStateListDataID = (apiRequestCallId: any, responseJson: any) => {
        if (this.stateListDataID === apiRequestCallId) {

            this.setState({
                stateList: responseJson.data,
            });

        }
    }



    handleVehcileListResponse(responseJson: any) {
        if (!responseJson.errors && !responseJson.error) {
            this.setState({
                vehcileList: responseJson.data,
            });
        }
    }

    handleHearAboutUsDetailsID = (apiRequestCallId: any, responseJson: any) => {
        if (this.hearAboutUsDetailsID === apiRequestCallId) {

            if (responseJson.length > 0) {
                let id = ""
                let cbdId: any = ""
                responseJson.map((item: any) => {
                    if (item?.title == "Other") {
                        id = item?.id
                    }
                    else if (item?.title == "Referral from CBD Express colleague") {
                        cbdId = item?.id
                    }


                })
                this.setState({ OtherId: id, cbdId })
            }
            this.setState({ hearAboutUsLst: responseJson });

        }
    }


    handleViewDriverApp = (id: any) => {
        this.setState({ applicationsLoader: true })
        const header = {
            "Content-Type": "application/json",
            token: window.localStorage.getItem("token"),
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.GetDriverVewAppCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.GetViewDriverApp}?id=${id}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }


    getStateListData() {
        const header = {
            "Content-Type": "application/json",
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.stateListDataID = requestMessage.messageId;
        // Set Method Type
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        // Set EndPoints
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.StateListDataApiEndPoint
        );
        // set Headers
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleVehcileList = () => {

        const header = {
            "Content-Type": "application/json",
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.vechileListId = requestMessage.messageId;
        
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        // Set EndPoints
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.vechileListApiEndpoint
        );
       
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getHearAboutUsList() {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.hearAboutUsDetailsID = requestMessage.messageId;
        
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethod
        );
       
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.HearAboutUsApiEndPoint
        );
       
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }






    handleInputChange = (event: any) => {
        this.setState({
            errors: {
                ...this.state.errors,
                [event.target.name]: this.validate(event.target.name, event.target.value),
            },
            personalDetails: {
                ...this.state.personalDetails,
                [event.target.name]: event.target.value,
            },
            bankDetails: {
                ...this.state.bankDetails,
                [event.target.name]: event.target.value,
            },
            emergencyContract: {
                ...this.state.emergencyContract,
                [event.target.name]: event.target.value,
            },
            vehicleDetails: {
                ...this.state.vehicleDetails,
                [event.target.name]: event.target.value,
            }
        });
    }

    handleCheckedInputChange = (event: any) => {
        const value =
            event.target.type === 'checkbox' ? event.target.checked : event.target.value;

        this.setState({
            personalDetails: {
                ...this.state.personalDetails,
                [event.target.name]: value === 'true', // Convert string to boolean
            },
            bankDetails: {
                ...this.state.bankDetails,
                [event.target.name]: value === 'true',
            },
            vehicleDetails: {
                ...this.state.vehicleDetails,
                [event.target.name]: value === 'true',
            }
        });
    };

    handleWorkExprienceChange(e: any, index: any, field: any) {
        const newWorkexpriences = JSON.parse(JSON.stringify(this.state.workExprience));
        newWorkexpriences[index][field] = e.target.value;
        const errors = [...this.state.workExpError];
        let fromDate, toDate; 
        const currentDate = new Date();

        switch (field) {
            case 'start_date':
                fromDate = new Date(e.target.value);

                if (fromDate > currentDate) {
                    errors[index][field] = 'From date cannot be greater than the current date.';
                } else {
                    errors[index][field] = ''; // Clear the error message
                }

                // Ensure "To" date is after or equal to "From" date
                toDate = new Date(newWorkexpriences[index].end_date);
                if (toDate < fromDate) {
                    newWorkexpriences[index].end_date = e.target.value; // Set "To" date to match "From" date
                }

                this.handleStartDate(fromDate, index, newWorkexpriences, errors)

                break;

            case 'end_date':
                fromDate = new Date(newWorkexpriences[index].start_date);
                toDate = new Date(e.target.value);

                // Additional validation for "To" date
                if (toDate > currentDate) {
                    errors[index]['end_date'] = 'To date cannot be greater than the current date.';
                } else {
                    if (toDate < fromDate) {
                        errors[index][field] = 'To date must be after the From date.';
                    } else {
                        errors[index][field] = ''; // Clear the error message
                    }
                }

                this.handleEndDate(toDate, index, newWorkexpriences, errors)
                break;

            default:
                break;
        }

        this.setState({ workExprience: newWorkexpriences, errors });
    }


    handleStartDate = (fromDate: any, index: any, newWorkexpriences: any, errors: any) => {
        for (let i = 0; i < newWorkexpriences.length; i++) {
            if (i !== index) {
                const existingFromDate = new Date(newWorkexpriences[i].start_date);
                const existingToDate = new Date(newWorkexpriences[i].end_date);

                if (fromDate >= existingFromDate && fromDate <= existingToDate) {
                    errors[index]['start_date'] = 'From date falls within an existing range.';
                    break; // No need to continue checking, we've found an error
                }
            }
        }

    }
    handleEndDate = (toDate: any, index: any, newWorkexpriences: any, errors: any) => {
        for (let i = 0; i < newWorkexpriences.length; i++) {
            if (i !== index) {
                const existingFromDate = new Date(newWorkexpriences[i].start_date);
                const existingToDate = new Date(newWorkexpriences[i].end_date);

                if (toDate >= existingFromDate && toDate <= existingToDate) {
                    errors[index]['end_date'] = 'To date falls within an existing range.';
                    break; // No need to continue checking, we've found an error
                }
            }
        }
    }






    removeUnderscoreAndCapitalize = (string: string) => {
        if (string === "draft") {
            return "Application draft"
        } else if (string === "contract_signature_req") {
            return "Contract signature required"
        } else {
            string = string.replace(/_/g, ' ');
            string = string.charAt(0).toUpperCase() + string.slice(1);
            return string;
        }
    }

    updateFullFrom = () => {
        const { errors, workExpError } = this.state
        const hasFieldWithLengthGreaterThanZero = workExpError.some((item: any) => item.start_date.length > 0 || item.end_date.length > 0);

        const _err = Object.values(errors).some((value: any) => value.length > 0);

        if (_err) {
            return
        }
        if (hasFieldWithLengthGreaterThanZero) {
            return
        }

        this.setState({ updateLoader: true })
        const param = new URLSearchParams(window.location.search).get('id')
        const { personalDetails, workExprience, bankDetails, emergencyContract, vehicleDetails } = this.state
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };

        const foundItem = this.state.hearAboutUsLst.find((item: any) => item.title.trim() === personalDetails.how_do_you_hear_about_us.trim());

        const requestBody = {
            data: {
                driver_id: param,
                personal_details: {
                    //   first_name: "Gopi",
                    //   last_name: "Das",
                    //   full_name: "Gopi Das",
                    //   full_phone_number: "8763850485",
                    //   country_code: null,
                    //   phone_number: null,
                    //   address: "bbsr",
                    //   suburb: "bls",
                    //   postcode: 1234,
                    //   state_name: "New South Wales",
                    //   date_of_birth: "2004-12-11",
                    //   gender: 1,
                    //   australian_citizen: false,
                    //   business_type: "company",
                    //   ABN_number: "12345678123",
                    //   ACN_number: "123456789",
                    //   company_name: "Orion",
                    //   own_vehicle: false
                    full_name: personalDetails.full_name,
                    gender: personalDetails.gender,
                    date_of_birth: personalDetails.date_of_birth,
                    address: personalDetails.address,
                    suburb: personalDetails.suburb,
                    postcode: personalDetails.postcode,
                    // state_id: personalDetails.state_name,
                    full_phone_number: personalDetails.full_phone_number,
                    australian_citizen: personalDetails.australian_citizen,
                    business_type: personalDetails.business_type,
                    ABN_number: personalDetails.ABN_number,
                    ACN_number: personalDetails.ACN_number,
                    search_from_id: foundItem?.id,
                    search_description: personalDetails.search_description
                },
                bank_detail: {
                    id: bankDetails.id,
                    account_name: bankDetails.account_name,
                    BSB_number: bankDetails.BSB_number,
                    account_number: parseInt(bankDetails.account_number),
                    bank_name: bankDetails.bank_name,
                    GST_registered: bankDetails.GST_registered,
                    medical_condition: bankDetails.medical_condition,
                    // medical_description: ""
                },
                emergency_contact: {
                    id: emergencyContract.id,
                    name: emergencyContract.name,
                    relationship: emergencyContract.relationship,
                    address: emergencyContract.address,
                    phone_number: parseInt(emergencyContract.phone_number),
                    post_code: emergencyContract.post_code
                },
                career_experiences: workExprience,
                vehicle_details: {
                    id: vehicleDetails.id,
                    own_vehicle: vehicleDetails.own_vehicle,
                    vehicle_id: vehicleDetails.vehicle_id,
                    vehicle_model: vehicleDetails.vehicle_model,
                    driver_licence_number: vehicleDetails.driver_licence_number,
                    driving_disquilified: vehicleDetails.driving_disquilified,
                    criminal_offence: vehicleDetails.criminal_offence,
                    expiry_date: vehicleDetails.expiry_date,
                    registration_number: vehicleDetails.registration_number,
                    registration_expiry_date: vehicleDetails.registration_expiry_date

                }
            }
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateDriverDetailsCallId = requestMessage.messageId;
        // Set Method Type
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.examplePutAPiMethod
        );
        // Set EndPoints
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateDriverDetailsEndpoints
        );
        // set Headers
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        // set body
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(requestBody)
        )
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }



    validate = (name: any, value: any) => {
        switch (name) {
            case "company_name":
                return this.defaultValidations(value)
            case "start_date":
                return this.defaultValidations(value)
            case "end_date":
                return this.defaultValidations(value)
            case "reason_for_leaving":
                return this.defaultValidations(value)
            case "name":
                return this.defaultValidations(value)
            case "date_of_birth":
                return this.validateDate(value)
            case "ABN_number":
                return this.validateABN(value)
            case "ACN_number":
                return this.validateACN2(value)
            case "BSB_number":
                return this.validateBSB(value)
            case "account_number":
                return this.validateACN(value)
            case "bank_name":
                return this.defaultValidations(value)
            case "postcode":
                return this.validatePostCode(value)
            case "full_phone_number":
                return this.validatePhoneNumber(value)
            case "phone_number":
                return this.validatePhoneNumber(value)
            case "post_code":
                return this.validatePostCode(value)
            case "GST_registered":
                return this.defaultValidations(value)
            default: {
                return "";
            }
        }
    };

    validateDate = (value: any) => {
        let cyear = new Date().getFullYear();
        let year = new Date(value).getFullYear();

        if (!value || value.trim() === "") {
            return "";
        } else if ((cyear - year) < 18) {
            return "Enter a valid date.";
        } else {
            return "";
        }
    }

    defaultValidations = (value: any) => {
        if (!value || value === "") {
            return "This Field is Required";
        } else {
            return "";
        }
    }

    validatePostCode = (value: any) => {
        if (!value || value === "") {
            return "";
        }
        if (!value.match(postcodeRegex)) {
            return "Enter a valid Post Code of 4 digits.";
        }
        return "";
    }

    validateACN = (value: any): string => {
        let _value = value?.toString()
        if (!_value || _value === "") {
            return "";
        }
        if (!_value.match(acnRegex)) {
            return "Bank account number should be 6 to 10.";
        }
        return "";
    };

    validateACN2 = (value: any): string => {
        let _value = value?.toString()
        const acnR = /^\d{9}$/
        if (!_value || _value === "") {
            return "";
        }
        if (!_value.match(acnR)) {
            return "Bank account number should be 9 digits..";
        }
        return "";
    };

    validateABN = (value: any): string => {
        let _value = value?.toString()
        if (!_value || _value === "") {
            return "";
        }
        if (!_value.match(abnRegex)) {
            return "Enter a valid ABN number of 11 digits.";
        }
        return "";
    };

    validateBSB = (value: any): string => {
        if (!value || value === "") {
            return "";
        }
        if (!value.match(BSBRegex)) {
            return "Enter a valid BSB number of 6 digits.";
        }
        return "";
    };

    validatePhoneNumber = (value: any) => {
        let _value = value?.toString()
        if (!_value || _value === "") {
            return "";
        }
        if (!_value.match(phoneNumRegexWorkExp)) {
            return "Enter a valid Mobile number.";
        }
        return "";

    }



    // Customizable Area End
}
