export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const tick = require("../assets/tick.png");
export const hatch = require("../assets/global_assets/hatch.png");
export const sedans = require("../assets/global_assets/Sedans.png");
export const Tail = require("../assets/global_assets/Tail.png");
export const Vans = require("../assets/global_assets/Vans.png");
export const call = require ("../assets/global_assets/call.png")
export const logo = require("../assets/global_assets/logo.png");
export const avatar = require("../assets/global_assets/avatar.png");
export const bgimg = require("../assets/global_assets/bgimg.jpg")
