import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const postcodeRegex = /^\d{4}$/;
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
interface ICardDetails {
    card_name: string;
    issue_date: string;
    [key: string]: string;
}

interface IAdditionalDetails {
    address: string,
    suburb: string,
    state: string,
    pass_code: string,
    country: string,
    [key: string]: string;
}
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    history: any;
    handleChange: any;
    handleNext: any;
    handleBack: any;
    errorMessage: string
    // Customizable Area End
}

// Customizable Area Start
interface S {
    isPaymentSuccess: boolean;
    isAddressConfirmed: boolean;
    stripeInfo: ICardDetails;
    stripeInfoError: ICardDetails;
    cardNumberError: string;
    expirationError: string;
    cvcError: string;
    cardNumber: string;
    expiration: string;
    cvc: string;
    additional_card_info: IAdditionalDetails;
    additional_card_info_Error: IAdditionalDetails;
    isBackgroundCheckPending: boolean;
    isBackGroundCheckPendingResolve: boolean;
    isVisiblePaymentForm: boolean;
    getPrice: string,
    backgrounPaid: boolean,
    stripeErrorMessage: string,
    isStripeError: boolean,
    apiLoader: boolean

}

interface SS {
    id: any;
}

// Customizable Area End

export default class PaymentFormController extends BlockComponent<Props, S, SS> {

    // Customizable Area Start
    StripePaymentApiCallId: string = "";
    vettingApiCallId: string = "";
    priceApiCallId: string = "";
    beCheckStatusCallId: string = ""
    profileDataID: string = "";
    finishBeCheckApiCallId: string = "";

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            isPaymentSuccess: false,
            isAddressConfirmed: true,
            stripeInfo: {
                card_name: "",
                issue_date: ""
            },
            stripeInfoError: {
                card_name: "",
                issue_date: ""
            },
            cardNumberError: '',
            expirationError: '',
            cvcError: '',
            cardNumber: '',
            expiration: '',
            cvc: '',
            additional_card_info: {
                address: '',
                suburb: '',
                state: '',
                pass_code: '',
                country: ''
            },
            additional_card_info_Error: {
                address: '',
                suburb: '',
                state: '',
                pass_code: '',
                country: ''
            },
            isBackgroundCheckPending: false,
            isBackGroundCheckPendingResolve: false,
            isVisiblePaymentForm: false,
            getPrice: '0',
            backgrounPaid: false,
            stripeErrorMessage: "",
            isStripeError: false,
            apiLoader: false
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async receive(_from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            if (apiRequestCallId && responseJson) {
                this.handleApiResponseStripePayment(apiRequestCallId, responseJson);
                this.handleBackgroundApiResponse(apiRequestCallId, responseJson);
                this.handleApiResponsePrice(apiRequestCallId, responseJson);
                this.handleApiResponseBackgroundStatus(apiRequestCallId, responseJson)
                this.handleProfileDataID(apiRequestCallId, responseJson)
                this.handleApiResponseFinishBackgroundCheck(apiRequestCallId, responseJson)

            }

        }
        // Customizable Area End
    }

    // Customizable Area Start

    handleApiResponseStripePayment = (apiRequestCallId: any, responseJson: any) => {
        if (this.StripePaymentApiCallId === apiRequestCallId) {
            this.setState({ backgrounPaid: true })
            console.log("respons paichi", responseJson)


        }
    }
    handleBackgroundApiResponse = (apiRequestCallId: any, responseJson: any) => {
        if (this.vettingApiCallId === apiRequestCallId) {
            if (responseJson.data) {
                this.setState({ apiLoader: false })
                window.open(responseJson.data.attributes.invite_link, "Vetting.com", 'noopener');
                this.setState({ isBackgroundCheckPending: true })
            }
            else {
                console.log("catch Error", responseJson.errors)
            }


        }
    }

    handleApiResponsePrice = (apiRequestCallId: any, responseJson: any) => {

        if (this.priceApiCallId === apiRequestCallId) {

            this.setState({
                getPrice: responseJson.price,
                // isPaymentSuccess: true
            })



        }
    }

    handleApiResponseBackgroundStatus = (apiRequestCallId: any, responseJson: any) => {
        if (this.beCheckStatusCallId === apiRequestCallId) {
            if (responseJson.status === "Completed" || responseJson.status === "Clear result" || responseJson.status === "Review result") {
                this.setState({ isBackGroundCheckPendingResolve: true })
            }

        }
    }

    handleApiResponseFinishBackgroundCheck = (apiRequestCallId: any, responseJson: any) => {
        if (this.finishBeCheckApiCallId === apiRequestCallId) {
            window.location.href = "/driver/dashboard"
        }
    }

    handleProfileDataID = (apiRequestCallId: any, responseJson: any) => {
        if (this.profileDataID === apiRequestCallId) {
            if (responseJson.data) {
                this.setState({ backgrounPaid: responseJson.data.attributes.background_paid })
            }
        }
    }


    async componentDidMount() {
        this.getPriceApi()
        this.getProfileData()
        this.getBEcheckStatusApi()
    }

    // Core Funtion

    handlePrimaryStripeInfo = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            stripeInfo: {
                ...this.state.stripeInfo,
                [e.target.name]: e.target.value
            },
            stripeInfoError: {
                ...this.state.stripeInfoError,
                [e.target.name]: this.validateAdditionalCardInfo(e.target.name, e.target.value),
            },
        })


    }
    handleSecondaryStripeInfo = (e: any) => {
        this.setState({
            additional_card_info: {
                ...this.state.additional_card_info,
                [e.target.name]: e.target.value
            },
            additional_card_info_Error: {
                ...this.state.additional_card_info_Error,
                [e.target.name]: this.validateAdditionalCardInfo(e.target.name, e.target.value),
            },
        })
    }

    handleCheckboxChanges = (value: boolean) => {

        let additionaValidationErrors: IAdditionalDetails = {
            address: "",
            country: "",
            pass_code: "",
            state: "",
            suburb: ""
        };
        this.setState({ isAddressConfirmed: value, additional_card_info_Error: additionaValidationErrors })

    }

    handleCardNumberChange(event: any) {

        if (event.error) {
            this.setState({ cardNumberError: event.error.message });
        } else {
            this.setState({ cardNumberError: '' });

        }
    }

    handleExpirationChange(event: any) {
        if (event.error) {
            this.setState({ expirationError: event.error.message });
        } else {
            this.setState({ expirationError: '' });
        }
    }

    handleCvcChange(event: any) {
        if (event.error) {
            this.setState({ cvcError: event.error.message });
        } else {
            this.setState({ cvcError: '' });
        }
    }

    validateAdditionalCardInfo = (name: string, value: string) => {
        switch (name) {
            case "card_name":
                return this.defaultValidations(value)
            case "address":
                return this.defaultValidations(value)
            case "suburb":
                return this.defaultValidations(value)
            case "state":
                return this.defaultValidations(value)
            case "country":
                return this.defaultValidations(value)
            case "pass_code":
                return this.validatePostCode(value)
            default: {
                return "";
            }
        }
    };

    defaultValidations = (value: any) => {
        if (!value || value === "") {
            return "This Field is Required";
        } else {
            return "";
        }
    }

    validatePostCode = (value: any) => {
        if (!value || value === "") {
            return "This Field is Required";
        }
        if (!value.match(postcodeRegex)) {
            return "Enter a valid Post Code of 4 digits.";
        }
        return "";
    }

    handleSubmit = async (event: any, stripe: any, elements: any) => {
        event.preventDefault();
        const { stripeInfo, additional_card_info, isAddressConfirmed } = this.state;

        const validationErrors: ICardDetails = this.validateCardDetails(stripeInfo);
        const additionalValidationErrors: IAdditionalDetails = this.validateAdditionalDetails(additional_card_info);

        if (!isAddressConfirmed && (Object.keys(additionalValidationErrors).length > 0 || Object.keys(validationErrors).length > 0)) {
            this.setState({ stripeInfoError: validationErrors, additional_card_info_Error: additionalValidationErrors });
            if (!stripe || !elements) {
                return;
            }
        } else if (isAddressConfirmed && validationErrors.card_name.length > 0) {
            this.setState({ stripeInfoError: validationErrors });
            if (!stripe || !elements) {
                return;
            }
        }

        const cardNumberElement = elements.getElement(CardNumberElement);
        const expirationElement = elements.getElement(CardExpiryElement);
        const cvcElement = elements.getElement(CardCvcElement);

        if (!cardNumberElement || !expirationElement || !cvcElement) {
            return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumberElement,
            billing_details: {
                name: stripeInfo.card_name,
            },
        });

        if (error) {
            this.setState({ stripeErrorMessage: "Your payment could not be processed at this time. Please check your card details and try again, or use a different payment method. For further assistance, contact your bank or our customer support.", isStripeError: true })
        } else {
            this.handlePaymentApiCall(paymentMethod.id);
        }
    }

    validateCardDetails = (stripeInfo: any): any => {
        const validationErrors: any = {
            card_name: "",
        };
        const _stripeInfo = {
            card_name: stripeInfo.card_name
        }

        Object.keys(_stripeInfo).forEach((name: string) => {
            const error = this.validateAdditionalCardInfo(name, stripeInfo[name]);
            if (error && error.length > 0) {
                validationErrors[name] = error;
            }
        });

        return validationErrors;
    }

    validateAdditionalDetails = (additional_card_info: any): IAdditionalDetails => {
        const validationErrors: IAdditionalDetails = {
            address: "",
            country: "",
            pass_code: "",
            state: "",
            suburb: ""
        };

        Object.keys(additional_card_info).forEach((name: string) => {
            const error = this.validateAdditionalCardInfo(name, additional_card_info[name]);
            if (error && error.length > 0) {
                validationErrors[name] = error;
            }
        });

        return validationErrors;
    }

    handlePaymentApiCall = (PaymentID: string) => {

        const { isAddressConfirmed, additional_card_info, stripeInfo } = this.state
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };

        const requestBody: any = {
            data: {
                payment_method_id: PaymentID,
                card_holder: stripeInfo.card_name,
                card_issue_date: stripeInfo.issue_date,
                same_billing_address: isAddressConfirmed,
                address: additional_card_info.address,
                suburb: additional_card_info.suburb,
                state_id: additional_card_info.state,
                postcode: additional_card_info.pass_code,
                country: additional_card_info.country
            }

        };

        if (isAddressConfirmed) {
            if (requestBody.data.card_holder.length === 0) {
                return;
            }
        } else {
            const isEmptyValue = (value: any) => typeof value === 'string' && value.length === 0;
            for (const key in requestBody.data) {
                if (key !== 'card_issue_date' && isEmptyValue(requestBody.data[key])) {
                    return;
                }
            }
        }


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.StripePaymentApiCallId = requestMessage.messageId;
        // Set Method Type
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PostMethodType
        );
        // Set EndPoints
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.stripePaymentEndpoint
        );
        // set Headers
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(requestBody)
        )
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    handleVettingApiCall = () => {
        this.setState({ apiLoader: true })
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };



        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.vettingApiCallId = requestMessage.messageId;
        // Set Method Type
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PostMethodType
        );
        // Set EndPoints
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.backgroundCheckApiEndpoint
        );
        // set Headers
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        // set body
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            null
        )
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    handleFinishBeCheckApiCall = () => {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };



        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.finishBeCheckApiCallId = requestMessage.messageId;
        // Set Method Type
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PutMethodType
        );
        // Set EndPoints
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.finishBeCheckApiEndpoint
        );
        // set Headers
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        // set body
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            null
        )
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    getPriceApi() {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.priceApiCallId = requestMessage.messageId;
        // Set Method Type
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GetMethodType
        );
        // Set EndPoints
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.priceApiEndpoint
        );
        // set Headers
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getBEcheckStatusApi() {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.beCheckStatusCallId = requestMessage.messageId;
        // Set Method Type
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GetMethodType
        );
        // Set EndPoints
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.backgroundCheckStatusApiEndpoint
        );
        // set Headers
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getProfileData() {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };
        let userId = localStorage.getItem('userId')
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.profileDataID = requestMessage.messageId;
        // Set Method Type
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GetMethodType
        );
        // Set EndPoints
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.profileDataApiEndPoint + userId
        );
        // set Headers
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleVettingDotCom = () => {
        this.handleVettingApiCall()

    }

    handleSaveAndExist = () => {
        this.props.navigation.navigate("Dashboard")
    }

    handlePendingBEcheck = () => {
        this.props.handleChange()
        this.handleFinishBeCheckApiCall()
        this.getBEcheckStatusApi()
    }

    handleBackFormToBePage = () => {
        this.setState({ isVisiblePaymentForm: false })
    }


    // Customizable Area End
}