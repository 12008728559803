// Customizable Area Start
//@ts-nocheck
import React from "react";
import "./VehicleDetails.css";
import {
  Container,
  Grid,
  TextField,
  Box,
  Typography
} from "@material-ui/core";

import VehicleformController, { Props } from "./VehicleformController";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { warning, closearrow } from "./assets";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { green } from "@material-ui/core/colors";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { arrowRight } from "../../landingpage/src/assets";


export const RenderItem = ({ yesTextarea2, criminal_offence, handleInputchange }: { yesTextarea2: any, criminal_offence: any, handleInputchange: any }) => {

  return (
    <>
      {yesTextarea2 && (
        <Grid>
          <TextField
            placeholder="&apos;&apos;Provide details and dates of offences&apos;&apos;"
            name="criminal_offence"
            value={criminal_offence}
            className="handleInputs"
            onChange={(event) => handleInputchange(event)}
            multiline
            minRows={20}
            maxRows={5}
            style={{
              backgroundColor: "white",
              border: "none",
              padding: "20px"
            }}
            InputProps={{ disableUnderline: true }}
          />
        </Grid>
      )}
    </>
  )
}

const RenderIteMore = ({ noTextarea1 }: { noTextarea1: boolean }) => {
  return (
    <>
      {noTextarea1 && (
        <Grid >
          <TextField
            placeholder="Provide details and dates of offences"
            multiline
            maxRows={5}
            style={{
              backgroundColor: "white",
              padding: "20px"
            }}
            InputProps={{ disableUnderline: true }}
          />
        </Grid>
      )}
    </>
  )
}




const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

class VehicleDetails extends VehicleformController {
  constructor(props: Props) {
    super(props);
  }


  yesTextarea1Handler = () => {
    this.setState({ yesTextarea1: true });
  };
  yesTextarea2Handler = () => {
    this.setState({ yesTextarea2: true });
  };


  noTextarea2Handler = () => {
    this.setState({ noTextarea2: true });
  };
  noTextarea2HandlerFalse = () => {
    this.setState({ noTextarea2: false });
  };
  step3Handler = () => {
    this.setState({ vehicleDetailForm: 3 });
  };
  step4Handler = () => {
    this.setState({ vehicleDetailForm: 4 });
  };
  renderCommonForm = () => {
    return (<Grid>
      <h2 className="titleDetails fs20 mt0 mb10">Vehicle Details :</h2>
      <label className="ownVehicleTitle">
        Do you own your own Vehicle ? <sup>*</sup>
      </label>
      <div className="df twoCheckbox">
        <div className={this.state.tickYes ? "div-checkbox mr15 mb15 selected" : "div-checkbox mr15 mb15"}>
          <FormControlLabel className="yesLabel"
            id="formControlLabel1"
            control={
              <GreenCheckbox
                checked={this.state.tickYes}
                style={{ padding: "0px 0px", margin: "1px" }}
                onClick={() => {
                  this.handleOnPress()
                }}
                name="checkedG"
              />
            }
            label="Yes"
          />
        </div>
        <div className={this.state.tickNo ? "div-checkbox mr15 mb15 selected" : "div-checkbox mr15 mb15"}>
          <FormControlLabel className="noLabel"
            id="formControlLabel2"
            control={
              <GreenCheckbox
                checked={this.state.tickNo}
                style={{ padding: "0px 0px", margin: "1px" }}
                onClick={() => {
                  this.handleOnPressNO()
                }}
                name="checkedG"
              />
            }
            label="No"
          />
        </div>
      </div>
      {this.state.validateYesorNo && (
        <div className="da">
          <img src={warning} alt="" />
          <p className="err ml10">please select one</p>
        </div>
      )}
    </Grid>)
  }
  renderFirstForm = () => {
    return (<div className="scrollContent pl-0">
      <section>
        <Grid>
          <div>
            <label htmlFor="address" style={{ color: "#7D7D7D" }}>
              Driver Licence Number
            </label>
            <sup>*</sup>
          </div>
          <input
            className="handleState1"
            type="text"
            placeholder="Type here"
            name="driver_licence_number"
            value={this.state.data.driver_licence_number}
            onChange={(event) => this.handleInputchange(event)}
          />
          {this.state.errors.driver_licence_number && (
            <div className="da">
              <img src={warning} alt="" />
              <p className="err ml10">
                {this.state.errors.driver_licence_number}
              </p>
            </div>
          )}
        </Grid>
        <Grid>
          <div>
            <label htmlFor="address" style={{ color: "#7D7D7D" }}>
              Expiry Date
            </label>
            <sup>*</sup>
          </div>
          <div className="date">
            <input
              type="date"
              name="expiry_date"
              className="handleState2"
              placeholder="DD/MM/YYYY"
              value={this.state.data.expiry_date}
              onChange={(event) => this.handleInputchange(event)}
              autoComplete="off"
            />
            {this.state.errors.expiry_date && (
              <div className="da">
                <img src={warning} alt="" />
                <p className="err ml10">{this.state.errors.expiry_date}</p>
              </div>
            )}
          </div>
        </Grid>
        <Grid>
          <div>
            <label htmlFor="address" style={{ color: "#7D7D7D" }}>
              Registration Number{" "}
            </label>
            <sup>*</sup>
          </div>
          <input
            type="text"
            placeholder="Type here"
            name="registration_number"
            className="handleState3"
            value={this.state.data.registration_number}
            onChange={(event) => this.handleInputchange(event)}
          />
          {this.state.errors.registration_number && (
            <div className="da">
              <img src={warning} alt="" />
              <p className="err ml10">
                {this.state.errors.registration_number}
              </p>
            </div>
          )}
        </Grid>
        <Grid>
          <div>
            <label style={{ color: "#7D7D7D" }}>
              Registration Expiry Date
            </label>
            <sup>*</sup>
          </div>
          <div className="date">
            <input
              type="date"
              placeholder="DD/MM/YYYY"
              name="registration_expiry_date"
              autoComplete="off"
              value={this.state.data.registration_expiry_date}
              className="handleState handleState4"
              onChange={(event) => this.handleInputchange(event)}
            />
            {this.state.errors.registration_expiry_date && (
              <div className="da">
                <img src={warning} alt="" />
                <p className="err ml10">
                  {this.state.errors.registration_expiry_date}
                </p>
              </div>
            )}
          </div>
        </Grid>
        <Grid>
          <div>
            <label htmlFor="address" style={{ color: "#7D7D7D" }}>
              Vehicle Type
            </label>
            <sup>*</sup>
          </div>
          <select
            name="vehicle_type"
            value={this.state.data.vehicle_type}
            onChange={(event) => this.handleInputchange(event)}
            >
            <option value="" disabled selected>
              Select
            </option>
            {this.state.vehcileList.map((item: any, index: any) => (
              <option key={item?.id} value={item.id}>{item.attributes.vehicle_type}</option>
            ))}
          </select>
          {this.state.errors.vehicle_type && (
            <div className="da">
              <img src={warning} alt="" />
              <p className="err ml10">{this.state.errors.vehicle_type}</p>
            </div>
          )}
        </Grid>
        <Grid>
          <div>
            <label htmlFor="address" style={{ color: "#7D7D7D" }}>
              Make and Model
            </label>
            <sup>*</sup>
          </div>
          <input
            type="text"
            placeholder="Type here"
            name="vehicle_model"
            className="handleState5"
            value={this.state.data.vehicle_model}
            onChange={(event) => this.handleInputchange(event)}
          />
          {this.state.errors.vehicle_model && (
            <div className="da">
              <img src={warning} alt="" />
              <p className="err ml10">{this.state.errors.vehicle_model}</p>
            </div>
          )}
        </Grid>
      </section>
    </div>)
  }
  renderSecondForm = () => {
    return (
      <section className="scrollContent">
        {this.state.vehicleDetailForm === 6 &&
          <>
            <Grid>
              <div>
                <label htmlFor="address" style={{ color: "#7D7D7D" }}>
                  What type of vehicle will you be hiring? <sup>*</sup>
                </label>
              </div>
              <select
                name="vehicle_type"
                value={this.state.data.vehicle_type}
                onChange={(event) => this.handleInputchange(event)}
              >
                <option value="" disabled selected>
                  Select
                </option>
                {this.state.vehcileList.map((item: any, index: any) => (
                  <option key={item?.id} value={item.id}>{item.attributes.vehicle_type}</option>
                ))}
              </select>
              {this.state.errors.vehicle_type && (
                <div className="da">
                  <img src={warning} alt="" />
                  <p className="err ml10">{this.state.errors.vehicle_type}</p>
                </div>
              )}
            </Grid>
            <Grid>
              <div>
                <label htmlFor="address" style={{ color: "#7D7D7D" }}>
                  Driver Licence Number
                </label>
                <sup>*</sup>
              </div>
              <input
                className="handleState1 inputchange1 secondVechile"
                type="text"
                placeholder="Type here"
                name="driver_licence_number"
                value={this.state.data.driver_licence_number}
                onChange={(event) => this.handleInputchange(event)}
              />
              {this.state.errors.driver_licence_number && (
                <div className="da">
                  <img src={warning} alt="" />
                  <p className="err ml10">
                    {this.state.errors.driver_licence_number}
                  </p>
                </div>
              )}
            </Grid>
          </>}

        <Grid className="disqualifiedDrive" >
          <label style={{ color: "#7D7D7D" }}>
          Have you ever been disqualified for driving?<sup>*</sup>
          </label>
          <div className="df">
            <FormControlLabel
              id="formControlLabel3"
              className="div-checkbox mr15"
              style={this.state.disqualifiedYes ? { borderColor: "green" } : {}}
              control={
                <GreenCheckbox
                  checked={this.state.disqualifiedYes}
                  className="greenCheckBox"
                  style={{ padding: "0px 0px", margin: "1px" }}
                  onClick={() => {
                    this.setState((prev) => ({
                      ...prev,
                      disqualifiedYes: true,
                      disqualifiedNo: false,
                      data: { ...prev.data, driving_disquilified: true },
                      errors: {...prev.errors, driving_disquilified: ""}
                    }));
                  }}
                  name="checkedG"
                />
              }
              label="Yes"
            />

            <FormControlLabel
              className="div-checkbox mr15"
              id="formControlLabel4"
              style={this.state.disqualifiedNo ? { borderColor: "green" } : {}}
              control={
                <GreenCheckbox
                  checked={this.state.disqualifiedNo}
                  style={{ padding: "0px 0px", margin: "1px" }}
                  onClick={() => {
                    this.setState((prev) => ({
                      ...prev,
                      disqualifiedYes: false,
                      disqualifiedNo: true,
                      data: { ...prev.data, driving_disquilified: false },
                      errors: {...prev.errors, driving_disquilified: ""}
                    }));
                  }}
                  name="checkedG"
                />
              }
              label="No"
            />
          </div>
          {this.state.errors.driving_disquilified && (
                <div className="da">
                  <img src={warning} alt="" />
                  <p className="err ml10">
                    {this.state.errors.driving_disquilified}
                  </p>
                </div>
              )}
        </Grid >

        <Grid>
          {this.state.disqualifiedYes && <textarea
            placeholder="Provide details and dates of offences"
            name="driving_disquilified_description"
            value={this.state.data.driving_disquilified_description}
            onChange={(event) => this.handleInputchange(event)}
            style={{
              backgroundColor: "white",
              padding: "10px",
              width: "100%"
            }}
          />}
        </Grid>
        <Grid className="disqualifiedDrive">
          <label style={{ color: "#7D7D7D" }}>
            Have you ever been convicted of a criminal offence?<sup>*</sup>
          </label>
          <div className="df">
            <div>
              <FormControlLabel
                id="formControlLabel5"
                className="div-checkbox mr15"
                style={this.state.criminalOffenceYes ? { borderColor: "green" } : {}}
                control={
                  <GreenCheckbox
                    checked={this.state.criminalOffenceYes}
                    style={{ padding: "0px 0px", margin: "1px" }}
                    onClick={() => {
                      this.setState((prev) => ({
                        ...prev,
                        criminalOffenceYes: true,
                        criminalOffenceNo: false,
                        data: { ...prev.data, criminal_offence: true },
                        errors: {...prev.errors, criminal_offence: ""}
                      }));
                    }}
                    name="checkedG"
                  />
                }
                label="Yes"
              />{" "}
            </div>
            <div className="">
              <FormControlLabel
                id="formControlLabel6"
                className="div-checkbox mr15"
                style={this.state.criminalOffenceNo ? { borderColor: "green" } : {}}
                control={
                  <GreenCheckbox
                    checked={this.state.criminalOffenceNo}
                    style={{ padding: "0px 0px", margin: "1px" }}
                    onClick={() => {
                      this.noTextarea2HandlerFalse();
                      this.setState((prev) => ({
                        ...prev,
                        criminalOffenceYes: false,
                        criminalOffenceNo: true,
                        data: { ...prev.data, criminal_offence: false },
                        errors: {...prev.errors, criminal_offence: ""}
                      }));
                    }}
                    name="checkedG"
                  />
                }
                label="No"
              />
            </div>
          </div>
          {this.state.errors.criminal_offence && (
                <div className="da">
                  <img src={warning} alt="" />
                  <p className="err ml10">
                    {this.state.errors.criminal_offence}
                  </p>
                </div>
              )}
        </Grid>
        <Grid >
          {this.state.criminalOffenceYes && <textarea
            placeholder="Provide details and dates of offences"
            name="criminal_offence_description"
            value={this.state.data.criminal_offence_description}
            onChange={(event) => this.handleInputchange(event)}
            style={{
              backgroundColor: "white",
              padding: "10px",
              width: "100%"
            }}
          />}
        </Grid>
      </section >)
  }
  renderFifthForm = () => {
    return (
      <section className="scrollContent pl-0">
        {this.state.vehicleDetailForm === 5 && (
          <section>
            <Grid className="noOwnVehicle">
              <h2>
                If you do not own a vehicle you are welcome to rent one.
              </h2>
              <p>
                Please see the link below for rental companies that maybe
                useful to you. Once you have secured a rental vehicle. Please
                upload the rental document which will be requested at the
                end of the application. Your Application will not be
                considered without one.
              </p>
            </Grid>
            <div style={{ height: "250px", overflow: "auto", overflowY: "auto" }}>
              {
                this.state.rentAVechile.map((data: any, index: any) => {
                  return (
                    <Box key={data?.attributes?.link} sx={{ display: "flex" }}>
                      <Box style={{ width: "10%" }}>
                        <img src={arrowRight} />
                      </Box>
                      <Box style={{ width: "90%", height: "50%" }}>
                        <a href={data.attributes.link} target="_blank" style={{ color: "#75A2C9", cursor: "pointer", marginTop: "5px", marginBottom: "10px" }}>{data.attributes.link}</a>
                        <Box style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
                          <img src={data.attributes.logo_url} style={{ width: "120px", height: "45px", marginTop: "5px", marginBottom: "5px" }} />
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: data.attributes.description
                            }}
                            paragraph={true}
                            style={{
                              fontSize: "1em",
                              marginLeft: ".5em"
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )
                })
              }
            </div>
          </section>
        )}
      </section>
    )
  }
  render() {
    const { validationCheck } = this.state;
    return (
      <div>
        <section className="bgGrey">
          <Container style={{ padding: "0px 20%" }}>
            <section>
            <div className="bgTitleheading">
                <div className="mrTitle">
                    <img data-testId="back-button"
                        src={closearrow}
                        onClick={() => this.onClickBackButton()}
                        className="closeArrowStyle"
                    />
                </div>
                <h3 className="bgheading">Driver Application</h3>
            </div>
              {(this.state.vehicleDetailForm !== 2 && this.state.vehicleDetailForm !== 6) && this.renderCommonForm()}
            </section >
            {this.state.vehicleDetailForm === 1 && this.renderFirstForm()}
            {this.state.vehicleDetailForm === 2 && this.renderSecondForm()}
            {this.state.vehicleDetailForm === 5 && this.renderFifthForm()}
            {this.state.vehicleDetailForm === 6 && this.renderSecondForm()}
            <div className="flexEnd pt40 btn-static-div">
              <button className="btn-transparent mr15" id="saveButton"
                onClick={() => this.handleSubmitVehicleDetailForm("save", this.state.vehicleDetailForm)}>
                Save and Exit
              </button>
              <button
                className={
                  validationCheck === false
                    ? "btn-green da"
                    : "btn-green-full-opacity da"
                }
                disabled={validationCheck ? false : true}
                id="Nextbtn"
                onClick={() => {
                  this.handleSubmitVehicleDetailForm("next", this.state.vehicleDetailForm)
                }
                }
                data-testId="next-button"
              >
                Next
                <span className="ml56">
                  <NavigateNextIcon />
                </span>
              </button>
            </div>
          </Container >
        </section >
      </div >
    );
  }
}

export default VehicleDetails;
// Customizable Area End
