// Customizable Area Start
import React from 'react';
import "./css/viewApplications.css"
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Typography } from "@material-ui/core";
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import ViewApplicationsController, { Props } from '../Controller/ViewApplicationsController';
import CustomLoader from '../../../../components/src/CustomLoader.web';
import { BackArrowIc, RightArrowIc, crossImage, OvalPng, GreenTick, closeIcon } from '../assets';
import { Link } from "react-router-dom";
import { Dialog, DialogTitle, Radio, RadioGroup } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import ReceivingManagerAvailabilityModalWeb from './ReceivingManagerAvailabilityModal.web';
import AskClientToReschedule from './AskClientRescheduleModal.web';
import ConfirmedReassign from './ConfirmedReassignModal.web';
import PerDriverQuestionAns from './PerDriverQuestionAns.web';
import NativeSelect from '@material-ui/core/NativeSelect';

const styles = (theme: any) => ({
    dialogRoot: {},
    paper: {
        height: '30em',
        width: '40em',
        padding: "2em"
    },
    smallPopup: {
        width: '20em',
        height: '20em',
        padding: '2rem'
    },
    formControl: {
        margin: 0,
        minWidth: '100%',
        "& div.MuiInput-formControl::before": {
            border: "none !important",
        },
        "& select.MuiNativeSelect-select": {
            background: "transparent !important",
            padding: '10px 15px',
        },
        "& .MuiInput-underline::after": {
            border: "none !important",
        },
        "& select.MuiNativeSelect-select option:hover": {
            background: "gray !important",
            color: "white !important"
        },
        "& select.MuiNativeSelect-select option:checked": {
            background: "gray !important",
            color: "white !important"
        },
        "& select.MuiNativeSelect-icon": {
            right: "10px",
        },
    },
    selectEmpty: {
        marginRight: theme.spacing(2),
        borderRadius: 8,
        minHeight: '60px',
        border: '1px solid #e5e5e5',
    }
})

const CustomDialog = withStyles(styles)(({ classes, ...props }: any) => (
    <Dialog
        classes={{
            root: classes.dialogRoot,
            paper: classes.paper,
            smallPopup: classes.smallPopup
        }}
        {...props}
    />
));

const CustomFormLabel = withStyles({
    root: {
        "& .MuiFormControlLabel-root": {
            marginBottom: "2em",
        }

    }
})((props: any) => <FormControlLabel {...props} />);

class ViewApplications extends ViewApplicationsController {
    statusReasonList: string | number | string[] | undefined;

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    options = ['Code Contract', 'CBD Contract', 'CBD Express Contract'];

    handleEmptyWorkExprienceUi = () => {
        return (
            <div className='margin-47'>
                <p className='personal-details'>Work Experience :</p>
                <Grid container className='margin-24'>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Company name </p>
                            <p className='details-text'>--</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>From </p>
                            <p className='details-text'>--</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>To </p>
                            <p className='details-text'>--</p>
                        </div>
                    </Grid>
                </Grid>

                <Grid container className='margin-32'>
                    <Grid item xs={12}>
                        <div>
                            <p className='details-title'>Reason for leaving</p>
                            <p className='details-text'>--</p>
                        </div>
                    </Grid>
                </Grid>
                <hr className='hr1 margin-47' />
            </div>
        )
    }

    handleBankDetailsUi = (data: any) => {
        return (
            <>
                <p className='personal-details'>Bank Details :</p>
                <Grid container className='margin-24'>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Account name </p>
                            <p className='details-text'>{this.handleNullChecker(data?.account_name)}</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>BSB number </p>
                            <p className='details-text'>{this.handleNullChecker(data?.BSB_number)}</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Account number </p>
                            <p className='details-text'>{this.handleNullChecker(data?.account_number)}</p>
                        </div>
                    </Grid>
                </Grid>

                <Grid container className='margin-32'>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Bank name </p>
                            <p className='details-text'>{this.handleNullChecker(data?.bank_name)}</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <div>
                            <p className='details-title'>GST registered </p>
                            <p className='details-text'>{this.handleBoolenCheck(data?.GST_registered)}</p>
                        </div>
                    </Grid>

                </Grid>

                <Grid container className='margin-32'>
                    <Grid item xs={12}>
                        <div>
                            <p className='details-title'>Any pre-existing injury or medical condition/disability that would effect ability to do this work?</p>
                            <p className='details-text'>{this.handleBoolenCheck(data?.medical_condition)}</p>
                        </div>
                    </Grid>
                </Grid>
                <hr className='hr1 margin-47' />
            </>
        )
    }

    handleEmptyBankDetailsUi = () => {
        return (
            <>
                <p className='personal-details'>Bank Details :</p>
                <Grid container className='margin-24'>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Account name </p>
                            <p className='details-text'>--</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>BSB number </p>
                            <p className='details-text'>--</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Account number </p>
                            <p className='details-text'>--</p>
                        </div>
                    </Grid>
                </Grid>

                <Grid container className='margin-32'>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Bank name </p>
                            <p className='details-text'>--</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <div>
                            <p className='details-title'>GST registered </p>
                            <p className='details-text'>--</p>
                        </div>
                    </Grid>

                </Grid>

                <Grid container className='margin-32'>
                    <Grid item xs={12}>
                        <div>
                            <p className='details-title'>Any pre-existing injury or medical condition/disability that would effect ability to do this work?</p>
                            <p className='details-text'>--</p>
                        </div>
                    </Grid>
                </Grid>
                <hr className='hr1 margin-47' />
            </>
        )
    }

    handleEmergencySectionUi = (data: any) => {
        return (
            <>
                <p className='personal-details'>Emergency Contact :</p>
                <Grid container className='margin-24'>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Name</p>
                            <p className='details-text'>{this.handleNullChecker(data?.name)}</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Relationship </p>
                            <p className='details-text'>{this.handleNullChecker(data?.relationship)}</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Aus mobile number </p>
                            <p className='details-text'>{this.handleNullChecker(data?.phone_number)}</p>
                        </div>
                    </Grid>
                </Grid>

                <Grid container className='margin-32'>
                    <Grid item xs={12} md={8}>
                        <div>
                            <p className='details-title'>Address</p>
                            <p className='details-text'>{this.handleNullChecker(data?.address)}</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Postcode</p>
                            <p className='details-text'>{this.handleNullChecker(data?.post_code)}</p>
                        </div>
                    </Grid>

                </Grid>


                <hr className='hr1 margin-47' />
            </>
        )
    }

    handleEmptyEmergencySectionUi = () => {
        return (
            <>
                <p className='personal-details'>Emergency Contact :</p>
                <Grid container className='margin-24'>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Name</p>
                            <p className='details-text'>--</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Relationship </p>
                            <p className='details-text'>--</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Aus mobile number </p>
                            <p className='details-text'>--</p>
                        </div>
                    </Grid>
                </Grid>

                <Grid container className='margin-32'>
                    <Grid item xs={12} md={8}>
                        <div>
                            <p className='details-title'>Address </p>
                            <p className='details-text'>--</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Postcode</p>
                            <p className='details-text'>--</p>
                        </div>
                    </Grid>

                </Grid>


                <hr className='hr1 margin-47' />
            </>
        )
    }

    handleVehicaleDetailsUi = (data: any) => {
        return (
            <div>
                <Grid container className='margin-32'>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Driver licence number </p>
                            <p className='details-text'>{this.handleNullChecker(data?.driver_licence_number)}</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Expiry date </p>
                            <p className='details-text'>{this.handleDateNullChecker(data?.expiry_date)}</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Registration number </p>
                            <p className='details-text '>{this.handleNullChecker(data?.registration_number)}</p>
                        </div>
                    </Grid>
                </Grid>

                <Grid container className='margin-32'>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Registration expiry date </p>
                            <p className='details-text'>{this.handleDateNullChecker(data?.registration_expiry_date)}</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Vehicle type</p>
                            <p className='details-text'>{this.handleNullChecker(data?.vehicle)}</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Make & Model </p>
                            <p className='details-text '>{this.handleNullChecker(data?.vehicle_model)} </p>
                        </div>
                    </Grid>
                </Grid>
                <Grid container className='margin-32'>
                    <Grid item xs={12}>
                        <div>
                            <p className='details-title'>Have you ever been disqualified for driving ?</p>
                            <p className='details-text'>{this.handleBoolenCheck(data?.driving_disquilified)}</p>
                        </div>
                    </Grid>
                </Grid>
                <Grid container className='margin-32'>
                    <Grid item xs={12}>
                        <div>
                            <p className='details-title'>Have you ever been convicted of a criminal offence ?</p>
                            <p className='details-text'>{this.handleBoolenCheck(data?.criminal_offence)}</p>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }

    handleEmptyVehicaleDetailsUi = () => {
        return (
            <div>
                <Grid container className='margin-32'>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Driver licence number </p>
                            <p className='details-text'>--</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Expiry date </p>
                            <p className='details-text'>--</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Registration number </p>
                            <p className='details-text '>--</p>
                        </div>
                    </Grid>
                </Grid>

                <Grid container className='margin-32'>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Registration expiry date </p>
                            <p className='details-text'>--</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Vehicle type</p>
                            <p className='details-text'>--</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Make & Model </p>
                            <p className='details-text '>-- </p>
                        </div>
                    </Grid>
                </Grid>
                <Grid container className='margin-32'>
                    <Grid item xs={12}>
                        <div>
                            <p className='details-title'>Have you ever been disqualified for driving ?</p>
                            <p className='details-text'>--</p>
                        </div>
                    </Grid>
                </Grid>
                <Grid container className='margin-32'>
                    <Grid item xs={12}>
                        <div>
                            <p className='details-title'>Have you ever been convicted of a criminal offence ?</p>
                            <p className='details-text'>--</p>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }

    vehiclesSection = () => {
        const { userDetails } = this.state
        return (<div className='margin-47' id='vehicle'>
            <div className='vehicale-title'>
                <p className='personal-details'>Vehicle Details :</p>
                <div className='own-vehicale-details'>
                    <p className='own-vehicale'>Own vehicle</p>
                    {
                        userDetails?.attributes?.own_vehicle === null ?
                            <p className='NO'>--</p>
                            :
                            <p className={`${this.handleBoolenCheck(userDetails?.attributes?.vehicle_details?.data?.attributes?.own_vehicle)}`}>{this.handleBoolenCheck(userDetails?.attributes?.vehicle_details?.data?.attributes?.own_vehicle)}</p>

                    }
                </div>
            </div>
            {userDetails?.attributes?.vehicle_details?.data === null ?
                this.handleEmptyVehicaleDetailsUi()

                : this.handleVehicaleDetailsUi(userDetails?.attributes?.vehicle_details?.data?.attributes)

            }
        </div>)
    }

    workExperienceSection = () => {
        const { userDetails } = this.state
        return (<div id='work-experience' className='margin-47'>
            {userDetails?.attributes?.career_experiences.length > 0 ?
                <>
                    <p className='personal-details'>Work Experience :</p>
                    {userDetails.attributes.career_experiences.map((values: any) =>
                        <div key={values.data.id}>
                            <Grid container className='margin-24'>
                                <Grid item xs={12} md={4}>
                                    <div>
                                        <p className='details-title'>Company name </p>
                                        <p className='details-text'>{this.handleNullChecker(values?.data?.attributes?.company_name)}</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <div>
                                        <p className='details-title'>From </p>
                                        <p className='details-text'>{this.handleDateNullChecker(values?.data?.attributes?.start_date)}</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <div>
                                        <p className='details-title'>To </p>
                                        <p className='details-text'>{this.handleDateNullChecker(values?.data?.attributes?.end_date)}</p>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container className='margin-32'>
                                <Grid item xs={12}>
                                    <div>
                                        <p className='details-title'>Reason for leaving</p>
                                        <p className='details-text'>{this.handleNullChecker(values?.data?.attributes?.reason_for_leaving)}</p>
                                    </div>
                                </Grid>
                            </Grid>
                            <hr className='hr1 margin-47' />
                        </div>
                    )}
                </>
                : <>{this.handleEmptyWorkExprienceUi()}</>
            }
        </div>
        )
    }

    bankDetailsSection = () => {
        const { userDetails } = this.state
        return (<div className='margin-47' id='bank-details'>
            {userDetails?.attributes?.bank_detail.data === null ?
                this.handleEmptyBankDetailsUi() : this.handleBankDetailsUi(userDetails?.attributes?.bank_detail?.data?.attributes)
            }
        </div>)
    }

    emergencySection = () => {
        const { userDetails } = this.state
        return (<div className='margin-47' id='emergency'>
            {userDetails?.attributes?.emergency_contact.data === null ?
                this.handleEmptyEmergencySectionUi() : this.handleEmergencySectionUi(userDetails?.attributes?.emergency_contact?.data?.attributes)
            }
        </div>)
    }

    handleCurrentStateofApplication = () => {
        const StatusParam: any = this.state.userDetails?.attributes?.status
        return (<>
            {StatusParam === "pending_approval" &&
                <>
                    <div className='button-section'>
                        <button className='reject' onClick={this.handleApplicationReject} >Reject</button>
                        <button className='approve' onClick={this.handleAplicationsApproval}>Approve</button>
                    </div>

                    {/* Modal for the selecting template */}
                    <CustomDialog onClose={() => { this.setState({ openrReasonList: false }) }} open={this.state.openrReasonList} sx={{ height: "18em", width: "31em" }}>
                        <div className="crossButtonContainer closeIcon">
                            <img src={closeIcon} onClick={() => { this.setState({ openrReasonList: false }) }} />
                        </div>
                        <p className='popupHead'>Please select below reason</p>
                        <div className='reasonList'>
                            <select value={this.statusReasonList} onChange={e => this.handleChangeStausId(e.target.value)}>
                                <option value="" disabled selected>
                                    Select
                                </option>
                                {
                                    this.state.statusReasonList.map((item: any) => {
                                        return (
                                            <option key={item.id} value={item.id}>{item.attributes.reason_text}</option>
                                        )
                                    })
                                }
                            </select >
                        </div >
                        <div className="continueButtonContiner">
                            <div className='button-section'>
                                <button className='reject' onClick={this.handleAplicationsReject} >Reject</button>
                            </div>
                        </div>
                    </CustomDialog >
                </>
            }
            {
                StatusParam === "background_checks_completed" &&
                <div className='button-section'>
                    <button className='upload' onClick={this.handleAplicationsUpload}>Upload Contract</button>
                </div>
            }

            {
                (StatusParam === "induction_booked" || StatusParam === "background_checks_required") &&
                <div className='button-section'>
                    <button
                        className='edit-group-button'
                        onClick={() => this.handleRequestbackgroundCheck()}
                    >Request background check</button>
                </div>
            }

        </>)
    }

    inductionDetailUI = ({ StatusParam, opManagersList, classes, userDetails }: any) => {
        const { start, officer_name, location } = userDetails?.attributes?.appointments?.data?.attributes ?? {};
        const currUserId = localStorage.getItem('userId')
        return (
            <>
                {
                    StatusParam === "induction_booked" && (
                        <>
                            <div className='margin-47'>
                                <p className='personal-details' id="inductionSlot">Induction slot details :</p>
                                <div >
                                    <Grid container className='margin-24'>
                                        <Grid item xs={12} md={4}>
                                            <div>
                                                <p className='details-title'>Induction slot date </p>
                                                <p className='details-text'>{moment(start).format('L')}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <div>
                                                <p className='details-title'>Time </p>
                                                <p className='details-text'>{moment(start).format('LT')}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <div>
                                                <p className='details-title'>Location </p>
                                                <p className='details-text'>{location}</p>
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container className='margin-32'>
                                        <Grid item xs={12}>
                                            <div>
                                                <p className='details-title'>Officer</p>
                                                <p className='details-text'>{officer_name}</p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container className='margin-32 ask_reschedule_box'>
                                        <Grid item xs={12}>
                                            <div>
                                                <button className='ask-reschedule-link' onClick={() => this.handleAskToReScheduleClick()}>Ask client to reschedule?</button>
                                                <span className='details-title'>Send as an email notification asking client to book again</span>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                            <hr className='hr1 margin-47' />
                            <div className='margin-47'>
                                <p className='personal-details' id="reassign">Reassign to another Operational Manager :</p>
                                <div >
                                    <Grid container className='margin-24'>
                                        <Grid item xs={12} md={8} lg={4} className='select_manager_grid selectDropDown'>
                                            <FormControl className={classes.formControl}>
                                                <NativeSelect
                                                    className={classes.selectEmpty}
                                                    value={this.state.selectedManager}
                                                    onChange={this.handleChange}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <option value="" disabled> Select Manager </option>
                                                    {opManagersList && opManagersList.length > 0 && opManagersList.filter((obj: any) => obj.id !== Number(currUserId) && obj.name !== null).map((item: any) => { return (<option value={item.id}>{item.name}</option>) })}
                                                </NativeSelect>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={4} lg={3}>
                                            <button data-testid="checkScheduleBtn" disabled={this.state.selectedManager === "" ? true : false} className="checkCalender" onClick={this.checkSchedule}> Check Calendar </button>
                                        </Grid>

                                        <Grid item xs={12} md={12} style={{ marginTop: "20px" }}>
                                            <button data-testid="cancelAssignmentBtn" className="cancelAssignBtn" onClick={() => this.handleCancleAssignment()}> Cancel</button>
                                            <button data-testid="confirmAssignmentBtn" disabled={this.state.selectedManager === "" ? true : false} onClick={() => this.handleConfirmAssignment()} className="btnMobile checkCalender">Confirm</button>
                                        </Grid >

                                    </Grid >


                                    <hr className='hr1 margin-47' />
                                </div >
                            </div >
                        </>
                    )
                }
            </>
        )
    }

    selectTemplateModal = ({ selectedOption, userDetails, openSelectTemplate }: any) => {
        return (
            <CustomDialog onClose={() => { this.setState({ openSelectTemplate: false }) }} open={openSelectTemplate} sx={{ height: "31em", width: "41em" }}>
                <DialogTitle>
                    <div className="crossButtonContainer">
                        <img src={crossImage} onClick={() => { this.setState({ openSelectTemplate: false }) }} />
                    </div>
                    <div className='titleContainer'>
                        <p className='selectTemplateText'>
                            Select Template
                        </p>
                        <p className='uploadContractText'>
                            Upload contract
                        </p>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <RadioGroup
                        value={selectedOption}
                        onChange={this.handleTemplateOptionChange}
                        className="radioGroup"
                    >
                        {this.options.map((option) => (
                            <CustomFormLabel
                                key={option}
                                value={option}
                                control={<Radio className="radioButton"
                                    checkedIcon={<img src={GreenTick} width={35} height={35} />}
                                    icon={<img src={OvalPng} width={25} height={25} />}

                                />}
                                label={<span className="label">{option}</span>}
                                className={selectedOption === option ? "formControlLabelactive" : "formControlLabel"}
                            />
                        ))}
                    </RadioGroup>
                </DialogContent>
                <div className="continueButtonContiner">
                    {selectedOption !== "" ?
                        <Link
                            to={{
                                pathname: '/operation-manager/contractInformation',
                                state: { id: 1, name: selectedOption, driverId: userDetails.id },
                            }}

                        >
                            <button disabled={selectedOption === "" ? true : false} className='continueButton'>Continue</button>
                        </Link> :
                        <button disabled={selectedOption === "" ? true : false} className='continueButton'>Continue</button>
                    }
                </div>

            </CustomDialog >
        )
    }

    customDialogSelectTemplate = ({ openSelectTemplate, selectedOption, userDetails }: any) => {
        return (
            <CustomDialog onClose={() => { this.setState({ openSelectTemplate: false }) }} open={openSelectTemplate} sx={{ height: "31em", width: "41em" }}>
                <DialogTitle>
                    <div className="crossButtonContainer">
                        <img src={crossImage} onClick={() => { this.setState({ openSelectTemplate: false }) }} />
                    </div>
                    <div className='titleContainer'>
                        <p className='selectTemplateText'>
                            Select Template
                        </p>
                        <p className='uploadContractText'>
                            Upload contract
                        </p>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <RadioGroup
                        value={selectedOption}
                        onChange={this.handleTemplateOptionChange}
                        className="radioGroup"
                    >
                        {this.options.map((option) => (
                            <CustomFormLabel
                                key={option}
                                value={option}
                                control={<Radio className="radioButton"
                                    checkedIcon={<img src={GreenTick} width={35} height={35} />}
                                    icon={<img src={OvalPng} width={25} height={25} />}

                                />}
                                label={<span className="label">{option}</span>}
                                className={selectedOption === option ? "formControlLabelactive" : "formControlLabel"}
                            />
                        ))}
                    </RadioGroup>
                </DialogContent>
                <div className="continueButtonContiner">
                    {selectedOption !== "" ?
                        <Link
                            to={{
                                pathname: '/operation-manager/contractInformation',
                                state: { id: 1, name: selectedOption, driverId: userDetails.id },
                            }}

                        >
                            <button disabled={selectedOption === "" ? true : false} className='continueButton'>Continue</button>
                        </Link> :
                        <button disabled={selectedOption === "" ? true : false} className='continueButton'>Continue</button>
                    }
                </div>

            </CustomDialog>
        )
    }

    render() {
        const { userDetails, applicationsLoader, opManagersList } = this.state
        const { classes } = this.props
        const StatusParam: any = this.state.userDetails?.attributes?.status
        const hasMap: any = {
            draft: 'color-blue',
            pending_approval: 'color-orange',
            pending_induction: 'color-orange',
            induction_booked: 'color-orange',
            cancelled_slot: 'color-orange',
            contract_signature_req: 'color-orange',
            contract_signed: 'color-green',
            application_rejected: 'color-red',
            documents_requested: 'color-orange',
            background_checks_required: 'color-orange',
            background_checks_completed: 'color-purple'
        }

        return (
            <div>
                {applicationsLoader ? <CustomLoader Open={applicationsLoader} /> :
                    <div className='view-applications'>
                        <Breadcrumbs separator={<img src={RightArrowIc} height={20} width={11} />} aria-label="breadcrumb">
                            <Link className='bread-1' to="/operation-manager/driver-applications" >
                                Driver Application
                            </Link>
                            <Typography color="textPrimary">View Application</Typography>
                        </Breadcrumbs>

                        <div className='drive-approval'>
                            <div className='right-section'>
                                <Link to={`/operation-manager/driver-applications`}>
                                    <img
                                        src={BackArrowIc}
                                        height={24}
                                        width={24}
                                        alt=""
                                    />
                                </Link>

                                <div>
                                    <p className='driver-id'>Driver ID -<span> {this.state.userDetails?.attributes?.driver_uniq_id}</span></p>
                                    <p className='driver-status'>Status -  <span className={hasMap[StatusParam]}>{userDetails?.attributes && this.removeUnderscoreAndCapitalize(userDetails.attributes.status)}</span></p>
                                </div>
                            </div>
                            {this.handleCurrentStateofApplication()}
                        </div>

                        <div className='view-certificates-section'>
                            <div className='grp-btn-div'>
                                <button className='group-button'>
                                    <a href='#personal-details' className='anchor-btn'>
                                        Personal Details
                                    </a>
                                </button>
                                <button className='group-button'>
                                    <a href='#work-experience' className='anchor-btn'>
                                        Work Experience
                                    </a>
                                </button>
                                <button className='group-button'>
                                    <a href='#emergency' className='anchor-btn'>
                                        Emergency Contact
                                    </a>
                                </button>
                                <button className='group-button'>
                                    <a href='#bank-details' className='anchor-btn'>
                                        Bank Details
                                    </a>
                                </button>
                                <button className='group-button'>
                                    <a href='#vehicle' className='anchor-btn'>
                                        Vehicle Details
                                    </a>
                                </button >
                                <button className='group-button'>
                                    <a href='#question-bank' className='anchor-btn'>
                                        Multiple choice
                                    </a>
                                </button>
                                {
                                    StatusParam === "induction_booked" && (
                                        <>
                                            <button className='group-button'>
                                                <a href='#inductionSlot' className='anchor-btn'>
                                                    Induction slot
                                                </a>
                                            </button>
                                            <button className='group-button'>
                                                <a href='#reassign' className='anchor-btn'>
                                                    Reassign
                                                </a>
                                            </button>
                                        </>
                                    )}
                            </div >

                            <div>
                                <Link to={`/operation-manager/viewCertificate?status=${StatusParam}&id=${userDetails.id}`}>
                                    <button className='documents'>
                                        View certificates & Documents
                                    </button>
                                </Link>
                            </div>
                        </div >

                        <div className='details-section'>
                            {/* Persoanl Details */}
                            <div id='personal-details'>
                                <div className='personal-details-and-edit-div'>
                                    <p className='personal-details'>Personal Details :</p>
                                    <Link to={`/operation-manager/edit?id=${userDetails.id}`}>
                                        <button className='edit-group-button'>
                                            Edit
                                        </button>
                                    </Link></div>
                                <Grid container className='margin-24' >
                                    <Grid item xs={12} md={4}>
                                        <div>
                                            <p className='details-title'>Full name </p>
                                            <p className='details-text'>{`${this.handleNullChecker(userDetails?.attributes?.full_name)}`}</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div>
                                            <p className='details-title'>Gender </p>
                                            <p className='details-text'>{this.handleGenderChecker(userDetails?.attributes?.gender)}</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div>
                                            <p className='details-title'>Date of birth </p>
                                            <p className='details-text'>{this.handleDateNullChecker(userDetails?.attributes?.date_of_birth)}</p>
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container className='margin-32' >
                                    <Grid item xs={12}>
                                        <div>
                                            <p className='details-title'>Address </p>
                                            <p className='details-text'>{this.handleNullChecker(userDetails?.attributes?.address)}</p>
                                        </div>
                                    </Grid>
                                </Grid>


                                <Grid container className='margin-32' >
                                    <Grid item xs={12} md={4}>
                                        <div>
                                            <p className='details-title'>Suburb </p>
                                            <p className='details-text'>{this.handleNullChecker(userDetails?.attributes?.suburb)}</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div>
                                            <p className='details-title'>State </p>
                                            <p className='details-text'>{this.handleNullChecker(userDetails?.attributes?.state_name)}</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div>
                                            <p className='details-title'>Postcode </p>
                                            <p className='details-text'>{this.handleNullChecker(userDetails?.attributes?.postcode)}</p>
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container className='margin-32' >
                                    <Grid item xs={12} md={4}>
                                        <div>
                                            <p className='details-title'>Aus mobile number </p>
                                            <p className='details-text'>{this.handleNullChecker(userDetails?.attributes?.full_phone_number)}</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div>
                                            <p className='details-title'>Email </p>
                                            <p className='details-text'>{this.handleNullChecker(userDetails?.attributes?.email)}</p>
                                        </div>
                                    </Grid>

                                </Grid>
                                <hr className='hr1 margin-47' />
                                <Grid container className='margin-32' >
                                    <Grid item xs={12} md={4}>
                                        <div>
                                            <p className='details-title'>Australian citizen </p>
                                            <p className='details-text'>{this.handleBoolenCheck(userDetails?.attributes?.australian_citizen)}</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div>
                                            <p className='details-title'>Business type </p>
                                            <p className='details-text'>{this.handleNullChecker(userDetails?.attributes?.business_type)}</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div>
                                            <p className='details-title'>ABN number </p>
                                            <p className='details-text number-color'>{this.handleNullChecker(userDetails?.attributes?.ABN_number)}</p>
                                        </div>
                                    </Grid>


                                </Grid>

                                <Grid container className='margin-32'>

                                    <Grid item xs={12} md={4}>
                                        <div>
                                            <p className='details-title'>How did you hear about us? </p>
                                            <p className='details-text'>{this.handleNullChecker(userDetails?.attributes?.how_do_you_hear_about_us)}</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div>
                                            <p className='details-title'>Enter Colleague name? </p>
                                            <p className='details-text'>{this.handleNullChecker(userDetails?.attributes?.search_description)}</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div>
                                            <p className='details-title'>ACN number </p>
                                            <p className='details-text number-color'>{this.handleNullChecker(userDetails?.attributes?.ACN_number)}</p>
                                        </div>
                                    </Grid>
                                </Grid>
                                <hr className='hr2 margin-32' />
                            </div>
                            {/* Work exprience */}
                            {this.workExperienceSection()}
                            {this.emergencySection()}
                            {/* Bank Details */}
                            {this.bankDetailsSection()}
                            {/* Vehicle Details*/}
                            {this.vehiclesSection()}

                            <hr className='hr1 margin-47' />
                            <div id="question-bank">
                                <p className='personal-details margin-47' style={{ marginBottom: "20px" }}>Multiple choice :</p>
                                <PerDriverQuestionAns data={userDetails?.attributes?.responses?.data} />
                            </div>

                            {this.inductionDetailUI({ StatusParam, opManagersList, classes, userDetails })}

                        </div>
                        {/* Modal for the selecting template */}
                        {this.selectTemplateModal({ selectedOption: this.state.selectedOption, userDetails, openSelectTemplate: this.state.openSelectTemplate })}
                    </div >
                }
                {this.customDialogSelectTemplate({ openSelectTemplate: this.state.openSelectTemplate, selectedOption: this.state.selectedOption, userDetails })}

                {
                    this.state.isShowOpManagerAvailability &&
                    <ReceivingManagerAvailabilityModalWeb isShowOpManagerAvailability={this.state.isShowOpManagerAvailability} handleClose={this.handleClose} userDetails={this.state.userDetails} selectedManager={this.state.selectedManager} />
                }
                {
                    this.state.isOpenConfirmedSuccessModal &&
                    <ConfirmedReassign userDetails={this.state.userDetails} handleCloseConfirmedSuccessModal={this.handleCloseConfirmedSuccessModal} isOpenConfirmedSuccessModal={this.state.isOpenConfirmedSuccessModal} />
                }
                {this.state.isOpenRescheduleConfimationModal &&
                    <AskClientToReschedule userDetails={this.state.userDetails} isOpenRescheduleConfimationModal={this.state.isOpenRescheduleConfimationModal} closeAskToRescheduleConfirmationModal={this.closeAskToRescheduleConfirmationModal} />
                }
            </div >
        );
    }
}
export { ViewApplications }
export default withStyles(styles, { withTheme: true })(ViewApplications)
// Customizable Area End 