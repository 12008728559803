// Customizable Area Start
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import SettingsPageController, { Props } from '../Controller/SettingsPageController';
import { imgPasswordVisible, visibilityOff } from "../assets";
import "./css/settingsPage.css"
import moment from 'moment';

export const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class SettingsPage extends SettingsPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    passWordVisibility = (showPassword: any) => {
        return showPassword ? imgPasswordVisible : visibilityOff
    }

    render() {
        const {
            current_password,
            new_password,
            confirm_password,
            errors,
            email, showCurrentPassword,
            showNewPassword,
            showConfirmPassword,
            name,
            stateName
        } = this.state;

        const { currentPassword,
            newPassword,
            confirmPassword
        } = errors
        const date = new Date()
        const currentDate = moment(date).format('L')
        const currentDay = moment(date).format('dddd')
        return (
            <div className='main-form' >
                <div className='date-box-form'>{`${currentDate} ${currentDay}`}</div>
                <div className='time-box'>{moment(new Date()).format('LT')}</div>
                <div className='setting-head'>Settings</div>
                <Snackbar open={this.state.showSnackBar} autoHideDuration={6000} onClose={this.handleCloseSnackBar}>
                    <Alert onClose={this.handleCloseSnackBar} severity="success">
                        Password reset successfully
                    </Alert>
                </Snackbar>
                <form className='form-box' onSubmit={this.handleSubmit}>
                    <div className='form-group-setting'>
                        <label className='label-heading' htmlFor="name">Name:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-input emailInput"
                            value={name}
                            disabled
                        />
                    </div>
                    <div className='form-group-setting'>
                        <label className='label-heading' htmlFor="state">state:</label>
                        <input
                            type="text"
                            id="state"
                            name="state"
                            className="form-input emailInput"
                            value={stateName}
                            disabled
                        />
                    </div>

                    <div className='form-group-setting'>
                        <label className='label-heading' htmlFor="email">Email:</label>
                        <input
                            type="text"
                            id="email"
                            name="email"
                            className="form-input emailInput"
                            value={email}
                            disabled
                        />
                    </div>

                    <div className='form-group-setting'>
                        <label className='label-heading' htmlFor="currentPassword">Current Password:</label>
                        <div className='eyeposition'>
                            <input
                                type={showCurrentPassword ? "text" : "password"}
                                id="currentPassword"
                                className={`form-input ${currentPassword && 'errorInput'}`}
                                name="current_password"
                                value={current_password}
                                onChange={this.handleChange}
                            />
                            <img src={this.passWordVisibility(showCurrentPassword)} data-testId="currentPasswordEye" onClick={() => this.visibilitySetter("showCurrentPassword", showCurrentPassword)} className="passwordeyes"></img>
                        </div>
                        {currentPassword && (
                            <span className="error">{currentPassword}</span>
                        )}
                    </div>

                    <div className='form-group-setting'>
                        <label className='label-heading' htmlFor="newPassword">New Password:</label>
                        <div className='eyeposition'>
                            <input
                                type={showNewPassword ? "text" : "password"}
                                id="newPassword"
                                className={`form-input ${newPassword ? 'errorInput' : ""}`}
                                name="new_password"
                                value={new_password}
                                onChange={this.handleChange}
                            />
                            <img src={this.passWordVisibility(showNewPassword)} data-testId="newPasswordEye" onClick={() => this.visibilitySetter("showNewPassword", showNewPassword)} className="passwordeyes"></img>
                        </div>

                        {newPassword && (
                            <span className="error">{newPassword}</span>
                        )}
                    </div>

                    <div className='form-group-setting'>
                        <label className='label-heading' htmlFor="confirmNewPassword">Confirm Password:</label>
                        <div className='eyeposition'>
                            <input
                                type={showConfirmPassword ? "text" : "password"}
                                id="confirmNewPassword"
                                className={`form-input ${confirmPassword ? 'errorInput' : ""}`}
                                name="confirm_password"
                                value={confirm_password}
                                onChange={this.handleChange}
                            />
                            <img src={this.passWordVisibility(showConfirmPassword)} data-testId="confirmPasswordEye" onClick={() => this.visibilitySetter("showConfirmPassword", showConfirmPassword)} className="passwordeyes"></img>
                        </div>
                        {confirmPassword && (
                            <span className="error">{confirmPassword}</span>
                        )}
                    </div>
                    <div className='btn-form'><button className='submit-form-btn' type="submit">Save</button></div>
                </form>
            </div>
        );
    }
}

export default SettingsPage;


// Customizable Area End
