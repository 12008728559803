import React, { Component } from 'react'
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
interface Props {
    isOpenConfirmedSuccessModal: boolean,
    handleCloseConfirmedSuccessModal: () => void,
    userDetails: any
}

class ConfirmedReassign extends Component<Props> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        const { isOpenConfirmedSuccessModal, userDetails, handleCloseConfirmedSuccessModal } = this.props
        const { data } = userDetails
        const assignedManagerName = data?.attributes?.reassigned_to_op ? data.attributes.reassigned_to_op : ''
        return (
            <Dialog aria-labelledby="customized-dialog-title" onClose={() => handleCloseConfirmedSuccessModal()} maxWidth={'md'} open={isOpenConfirmedSuccessModal} PaperProps={{
                style: {
                    padding: "16px 20px",
                    minWidth: "600px",
                    borderRadius: 0,
                    border: "2px solid #494949",
                },
            }}>
                <DialogContent>
                    <div className='modal-reassign'>
                        <h2>Reassign to another Operational Manager</h2>
                        <p className='confirm-text'>Confirmed</p>
                        <div className='id-desc'>
                            <p>Driver ID - <span>{data && data.id}</span>Sent to <span>{assignedManagerName}</span></p>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }
}
export default ConfirmedReassign

