// Customizable Area Start
import React, { Component } from 'react';
import { ActiveApplicationIC, ActiveDriverApplicationIC, ActiveSettingsIC, ApplicationIC, DriverApplicationIC, SettingsIC } from '../assets';
import "./sidebar.css";
import { Link } from 'react-router-dom';

interface SidebarItemProps {
    name: string;
    link: string;
    active: boolean;
    icon: string;
    history: any;
    sidebarClasses: string;
    ActiveIcon: string;
    open: boolean;
    clicked: any;

    onItemClick: (name: string, history: any) => void;
}

export const SidebarItem = ({ name, link, active, icon, onItemClick, ActiveIcon }: SidebarItemProps) => (
    <li className={active ? "active" : ""}
    >
        <Link to={link} onClick={() => onItemClick(name, history)}>
            <img className='icons' src={active ? ActiveIcon : icon} alt="" />
            <p className='margin-0'>{name}</p>
        </Link>
    </li>
);

interface SidebarState {
    items: {
        name: string;
        link: string;
        icon: string;
        activeIcon: string;
    }[];
    activeItem: string;
    collapsed: boolean;
    open: boolean;
}

class DashboardSidebar extends Component<{}, SidebarState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            open: false,
            items: [
                { name: 'Driver applications', link: '/operation-manager/driver-applications', icon: DriverApplicationIC, activeIcon: ActiveDriverApplicationIC },
                { name: 'Calendar', link: '/operation-manager/calendar', icon: ApplicationIC, activeIcon: ActiveApplicationIC },
                { name: 'Settings', link: '/operation-manager/settings', icon: SettingsIC, activeIcon: ActiveSettingsIC },
                { name: 'New application', link: '/?redirected_from=manager', icon: ApplicationIC, activeIcon: ActiveApplicationIC },

            ],
            activeItem: '',
            collapsed: window.innerWidth < 992
        };
    }

    handleItemClick = (name: string, history: any) => {
        this.setState({
            activeItem: name,
            open: !this.state.open,
        });
        if (name === 'New application') {
            window.localStorage.setItem('redirected_from', 'manager')
            history.push('/')
        }
    }

    handleClickMenuBar = () => {
        this.setState({
            open: !this.state.open
        })
    }

    handleWindowResize = () => {
        this.setState({ collapsed: window.innerWidth < 992 }); // update the collapsed state based on the window width
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
        let url = window.location.href;
        const segments = url.split("/");
        const lastSegment = segments[segments.length - 1];
        const hashMap: any = {
            "driver-applications": "Driver applications",
            "calendar": "Calendar",
            "available-appointment": "Calendar",
            "viewApplications": "Driver applications",
            "viewCertificate": "Driver applications",
            "edit": "Driver applications",
            "settings": "Settings",
            "contractInformation": "Driver applications"
        }
        const cleanedUrl = lastSegment.replace(/\?.*/, "");
        let lastActive = hashMap[cleanedUrl]
        this.setState({ activeItem: lastActive });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    render() {
        const { collapsed } = this.state;
        const sidebarClasses = (window.location.pathname.includes("viewApplications")
            || window.location.pathname.includes("viewCertificate") || collapsed
            || window.location.pathname.includes("edit") || collapsed)
            ? "sidebar collapsed" : "sidebar";
        const menuList = <div className={sidebarClasses}>
            <ul>
                {this.state.items.map(item => (
                    <SidebarItem
                        key={item.name}
                        name={item.name}
                        link={item.link}
                        icon={item.icon}
                        ActiveIcon={item.activeIcon}
                        sidebarClasses={sidebarClasses}
                        active={item.name === this.state.activeItem}
                        onItemClick={this.handleItemClick} history={undefined} open={false} clicked={undefined} />
                ))}
            </ul>
        </div>

        return (
            <>
                <span className='menuIcon' onClick={this.handleClickMenuBar}>
                    Click
                </span>
                {this.state.open ?
                    <div className='mobileMenu'>{menuList}</div>
                    : ''}

                <div className='desktopMenu'>
                    {menuList}
                </div>

            </>
        );
    }
}

export default DashboardSidebar;
// Customizable Area End