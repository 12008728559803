// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

import { TopBarContextType, defaultContext } from "./TopbarContext";
import { IBlock } from "../../../framework/src/IBlock";

export const configJSON = require("../config");

export interface Props {
  navigation?: any;
  id?: string;
  children?: JSX.Element | JSX.Element[] | string | string[];
}

interface S extends TopBarContextType {
  isloading: boolean;
  errorMsg: string;
  showModelSignup: boolean;
  showForgetPassword: boolean;
  showModelLogin: boolean;
}

interface SS {
  id: any;
}

export default class TopBarContextController extends BlockComponent<
  Props,
  S,
  SS
> {
  getThemeCallId: string = "";
  getNotificationsCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      ...defaultContext,
      isloading: true,
      errorMsg: "",
      showModelSignup: false,
      showForgetPassword: false,
      showModelLogin: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        console.log(responseJson, errorReponse);
      }
    }
  }


  changeSignupState = () => {
    this.setState({
      showModelSignup: !this.state.showModelSignup
    });
  };

  changeFpassState = () => {
    this.setState({
      showForgetPassword: !this.state.showForgetPassword
    });
  };

  changeLoginState = () => {
    this.setState({
      showModelLogin: !this.state.showModelLogin
    });
  };
}

// Customizable Area End
