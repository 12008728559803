// Customizable Area Start
import React from "react";
// Customizable Area Start
import Footer from "../../../../web/src/Footer.web";
import { Button } from "@material-ui/core";
import { TopbarContex } from "../../../user-profile-basic/src/TopbarContext";
// Customizable Area End
import ForgetPassword from "../../../forgot-password/src/ForgotPassword.web";
import EmailAccountLoginBlock from "../../../email-account-login/src/EmailAccountLoginBlock.web";
import LandingPageController from "./LandingPageController";
import CustomLoader from "../../../../components/src/CustomLoader.web";
import "../../../../web/src/App.css";


export default class Main extends LandingPageController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  static contextType = TopbarContex;

  // Customizable Area End

  render() {
    // Customizable Area Start
    const userType: string | null = localStorage.getItem('userType');
    const isLoggedIn: boolean = userType === "operation_manager";
    let url = window.location.href;
    

    const btn = <Button
      className="mainButton"
      data-test-id="driveWithUs"
      onClick={this.context.changeSignupState}
    >
      Drive with us
    </Button>

    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.state.AppStatusLoader ? (
          <CustomLoader Open={this.state.AppStatusLoader} />
        ) : (
          <>
            <div className="mainSection" id="mainContent">
              <div className="mainBanner" id="bannerImage">
                <div className="innerBannerContent">
                  <div className="innerContent">
                    {this.state.homeContent
                      .filter(
                        item => item.attributes.key_name === "banner_part"
                      )
                      .map((item: any, index: any) => {
                        // istanbul ignore next
                        return (
                          <div key={index} data-testid="1stcontent">
                            <h1
                              dangerouslySetInnerHTML={{
                                __html: item.attributes.content_title
                              }}
                            />
                            <div
                              className="subHeading"
                              dangerouslySetInnerHTML={{
                                __html: item.attributes.content_text
                              }}
                            />
                          </div>
                        );
                      })}
                    {(!isLoggedIn && userType !== "driver") && (
                      btn
                    )}
                    {(url.includes("/?redirected_from=manager") && isLoggedIn) && (
                      btn
                    )}

                    <EmailAccountLoginBlock
                      navigation={this.props.history}
                      id={""}
                      history={undefined}
                      location={undefined}
                      match={undefined}
                      signupState={this.context?.state?.showModelSignup}
                      loginstate={this.context?.state?.showModelLogin}
                      handleSignupState={this.context?.changeSignupState}
                      handleFPassState={this.context?.changeFpassState}
                      handleLoginState={this.context?.changeLoginState}
                    />
                    <ForgetPassword
                      navigation={undefined}
                      forgetPasswordState={this.context?.state?.showForgetPassword}
                      handleFPassState={this.context?.changeFpassState}
                      loginState={this.context?.state?.showModelLogin}
                      handleLoginState={this.context?.changeLoginState}
                      handleSignupState={this.context?.changeSignupState}
                    />
                  </div>
                </div>
              </div>
              <div className="iconsSections" data-test-id="contractors">
                <div className="container">
                  <h3>
                    {this.state.homeContent
                      .filter(
                        item => item.attributes.key_name === "contractor_title"
                      )
                      .map((item: any, index: any) => {
                        // istanbul ignore next
                        return (
                          <h3
                            key={index}
                            dangerouslySetInnerHTML={{
                              __html: item.attributes.content_title
                            }}
                          />
                        );
                      })}
                  </h3>
                  <ul className="iconList">
                    <li>
                      {this.state.homeContent
                        .filter(item => item.attributes.key_name === "sedans")
                        .map((item: any) => {
                          return (
                            <>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item.attributes.content_text
                                }}
                                className="iconSection"
                              />
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.attributes.content_title
                                }}
                                className="iconHead"
                              />
                            </>
                          );
                        })}
                    </li>
                    <li>
                      {this.state.homeContent
                        .filter(item => item.attributes.key_name === "vans")
                        .map((item: any) => {
                          // istanbul ignore next
                          return (
                            <>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item.attributes.content_text
                                }}
                                className="iconSection"
                              />
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.attributes.content_title
                                }}
                                className="iconHead"
                              />
                            </>
                          );
                        })}
                    </li>
                    <li>
                      {this.state.homeContent
                        .filter(item => item.attributes.key_name === "tops")
                        .map((item: any) => {
                          // istanbul ignore next
                          return (
                            <>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item.attributes.content_text
                                }}
                                className="iconSection"
                              />
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.attributes.content_title
                                }}
                                className="iconHead"
                              />
                            </>
                          );
                        })}
                    </li>
                    <li>
                      {this.state.homeContent
                        .filter(item => item.attributes.key_name === "tonnes")
                        .map((item: any) => {
                          // istanbul ignore next
                          return (
                            <>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item.attributes.content_text
                                }}
                                className="iconSection"
                              />
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.attributes.content_title
                                }}
                                className="iconHead"
                              />
                            </>
                          );
                        })}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="container">
                <div className="contentSection">
                  {this.state.homeContent
                    .filter(
                      item =>
                        item.attributes.key_name === "vehicle_benefits_img"
                    )
                    .map((item: any) => {
                      // istanbul ignore next
                      return (
                        <>
                          <div
                            className="imgContainer"
                            dangerouslySetInnerHTML={{
                              __html: item.attributes.content_text
                            }}
                          />
                        </>
                      );
                    })}
                  <div className="listData">
                    {this.state.homeContent
                      .filter(
                        item => item.attributes.key_name === "vehicle_benefits"
                      )
                      .map((item: any) => {
                        // istanbul ignore next
                        return (
                          <>
                            <h3
                              dangerouslySetInnerHTML={{
                                __html: item.attributes.content_title
                              }}
                            />
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.attributes.content_text
                              }}
                            />
                          </>
                        );
                      })}
                  </div>
                </div>

                <div className="contentSection mt40">
                  <div className="listData oddContent">
                    {this.state.homeContent
                      .filter(
                        item => item.attributes.key_name === "succeed_data"
                      )
                      .map((item: any) => {
                        // istanbul ignore next
                        return (
                          <>
                            <h3
                              dangerouslySetInnerHTML={{
                                __html: item.attributes.content_title
                              }}
                            />
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.attributes.content_text
                              }}
                            />
                          </>
                        );
                      })}
                  </div>
                  {this.state.homeContent
                    .filter(item => item.attributes.key_name === "succeed_img")
                    .map((item: any) => {
                      // istanbul ignore next
                      return (
                        <>
                          <div
                            className="imgContainer odd"
                            dangerouslySetInnerHTML={{
                              __html: item.attributes.content_text
                            }}
                          />
                        </>
                      );
                    })}
                </div>

                <div className="contentSection earningContent mt40">
                  {this.state.homeContent
                    .filter(item => item.attributes.key_name === "earning_img")
                    .map((item: any) => {
                      // istanbul ignore next
                      return (
                        <>
                          <div
                            className="imgContainer"
                            dangerouslySetInnerHTML={{
                              __html: item.attributes.content_text
                            }}
                          />
                        </>
                      );
                    })}
                  <div className="listData">
                    {this.state.homeContent
                      .filter(
                        item => item.attributes.key_name === "earning_data"
                      )
                      .map((item: any) => {
                        // istanbul ignore next
                        return (
                          <>
                            <h3
                              dangerouslySetInnerHTML={{
                                __html: item.attributes.content_title
                              }}
                            />
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.attributes.content_text
                              }}
                            />
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="delay">
                <div className="container">
                  <div className="notDelay">
                    <div className="contain">
                      {this.state.homeContent
                        .filter(
                          item =>
                            item.attributes.key_name === "applynow_section"
                        )
                        .map((item: any) => {
                          // istanbul ignore next
                          return (
                            <>
                              <h3
                                dangerouslySetInnerHTML={{
                                  __html: item.attributes.content_title
                                }}
                              />
                              <div
                                className="aboutContent"
                                dangerouslySetInnerHTML={{
                                  __html: item.attributes.content_text
                                }}
                              />
                            </>
                          );
                        })}
                    </div>
                    <div className="viewmorebtn">
                      <Button className="commonButton" 
                      onClick={this.context.changeSignupState} 
                      variant="contained">
                        Apply Now
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="about">
                <div className="container flex">
                  <div className="aboutImg">
                    {this.state.homeContent
                      .filter(item => item.attributes.key_name === "about_img")
                      .map((item: any) => {
                        // istanbul ignore next
                        return (
                          <>
                            <span
                              className="aboutImgspan"
                              dangerouslySetInnerHTML={{
                                __html: item.attributes.content_text
                              }}
                            />
                          </>
                        );
                      })}
                    <span className="first" />
                    <span className="second" />
                  </div>
                  <div className="aboutContentSection">
                    {this.state.homeContent
                      .filter(item => item.attributes.key_name === "about")
                      .map((item: any) => {
                        // istanbul ignore next
                        return (
                          <>
                            <h3
                              dangerouslySetInnerHTML={{
                                __html: item.attributes.content_title
                              }}
                            />
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item.attributes.content_text
                              }}
                            />
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="happysafe">
                <div className="container">
                  <p className="happytext">
                    {this.state.homeContent
                      .filter(
                        item =>
                          item.attributes.key_name === "testimonial_content"
                      )
                      .map((item: any) => {
                        // istanbul ignore next
                        return (
                          <>
                            <p
                              className="paragraphText"
                              dangerouslySetInnerHTML={{
                                __html: item.attributes.content_text
                              }}
                            />
                          </>
                        );
                      })}
                  </p>
                </div>
              </div>
            </div>
            <Footer />
          </>
        )}
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area End
