Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.sellerDetailsApiContentType = "application/json";
exports.sellersAPIEndPoint = "custom_form/seller_accounts";

exports.sellerDetailsAPIMethodPUT = "PUT";
exports.sellerDetailsAPIMethodPOST = "POST";
exports.sellerDetailsAPIMethodPATCH = "PATCH";
exports.getSellersAPIMethod = "GET";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customform";
exports.labelBodyText = "customform Body";
exports.errorMessageShopName = "Shop name is mandatory";
exports.errorMessageGstin = "GSTIN is mandatory";

exports.btnExampleTitle = "CLICK ME";

exports.GetMethodType = "GET";
exports.PostMethodType = "POST";
exports.DeleteMethodType = "DELETE";
exports.PutMethodType = "PUT";

exports.congrats = "Congratulations!";
exports.formSubmitted = "Your form is submitted";
exports.sellerDetails = "Seller Details";

// Driver Application end URL
exports.StateListDataApiEndPoint = "account_block/states";
exports.PersonalDetailsApiEndPoint = "account_block/personal_details";
exports.AdditionalDetailsApiEndPoint = "account_block/additional_details";
exports.VehcileDetailsApiEndPoint = "bx_block_profile/vehicle_details";
exports.HearAboutUsApiEndPoint = "account_block/search_froms";
exports.WorkExperienceApiEndPoint = "bx_block_profile/create_contact_wexp_bank";
exports.EmergencyUpdateApiEndPoint = "bx_block_profile/update_ec";
exports.BankDetailsApiEndPoint = "bx_block_profile/create_bank_detail";
exports.BankUpdateApiEndPoint = "bx_block_profile/update_bank_detail";
exports.CareerUpdateApiEndPoint = "/bx_block_profile/career_experiences";
exports.CareerUpdateAddApiEndPoint = "/bx_block_profile/create_contact_wexp_bank"
exports.getVideoUrlApiEndPoint = "bx_block_profile/videos";
exports.getQuestionListApiEndPoint = "bx_block_questionbank/qbanks";
exports.saveOptionsApiEndPoint = "bx_block_profile/responses";
exports.vechileListApiEndpoint = "bx_block_profile/vehicles"
exports.profileDataApiEndPoint = "account_block/accounts/"
exports.DocumentUploadApiEndPoint = "account_block/documents"
exports.videoStatusUpdate = "bx_block_profile/video_status"
exports.videoStatus = "bx_block_profile/video_watched_status"
exports.getAllDocuments = "account_block/documents"
exports.deleteDocument = "account_block/attachments"
exports.personalInsurance = "bx_block_profile/personal_insurances"
exports.phoneNumRegexWorkExp = /^\d\d{9}$/;
exports.stripePaymentEndpoint = "bx_block_payments/payments";
exports.backgroundCheckApiEndpoint = "bx_block_profile/backgrounds";
exports.priceApiEndpoint = "bx_block_payments/price"
exports.backgroundCheckStatusApiEndpoint = "bx_block_profile/backgrounds/status"
exports.finishBeCheckApiEndpoint = "bx_block_profile/backgrounds/finish_backgrounds_check"
exports.Load_Stripe = "pk_live_QdJxHPX8d147jIeLP7EOb1Ds"
exports.incrementStepCountDocCheck = "account_block/update_application_step"
exports.deleteWorkExpEndpoint="/bx_block_profile/career_experiences"
// Customizable Area End
