import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const Colors = {
    inputLabel: "rgba(31, 31, 34, 0.6)",
    inputTextColor: "rgb(31, 31, 34)",
    borderGrey: "rgba(28, 28, 30, 0.3)",
    borderYellow: "rgb(205, 149, 12)",
    white: "#FFFFFF",
    modalBg: "rgb(243, 243, 243)",
};

export const postcodeRegex = /^\d{4}$/;
export const acnRegex = /^\d{6,10}$/;
export const BSBRegex = /^\d{6}$/;
export const RelationsRegex = /^[A-Za-z ]{2,30}$/;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    handleChange: any;
    handleNext: any;
    history: any
    handleBack: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    getSellerDetailsMessageId: string;
    postSellerDetailsMessageId: string;
    sellerID: string;
    shopName: string;
    address: string;
    gstin: string;
    showSuccessModal: boolean;
    token: string;
    lat: any;
    long: any;
    data: any;
    errors: any;
    [key: string]: any;
    stateList: any;
    textAreaOpen: any;
    activeStep: any;
    workExprienceObj: any;
    addresses: any;
    addressesToUpdate: any;
    formErrors: any;
    validateNextPage: boolean;
    emergencyData: boolean;
    bankDataFlag: boolean;
    addressUpdateFlag: boolean;
    emergencyUserId: string;
    bankDataId: string;
    addressesError: any;
    // Customizable Area End
}
interface SS {
    id: any;
}

export default class WorkExperienceController extends BlockComponent<Props, S, SS> {
    // validateSecondStep(name: any, value: any) {
    //   throw new Error("Method not implemented.");
    // }
    // Customizable Area Start
    // workexperienceApi
    workExperienceApiCallId: string = "";
    additionalDetailsId: string = "";
    stateListDataID: string = "";
    bankDetailsApiCallId: string = "";
    profileDataID: string = "";
    emergencyUpdateId: string = "";
    bankUpdateId: string = "";
    workExpId: string = "";
    workExpAddId: string = "";
    deleteWorkExp: string = "";
    deletedWorkExpID: any = "";




    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        getName(MessageEnum.AlertMessage);
        this.state = {
            getSellerDetailsMessageId: "",
            postSellerDetailsMessageId: "",
            sellerID: "",
            shopName: "",
            address: "",
            gstin: "",
            token: "",
            showSuccessModal: false,
            lat: 0,
            long: 0,
            stateList: [],
            workExprienceObj: {},
            data: {
                name: "",
                relationship: "",
                address: "",
                phone_number: "",
                account_name: "",
                BSB_number: "",
                account_number: "",
                bank_name: "",
                GST_registered: "",
                medical_condition: "",
                medical_description: "",
                email: "",
                postcode: ""
            },
            validateNextPage: false,
            addresses: [{ id: '', company_name: '', start_date: '', end_date: '', reason_for_leaving: '', localCreate: true }],
            addressesError: [{ id: '', start_date: '', end_date: '' }],
            addressesToUpdate: [{ id: '', company_name: '', start_date: '', end_date: '', reason_for_leaving: '' }],

            emergencyData: false,
            bankDataFlag: false,
            addressUpdateFlag: false,
            emergencyUserId: "",
            bankDataId: "",

            formErrors: {},

            // State for showing the error of personal detail 1st-form
            errors: {
                name: "",
                relationship: "",

                address: "",
                account_name: "",
                account_number: "",
                BSB_number: "",
                bank_name: "",
                GST_registered: "",
                medical_condition: "",
                medical_description: "",

                phone_number: "",
                email: "",
                postcode: ""
            },

            // State for handling next button enable disable of personal detail 1st-form
            validationCheck: false,
            personalDetailFirstBtnResponse: "",
            showPersoanlDetailForm: false,
            textAreaOpen: "",
            businessType: "",
            activeStep: 1,

        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }



    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId && responseJson) {
                this.handleResponseForWorkExperienceApiCallId(apiRequestCallId, responseJson)
                this.handleResponseForWorkBankDetailsApiCallId(apiRequestCallId, responseJson)
                this.handleResponseForProfileDataId(apiRequestCallId, responseJson)
                this.handleResponseForemergencyUpdateId(apiRequestCallId, responseJson)
                this.handleResponseForBankUpdateId(apiRequestCallId, responseJson)
                this.handleResponseworkExpId(apiRequestCallId, responseJson)
                this.handleDeletedWorkExpResponse(apiRequestCallId, responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start


    async componentDidMount() {
        super.componentDidMount();
        this.getToken();
        this.getProfileData();
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.validateNextPage !== this.state.validateNextPage) {
            this.getProfileData();
        }
    }

    handleDeletedWorkExpResponse = (apiRequestCallId: any, responseJson: any) => {
        if (this.deleteWorkExp === apiRequestCallId) {
            if (responseJson) {
                const deleteObj = this.state.addresses.filter((data: any) => data.id !== this.deletedWorkExpID)
                const errorObj = this.state.addressesError.filter((data: any) => data.id !== this.deletedWorkExpID)
                this.setState({ addresses: deleteObj, addressesError: errorObj })
            }
        }

    }
    handleResponseForWorkExperienceApiCallId = (apiRequestCallId: any, responseJson: any) => {
        if (this.workExperienceApiCallId === apiRequestCallId) {
            if (this.state.personalDetailFirstBtnResponse === "save") {
                this.props.navigation.navigate("Dashboard")

            }
            if (this.state.personalDetailFirstBtnResponse === "next") {
                this.setState({ validateNextPage: true })
            }

        }
    }
    handleResponseForemergencyUpdateId(apiRequestCallId: any, responseJson: any) {
        try {
            if (this.emergencyUpdateId === apiRequestCallId) {
                if (this.state.personalDetailFirstBtnResponse === "save") {
                    this.props.navigation.navigate("Dashboard")

                }
                if (this.state.personalDetailFirstBtnResponse === "next") {
                    this.setState({ validateNextPage: true })
                }
                console.log(apiRequestCallId, responseJson, "-emergency update")
            }
        } catch (error) {
            console.log("error")
        }
    }

    handleResponseForBankUpdateId(apiRequestCallId: any, responseJson: any) {


        if (this.bankUpdateId === apiRequestCallId) {
            if (this.state.personalDetailFirstBtnResponse === "save") {
                this.props.navigation.navigate("Dashboard")

            }
            if (this.state.personalDetailFirstBtnResponse === "next") {
                this.props.handleChange();

            }
            console.log(apiRequestCallId, responseJson, "---bank update")
        }

    }

    handleResponseworkExpId = (apiRequestCallId: any, responseJson: any) => {
        try {

            if (this.workExpId === apiRequestCallId) {
                if (this.state.personalDetailFirstBtnResponse === "save") {
                    this.props.navigation.navigate("Dashboard")

                }
                if (this.state.personalDetailFirstBtnResponse === "next") {
                    this.setState({ validateNextPage: true })

                }
                console.log(apiRequestCallId, responseJson, "---bank update")
            }
        }
        catch (error) {
            console.log("error")
        }
    }

    handleResponseForWorkBankDetailsApiCallId = (apiRequestCallId: any, responseJson: any) => {

        if (this.bankDetailsApiCallId === apiRequestCallId) {

            if (this.state.personalDetailFirstBtnResponse === "save") {

                this.props.navigation.navigate("Dashboard")
            }
            if (this.state.personalDetailFirstBtnResponse === "next") {

                this.props.handleChange()

            }

        }
    }
    handleResponseForProfileDataId = (apiRequestCallId: any, responseJson: any) => {
        if (this.profileDataID === apiRequestCallId) {

            let _addresses = [...this.state.addresses]
            let _addressesToUpdate = [...this.state.addressesToUpdate]
            let _emergencyData = this.state.emergencyData;
            let _bankDataFlag = this.state.bankDataFlag;
            let _addresUpdateFlag = this.state.addressUpdateFlag
            let _addressesError = [...this.state.addressesError]

            if (responseJson.data.attributes.career_experiences.length) {
                _addresUpdateFlag = true

                _addresses = responseJson.data.attributes.career_experiences.map((item: any) => {

                    let obj = {
                        id: "",
                        start_date: "", end_date: "", company_name: "",
                        reason_for_leaving: ""
                    }

                    obj.id = item.data.id
                    obj.start_date = item.data.attributes.start_date
                    obj.end_date = item.data.attributes.end_date
                    obj.company_name = item.data.attributes.company_name
                    obj.reason_for_leaving = item.data.attributes.reason_for_leaving

                    return obj
                })

                _addressesError = responseJson.data.attributes.career_experiences.map((item: any) => {

                    let obj = {
                        id: "",
                        start_date: "", end_date: "",
                    }

                    obj.id = item.data.id
                    obj.start_date = ""
                    obj.end_date = ""


                    return obj
                })

                let objForUpdate = {
                    id: "",
                    start_date: "", end_date: "", company_name: "",
                    reason_for_leaving: ""
                }

                _addressesToUpdate = responseJson.data.attributes.career_experiences.map((item: any) => {
                    objForUpdate.id = item.data.id
                    objForUpdate.start_date = item.data.attributes.start_date
                    objForUpdate.end_date = item.data.attributes.end_date
                    objForUpdate.company_name = item.data.attributes.company_name
                    objForUpdate.reason_for_leaving = item.data.attributes.reason_for_leaving

                    return objForUpdate
                })
            }
            if (responseJson.data.attributes.emergency_contact.data) {
                _emergencyData = true;
                this.setState({ emergencyUserId: responseJson.data.attributes.emergency_contact.data.id })

            }
            if (responseJson.data.attributes.bank_detail.data) {
                _bankDataFlag = true;
                this.setState({ bankDataId: responseJson.data.attributes.bank_detail.data.id })
            }

            this.setState({
                addresses: _addresses,
                addressesToUpdate: _addressesToUpdate,
                validationCheck: true,
                emergencyData: _emergencyData,
                bankDataFlag: _bankDataFlag,
                addressUpdateFlag: _addresUpdateFlag,
                addressesError: _addressesError

            })
            this.handleDataforEmergencyAndBankDetails(responseJson);
        }
    }

    handleDataforEmergencyAndBankDetails = (responseJson: any) => {
        if (responseJson.data.attributes.emergency_contact.data) {
            let _data = { ...this.state.data }

            let { name,
                relationship,
                address,
                phone_number, post_code } = responseJson.data.attributes.emergency_contact.data.attributes
            _data.name = name
            _data.relationship = relationship
            _data.phone_number = phone_number
            _data.address = address
            _data.postcode = post_code


            this.setState({
                data: _data,
                validationCheck: true

            }
            )
        }

        if (responseJson.data.attributes.bank_detail.data) {
            let _data = { ...this.state.data }

            let { account_name,
                BSB_number,
                account_number,
                bank_name,
                GST_registered,
                medical_condition,
                medical_description } = responseJson.data.attributes.bank_detail.data.attributes
            _data.account_name = account_name
            _data.BSB_number = BSB_number
            _data.account_number = account_number
            _data.bank_name = bank_name
            _data.GST_registered = GST_registered ? "true" : "false"
            _data.medical_condition = medical_condition ? "true" : "false"
            _data.medical_description = medical_description == "no one" ? "" : medical_description

            this.setState({
                data: _data,
                validationCheck: true
            }
            )



        }
    }


    // Form Part Start
    // Validate function for handling error of personal detail 1st-form
    validate = (name: any, value: any) => {
        switch (name) {
            case "company_name":
                return this.defaultValidations(value)
            case "start_date":
                return this.defaultValidations(value)
            case "end_date":
                return this.defaultValidations(value)
            case "reason_for_leaving":
                return this.defaultValidations(value)
            case "name":
                return this.defaultValidations(value)
            case "relationship":
                return this.validateRelationship(value)
            case "address":
                return this.defaultValidations(value)
            case "account_name":
                return this.defaultValidations(value)
            case "BSB_number":
                return this.validateBSB(value)
            case "account_number":
                return this.validateACN(value)
            case "bank_name":
                return this.defaultValidations(value)
            case "postcode":
                return this.validatePostCode(value)
            case "phone_number":
                return this.validatePhoneNumber(value)
            case "medical_description":
                return this.validateMedicalDescreptions(value)
            case "medical_condition":
                return this.defaultValidations(value)
            case "GST_registered":
                return this.defaultValidations(value)
            default: {
                return "";
            }
        }
    };
    // end

    validateRelationship = (value: any) => {
        if (!value || value === "") {
            return "This Field is Required";
        }
        if (!value.match(RelationsRegex)) {
            return "Enter a valid Text";
        }
        return "";
    }
    defaultValidations = (value: any) => {
        if (!value || value === "") {
            return "This Field is Required";
        } else {
            return "";
        }
    }


    validateMedicalDescreptions = (value: any) => {
        if (this.state.data.medical_condition == "true") {
            if (!value || value.trim() === "") {
                return "This Field is Required";
            } else {
                return "";
            }
        }
        else {
            return ""
        }
    }
    validatePostCode = (value: any) => {
        if (!value || value === "") {
            return "This Field is Required";
        }
        if (!value.match(postcodeRegex)) {
            return "Enter a valid Post Code of 4 digits.";
        }
        return "";
    }

    validatePhoneNumber = (value: any) => {
        let _value = value?.toString()
        if (!_value || _value === "") {
            return "This Field is Required";
        }
        if (!_value.match(configJSON.phoneNumRegexWorkExp)) {
            return "Enter a valid Mobile number.";
        }
        return "";

    }
    validateACN = (value: any): string => {
        let _value = value?.toString()
        if (!_value || _value === "") {
            return "This Field is Required";
        }
        if (!_value.match(acnRegex)) {
            return "Bank account number should be 6 to 10.";
        }
        return "";
    };

    validateBSB = (value: any): string => {
        if (!value || value === "") {
            return "This Field is Required";
        }
        if (!value.match(BSBRegex)) {
            return "Enter a valid BSB number of 6 digits.";
        }
        return "";
    };



    handleWorkExprienceChange(e: any, index: any, field: any) {
        const newAddresses = JSON.parse(JSON.stringify(this.state.addresses));
        newAddresses[index][field] = e.target.value;

        const errors = [...this.state.addressesError];

        let fromDate, toDate; // Declare the variables here

        const currentDate = new Date();

        switch (field) {
            case 'start_date':
                fromDate = new Date(e.target.value);

                if (fromDate > currentDate) {
                    errors[index][field] = 'From date cannot be greater than the current date.';
                } else {
                    errors[index][field] = ''; // Clear the error message
                }

                // Ensure "To" date is after or equal to "From" date
                toDate = new Date(newAddresses[index].end_date);
                if (toDate < fromDate) {
                    newAddresses[index].end_date = e.target.value; // Set "To" date to match "From" date
                }

                this.handleStartDate(fromDate, index, newAddresses, errors)

                break;

            case 'end_date':
                fromDate = new Date(newAddresses[index].start_date);
                toDate = new Date(e.target.value);

                // Additional validation for "To" date
                if (toDate > currentDate) {
                    errors[index]['end_date'] = 'To date cannot be greater than the current date.';
                } else {
                    if (toDate < fromDate) {
                        errors[index][field] = 'To date must be after the From date.';
                    } else {
                        errors[index][field] = ''; // Clear the error message
                    }
                }

                this.handleEndDate(toDate, index, newAddresses, errors)
                break;

            default:
                break;
        }
        // Remove error message when user types something in the field
        const newErrors = { ...this.state.formErrors };
        delete newErrors[`${index}-${field}`];
        
        this.setState({ addresses: newAddresses, errors, formErrors: newErrors });
    }

    handleStartDate = (fromDate: any, index: any, newAddresses: any, errors: any) => {
        for (let i = 0; i < newAddresses.length; i++) {
            if (i !== index) {
                const existingFromDate = new Date(newAddresses[i].start_date);
                const existingToDate = new Date(newAddresses[i].end_date);

                if (fromDate >= existingFromDate && fromDate <= existingToDate) {
                    errors[index]['start_date'] = 'From date falls within an existing range.';
                    break; // No need to continue checking, we've found an error
                }
            }
        }

    }
    handleEndDate = (toDate: any, index: any, newAddresses: any, errors: any) => {
        for (let i = 0; i < newAddresses.length; i++) {
            if (i !== index) {
                const existingFromDate = new Date(newAddresses[i].start_date);
                const existingToDate = new Date(newAddresses[i].end_date);

                if (toDate >= existingFromDate && toDate <= existingToDate) {
                    errors[index]['end_date'] = 'To date falls within an existing range.';
                    break; // No need to continue checking, we've found an error
                }
            }
        }
    }


    // To take input from the user of  forms 
    handleInputChange = (e: any) => {

        this.setState({
            errors: {
                ...this.state.errors,
                [e.target.name]: this.validate(e.target.name, e.target.value),
            },

            data: {
                ...this.state.data,
                [e.target.name]: e.target.value
            }
        })

        // Functionality to enable disable next button for the  form

        let error = this.validate(e.target.name, e.target.value)
        if (error.length === 0) {
            this.setState({ validationCheck: true });
        }
        else {
            this.setState({ validationCheck: false });
        }

    };
    // end

    handelWorkExpValidation = (newErrors: any) => {
        this.state.addresses.forEach((address: any, index: any) => {
            if (!address.company_name) {
                newErrors[`${index}-company_name`] = 'This Field is Required';
            }
            if (!address.start_date) {
                newErrors[`${index}-start_date`] = 'This Field is Required';
            }
            if (!address.end_date) {
                newErrors[`${index}-end_date`] = 'This Field is Required';
            }
            if (!address.reason_for_leaving) {
                newErrors[`${index}-reason_for_leaving`] = 'This Field is Required';
            }
        });

        return newErrors;
    }
    // Form submit function to save and exit
    handleWorkEperienceSaveandExitForm = (btnResponse: string) => {
        this.setState({ personalDetailFirstBtnResponse: btnResponse });

        if (this.state.emergencyData || this.state.addressUpdateFlag) {
            this.handleEmergencyUpdate();
            this.handleWorkExperience();
        }
        else {

            this.handleCreateWorkExperience();
        }


    };

    handleWorkExperience = () => {

        this.state.addresses.map((item: any) => {
            if (item.localCreate) {
                this.workExpAddUpdateHandler(item);
            }
            else {
                this.workExpUpdateHandler(item);

            }
        })

    }


    workExpUpdateHandler = (item: any) => {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };

        const requestBody = {
            data: {
                company_name: item.company_name,
                start_date: item.start_date,
                end_date: item.end_date,
                reason_for_leaving: item.reason_for_leaving,
            }

        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.workExpId = requestMessage.messageId;
        // Set Method Type
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PutMethodType
        );
        // Set EndPoints
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.CareerUpdateApiEndPoint + "/" + item.id
        );
        // set Headers
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(requestBody)
        )
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    workExpAddUpdateHandler = (item: any) => {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };

        const requestBody = {
            work_exp: [{
                company_name: item.company_name,
                start_date: item.start_date,
                end_date: item.end_date,
                reason_for_leaving: item.reason_for_leaving,
            }]
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.workExpAddId = requestMessage.messageId;
        // Set Method Type
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PostMethodType
        );
        // Set EndPoints
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.CareerUpdateAddApiEndPoint
        );
        // set Headers
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(requestBody)
        )
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    handleWorkEperienceSaveandNextForm = (btnResponse: string) => {
        this.setState({ personalDetailFirstBtnResponse: btnResponse });
        const { name, relationship, address, phone_number, postcode } = this.state.data
        const damiObject: any = {
            name,
            relationship,
            address,
            phone_number: phone_number ?? "",
            postcode
        }
        // Validate all fields
        const newErrors: any = {};
        this.handelWorkExpValidation(newErrors)
        this.setState({ formErrors: newErrors });

        // Check for errors
        let validationErrors: any = {};

        Object.keys(damiObject).forEach((name) => {
            const error = this.validate(name, damiObject[name]);
            if (error && error.length > 0) {
                // @ts-ignore
                validationErrors[name] = error;

            }

        });
        const hasErrors = Object.keys(newErrors).length > 0;
        const updatedValidationerror = Object.keys(validationErrors).length > 0
        const hasFieldWithLengthGreaterThanZero = this.state.addressesError.some((item: any) => item.start_date.length > 0 || item.end_date.length > 0);

        if (updatedValidationerror || hasErrors || hasFieldWithLengthGreaterThanZero) {
            this.setState({ errors: validationErrors });
            return;
        }

        if (this.state.emergencyData || this.state.addressUpdateFlag) {
            this.handleEmergencyUpdate();
            this.handleWorkExperience();
        }
        else {
            this.handleCreateWorkExperienceNext();
        }
    };

    handleBankDetailsSaveandExitForm = (btnResponse: string) => {
        this.setState({ personalDetailFirstBtnResponse: btnResponse });

        let validationErrors = {};

        const requestBody: any = {
            account_name: this.state.data.account_name,
            account_number: this.state.data.account_number,
            BSB_number: this.state.data.BSB_number,
            bank_name: this.state.data.bank_name,
            GST_registered: this.state.data.GST_registered,
            medical_condition: this.state.data.medical_condition,
            medical_description: this.state.data.medical_description

        };

        if (this.state.bankDataFlag) {
            this.handleBankUpdate();
        }
        else {
            this.handleBankDetailForm();
        }
    };



    handleBankDetailsSaveandNextForm = (btnResponse: string) => {
        this.setState({ personalDetailFirstBtnResponse: btnResponse });
        const requestBody: any = {
            account_name: this.state.data.account_name,
            account_number: this.state.data.account_number,
            BSB_number: this.state.data.BSB_number,
            bank_name: this.state.data.bank_name,
            GST_registered: this.state.data.GST_registered,
            medical_condition: this.state.data.medical_condition,
            medical_description: this.state.data.medical_description

        };

        let validationErrors = {};
        Object.keys(requestBody).forEach((name) => {
            const error = this.validate(name, requestBody[name]);
            if (error && error.length > 0) {
                // @ts-ignore
                validationErrors[name] = error;
            }

        });


        if (Object.keys(validationErrors).length > 0) {
            this.setState({ errors: validationErrors });
            return;
        }

        if (this.state.bankDataFlag) {
            this.handleBankUpdate();
        }
        else {

            this.handleBankDetailFormNext();
        }

    };

    handleBankDetailFormNext = () => {

        const requestBody: any = {
            account_name: this.state.data.account_name,
            account_number: this.state.data.account_number,
            BSB_number: this.state.data.BSB_number,
            bank_name: this.state.data.bank_name,
            GST_registered: this.state.data.GST_registered,
            medical_condition: this.state.data.medical_condition,
            medical_description: this.state.data.medical_description

        };

        const contentType = configJSON.validationApiContentType;
        const apiEndPoint = configJSON.BankDetailsApiEndPoint;
        const apiMethod = configJSON.PostMethodType
        const payload = {
            data: requestBody
        }

        const apiCallingData = {
            contentType, apiEndPoint, apiMethod, payload
        }
        const requestMessage = this.handleApiManager(apiCallingData);

        this.bankDetailsApiCallId = requestMessage.messageId;


        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    handleApiManager = ({ contentType, apiEndPoint, payload = null, apiMethod }: any) => {
        // get token from localStore
        const userToken = localStorage.getItem("token");

        // api header
        const header = {
            'Content-Type': contentType,
            token: userToken,
        };

        // rest api call message create
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );


        // set api end point
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint
        );

        // set header
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        // set body data if method POST or PETCH
        if (payload) {
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(payload)
            );
        }

        // set api method
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            apiMethod
        );
        //  return created message
        return requestMessage;
    };


    addmorefiled = () => {
        const randomID = new Date().getTime()
        this.setState({
            addresses: [...this.state.addresses, { id: randomID.toString(), company_name: '', start_date: '', end_date: '', reason_for_leaving: '', localCreate: true }],
            addressesError: [...this.state.addressesError, { id: randomID.toString(), start_date: '', end_date: '' }]
        })


    }

    handleDeleteWorkExp = (id: any) => {
        let checkLocal = false
        const errorObj = this.state.addressesError.filter((data: any) => data.id !== id)
        const deleteObj = this.state.addresses.filter((data: any) => {
            if (data.id === id) {
                checkLocal = data.localCreate
            }
            return data.id !== id
        })


        if (checkLocal) {
            this.setState({ addresses: deleteObj, addressesError: errorObj })
            return
        }

        this.deletedWorkExpID = id
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteWorkExp = requestMessage.messageId;
        // Set Method Type
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.DeleteMethodType
        );
        // Set EndPoints
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteWorkExpEndpoint}/${id}`
        );
        // set Headers
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // To take input from the user of personal details forms 2nd-Form
    handleMedicalCondition = (e: any, value: string, name: string) => {


        this.setState({
            data: {
                ...this.state.data,
                [name]: value
            },
            errors: {
                ...this.state.errors,
                [name]: this.validate(name, value),
            },
        })

        if (e.target.value === "Yes") {
            this.setState({ businessType: "Yes" });
        }
        if (e.target.value === "No") {
            this.setState({ businessType: "No" });
        }
        this.setState({
            personalDetailSecondErrors: {
                ...this.state.personalDetailSecondErrors,
                [e.target.name]: e.target.value,
            },
            personalDetailSeconddata: {
                ...this.state.personalDetailSeconddata,
                [e.target.name]: e.target.value,
            },
        });


    };



    handleGSTRegistered = (e: any, value: string, name: string) => {

        this.setState({
            errors: {
                ...this.state.errors,
                [name]: this.validate(name, value),
            },

            data: {
                ...this.state.data,
                [name]: value
            }

        })

        if (e.target.value === "Yes") {
            this.setState({ businessType: "Yes" });
        }
        if (e.target.value === "No") {
            this.setState({ businessType: "No" });
        }

    };

    getToken = () => {
        console.log("msg enum");

        const msg: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(msg);
    };

    onChangeInput = (name: string, value: any) => {
        // @ts-ignore
        this.setState({ [name]: value });
    };


    inputLabelProps = {
        fontSize: 14,
        color: Colors.inputLabel,
        lineHeight: 16,
    };

    showModal = () => {
        this.setState({ showSuccessModal: true });
    };

    hideModal = () => {
        this.setState({ showSuccessModal: false }, () => { });
    };
    getProfileData() {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };
        let userId = localStorage.getItem('userId')
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.profileDataID = requestMessage.messageId;
        // Set Method Type
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GetMethodType
        );
        // Set EndPoints
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.profileDataApiEndPoint + userId
        );
        // set Headers
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleEmergencyUpdate = () => {

        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };

        const requestBody = {
            data: {
                name: this.state.data.name,
                relationship: this.state.data.relationship,
                address: this.state.data.address,
                phone_number: this.state.data.phone_number,
                post_code: this.state.data.postcode

            }

        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.emergencyUpdateId = requestMessage.messageId;
        // Set Method Type
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PutMethodType
        );
        // Set EndPoints
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.EmergencyUpdateApiEndPoint + "?id=" + this.state.emergencyUserId
        );
        // set Headers
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(requestBody)
        )
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleCreateWorkExperience = () => {

        const requestBody = {
            work_exp: this.state.addresses,
            emergency_contact: {
                name: this.state.data.name,
                relationship: this.state.data.relationship,
                address: this.state.data.address,
                phone_number: this.state.data.phone_number,
                post_code: this.state.data.postcode
            }

        };
        const contentType = configJSON.validationApiContentType;

        const apiEndPoint = configJSON.WorkExperienceApiEndPoint;
        const apiMethod = configJSON.PostMethodType
        const payload = requestBody

        const apiCallingData = {
            contentType, apiEndPoint, apiMethod, payload
        }
        const requestMessage = this.handleApiManager(apiCallingData);

        this.workExperienceApiCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    handleBankUpdate = () => {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };

        const requestBody = {

            data: {
                account_name: this.state.data.account_name,
                account_number: this.state.data.account_number,
                BSB_number: this.state.data.BSB_number,
                bank_name: this.state.data.bank_name,
                GST_registered: this.state.data.GST_registered,
                medical_condition: this.state.data.medical_condition,
                medical_description: this.state.data.medical_description
            }
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.bankUpdateId = requestMessage.messageId;

        // Set Method Type
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PutMethodType
        );
        // Set EndPoints
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.BankUpdateApiEndPoint + "?id=" + this.state.bankDataId
        );
        // set Headers
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(requestBody)
        )
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    handleCreateWorkExperienceNext = () => {

        const requestBody = {
            work_exp: this.state.addresses,
            emergency_contact: {
                name: this.state.data.name,
                relationship: this.state.data.relationship,
                address: this.state.data.address,
                phone_number: this.state.data.phone_number,
                post_code: this.state.data.postcode
            }

        };


        const contentType = configJSON.validationApiContentType;
        const apiEndPoint = configJSON.WorkExperienceApiEndPoint;
        const apiMethod = configJSON.PostMethodType
        const payload = requestBody

        const apiCallingData = {
            contentType, apiEndPoint, apiMethod, payload
        }
        const requestMessage = this.handleApiManager(apiCallingData);

        this.workExperienceApiCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    handleBankDetailForm = () => {

        const requestBody: any = {
            account_name: this.state.data.account_name,
            account_number: this.state.data.account_number,
            BSB_number: this.state.data.BSB_number,
            bank_name: this.state.data.bank_name,
            GST_registered: this.state.data.GST_registered,
            medical_condition: this.state.data.medical_condition,
            medical_description: this.state.data.medical_description

        };

        const contentType = configJSON.validationApiContentType;
        let apiEndPoint = "";

        apiEndPoint = configJSON.BankDetailsApiEndPoint;

        const apiMethod = configJSON.PostMethodType
        const payload = {
            data: requestBody
        }

        const apiCallingData = {
            contentType, apiEndPoint, apiMethod, payload
        }
        const requestMessage = this.handleApiManager(apiCallingData);

        this.bankDetailsApiCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }


    // Customizable Area End
}