// Customizable Area Start
import React from 'react';
import "./css/viewApplications.css"
import Grid from '@material-ui/core/Grid';
import { BackArrowIc, warning } from '../assets';
import { Link } from "react-router-dom";
import EditDriverDetailsController, { Props } from '../Controller/EditDriverDetailsController';
import CustomToast from '../../../../components/src/CustomToast.web';

interface TextIProps {
    name: any,
    value: any,
    onChange: any,
    className?: any
}
export const TextInput = ({ name, value, className, onChange }: TextIProps) => {
    return (
        <input
            type="text"
            id={name}
            name={name}
            autoComplete="off"
            value={value}
            onChange={onChange}
            className={className}
            readOnly={name === "email" ? true : false}
        />
    );
};

const checkBoxBorder = (condition: any, margin: any) => {
    return condition
        ? "div-checkbox mr15 green-border"
        : `div-checkbox ${margin}`;
};

const RadioInput = ({ id, name, value, checked, onChange, label }: any) => {
    return (
        <div className={checkBoxBorder(checked, "mr15")}>
            <input
                type="radio"
                id={id}
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
                data-testid={`${value}${name}`}
            />
            <label htmlFor={id}>{label}</label>
        </div>
    );
};


class EditDriverDetails extends EditDriverDetailsController {
    constructor(props: Props) {
        super(props);

    }

    showingError = (error: any) => {
        return (error && (
            <div className="da">
                <img src={warning} alt="" />
                <p className="err ml10">{error}</p>
            </div>
        ))
    }

    handleEmergencyContactUi = () => {
        const { emergencyContract } = this.state
        return (
            <div className='margin-47'>
                <p className='personal-details'>Emergency Contact :</p>
                <Grid container className='margin-24' spacing={2}>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Name </p>
                            <TextInput

                                name="name"
                                data-testid="name"
                                value={emergencyContract.name}
                                onChange={(event: any) => this.handleInputChange(event)}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Relationship</p>
                            <TextInput
                                data-testid="relationship"
                                name="relationship"
                                value={emergencyContract.relationship}
                                onChange={(event: any) => this.handleInputChange(event)}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Aus mobile number </p>
                            <TextInput
                                data-testid="phone_number"
                                name="phone_number"
                                value={emergencyContract.phone_number}
                                onChange={(event: any) => this.handleInputChange(event)}
                            />
                            {this.showingError(this.state.errors.phone_number)}
                        </div>
                    </Grid>
                </Grid>

                <Grid container className='margin-32' spacing={2}>
                    <Grid item xs={12} md={8}>
                        <div>
                            <p className='details-title'>Address </p>
                            <TextInput
                                data-testid="emergencyAddress"
                                name="address"
                                value={emergencyContract.address}
                                onChange={(event: any) => this.handleInputChange(event)}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Postcode</p>
                            <TextInput
                                data-testid="emergencyPost"
                                name="post_code"
                                value={emergencyContract.post_code}
                                onChange={(event: any) => this.handleInputChange(event)}
                            />

                            {this.showingError(this.state.errors.post_code)}
                        </div>
                    </Grid>

                </Grid>
                <hr className='hr1 margin-47' />
            </div>
        )
    }



    vehiclesSection = () => {
        const { vehicleDetails } = this.state
        return (<div className='margin-47' id='vehicle'>
            <div className='vehicale-title'>
                <p className='personal-details'>Vehicle Details :</p>
                <div className='own-vehicale-details'>
                    <p className='own-vehicale'>Own vehicle</p>
                    <div className="df">
                        <RadioInput
                            id="yesown_vehicle"
                            name="own_vehicle"
                            value={true}
                            checked={
                                vehicleDetails?.own_vehicle === true
                            }
                            onChange={(event: any) => this.handleCheckedInputChange(event)}
                            label="Yes"
                            data-testid="yesown_vehicle"
                        />
                        <RadioInput
                            id="noown_vehicle"
                            name="own_vehicle"
                            value={false}
                            checked={
                                vehicleDetails?.own_vehicle === false
                            }
                            onChange={(event: any) => this.handleCheckedInputChange(event)}
                            label="No"
                            data-testid="noown_vehicle"
                        />
                    </div>
                </div>
            </div>
            <div>
                <Grid container className='margin-32' spacing={2}>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Driver licence number </p>
                            <TextInput
                                data-testid="driver_licence_number"
                                name="driver_licence_number"
                                value={vehicleDetails.driver_licence_number}
                                onChange={(event: any) => this.handleInputChange(event)}
                            />

                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Expiry date </p>
                            <input
                                id="expiry_date"
                                type="date"
                                name="expiry_date"
                                data-testid="expiry_date"
                                autoComplete="off"
                                value={vehicleDetails.expiry_date}
                                // onKeyDown={(e) => e.preventDefault()}
                                onChange={event => this.handleInputChange(event)}
                            />

                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Registration number </p>
                            <TextInput
                                data-testid="registration_number"
                                name="registration_number"
                                value={vehicleDetails.registration_number}
                                onChange={(event: any) => this.handleInputChange(event)}
                            />
                        </div>
                    </Grid>
                </Grid>

                <Grid container className='margin-32' spacing={2}>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Registration expiry date </p>
                            <input
                                id="registration_expiry_date"
                                type="date"
                                name="registration_expiry_date"
                                data-testid="registration_expiry_date"
                                autoComplete="off"
                                value={vehicleDetails.registration_expiry_date}
                                // onKeyDown={(e) => e.preventDefault()}
                                onChange={event => this.handleInputChange(event)}
                            />

                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Vehicle type</p>
                            <select
                                data-testid="vehicle_id"
                                name="vehicle_id"
                                value={vehicleDetails.vehicle_id}
                                onChange={(event) => this.handleInputChange(event)}
                            >
                                <option value="" disabled selected>
                                    Select
                                </option>
                                {this.state.vehcileList.map((item: any, index: any) => (
                                    <option key={item?.id} value={item.id}>{item.attributes.vehicle_type}</option>
                                ))}
                            </select>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Make & Model </p>
                            <TextInput
                                data-testid="vehicle_model"
                                name="vehicle_model"
                                value={vehicleDetails.vehicle_model}
                                onChange={(event: any) => this.handleInputChange(event)}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid container className='margin-32'>
                    <Grid item xs={12}>
                        <div>
                            <p className='details-title'>Have you ever been disqualified for driving ?</p>
                            <div className="df">
                                <RadioInput
                                    id="yesdriving_disquilified"
                                    name="driving_disquilified"
                                    value={true}
                                    checked={
                                        vehicleDetails.driving_disquilified === true
                                    }
                                    onChange={(event: any) => this.handleCheckedInputChange(event)}
                                    label="Yes"
                                    data-testid="yesdriving_disquilified"
                                />
                                <RadioInput
                                    id="nodriving_disquilified"
                                    name="driving_disquilified"
                                    value={false}
                                    checked={
                                        vehicleDetails.driving_disquilified === false
                                    }
                                    onChange={(event: any) => this.handleCheckedInputChange(event)}
                                    label="No"
                                    data-testid="nodriving_disquilified"
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Grid container className='margin-32' >
                    <Grid item xs={12}>
                        <div>
                            <p className='details-title'>Have you ever been convicted of a criminal offence ?</p>
                            <div className="df">
                                <RadioInput
                                    id="yescriminal_offence"
                                    name="criminal_offence"
                                    value={true}
                                    checked={
                                        vehicleDetails.criminal_offence === true
                                    }
                                    onChange={(event: any) => this.handleCheckedInputChange(event)}
                                    label="Yes"
                                    data-testid="yescriminal_offence"
                                />
                                <RadioInput
                                    id="nocriminal_offence"
                                    name="criminal_offence"
                                    value={false}
                                    checked={
                                        vehicleDetails.criminal_offence === false
                                    }
                                    onChange={(event: any) => this.handleCheckedInputChange(event)}
                                    label="No"
                                    data-testid="nocriminal_offence"
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>


        </div>)
    }

    workExperienceSection = () => {
        const { workExprience } = this.state
        return (<div id='work-experience' className='margin-47'>
            <>
                <p className='personal-details'>Work Experience :</p>
                {workExprience.map((values: any, index: any) =>
                    <div key={values.id}>
                        <Grid container className='margin-24' spacing={2}>
                            <Grid item xs={12} md={12}>
                                <div>
                                    <p className='details-title'>Company name </p>
                                    <input
                                        type="text"
                                        id={`company_name-${index}`}
                                        value={values.company_name}
                                        placeholder="Type here..."
                                        data-testid="company_nameInputField"
                                        onChange={e =>
                                            this.handleWorkExprienceChange(
                                                e,
                                                index,
                                                "company_name"
                                            )
                                        }
                                    />
                                </div>

                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div>
                                    <p className='details-title'>From </p>
                                    <input
                                        style={{ width: 250 }}
                                        type="date"
                                        id={`start_date-${index}`}
                                        value={values.start_date}
                                        autoComplete="off"
                                        // onKeyDown={(e) => e.preventDefault()}
                                        // max={new Date().toISOString().split("T")[0]}
                                        data-testid="start_dateInputField"
                                        onChange={e =>
                                            this.handleWorkExprienceChange(
                                                e,
                                                index,
                                                "start_date"
                                            )
                                        }
                                    />
                                    {this.showingError(this.state.workExpError[index].start_date)}
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div>
                                    <p className='details-title'>To </p>
                                    <input
                                        style={{ width: 250 }}
                                        type="date"
                                        autoComplete="off"
                                        id={`end_date-${index}`}
                                        value={values.end_date}
                                        // min={values.start_date}
                                        // max={new Date().toISOString().split("T")[0]}
                                        // onKeyDown={(e) => e.preventDefault()}
                                        data-testid="end_dateInputField"
                                        onChange={e =>
                                            this.handleWorkExprienceChange(
                                                e,
                                                index,
                                                "end_date"
                                            )
                                        }
                                    />
                                    {this.showingError(this.state.workExpError[index].end_date)}
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container className='margin-32' spacing={2}>
                            <Grid item xs={12}>
                                <div>
                                    <p className='details-title'>Reason for leaving</p>
                                    <input
                                        type="text"
                                        placeholder="Type here..."
                                        id={`reason_for_leaving-${index}`}
                                        value={values.reason_for_leaving}
                                        data-testid="reason_for_leavingInputField"
                                        onChange={e =>
                                            this.handleWorkExprienceChange(
                                                e,
                                                index,
                                                "reason_for_leaving"
                                            )
                                        }
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <hr className='hr1 margin-47' />
                    </div>
                )}
            </>
        </div>
        )
    }

    bankDetailsSection = () => {
        const { bankDetails } = this.state
        return (<div className='margin-47' id='bank-details'>

            <>
                <p className='personal-details'>Bank Details :</p>
                <Grid container className='margin-24' spacing={2}>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Account name </p>
                            <TextInput
                                data-testid="account_name"
                                name="account_name"
                                value={bankDetails.account_name}
                                onChange={(event: any) => this.handleInputChange(event)}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>BSB number </p>
                            <TextInput
                                data-testid="BSB_number"
                                name="BSB_number"
                                value={bankDetails.BSB_number}
                                onChange={(event: any) => this.handleInputChange(event)}
                            />
                            {this.showingError(this.state.errors.BSB_number)}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Account number </p>
                            <TextInput
                                data-testid="account_number"
                                name="account_number"
                                value={bankDetails.account_number}
                                onChange={(event: any) => this.handleInputChange(event)}
                            />
                            {this.showingError(this.state.errors.account_number)}
                        </div>
                    </Grid>
                </Grid>

                <Grid container className='margin-32' spacing={2}>
                    <Grid item xs={12} md={4}>
                        <div>
                            <p className='details-title'>Bank name </p>
                            <TextInput
                                data-testid="bank_name"
                                name="bank_name"
                                value={bankDetails.bank_name}
                                onChange={(event: any) => this.handleInputChange(event)}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <div>
                            <p className='details-title'>GST registered </p>
                            <div className="df">
                                <RadioInput
                                    id="yesGST_registered"
                                    name="GST_registered"
                                    value={true}
                                    checked={
                                        bankDetails.GST_registered === true
                                    }
                                    onChange={(event: any) => this.handleCheckedInputChange(event)}
                                    label="Yes"
                                    data-testid="yesGST_registered"
                                />
                                <RadioInput
                                    id="noGST_registered"
                                    name="GST_registered"
                                    value={false}
                                    checked={
                                        bankDetails.GST_registered === false
                                    }
                                    onChange={(event: any) => this.handleCheckedInputChange(event)}
                                    label="No"
                                    data-testid="noGST_registered"
                                />
                            </div>
                        </div>
                    </Grid>

                </Grid>

                <Grid container className='margin-32' spacing={2}>
                    <Grid item xs={12}>
                        <div>
                            <p className='details-title'>Any pre-existing injury or medical condition/disability that would effect ability to do this work?</p>
                            <div className="df">
                                <RadioInput
                                    id="yesmedical_condition"
                                    name="medical_condition"
                                    value={true}
                                    checked={
                                        bankDetails.medical_condition === true
                                    }
                                    onChange={(event: any) => this.handleCheckedInputChange(event)}
                                    label="Yes"
                                    data-testid="yesmedical_condition"
                                />
                                <RadioInput
                                    id="nomedical_condition"
                                    name="medical_condition"
                                    value={false}
                                    checked={
                                        bankDetails.medical_condition === false
                                    }
                                    onChange={(event: any) => this.handleCheckedInputChange(event)}
                                    label="No"
                                    data-testid="nomedical_condition"
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <hr className='hr1 margin-47' />

            </>
        </div>
        )
    }



    render() {
        const { userDetails, personalDetails, hearAboutUsLst } = this.state
        const StatusParam: any = this.state.userDetails?.attributes?.status
        const hasMap: any = {
            draft: 'color-blue',
            pending_approval: 'color-orange',
            pending_induction: 'color-orange',
            induction_booked: 'color-orange',
            cancelled_slot: 'color-orange',
            contract_signature_req: 'color-orange',
            contract_signed: 'color-green',
            application_rejected: 'color-red',
            documents_requested: 'color-orange',
            background_checks_required: 'color-orange',
            background_checks_completed: 'color-purple'
        }
        return (
            <div className='view-applications'>
                <CustomToast handleOpen={this.state.updateLoader} massages={this.state.updateMassages} status='success' />
                <div className='drive-approval'>
                    <div className='right-section'>
                        <Link to={`/operation-manager/viewApplications?id=${userDetails.id}`}>
                            <img
                                src={BackArrowIc}
                                height={24}
                                width={24}
                                alt=""
                            />
                        </Link>

                        <div>
                            <p className='driver-id'>Driver ID -<span> {this.state.userDetails?.attributes?.driver_uniq_id}</span></p>
                            <p className='driver-status'>Status -  <span className={hasMap[StatusParam]}>{userDetails?.attributes && this.removeUnderscoreAndCapitalize(userDetails.attributes.status)}</span></p>
                        </div>
                    </div>
                </div>

                <div className='details-section'>
                    {/* Persoanl Details .....*/}
                    <div id='personal-details'>
                        <p className='personal-details'>Personal Details :</p>

                        <Grid container className='margin-24' spacing={2}>
                            <Grid item xs={12} md={4}>
                                <div>
                                    <p className='details-title'>Full name </p>
                                    <TextInput
                                        data-testid="full_name"
                                        name="full_name"
                                        value={personalDetails.full_name}
                                        onChange={(event: any) => this.handleInputChange(event)}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div>
                                    <p className='details-title'>Gender </p>
                                    <select
                                        name="gender"
                                        id="gender"
                                        autoComplete="off"
                                        data-testid="gender"
                                        value={personalDetails.gender}
                                        onChange={event => this.handleInputChange(event)}
                                    >
                                        <option value="" disabled selected>
                                            Select
                                        </option>
                                        <option value="1">Male</option>
                                        <option value="2">Female</option>
                                        <option value="3">Others</option>
                                    </select>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div>
                                    <p className='details-title'>Date of birth </p>
                                    <input
                                        id="date_of_birth"
                                        type="date"
                                        name="date_of_birth"
                                        data-testid="date_of_birth"
                                        autoComplete="off"
                                        value={personalDetails.date_of_birth}
                                        onChange={event => this.handleInputChange(event)}
                                    />
                                    {
                                        this.showingError(this.state.errors.date_of_birth)
                                    }
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container className='margin-32' >
                            <Grid item xs={12}>
                                <div>
                                    <p className='details-title'>Address </p>
                                    <TextInput
                                        data-testid="address"
                                        name="address"
                                        value={personalDetails.address}
                                        onChange={(event: any) => this.handleInputChange(event)}
                                    />
                                </div>
                            </Grid>
                        </Grid>


                        <Grid container className='margin-32' spacing={2}>
                            <Grid item xs={12} md={4}>
                                <div>
                                    <p className='details-title'>Suburb </p>
                                    <TextInput
                                        data-testid="suburb"
                                        name="suburb"
                                        value={personalDetails.suburb}
                                        onChange={(event: any) => this.handleInputChange(event)}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div>
                                    <p className='details-title'>State </p>
                                    {/* <TextInput
                                        data-testid="state_name"
                                        name="state_name"
                                        value={personalDetails.state_name}
                                        onChange={(event: any) => this.handleInputChange(event)}
                                    /> */}

                                    <input
                                        data-testid="state_name"
                                        type="text"
                                        id={"state_name"}
                                        name={"state_name"}
                                        autoComplete="off"
                                        value={personalDetails.state_name}
                                        onChange={(event: any) => this.handleInputChange(event)}
                                        readOnly={true}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div>
                                    <p className='details-title'>Postcode </p>
                                    <TextInput
                                        data-testid="postcode"
                                        name="postcode"
                                        value={personalDetails.postcode}
                                        onChange={(event: any) => this.handleInputChange(event)}
                                    />
                                    {
                                        this.showingError(this.state.errors.postcode)
                                    }
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container className='margin-32' spacing={2}>
                            <Grid item xs={12} md={4}>
                                <div>
                                    <p className='details-title'>Aus mobile number </p>
                                    <TextInput
                                        data-testid="full_phone_number"
                                        name="full_phone_number"
                                        value={personalDetails.full_phone_number}
                                        onChange={(event: any) => this.handleInputChange(event)}
                                    />
                                    {
                                        this.showingError(this.state.errors.full_phone_number)
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div>
                                    <p className='details-title'>Email </p>
                                    <TextInput
                                        data-testid="email"
                                        name="email"
                                        value={personalDetails.email}
                                        onChange={(event: any) => this.handleInputChange(event)}
                                    />
                                </div>
                            </Grid>

                        </Grid>
                        <hr className='hr1 margin-47' />
                        <Grid container className='margin-32' spacing={2}>
                            <Grid item xs={12} md={12}>
                                <div>
                                    <p className='details-title'>Australian citizen </p>
                                    <div className="df">
                                        <RadioInput
                                            id="yes"
                                            name="australian_citizen"
                                            value={true}
                                            checked={
                                                personalDetails.australian_citizen === true
                                            }
                                            onChange={(event: any) => this.handleCheckedInputChange(event)}
                                            label="Yes"
                                            data-testid="yesAustralian"
                                        />
                                        <RadioInput
                                            id="no"
                                            name="australian_citizen"
                                            value={false}
                                            checked={
                                                personalDetails.australian_citizen === false
                                            }
                                            onChange={(event: any) => this.handleCheckedInputChange(event)}
                                            label="No"
                                            data-testid="noAustralian"
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div>
                                    <p className='details-title'>Business type </p>
                                    <div className="df mb10">
                                        <RadioInput
                                            id="sole"
                                            name="business_type"
                                            value="sole_trader"
                                            checked={personalDetails.business_type === "sole_trader"}
                                            onChange={(event: any) => this.handleInputChange(event)}
                                            label="Sole Trader"
                                            data-testid="solebusinessType"
                                        />
                                        <RadioInput
                                            id="company"
                                            name="business_type"
                                            value="company"
                                            checked={personalDetails.business_type === "company"}
                                            onChange={(event: any) => this.handleInputChange(event)}
                                            label="Company"
                                            data-testid="companybusinessType"
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div>
                                    <p className='details-title'>ABN number </p>
                                    <TextInput
                                        data-testid="ABN_number"
                                        name="ABN_number"
                                        value={personalDetails.ABN_number}
                                        onChange={(event: any) => this.handleInputChange(event)}
                                    />

                                    {this.showingError(this.state.errors.ABN_number)}
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container className='margin-32' spacing={2}>
                            <Grid item xs={12} md={4}>
                                <div>
                                    <p className='details-title'>  How did you hear about us? </p>
                                    <select style={{ marginTop: "0", height: "48px" }}
                                        name="how_do_you_hear_about_us"
                                        id="how_do_you_hear_about_us"
                                        data-testid="how_do_you_hear_about_us"
                                        autoComplete="off"
                                        value={personalDetails.how_do_you_hear_about_us}
                                        onChange={event => this.handleInputChange(event)}
                                    >
                                        <option value="" disabled selected>
                                            Select
                                        </option>
                                        {hearAboutUsLst.map((elem: any) => {
                                            return (
                                                <option key={elem.id} value={elem.title}>
                                                    {elem.title}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div>
                                    <p className='details-title'> Enter Colleague name </p>
                                    <TextInput
                                        data-testid="search_description"
                                        name="search_description"
                                        value={personalDetails.search_description}
                                        onChange={(event: any) => this.handleInputChange(event)}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div>
                                    <p className='details-title'>ACN number </p>
                                    <TextInput
                                        data-testid="ACN_number"
                                        name="ACN_number"
                                        value={personalDetails.ACN_number}
                                        onChange={(event: any) => this.handleInputChange(event)}
                                    />
                                    {this.showingError(this.state.errors.ACN_number)}
                                </div>
                            </Grid>
                        </Grid>
                        <hr className='hr2 margin-32' />
                    </div>
                    {/* Work exprience */}
                    {userDetails?.attributes?.career_experiences != 0 && this.workExperienceSection()}
                    {/* Emergency Contract */}
                    {userDetails.attributes?.emergency_contact?.data != null && this.handleEmergencyContactUi()}
                    {/* Bank Details */}
                    {userDetails.attributes?.bank_detail?.data != null && this.bankDetailsSection()}
                    {/* Vehicle Details*/}
                    {userDetails.attributes?.vehicle_details?.data != null && this.vehiclesSection()}
                </div>



                <div className='update-button-div'>
                    {this.state.updateLoader ? <button
                        className='update-button-disable'
                        disabled
                    >
                        loading....
                    </button> :
                        <button
                            data-testid="update-btn"
                            className='update-button'
                            onClick={() => this.updateFullFrom()}>
                            Update
                        </button>}
                </div>
            </div>
        );
    }
}

export default EditDriverDetails;
// Customizable Area End