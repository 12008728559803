// Customizable Area Start
import moment from "moment";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface AttributeProps {
  from: string;
  to: string;
}

export interface SlotContentProps {
  id: number;
  type: string;
  attributes: AttributeProps;
}

export interface S {
  available_date: any;
  start_time: any;
  end_time: any;
  id: any;
  token: string;
  appointmentsList: any;
  isRefreshing: boolean;
  showCalendar: boolean;
  avaliabel_time: any;
  currentMonthYear: any;
  open: boolean;
  isLoading: boolean;
  showDialogBox: boolean;
  slotList: any;
  perDaySlots: any;
  AllEvents: any;
  monthCalendarValue: any;
  AllSlotContent: SlotContentProps[];
  rangeDate: any;
  popoverOpen: boolean,
  clickedEvent: any,
  monthAndYear: any,
  startAndEnd: any,
  currentDate: any,
  calenderOpen: boolean;
  isNotPermitToBook: boolean
}

export interface SS {
  id: any;
}

export default class AppointmentmanagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  addAppointmentApiCallId: any;
  getAppointmentsListApiCallId: any;
  getAllslotListApiCallId: any;
  deleteAllAppointmentsApiCallId: any;
  calendarRef: any;
  getAppointmentListId: any;
  getPerDaySlotsCallId: any;
  bookAppointmentApiId: any;
  AllSlotContentpiCallId: string = "";
  profileDataID: string = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    let endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);
    this.state = {
      id: 0,
      start_time: new Date(),
      end_time: endTime,
      available_date: moment(new Date()).format("DD/MM/YYYY dddd"),
      appointmentsList: [],
      perDaySlots: [],
      AllEvents: [],
      token: "",
      isRefreshing: false,
      showCalendar: false,
      avaliabel_time: moment(new Date()).format("LT"),
      currentMonthYear: moment(new Date()).format("MMMM YYYY"),
      open: true,
      isLoading: false,
      showDialogBox: false,
      slotList: [],
      monthCalendarValue: new Date(),
      AllSlotContent: [],
      rangeDate: [],
      popoverOpen: false,
      clickedEvent: null,
      monthAndYear: {
        month: '',
        year: ''
      },
      startAndEnd: {
        start: "",
        end: ""
      },
      currentDate: new Date(),
      calenderOpen: false,
      isNotPermitToBook: false

    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getPerDaySlotsApi();
    this.getCurrentWeek()
    this.getToken();
    this.getProfileData()
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.rangeDate !== this.state.rangeDate) {
      this.getPerDaySlotsApi();
      this.getProfileData()
    }
  }

  getCurrentWeek() {
    const startOfWeek = moment().startOf("week").toDate();
    const endOfWeek = moment().endOf("week").toDate();
    console.log("start and end", startOfWeek, endOfWeek);
    let start = moment(startOfWeek).format("DD/MM/YYYY");
    let end = moment(endOfWeek).format("DD/MM/YYYY");
    let _value = {
      start: start,
      end: end
    }
    this.setState({ startAndEnd: _value })
    const startDate = startOfWeek
    const endDate = endOfWeek
    const dates = [];
    const currentDate = new Date(startDate);
    currentDate.setHours(0, 0, 0, 0);
    while (currentDate < endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    if (dates.length > 0) {
      this.setState({ rangeDate: dates })

    }

  }


  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  receive = async (from: String, message: Message) => {
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addAppointmentApiCallId != null &&
      this.addAppointmentApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJsonOne = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponseOne = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJsonOne && !responseJsonOne.errors && responseJsonOne.data) {
        this.props.navigation.navigate("Appointments");
      } else {
        this.parseApiCatchErrorResponse(errorReponseOne);
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);

      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }


    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJsonApi = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponseApi = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId == this.getAppointmentListId) {
      this.handleAppointmentListApiResponse(responseJsonApi, errorReponseApi);
    }

    if (apiRequestCallId == this.getPerDaySlotsCallId) {
      this.getPerDaySlotsApiResponse(responseJsonApi, errorReponseApi);
    }

    if (apiRequestCallId == this.bookAppointmentApiId) {
      this.handleBookSlotApiResponse(responseJsonApi, errorReponseApi);
    }
    if (apiRequestCallId == this.profileDataID) {
      this.handleProfileDataID(responseJsonApi, errorReponseApi);
    }



  };

  // response funtion
  handleAppointmentListApiResponse = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson?.data) {
      let appointments = responseJson?.data?.map((item: any) => {
        let obj = {
          id: "",
          start: "",
          end: "",
          available: true,
          slotLabel: "",
          slotId: "",
          slotFullLabel: "",
          booked: false
        };
        obj.id = item?.id;
        obj.start = item?.attributes?.start;
        obj.end = item?.attributes?.end;
        obj.available =
          item?.attributes?.available;
        obj.booked = item?.attributes?.booked
        obj.slotLabel = item?.attributes?.slot;
        obj.slotId = item?.id;
        let start = moment(item?.attributes?.start).format("LT");
        let end = moment(item?.attributes?.end).format("LT");
        obj.slotFullLabel = `${start}-${end}`;
        return obj;
      });
      this.setState({
        isLoading: false,
        slotList: appointments,
      }
        , () => this.handleEventDrop()
      );
    } else {
      this.setState({
        isLoading: false,
        slotList: [],
      });
      this.parseApiCatchErrorResponse(errorReponse);

    }
  };
  getPerDaySlotsApiResponse = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.data?.length > 0) {
      let allSlotsData = responseJson.data.map((item: any) => {
        let obj = {
          id: "",
          from: "",
          to: ""
        };
        obj.id = item?.id;
        obj.from = item?.attributes?.from;
        obj.to = item?.attributes?.to;
        return obj;
      });

      this.setState({
        isLoading: false,
        perDaySlots: allSlotsData
      }
        , () => this.getAppointmentListApiCall(this.state.startAndEnd.start, this.state.startAndEnd.end)
      );
    } else {
      this.setState({
        isLoading: false,
        perDaySlots: [],
      });
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  handleBookSlotApiResponse = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson?.data) {
      this.setState({
        showDialogBox: true,
        isLoading: false,
        isNotPermitToBook: true
      });
      this.getPerDaySlotsApi();
    } else {
      this.parseApiCatchErrorResponse(errorReponse);
    }
  };

  handleProfileDataID = (responseJson: any, errorReponse: any) => {

    if (responseJson && responseJson?.data) {
      if (responseJson?.data?.attributes?.appointments.data !== null) {
        this.setState({ isNotPermitToBook: true })

      }
    } else {
      this.parseApiCatchErrorResponse(errorReponse);
    }

  }

  // response funtion end

  // normal funtion

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  addAppointment(): boolean {

    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      token: this.state.token,
    };

    const attrs = {
      start_time: moment(this.state.start_time, "HH:mm A").format("HH:mm A"),
      end_time: moment(this.state.end_time, "HH:mm A").format("HH:mm A"),
      availability_date: moment(this.state.available_date, "DD/MM/YY").format(
        "YY/MM/DD"
      ),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addAppointmentApiCallId = requestMessage.messageId;

    const httpBody = {
      ...attrs,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.appointmentAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAppointmentAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAppointmentList = (token: any) => {
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const attrs = {
      service_provider_id: "1",
      availability_date: this.state.available_date,
    };

    this.getAppointmentsListApiCallId = requestMessage.messageId;
    let urlParams = new URLSearchParams(attrs).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.appointmentAPiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  navigateToAppointments = () => {
    this.props.navigation.navigate("Appointments");
  };



  handleTodayBtn = () => {
    this.calendarRef?.current?.getApi()?.gotoDate(new Date());
    this.setState({
      monthCalendarValue: new Date(),
    });
  };

  getProfileData() {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    let userId = localStorage.getItem('userId')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.profileDataID = requestMessage.messageId;
    // Set Method Type
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );
    // Set EndPoints
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileDataApiEndPoint + userId
    );
    // set Headers
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAppointmentListApiCall = (start: any, end: any) => {

    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const attrs = {
      from: start,
      to: end,
    };

    this.getAppointmentListId = requestMessage.messageId;

    let urlParams = new URLSearchParams(attrs).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAppointmentsAPI}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };



  getPerDaySlotsApi = () => {
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPerDaySlotsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetAllTimeSlots
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleBookSlotApiCall = (slotInfo: any) => {
    const headers = {
      "Content-Type": configJSON.appointmentApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestBody = {

      data: {
        time_slot_id: slotInfo.id,
        date: slotInfo.dateValue,
      }
    }

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.bookAppointmentApiId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAppointmentsAPI}/book`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PutMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  closeDialogBox = () => {
    this.setState({
      showDialogBox: false,
    });
  };




  // calender handleing 
  smallCalnederOpener = () => {
    this.setState({ calenderOpen: !this.state.calenderOpen })
  }


  getMonthAndYear = (data: any) => {
    const upgradingDate = new Date(data.year, data.month, 1);
    this.setState({
      currentDate: upgradingDate,
      startAndEnd: {
        start: data.startWeek,
        end: data.endWeek
      }
    })
    let start = Date.parse(data.startWeek);
    let end = Date.parse(data.endWeek);

    let _value = {
      start: start,
      end: end
    }

    this.getDatesInRange(_value)
  }

  handleRangeChange = (range: any) => {
    this.setState({ rangeDate: range, currentDate: range[0] })
    let start = moment(range[0]).format("DD/MM/YYYY");
    let end = moment(range[6]).format("DD/MM/YYYY");
    let _value = {
      start: start,
      end: end
    }
    this.setState({ startAndEnd: _value })
  };


  getDatesInRange = (arg: any) => {
    let startDate = arg.start
    const endDate = arg.end
    const dates = [];
    while (startDate <= endDate) {
      let currentDate = new Date(startDate);
      dates.push(currentDate);
      startDate += 24 * 60 * 60 * 1000;
    }
    if (dates.length > 0) {
      this.setState({ rangeDate: dates })
    }
  };

  handleEventDrop = () => {
    const { perDaySlots, slotList, rangeDate } = this.state;
    const updatedData: any = [];
    rangeDate.forEach((element: any) => {
      const _element: any = moment(element).format('YYYY-MM-DD');
      perDaySlots?.forEach((dataVal: any) => {
        const _start = `${_element} ${dataVal.from}`
        const _end = `${_element} ${dataVal.to}`
        const matchingData = slotList?.find((dami: any) => dami.start === _start && dami.end === _end && (dami.available === false || dami.booked === true));
        const _available = matchingData ? false : true;
        let newStart = `${_element}T${dataVal.from}`
        let newEnd = `${_element}T${dataVal.to}`
        const obj = {
          slotId: dataVal.id,
          start: new Date(newStart),
          end: new Date(newEnd),
          available: _available
        };
        updatedData.push(obj);
      });
    });
    this.setState({ AllEvents: updatedData })
  }

}
// Customizable Area End
