// Customizable Area Start
import React from 'react';
import { Calendar, momentLocalizer, ToolbarProps } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import AppointmentmanagementController, { Props } from './AppointmentmanagementController';
import './newAppointment.css';
import { leftArrow, rightArrow, backIcon, robotSucces, downarrow } from './assets';
import "./Appointments.css";
import CustomPopover from './CustomPopover.web';
import { Calendar as SmallCalender } from "react-calendar";
import { Dialog } from "@material-ui/core";

interface CustomToolbarProps extends ToolbarProps {
    onView: (view: string) => void;
    getMonthAndYear: any;
    smallCalenderOpener: boolean
    handleSmallCalender: () => void;

}

export const CustomToolbar: React.FC<CustomToolbarProps> = ({ date, label, onView, onNavigate, getMonthAndYear, smallCalenderOpener, handleSmallCalender }) => {
    const [value, onChange] = React.useState(new Date());


    const handleNavigate = (action: any) => {
        onNavigate(action);
    };

    const handleCalendarChange = (date: any) => {
        const selectedMonth = date.getMonth();
        const selectedYear = date.getFullYear();
        const firstWeekStart = moment(date).startOf('month').startOf('week').toDate();
        const firstWeekEnd = moment(firstWeekStart).add(6, 'days').toDate();


        let custom_date = {
            month: selectedMonth,
            year: selectedYear,
            startWeek: firstWeekStart,
            endWeek: firstWeekEnd
        }

        getMonthAndYear(custom_date)
        onChange(date);
    };

    const monthName = date.toLocaleString('default', { month: 'long', year: 'numeric' });

    return (
        <div className="custom-toolbar">
            <div className='date-and-today-section'>
                <div className='current-date-section'>
                    <img
                        onClick={() => window.location.href = "/driver/dashboard"}
                        data-testid='back-btn'
                        className='backIcon'
                        src={backIcon}
                        alt=""
                    />
                    <div className='last-check'>
                        <p className='date text-font'>{moment().format('L')} {moment().format('dddd')}</p>
                        <p className='time text-font'>{moment().format('LT')}</p>
                    </div>
                </div>

                <button
                    className='todayBtn'
                    onClick={() => handleNavigate('TODAY')}>
                    Today
                </button>
            </div>


            <div className='custom-tootlbar-all-group'>
                <div>
                    <button
                        className='month-select-btn'
                        onClick={() => handleSmallCalender()}>
                        {monthName}
                        <img src={downarrow} height={8} width={10} alt="" />
                    </button>
                    {smallCalenderOpener ?

                        <div className="slotMonthCalendar">
                            <SmallCalender
                                data-testid='small-calender'
                                calendarType="US"
                                defaultView="year"
                                onChange={handleCalendarChange}
                                value={value}
                                maxDetail="year"
                            />
                        </div> : null}
                    <p className='select_induction_slot'>Select induction slot</p>
                </div>
                <div className="toolbar-buttons">

                    <img
                        data-testid='left-img'
                        src={leftArrow}
                        alt=""
                        height={48}
                        width={48}
                        onClick={() => handleNavigate('PREV')}
                    />
                    <div className="toolbar-label">{label}</div>
                    <img
                        data-testid='right-img'
                        src={rightArrow}
                        alt=""
                        height={48}
                        width={48}
                        onClick={() => handleNavigate('NEXT')}
                    />

                </div>

                <div className='indicate-slot'>
                    <div className='indicate-slot-insider'>
                        <div className='available'></div>
                        <p> Available slot</p>
                    </div>
                    <div className='indicate-slot-insider'>
                        <div className='unavailable'></div>
                        <p>  Slot booked</p>
                    </div>

                </div>
            </div>

        </div>
    );
};


export const CustomEventComponent: React.FC<{ event: any; slotBookingApi: any, isNotPermitBook: boolean }> = ({ event, slotBookingApi, isNotPermitBook }) => {
    const currentDate = new Date();
    const targetDate = event.start
    const isPreviousDate = targetDate.getTime() < currentDate.getTime();
    return (
        <>
            {(event.available && !isPreviousDate) ? <CustomPopover data={event} slotBookingApi={slotBookingApi} isNotPermitBook={isNotPermitBook} /> :
                <div className='grey-slot-btn'>
                    <p>
                        {moment(event.start, 'h:mm A').format('h:mm')} - {moment(event.end, 'h:mm A').format('h:mm')}
                    </p>
                </div>

            }
        </>
    );
};



class NewAppointment extends AppointmentmanagementController {
    constructor(props: Props) {
        super(props);

    }

    localizer = momentLocalizer(moment);

    renderDialog = () => {
        return (
            <Dialog
                open={this.state.showDialogBox}
                onClose={this.closeDialogBox}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={false}
                fullWidth
                PaperProps={{
                    style: {
                        width: "auto",
                        height: 340,
                    },
                }}
            >
                <div className="dialogBoxContainer">
                    <div>
                        <img src={robotSucces} className="robot-img" />
                    </div>
                    <div>
                        <h2 className="dialogBoxText">
                            Induction Slot <span className="booked-text">Booked</span>
                        </h2>
                        <p className="notifyText">
                            please check your <span className="emailText">email</span> inbox
                            for
                        </p>
                        <p className="notifyText">for further instructions</p>
                    </div>
                </div>
            </Dialog>
        );
    };

    render() {

        return (
            <div
                className="driver-main-conatiner"
            >
                {this.renderDialog()}
                {/* @ts-ignore */}


                <Calendar
                    localizer={this.localizer}
                    events={this.state.AllEvents}
                    date={this.state.currentDate}
                    endAccessor="end"
                    style={{ height: '99vh' }}
                    views={['week']}
                    defaultView="week"
                    onRangeChange={this.handleRangeChange}
                    min={moment().startOf('day').add(9, 'hours')}
                    max={moment().startOf('day').add(24, 'hours').subtract(1, 'minute')}
                    components={{
                        toolbar: (toolbarProps: ToolbarProps) => (
                            <CustomToolbar
                                {...toolbarProps}
                                onView={(view: any) => toolbarProps.onNavigate('TODAY', view)}
                                getMonthAndYear={this.getMonthAndYear}
                                smallCalenderOpener={this.state.calenderOpen}
                                handleSmallCalender={this.smallCalnederOpener}

                            />

                        ),
                        event: (props: any) => (
                            <CustomEventComponent
                                {...props}
                                slotBookingApi={this.handleBookSlotApiCall}
                                isNotPermitBook={this.state.isNotPermitToBook}
                            />
                        ),
                    }}

                />
            </div>
        );
    }
}

export default NewAppointment;
  // Customizable Area End
