export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const DriverApplicationIC = require("../assets/driverApplication.png");
export const SettingsIC = require("../assets/settings.png");
export const ApplicationIC = require("../assets/application.png");
export const ActiveDriverApplicationIC = require("../assets/ActivedriverApplication.png");
export const ActiveSettingsIC = require("../assets/ActiveSettingsIC.png");
export const ActiveApplicationIC = require("../assets/ActiveCalenderIC.png");
export const CameraIC = require("../assets/CameraIC.png");
export const downloadIc = require("../assets/downloadIC.png");
export const GreenDocIc = require("../assets/GreenDocIC.png")
export const calenderPathIcon = require("../assets/Path.png")
export const backwardIcon = require("../assets/backwardIcon.png")
export const forwardIcon = require("../assets/forwardIcon.png")
export const RightArrowIc = require("../assets/Right_Arrow.png");
export const BackArrowIc = require("../assets/BackArrow.png");
export const crossImage = require("../assets/Path.png")
export const OvalPng = require("../assets/Oval.png")
export const greenRound = require("../assets/greenRound.png")
export const GreenTick = require("../assets/GreenTick.png")
export const warning = require("../assets/warning.png")
export const CalendarIcon = require("../assets/CalendarIcon.png")
export const Processing = require("../assets/processing.png")
export const sucess = require("../assets/sucess.png")
export const AusFlagIcon = require("../assets/Flag-Australia.png")
export const visibilityOff = require("../assets/visibility_off.png");
export const closeIcon = require("../assets/close-button.png");
export const leftArrow = require("../assets/greyBackIcon.png");
export const rightArrow = require("../assets/greyRightIcon.png");