import React from 'react';
import { Link } from 'react-router-dom';

// Customizable Area Start
import { Box, Grid, Typography } from "@material-ui/core";
import { imgFacebook, imgInsta, imgLinkedin, imgLogo, imgYoutube } from '../../blocks/landingpage/src/assets';
// import { imgLogo, imgFacebook, imgYoutube, imgLinkedin, imgInsta } from "./assets";
// Customizable Area End
// Customizable Area Start
const Footer = () => {
	return (
		<div className='container'>
		<Grid container justifyContent="space-between" className="footerSection">
			<Grid item xs={12} sm={12} lg={4}>
				<Box p={1} className='copyrightText'>
					<img src={imgLogo} width="174" height="auto" />
					<Typography>Copyright @ Couries by Demand Express.<br />
						All Rights reserved
					</Typography>
				</Box>
			</Grid >
			<Grid item xs={12} sm={12} lg={2}>
				<Box p={1} display="flex" flexDirection="column">
 					<Typography
						component={Link}
						to="/Faq"
					>
						FAQ
					</Typography>
					<Typography
						component={Link}
						to="/BackgroundCheck"
					>
						Background Check
					</Typography>
				</Box>
			</Grid >
			<Grid item xs={12} sm={12} lg={2}>
				<Box p={1}>
					<Typography > Tel: 0290955000 </Typography>
				</Box>
			</Grid>
			<Grid item xs={12} sm={12} lg={4}>
				<Box p={1}>
					<Typography>
						Head Office : Sydney <br />
						3/450 West Botany St, Rockdale NSW 2216
					</Typography>
				</Box>
			</Grid>
		</Grid>
		</div>
	);
};

export default Footer;
// Customizable Area End
