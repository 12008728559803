// Customizable Area Start
import React from 'react';
import './css/viewCertificate.css'
import { Link } from "react-router-dom";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Dialog } from '@material-ui/core';
import { BackArrowIc, CameraIC, downloadIc, RightArrowIc, closeIcon } from '../assets';
import ViewCertificateApplicationsController from '../Controller/ViewCertificateApplicationsController.web';

const styles = (theme: any) => ({
    dialogRoot: {},
    paper: {
        height: '30em',
        width: '40em',
        padding: "2em"
    },
    smallPopup: {
        width: '20em',
        height: '20em',
        padding: '2rem'
    },
    formControl: {
        margin: 0,
        minWidth: '100%',
        "& div.MuiInput-formControl::before": {
            border: "none !important",
        },
        "& select.MuiNativeSelect-select": {
            background: "transparent !important",
            padding: '10px 15px',
        },
        "& .MuiInput-underline::after": {
            border: "none !important",
        },
        "& select.MuiNativeSelect-select option:hover": {
            background: "gray !important",
            color: "white !important"
        },
        "& select.MuiNativeSelect-select option:checked": {
            background: "gray !important",
            color: "white !important"
        },
        "& select.MuiNativeSelect-icon": {
            right: "10px",
        },
    },
    selectEmpty: {
        marginRight: theme.spacing(2),
        borderRadius: 8,
        minHeight: '60px',
        border: '1px solid #e5e5e5',
    }
})

const CustomDialog = withStyles(styles)(({ classes, ...props }: any) => (
    <Dialog
        classes={{
            root: classes.dialogRoot,
            paper: classes.paper,
            smallPopup: classes.smallPopup
        }}
        {...props}
    />
));

const CustomFormLabel = withStyles({
    root: {
        "& .MuiFormControlLabel-root": {
            marginBottom: "2em",
        }

    }
})((props: any) => <FormControlLabel {...props} />);

class ViewCertificateApplications extends ViewCertificateApplicationsController {
    render() {
        const id: any = new URLSearchParams(window.location.search).get('id')
        const StatusParam: any = new URLSearchParams(window.location.search).get('status')
        const hasMap: any = {
            draft: 'color-blue',
            pending_approval: 'color-orange',
            pending_induction: 'color-orange',
            induction_booked: 'color-orange',
            cancelled_slot: 'color-orange',
            contract_signature_req: 'color-orange',
            contract_signed: 'color-green',
            application_rejected: 'color-red',
            documents_requested: 'color-orange',
            background_checks_required: 'color-orange',
            background_checks_completed: 'color-purple'
        }
        const { driverDetails, uploadedDocList } = this.state
        const keysWithoutNull = Object.keys(uploadedDocList).filter(key => uploadedDocList[key] !== null);
        const requestDoc = Object.keys(uploadedDocList).filter(key => uploadedDocList[key] === null);
        
        return (
            <div className='viewCertificate'>
                <Breadcrumbs separator={<img src={RightArrowIc} height={20} width={11} />} aria-label="breadcrumb">
                    <Link to="/operation-manager/driver-applications" className='bread-1'>
                        Driver Application
                    </Link>
                    <Link to={`/operation-manager/viewApplications?status=${StatusParam}&id=${id}`} className='bread-1'>
                        View Application
                    </Link>
                    <Typography color="textPrimary">View Certificate & Applications </Typography>
                </Breadcrumbs>
                <div className='drive-approval'>
                    <div className='right-section'>
                        <img
                            onClick={() => this.goBack()}
                            src={BackArrowIc}
                            height={24}
                            width={24}
                            alt=""
                            data-testid="documentViewGoBack"
                        />
                        <div>
                            <p className='driver-id'>Driver ID -<span> {driverDetails?.attributes?.driver_uniq_id}</span></p>
                            <p className='driver-status'>Status -  <span className={hasMap[StatusParam]}>{this.removeUnderscoreAndCapitalize(StatusParam)}</span></p>
                        </div>
                    </div>

                    {this.state.driverDetails?.attributes?.status === "pending_approval" &&  
                     <>
                     {requestDoc?.length > 0  ? 
                        <div className='button-section'>
                            <button className='reject' onClick={this.handleApplicationReject} >Request for Document</button>
                        </div>: <p className='msgDoc'>All Document Already Uploaded</p>}
                        </>
                    }

                    {/* Modal for the selecting template */}
                    <CustomDialog onClose={() => { this.setState({ openrReasonList: false }) }} open={this.state.openrReasonList} sx={{ height: "18em", width: "31em" }}>
                        <div className="crossButtonContainer closeIcon">
                            <img src={closeIcon} onClick={() => { this.setState({ openrReasonList: false }) }} />
                        </div>
                        <p className='popupHead'>Please select request document <span>Hold down the Ctrl(windows)/Command(Mac) button to select multiple options</span></p>
                        <div className='requireDoclist'>
                            <select multiple={true}  onChange={this.handleOptionChange}>
                                <option value="" disabled selected>
                                    Select
                                </option>
                                {
                                    requestDoc.map((item: any) => {
                                        return (
                                            <option key={item.id} value={item.id}>{this.removeUnderscoreAndCapitalize(item)}</option>
                                        )
                                    })
                                }
                            </select >
                        </div >
                        <div className="continueButtonContiner">
                            <div className='button-section'>
                                <button className='reject' onClick={this.handleRequestDocument} >Request Document</button>
                            </div>
                        </div>
                    </CustomDialog >

                </div>

                <div className='certificateAndDocument-div'>
                    <div className='certificateAndDocument'>
                        <p>Certificates & Documents</p>
                    </div>
                    <Grid container spacing={3} className='margin-31 doc-padding-0'>
                        {keysWithoutNull?.length > 0 ?
                            keysWithoutNull.map((ele, index) => (
                                <Grid key={index} data-testid="pictureOfselfGrid" item xs={12} md={6} lg={4}>
                                    <div className='downloadBox'>
                                        <div data-testid="upperpersonaldocuments-document-label" onClick={() => this.downloadDocument(ele)} className='doc-download-button'>
                                            <div><img src={CameraIC} height={20} width={16} alt="" />
                                                <p> {this.removeUnderscoreAndCapitalize(ele)}</p></div>
                                            <img src={downloadIc} height={24} width={24} alt="" />
                                        </div>
                                    </div>
                                </Grid>
                            ))
                            : <p className='details-textnoDoc'>No Document Here</p>}
                    </Grid>
                </div>
                {driverDetails && driverDetails?.attributes?.invite_link && (
                    <div
                        className="historyContainer historyContainerfull"
                        onClick={() => this.handleVettingScreenOpen()}
                    >
                        <p>Vetting.com</p>
                    </div>
                )}

                {keysWithoutNull?.length > 0 ? <div className='view-download-btn-div'>
                    <button className='Download-All' onClick={() => this.downloadAllDocuments()}>Download All</button>
                </div>
                    : ''}
            </div>
        );
    }
}

export default ViewCertificateApplications;
// Customizable Area End
