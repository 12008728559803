import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";


// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config");

export interface Props { }

interface S {
    // Customizable Area Start
    requiredDocsList: any
    uploadedDocList: any
    driverDetails: any
    openrReasonList: boolean
    statusReasonList: any
    statusReasonListId: any
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ViewCertificateApplicationsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getRequiredDocArrayMessageId: string = "";
    GetDriverVewAppCallId: string = ""
    requestRequiredDocumentCallId: string = "";
    beCheckStatusCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            requiredDocsList: [],
            uploadedDocList: {},
            driverDetails: {},
            openrReasonList: false,
            statusReasonList: [],
            statusReasonListId: ''
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount(): Promise<void> {
        const param = new URLSearchParams(window.location.search).get('id')
        this.handleViewDriverApp(param)
        this.getDriverDocument()
    }

    goBack = () => {
        window.history.back()
    }

    handleViewDriverApp = (id: any) => {
        const header = {
            "Content-Type": "application/json",
            token: window.localStorage.getItem("token"),
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.GetDriverVewAppCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.GetViewDriverApp}?id=${id}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    handleVettingClick = () => {
        window.open(this.state.driverDetails?.attributes?.invite_link, "Vetting.com", 'noopener');
    }

    handleApplicationReject = () => {
        this.setState({ openrReasonList: true })
    }

    removeUnderscoreAndCapitalize = (string: string) => {
        if (string === "draft") {
            return "Application draft"
        } else if (string === "contract_signature_req") {
            return "Contract signature required"
        } else if (string === "abn") {
            return "ABN"
        } else {
            string = string?.replace(/_/g, ' ');
            string = string?.charAt(0)?.toUpperCase() + string?.slice(1);
            return string;
        }
    }

    downloadDocument = async (docToDownload: string) => {
        const { uploadedDocList } = this.state
        const selectedDoc = uploadedDocList[docToDownload]
        try {
            const response = await fetch(selectedDoc.url);
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(new Blob([blob]));

            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute(
                "download",
                selectedDoc.filename
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error downloading the file:", error);
        }
    }

    downloadAllDocuments = async () => {
        const { requiredDocsList } = this.state
        requiredDocsList && requiredDocsList.map(this.downloadDocument)
    }

    getDriverDocument = () => {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };
        const windowUrl = window.location.search;
        const userId = new URLSearchParams(windowUrl).get('id');

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getRequiredDocArrayMessageId = requestMessage.messageId;
        // Set Method Type
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethod
        );
        // Set EndPoints
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getAllDocuments}?driver_id=${userId}`
        );
        // set Headers
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleRequestDocument = () => {
        const userID = new URLSearchParams(window.location.search).get('id')
        const header = {
            "Content-Type": "application/json",
            token: window.localStorage.getItem("token"),
        };


        const selectedOptionsString = this.state.statusReasonListId.join(', ');

        const body = {
            data: {
                driver_id: userID,
                mail_for: "upload_document",
                document_names: selectedOptionsString
            }
        }

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.requestRequiredDocumentCallId = getValidationsMsg.messageId;
        // need to check config
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.requestBackgroundCheckEndpoints}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.examplePutAPiMethod
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        )
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    handleViewDriverAppResponse = (responseJson: any, errorResponse: any, apiRequestCallId: any) => {
        if (this.GetDriverVewAppCallId === apiRequestCallId) {
            if (responseJson && responseJson.data) {
                this.setState({ driverDetails: responseJson.data })
            } else {
                console.log(errorResponse);
            }
        }
    }

    handleChangeStausId = (event: any) => {
        this.setState({ statusReasonListId: event })
    }


    handleOptionChange = (event: any) => {
        const selectedValues = Array.from(event.target.selectedOptions, (option: any) => option.value);
        this.setState({ statusReasonListId: selectedValues })
    }

    RequestRequiredDocument = (apiRequestCallId: any, responseJson: any) => {
        if (this.requestRequiredDocumentCallId === apiRequestCallId) {
            if (responseJson && responseJson.data) {
                this.setState({
                    statusReasonList: responseJson.data,
                    openrReasonList: false
                })
            }
        }
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            let errorResponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (this.getRequiredDocArrayMessageId === apiRequestCallId) {
                if (responseJson && responseJson.data) {
                    const { required, documents } = responseJson.data.attributes
                    this.setState({ requiredDocsList: required, uploadedDocList: documents })
                } else {
                    console.log(errorResponse);
                }
            }
            this.RequestRequiredDocument(apiRequestCallId, responseJson)
            this.handleViewDriverAppResponse(responseJson, errorResponse, apiRequestCallId)
            this.handleApiResponseBackgroundStatus(apiRequestCallId, responseJson)
        }
        // Customizable Area End
    }

    // Customizable Area Start
    handleApiResponseBackgroundStatus = (apiRequestCallId: any, responseJson: any) => {
        if (this.beCheckStatusCallId === apiRequestCallId) {
            window.open(responseJson.report_link, "Vetting.com", 'noopener');
        }
    }

    handleVettingScreenOpen() {
        const userID = new URLSearchParams(window.location.search).get('id')
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.beCheckStatusCallId = requestMessage.messageId;
        // Set Method Type
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethod
        );
        // Set EndPoints
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.backgroundCheckScreeningApiEndpoint}?driver_id=${userID}`
        );
        // set Headers
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}