import React from "react";
// Customizable Area Start

import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import Forgetpasswrdmailsent from "./components/forgetpasswrdmailsent.web";

// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
// Customizable Area End
export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Forgetpasswrdmailsent
          forgetPasswordState={this.props.forgetPasswordState}
          handleFPassState={this.props.handleFPassState}
          loginState={this.props.loginState}
          handleLoginState={this.props.handleLoginState}
          navigation={undefined}
          handleSignupState={this.props.handleSignupState}
        />
      </ThemeProvider>
    );
  }
}
