import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Typography,
  Divider,
  Breadcrumbs,
  Link,
  Grid
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import BackgroundCheckController, { Props } from "./BackgroundCheckController";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Footer from "../../../../web/src/Footer.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

// Customizable Area Ends
export default class BackgroundCheck extends BackgroundCheckController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
        {/* <Header changeSignupState={undefined} /> */}

        <ThemeProvider theme={theme}>
          <Container
            maxWidth="xl"
            disableGutters
            style={{ background: "#f9f9f9" }}
          >
            <Box className="container">
              <Box
                sx={{
                  marginTop: "30px",
                  marginBottom: "60px"
                }}
              >
              
              <Breadcrumbs className="breadcrumbsList"
                separator={
                  <NavigateNextIcon className="arrowRight" />
                }
                area-label="breadcrumb"
                >
                <Link
                  color="inherit"
                  style={{
                    opacity: 0.3,
                    fontFamily: "Lexend",
                    fontSize: "16px",
                    fontWeight: 400,
                    textAlign: "center"
                  }}
                  href="/"
                >
                  Home Page
                </Link>
                <Typography
                  color="textPrimary"
                  style={{
                    fontFamily: "Lexend",
                    fontSize: "16px",
                    fontWeight: 400,
                    textAlign: "center"
                  }}
                >
                  Background Check
                </Typography>
              </Breadcrumbs>
              </Box>
              <Box style={{ margin: "0 0 50px 0" }}>
                <Typography
                  variant="h4" className="bgHead">
                  Background Check
                </Typography>
                <Divider />
              </Box>


              <div className="container bgCheck">
              {this.state.backgroundCheckContent.map(
                (ele: any, idx: number) => (
                  <>
                    {idx % 2 === 0 ? (
                       <div className="contentSection mt40">
                        <div className="imgContainer">
                          <p><img className="bgleftimg"
                            src={ele.attributes.image_url}
                          /></p>
                        </div>

                        <div className="listData">
                          <Box>
                            <h3>
                              {ele.attributes.background_title}
                            </h3>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: ele.attributes.background_content
                              }}
                            />
                          </Box>
                        </div>
                      </div>
                    ) : (
                      
                      <div className="contentSection mt40">
                        <div className="listData oddContent">
                            <h3>
                              {ele.attributes.background_title}
                            </h3>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: ele.attributes.background_content
                              }}
                            />
                        </div>
                        <div className="imgContainer odd bgRightimg">
                          <img
                            src={ele.attributes.image_url}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )
              )}
              </div>
            </Box>
          </Container>
          <Footer />
        </ThemeProvider>
      </>
      // Customizable Area Ends
    );
  }
}
