import React from "react";
// Customizable Area Start
import StepsContainerController, { Props } from "./StepsContainerController";
import { Container, Grid } from "@material-ui/core";
import "./PersonalDetails.css";
import PersonalDetails from "./PersonalDetails.web";
import BackGroundCheckWeb from "./BackGroundCheck.web";
import WorkExperience from "./WorkExperience.web";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import { withStyles } from "@material-ui/core/styles";
import VehicleDetails from "./VehicleDetails.web";
import DocumentCheck from "./DocumentCheck.web";
import BackGroundStep from "./BackgroundStep3.web"
import PaymentThanks from "./PaymentThanks.web";
import BackGroundCheckPending from "./BackGroundCheckPending.web";
import BackGroudCheckComplete from "./BackGroundCheckComplete.web";

// Customizable Area End

export default class StepsContainer extends StepsContainerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleStep = () => {
    let step = this.state.activeStep;
    if (step == 2) {
      this.setState({ activeStep: 2.5 });
    } else if (step == 2.5) {
      this.setState({ activeStep: 3 });

    } 
    else {
      this.setState({ activeStep: step + 1 });
    }
  };
  getStepContents = (step: any) => {
    switch (step) {
      case 0:
        return (
          <>
            <PersonalDetails
              handleNext={undefined}
              handleChange={this.handleStep}
              navigation={this.props.navigation}
              id={""}
              history={undefined}
            />
          </>
        );
      case 1:
        return (
          <>
            <WorkExperience
              handleNext={undefined}
              handleChange={this.handleStep}
              navigation={this.props.navigation}
              id={""}
              handleBack={this.handlePrevStep}
              history={undefined}
            />
          </>
        );
      case 2:
        return (
          <>
            <VehicleDetails
              handleNext={undefined}
              handleChange={this.handleStep}
              navigation={this.props.navigation}
              id={""}
              handleBack={this.handlePrevStep}
              history={undefined}
            />
          </>
        );
      case 2.5:
        return (
          <BackGroundCheckWeb
            handleChange={this.handleStep}
            navigation={this.props.navigation}
            id={""}
            history={undefined}
            handleNext={undefined}
            handleBack={this.handlePrevStep}
          />
        );
      case 4:
        return (
          <>
            <BackGroundStep
              handleChange={this.handleStep}
              navigation={this.props.navigation}
              id={""}
              handleBack={this.handlePrevStep}
              history={undefined}
              errorMessage={""}
              handleNext={undefined} />
          </>
        );
      case 4.1:
        return (
          <>
            <PaymentThanks
              handleChange={this.handleStep}
              navigation={this.props.navigation}
              id={""}
              handleBack={this.handlePrevStep}
              history={undefined}
              errorMessage={""}
              handleNext={undefined} />
          </>
        );
      case 4.2:
        return (
          <>
            <BackGroundCheckPending
              handleChange={this.handleStep}
              navigation={this.props.navigation}
              id={""}
              handleBack={this.handlePrevStep}
              history={undefined}
              handleNext={undefined}
              errorMessage={""}
              />
          </>
        );
      case 4.3:
        return (
          <>
            <BackGroudCheckComplete
              handleChange={this.handleStep}
              navigation={this.props.navigation}
              id={""}
              handleBack={this.handlePrevStep}
              history={undefined}
              handleNext={undefined} 
              errorMessage={""}
              />
          </>
        );
      case 3:
        return (
          <>
            <DocumentCheck
              handleStep={this.handleStep}
              handlePrevStep={this.handlePrevStep}
              navigation={this.props.navigation}
              id={""}
            />
          </>
        );
    }
  };
  handlePrevStep = () => {
    const step = this.state.activeStep;
   
    let newStep: number;
    if (step === 4.1 || step === 4.2 ) {
      newStep = 4;
    } else if (step === 2.5) {
      newStep = 2;
    } else {
      newStep = step - 1;
    }

    this.setState({ activeStep: newStep });
  };

  // Customizable Area End
  render() {
    const { activeStep } = this.state;
    return (
      // Customizable Area Start
      <section className="bgGrey">
        <Container>
          <Grid container spacing={2} justifyContent="center">
            <Grid item lg={9}>
              <FormStepper
                connector={<QontoConnector />}
                activeStep={activeStep}
                alternativeLabel
              >
                {this.state.stepsLabel.map((label: any) => (
                  <Step key={label}>
                    <CustomStepLabel>{label}</CustomStepLabel>
                  </Step>
                ))}
              </FormStepper>
            </Grid>
          </Grid>
          <section className="personal-detail">
            {this.getStepContents(activeStep)}
          </section>
        </Container>
      </section>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const FormStepper = withStyles({
  root: {
    background: "inherit",
  },
})(Stepper);

const CustomStepLabel = withStyles({
  root: {
    color: "black",
    "& .MuiStepLabel-labelContainer": {
      color: "black",
      "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
        color: "#222222",
        fontFamily: "Lexend",
        fontSize: "10px",
        fontWeight: 400,
        opacity: 0.6,
      },
    },
    "& .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel": {
      color: "black",
      "& .MuiStepIcon-root.MuiStepIcon-completed": {
        color: "#80C343",
        border: "none",
      },
      "& .MuiStepIcon-root.MuiStepIcon-active": {
        color: "#F9F9F9",
        boxShadow: "0px 0px 14px rgba(128, 195, 67, 0.842493)",
        border: "3px solid #80C343",
        "& .MuiStepIcon-text": {
          fill: "black",
          fontFamily: "Lexend",
          fontSize: "12px",
          fontWeight: 500,
          lineHeight: "15px",
        },
      },
      "& .MuiStepIcon-root": {
        border: "1px solid white",
        borderRadius: "12px",
        color: "#F9F9F9",
      },
      "& .MuiStepIcon-text": {
        fill: "grey",
        fontFamily: "Lexend",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "15px",
      },
    },
  },
})(StepLabel);

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#80C343",
      boxShadow: "0px 0px 14px #80C343",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#80C343",
    },
  },
  line: {
    borderColor: "#FFFFFF",
    borderTopWidth: 3,
    borderRadius: 2,
  },
})(StepConnector);
// Customizable Area End
