import React from 'react'
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import './css/mainCalendar.css'
import { backwardIcon, forwardIcon } from "../assets";

export const NavButton = ({ label, onClick }: any) => (
    <button
        className="nav-month-btn"
        data-test-id="na"
        onClick={() => onClick()}
    >
        {label}
    </button>
);

const MainCalendar = ({ value, activeStartDate, onChangeHandler, backwardMonth, forwardMonth, events, getDate }: any) => {
    return (
        <div className="mainCalendar">
            <div id="nav-months" className='nav-month-arror' style={{ width: '100%' }}>
                <NavButton
                    label={<img
                        src={backwardIcon}
                        alt="icon"
                    />}
                    onClick={backwardMonth}
                />
                <NavButton
                    label={<img src={forwardIcon} alt="icon" />}
                    onClick={forwardMonth}
                />

            </div>
            <Calendar
                calendarType="US"
                value={value}
                activeStartDate={activeStartDate}
                onChange={onChangeHandler}
                showNavigation={false}
                formatDay={(locale: any, date: any): any => getDate({ events, date })}
            />

        </div>
    )
}

export default MainCalendar