Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.docusignGetUrl = "bx_block_profile/driver_contract_info/driver_contract_signing_url";
exports.docusignGetManagereUrl = "bx_block_profile/driver_contract_info/manager_contract_signing_url";
exports.viewDocusign = "bx_block_profile/driver_contract_info/view_contract_url";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboarApiPUTMethodType="PUT";
exports.PostMethodType = "POST";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.getApplicationStatusApiEndpoint = "account_block/driver_app_status";
exports.profileDataApiEndPoint = "account_block/accounts/"
exports.cancelAppointmentEndPoint = "bx_block_appointment_management/appointments"
exports.getApplicationStatusApiEndpoint = "account_block/driver_app_status"
// Customizable Area End