import React from "react";
// Customizable Area Start
import { Container } from "@material-ui/core";
import { closearrow } from "./assets";
import "./PersonalDetails.css";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import FileUpload from "./FileUpload.web";
import DocumentsCheckController, { Props } from "./DocumentCheckController";

class DocumentCheck extends DocumentsCheckController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        const { attributes } = this.state.allDocuments
        const isDisabled = this.isDisabled()
        return (
            <>
                <div>
                    <section className="bgGrey">
                        <Container style={{ maxWidth: '770px', padding: "0px 15px" }}>
                            <section>
                                <div>
                                    <img
                                        src={closearrow}
                                        style={webStyle.BackArrow}
                                        data-testId="HandlebackId"
                                        onClick={() => this.props.handlePrevStep()}
                                    ></img>
                                    <h1 style={{ textAlign: "center", marginTop: "-30px" }}> Document Check</h1>
                                    <span style={webStyle.DocumentHeadingText}><span style={webStyle.heading}> Thank you for your interest is becoming a driver.</span><br />
                                        We are so nearly at the end! We just need the following documents from you to support your application.Once we have those our internal team will review and reach out to you with a response.</span>
                                </div>
                                <div style={{ marginTop: "25px" }}>
                                    {attributes && attributes.required?.length > 0 && attributes.required.map((label: string, index: number) => {
                                        return (
                                            <>
                                                <FileUpload
                                                infolink={this.state.infoURL} infoTitle={ this.state.infoTitle} linkTitle={this.state.personalInsuranceTitle} link={this.state.personalInsurance} label={label} index={index}
                                                allDocuments={this.state.allDocuments}
                                                getAllDocuments={this.getAllDocuments} />
                                            </>
                                        )

                                    })}
                                </div>
                                <div className="flexEnd pt40 btn-static-div">
                                    <button
                                        className="btn-transparent mr15"
                                        id="saveButton"
                                        data-testId="saveButton"
                                        onClick={() => this.stepStoringApiCall(this.state.saveAndExitStepCount)}
                                    >
                                        Save and Exit
                                    </button>
                                    <button id="Nextbtn" data-testId="Nextbtn" className="doc-check-submit-Btn" disabled={isDisabled} onClick={() => this.stepStoringApiCall(this.state.submitStepCount)}>
                                        Submit
                                    </button>

                                </div>

                            </section>
                        </Container>
                    </section>
                </div >
            </>
        );
        // Customizable Area End
    }
}

export default DocumentCheck;
// Customizable Area End

// Customizable Area Start
const webStyle = {
    BackArrow: {
        width: "20px",
        height: "20px",
        marginTop: "40px"
    },
    DocumentHeadingText: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px"
    },
    heading: {
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "24px"
    },
}
// Customizable Area End
