// Customizable Area Start
import React from 'react';
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import './css/toggleCalendar.css'

const ToggleCalendar = ({ value, activeStartDate, onChangeHandler, monthClickHandler, navigationHandler }: any) => {
    return (
        <div className="toggleCalender">
            <Calendar
                calendarType="US"
                value={value}
                activeStartDate={activeStartDate}
                onChange={onChangeHandler}
                showNavigation={true}
                onClickMonth={monthClickHandler}
                onActiveStartDateChange={navigationHandler}
            />
        </div>
    );
}

export default ToggleCalendar;
// Customizable Area End