//@ts-ignore
import { DirectUpload } from '@rails/activestorage';
const config = require('../../../framework/src/config.js');

export class Uploader {
    options: any;
    status: any;
    file: File;
    directUpload: DirectUpload;

    constructor(file: File, options: any) {
        this.options = options;
        this.status = {
            ERROR: 'error',
            FINISHED: 'finished',
            PROGRESS: 'uploading',
            WAITING: 'waiting'
        };
        this.file = this.changeFilename(file);

        this.directUpload = new DirectUpload(
            this.file,
            `${config.baseURL}/account_block/attachments`,
            this
        );

        this.handleChangeFile({ progress: 0, state: this.status.WAITING });
    }
    // Customizable Area Start
    changeFilename(file: File): File {
        const blob = file.slice(0, file.size, file.type);
        const newFilename = file.name?.replace(/[- )(]/g, '');

        return new File([blob], newFilename, { type: file.type });
    }

    handleChangeFile(upload: any): void {
        const fileInfo = {
            id: this.id,
            name: this.name,
            size: this.size,
            type: this.type
        };

        this.options.onChangeFile({ ...fileInfo, ...upload });
    }

    handleSuccess(blob: any): any {
        const response = blob;

        this.handleChangeFile({
            attachmentId: response.id,
            progress: 100,
            serviceUrl: response.url,
            signedId: response.signed_id,
            state: this.status.FINISHED,
            url: response.service_url
        });

        return response;
    }

    handleError(error: any): void {
        this.handleChangeFile({
            error,
            progress: 0,
            state: this.status.ERROR
        });

        throw error;
    }

    handleProgress({ loaded, total }: any): void {
        const progress = (loaded / total) * 100;

        this.handleChangeFile({
            progress: Math.floor(progress),
            state: this.status.PROGRESS
        });
    }

    get id(): any {
        return this.directUpload.id;
    }

    get name(): string {
        return `${this.file.name}`;
    }

    get size(): number {
        return this.file.size;
    }

    get type(): string {
        return `${this.file.type}`;
    }

    start(): Promise<any> {
        const promise = new Promise((resolve, reject) => {
            this.directUpload.create((error: any, blob: any) => {
                if (error) {
                    reject(error);
                } else {
                    resolve(blob);
                }
            });
        });

        return promise.then(this.handleSuccess.bind(this)).catch(this.handleError.bind(this));
    }

    directUploadWillStoreFileWithXHR(request: any): void {
        request.upload.addEventListener('progress', this.handleProgress.bind(this));
    }
    // Customizable Area End
}
