import React from 'react'
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import "./css/receivingManagerAvailabilityModal.css"
import { leftArrow, rightArrow } from '../assets';
import { Dialog, DialogContent, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button } from '@material-ui/core';
import ReceivingManagerAvailabilityController, { Props } from '../Controller/ReceivingManagerAvailabilityController';
import moment from 'moment';

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const styles = (theme: any) => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    timeColumn: {
        backgroundColor: theme.palette.grey[200]
    },
    cell: {
        padding: theme.spacing(1)
    },
    button: {
        width: "100%"
    }
})

class ReceivingManagerAvailabilityModal extends ReceivingManagerAvailabilityController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        const { classes, isShowOpManagerAvailability, handleClose } = this.props
        const { timeSlots, managerAvailability, weekStartDate, weekEndDate, weekDates } = this.state

        return (
            <div>
                <Dialog className='customized-dialog-week' aria-labelledby="customized-dialog-title" maxWidth={'xl'} open={isShowOpManagerAvailability}>
                    <DialogContent dividers>
                        <div className={classes.root}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <div className='previous-week-box'>
                                        <div className='previous-week'>
                                            <Button data-testid="previousWeekBtn" onClick={() => this.handlePreviousWeek()}> <img
                                                data-testid='left-img'
                                                src={leftArrow}
                                                alt=""
                                                height={48}
                                                width={48}

                                            />
                                            </Button>
                                            {`${weekStartDate} - ${weekEndDate}`}
                                            <Button data-testid="nextWeekBtn" onClick={() => this.handleNextWeek()}>
                                                <img
                                                    data-testid='right-img'
                                                    src={rightArrow}
                                                    alt=""
                                                    height={48}
                                                    width={48}

                                                />
                                            </Button>
                                        </div>
                                        <div className='available-right'>
                                            <div className='available-sub'><span className='greenbg'></span>Available</div>
                                            <div className='available-sub'><span className='graybg'></span>Unavailable</div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    {weekDates.map((date: any, index: any) => (
                                                        <TableCell key={index}>
                                                            <Typography variant="h6">
                                                                <span className='dayOfWeek'>{date.dayOfWeek}</span>
                                                                <br />
                                                                <span className='dateOfWeek'>{`${date.date}`}</span>
                                                            </Typography>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {timeSlots.map((timeSlot: any) => (
                                                    <TableRow key={timeSlot}>
                                                        {weekDates.map((date: any, index: any) => {
                                                            const { attributes } = timeSlot;
                                                            const { from, to } = attributes
                                                            const { data } = managerAvailability

                                                            const getDataSlot = data?.find(
                                                                (dataItem: any) => date.date === moment(dataItem.attributes.start).format("DD/MM/YYYY") && Number(timeSlot.id) === dataItem.attributes.time_slot_id
                                                            )
                                                            let isDisabled = getDataSlot?.attributes?.available === false ? true : false;
                                                            return (
                                                                < TableCell className='green-day' key={index} >
                                                                    <Button
                                                                        className={classes.button}
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        disabled={isDisabled}
                                                                    >
                                                                        {`${from} - ${to}`}
                                                                    </Button>
                                                                </TableCell>
                                                            )

                                                        })}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus color="primary" onClick={() => handleClose()}>
                            close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        )
    }
}
export { ReceivingManagerAvailabilityModal }
export default withStyles(styles, { withTheme: true })(ReceivingManagerAvailabilityModal)
