import React from "react";
// Customizable Area Start
import BackgroundCheckController, { Props } from "./BackgroundCheckController.web";
import { Container, Grid,CircularProgress,Backdrop } from "@material-ui/core";
import "./PersonalDetails.css";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ReactPlayer from "react-player";
import { closearrow } from "./assets";
import QuestionBank from "./QuestionBank.web";


class BackGroundCheckWeb extends BackgroundCheckController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        const { validationCheck,videoUrl,showQuestionBank,isLoading } = this.state
        return (
            // Customizable Area Start
            <>
            <Backdrop
        style={{ color: '#fff', zIndex: 1300}}
        open={isLoading}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
      {this.state.showQuestionBank === false ? (
           <div className="bgTitleheading">
            <div className="bgcheckTitle">
           <div className="mrTitle">
               <img
                   src={closearrow}
                   onClick={() => this.props.handleBack()}
                   className="closeArrowStyle"
               />
           </div>
           <h3 className="bgheading">Driver Application</h3>
           </div>
       </div>
        ) : (
          <div className="bgTitleheading">
            <div className="multiplecheckTitle">
              <div className="mrTitle">
                  <img
                      src={closearrow}
                      onClick={() => this.setState({ showQuestionBank: false })}
                      className="closeArrowStyle"
                  />
              </div>
              <h3 className="bgheading">Multiple Choice</h3>
              </div>
          </div>
        )}
            {!showQuestionBank?
            (<section className="bgGrey">
                <Container>
                    <section className="personal-detail">
                        <section className="citizen-ship">
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item lg={8}>
                                    <h4>Please watch the following video.</h4>
                                    <p>We will ask you questions about the content in the next page so please pay attention.</p>
                                    <div className="videoBg">
                                      <ReactPlayer
                                          onEnded={this.removeValidationCheck}
                                          controls={true}
                                          url={videoUrl?.attributes?.video_url}
                                      />
                                    </div>
                                </Grid>
                            </Grid>
                            <div className="flexEnd pt40 btn-static-div">
                                <button
                                    className="btn-transparent mr15"
                                    onClick={()=> this.props.navigation.navigate("Dashboard")}
                                    data-testid="saveExitSecondForm"
                                >
                                    Save and Exit
                                </button>
                                <button
                                    className={
                                        validationCheck === false
                                            ? "btn-green da"
                                            : "btn-green-full-opacity da"
                                    }
                                    data-testid="nextSecondForm"
                                    onClick={this.handleSubmitPersonalDetailSecondForm}
                                    disabled={!validationCheck}
                                >
                                    Next
                                    <span className="ml56">
                                        <NavigateNextIcon />
                                    </span>
                                </button>
                            </div>
                        </section>
                    </section>
                </Container>
            </section>)
            :<QuestionBank handleNext={this.props.handleChange} handleBack={undefined} handleChange={undefined} navigation={this.props.navigation} id={""} history={undefined}/>}
            </>
            // Customizable Area End
        );
    }
}

// @ts-ignore
export default BackGroundCheckWeb;
// Customizable Area End
