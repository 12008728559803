// Customizable Area Start
import React from 'react';
import "./css/driverApplications.css";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DriverApplicationsController, { Props } from '../Controller/DriverApplicationsController';
import moment from "moment";
import Pagination from '@material-ui/lab/Pagination';
import { Link } from "react-router-dom";
import CustomLoader from '../../../../components/src/CustomLoader.web';
import Box from '@material-ui/core/Box';

class DriverApplications extends DriverApplicationsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        return (
            <div>
                {
                    this.state.AppStatusLoader ? <CustomLoader Open={this.state.AppStatusLoader} /> :
                        <div className='driver-application'>
                            <div className='last-check'>
                                <p className='date text-font'>{moment().format('L')} {moment().format('dddd')}</p>
                                <p className='time text-font'>{moment().format('LT')}</p>
                            </div>
                            <p className='Outstanding title-font'>Outstanding Applications</p>
                            {/* table */}
                            <Box style={{ overflow: "auto" }} className='applications-table'>
                                <Box style={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Driver ID</TableCell>
                                                <TableCell>Driver name</TableCell>
                                                <TableCell>Date</TableCell>
                                                <TableCell>Vehicle type</TableCell>
                                                <TableCell>Application status</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {(this.state.appStatus.length > 0) ?
                                            <TableBody>
                                                {this.state.appStatus.map((row: any) => (
                                                    <TableRow key={row.id}>
                                                        <TableCell>{row?.driver_uniq_id}</TableCell>
                                                        <TableCell>{row.driver_name}</TableCell>
                                                        <TableCell>{moment(row.date).format("DD/MM/YYYY")}</TableCell>
                                                        <TableCell>{row.vehicle_type}</TableCell>
                                                        <TableCell>
                                                            <div className={this.state.hasMap[row.status]}>
                                                                {this.removeUnderscoreAndCapitalize(row.status)}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Link to={`/operation-manager/viewApplications?id=${row.id}`}>
                                                                <button className='view-app-button'>View Application</button>
                                                            </Link>

                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            : <TableBody> <TableRow><TableCell colSpan={6}>no records found</TableCell></TableRow> </TableBody>
                                        }
                                    </Table>
                                </Box>
                            </Box>
                            <div className='pagination'>
                                <Pagination
                                    count={this.state.totalCount}
                                    page={this.state.page}
                                    onChange={this.handlePageChange}
                                    variant="outlined"
                                    color="primary"
                                />
                            </div>
                        </div>
                }
            </div>
        );
    }
}

export default DriverApplications;
// Customizable Area End