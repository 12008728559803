// Customizable Area Start
import React, { useContext } from "react";
export interface TopBarContextType {
  changeSignupState: () => void;
}
export const defaultContext: TopBarContextType = {
  changeSignupState: () => {}
};

export const TopbarContex = React.createContext<TopBarContextType>(
  defaultContext
);

export function useTopbar() {
  return useContext(TopbarContex);
}
// Customizable Area End
