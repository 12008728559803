
// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";



export const configJSON = require("../../src/config");

export interface Props {
    navigation: any;
    id: string;

}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    hasMap: any;
    appStatus: any;
    page: number;
    totalCount: number;
    AppStatusLoader: boolean

}

interface SS {
    id: any;

}

export default class DriverApplicationsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    GetAppStatusCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIRequestMethodMessage),
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage)

        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            appStatus: [],
            hasMap: {
                draft: 'color-blue',
                pending_approval: 'color-orange',
                pending_induction: 'color-orange',
                induction_booked: 'color-orange',
                cancelled_slot: 'color-orange',
                contract_signature_req: 'color-orange',
                contract_signed: 'color-green',
                application_rejected: 'color-red',
                documents_requested: 'color-orange',
                background_checks_required: 'color-orange',
                background_checks_completed: 'color-purple'
            },
            page: 1,
            totalCount: 0,
            AppStatusLoader: false


        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            let errorResponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (this.GetAppStatusCallId === apiRequestCallId) {
                this.setState({ AppStatusLoader: false })
                if (responseJson && responseJson.data) {

                    let calc = Math.ceil(responseJson.total_count / 10)

                    this.setState({
                        appStatus: responseJson.data,
                        totalCount: calc,
                    })

                } else {
                    console.log(errorResponse);
                }
            }
        }


    }

    async componentDidMount() {
        super.componentDidMount();
        this.handleUserAppStatus()
    }
    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.state.page !== prevState.page) {
            // Do something if someState has changed
            this.handleUserAppStatus();
        }
    }
    // here write Funtionality

    removeUnderscoreAndCapitalize = (string: string) => {
        if (string === "draft") {
            return "Application draft"
        } else if (string === "contract_signature_req") {
            return "Contract signature required"
        } else {
            string = string.replace(/_/g, ' ');
            string = string.charAt(0).toUpperCase() + string.slice(1);
            return string;
        }
    }

    handleUserAppStatus = () => {

        this.setState({ AppStatusLoader: true })
        const header = {
            "Content-Type": "application/json",
            token: window.localStorage.getItem("token"),
        };



        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.GetAppStatusCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.GetUserAppEndPoints}?per_page=10&page=${this.state.page}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    handlePageChange = (event: any, value: number) => {
        this.setState({ page: value })
    };

    // Customizable Area End
}
