// Customizable Area Start
import React, { Component } from 'react';

class NewApplications extends Component {
    render() {
        return (
            <div>
                 <h1>I am in New App</h1>
            </div>
        );
    }
}

export default NewApplications;
// Customizable Area End