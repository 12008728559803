Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.appointmentApiContentType = 'application/json'

exports.appointmentAPiEndPoint = 'appointment_management/availabilities'

exports.addAppointmentAPiMethod = 'POST'
exports.deleteAllAppointmentsAPiMethod = 'DELETE'
exports.getAppointmentListAPiMethod = 'GET'

exports.errorTitle = 'Error'
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'
exports.GetAllTimeSlots="account_block/time_slots"
exports.getAppointmentsAPI="bx_block_appointment_management/appointments"
exports.profileDataApiEndPoint = "account_block/accounts/"
exports.PutMethodType="PUT"
// Customizable Area End
