export const SET_USER_DATA = "SET_USER_DATA";

const initialState = {
	user: {},
};

// Customizable Area Start
export const loginReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case SET_USER_DATA: {
			return { ...state, user: action.payload };
		}
		default:
			return state;
	}
};
// Customizable Area End