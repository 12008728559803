// Customizable Area Start
import React from 'react';
import { format } from "date-fns";
import ToggleCalendar from './ToggleCalendar.web';
import MainCalendar from './MainCalendar.web';
import { calenderPathIcon } from '../assets';
import { Button } from "@material-ui/core";
import './css/calenderPage.css'
import CalendarPageController from '../Controller/CalendarPageController';
import moment from 'moment';
//@ts-ignore
import Cookies from 'js-cookie';

class CalenderPage extends CalendarPageController {
    render() {
        let isOutlookAuthenticated = Cookies.get("outlook_access_token")
        const date = new Date()
        const currentDate = moment(date).format('L')
        const currentDay = moment(date).format('dddd')
        return (
            <div>
                <div className="calenderHead">
                    <div className="left">
                        <p className="todayTitle"><strong>{`${currentDate} ${currentDay}`}</strong></p>
                        <p className="todaysubTitle">{moment(new Date()).format('LT')}</p>
                    </div>
                    <div className="right">
                        <Button data-test-id="todayBtn" className="todayBTN" onClick={() => this.jumpToday()}>Today</Button>
                        <Button className="monthlyBTN">Monthly</Button>
                        {
                            isOutlookAuthenticated ?
                                <Button className="sync-outlook" onClick={() => this.logout()}>Logout outlook </Button> :
                                <Button className="sync-outlook" onClick={() => this.login()}>Sync Outlook</Button>
                        }

                    </div>
                </div>
                <div id="navigation">
                    <div className="calenderHead p-0">
                        <div className="left">
                            <div className='mainCalendarHeader' onClick={() => this.setState({ showCalendar: !this.state.showCalendar })}>
                                {moment(this.state.value).format("MMMM")}, {moment(this.state.value).format("yyyy")}
                                <img src={calenderPathIcon} />
                            </div>
                        </div>
                        <div className="right">
                            <p className="holidayTitle">Holiday</p>
                        </div>
                    </div>
                    {
                        this.state.showCalendar && (
                            <ToggleCalendar value={this.state.value} onChangeHandler={this.onChangeHandler} monthClickHandler={this.monthClickHandler} navigationHandler={this.navigationHandler} />
                        )
                    }
                    <MainCalendar value={this.state.value} onChangeHandler={this.onChangeHandler} backwardMonth={this.backwardMonth} forwardMonth={this.forwardMonth} getDate={this.getDate} events={this.state.events} />
                </div>
            </div >
        );
    }
}

export default CalenderPage;
// Customizable Area End