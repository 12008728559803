export const imgLogo = require("../assets/cbdlogo.png");
export const imgLogo1 = require("../assets/cbdLogo1.png");
export const imgFile = require("../assets/fileIcon.png");
export const imgPhone = require("../assets/telephoneIcon.png");
export const imgFacebook = require("../assets/Facebook.png");
export const imgInsta = require("../assets/Insta.png");
export const imgYoutube = require("../assets/Youtube.png");
export const imgLinkedin = require("../assets/Linkedin.png");
export const imgVan = require("../assets/ManImage.png");
export const Men1 = require("../assets/Men1.png");
export const Men2 = require("../assets/Men2.png");
export const Men3 = require("../assets/Men3.png");
export const imgNotepad = require("../assets/Notepad.png");
export const imgLaptop = require("../assets/Laptop.png");
export const arrowRight = require("../assets/arrow_right.png");
export const ellipse = require("../assets/Ellipse.png");
export const reverseEllipse = require("../assets/reverseEllipse.png");
