// Customizable Area Start
import React from "react";
import BackGroundCheckPaymentForm from "./BackGroudCheckPaymentForm.web";
import "./BackGroundSteps3.css"
import { Grid } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import PaymentFormController, { Props } from "./PaymentFormController.web";



class BackGroundStep extends PaymentFormController {
    constructor(props: Props) {
        super(props);
    }

    render() {

        return (
            <>
                {

                    this.state.isVisiblePaymentForm ? <>
                        <BackGroundCheckPaymentForm
                            handleNext={this.props.handleChange}
                            handleBack={this.handleBackFormToBePage}
                            handleChange={this.props.handleChange}
                            navigation={this.props.navigation}
                            id={""}
                            history={undefined}
                            errorMessage={""}
                        />

                    </>
                        : <>
                            <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Grid item lg={8}>
                                    <div className="bgTitleheading">
                                        <div className="mrTitle">
                                            {/* <img
                                                src={closearrow}
                                                onClick={() => this.props.handleBack()}
                                                className="closeArrowStyle"
                                            /> */}
                                        </div>
                                        <h3 className="bgheading">Background Check</h3>
                                    </div>
                                    <div className="bgcontent">
                                        <p>
                                            We need to run a few checks on all our Drivers, one to check your criminal history and one to check your Driving history. These come at a flat cost. Click "next" to complete the payment. After the payment has processed you will be given access to your partner portal (Vetting.com) where you could request your criminal history check and your driving history report.
                                        </p>
                                    </div>

                                    <div className="flexEnd pt40 btn-static-div bgbutton">
                                        <button
                                            data-testid="bc-step-3"
                                            className="btn-transparent mr15"
                                            onClick={this.handleSaveAndExist}
                                        >
                                            Save and Exit
                                        </button>
                                        <button
                                            data-testid="bc-step-3-second"
                                            className="btn-green-full-opacity da"
                                            onClick={() => this.setState({ isVisiblePaymentForm: true })} >
                                            Next
                                            <span
                                                className="ml56">
                                                <NavigateNextIcon />
                                            </span>
                                        </button>
                                    </div>
                                </Grid>
                            </Grid>

                        </>
                }

            </>
        );
    }
}

export default BackGroundStep;
// Customizable Area End