import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Typography,
  Divider,
  Breadcrumbs,
  Link
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import FaqController, { Props } from "./FaqController";
import { imgFile } from "../assets";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Footer from "../../../../web/src/Footer.web";
// Customizable Area End

// Customizable Area Start
export const theme = createTheme({
  typography: {
    h6: {
      color: "rgba(128,195,67,1)"
    }
  },
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  }
});

// Customizable Area End

export default class Faq extends FaqController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Customizable Area Start
      // Required for all blocks

      <ThemeProvider theme={theme}>
        <Container
          style={{
            width: "100%",
            maxWidth: "100%",
            background: "#f9f9f9"
          }}
        >
          <Box className="container">
            <Box sx={{ marginTop: "30px", marginBottom: "60px" }}>
              <Breadcrumbs
                className="breadcrumbsList"
                separator={<NavigateNextIcon className="arrowRight" />}
                area-label="breadcrumb"
              >
                <Link
                  color="inherit"
                  style={{
                    opacity: 0.3,
                    fontFamily: "Lexend",
                    fontSize: "16px",
                    fontWeight: 400,
                    textAlign: "center"
                  }}
                  href="/"
                >
                  Home Page
                </Link>
                <Typography
                  color="textPrimary"
                  style={{
                    fontFamily: "Lexend",
                    fontSize: "16px",
                    fontWeight: 400,
                    textAlign: "center"
                  }}
                >
                  FAQ
                </Typography>
              </Breadcrumbs>
            </Box>
            <Typography
              variant="h4"
              style={{
                fontFamily: "Montserrat",
                fontSize: "56px",
                fontWeight: 600
              }}
            >
              FAQ
            </Typography>
            <Divider
              style={{
                marginTop: "20px"
              }}
            />
            {this.state.faqContent.map((ele: any, index: any) => (
              <Box
                style={{
                  borderBottom: "1px solid #e6e6e6",
                  marginBottom: "25px",
                  paddingBottom: "15px"
                }}
                key={index}
                data-testid="faq-content"
                component="div"
              >
                <Typography
                  variant="h6"
                  data-test-id="faq-content-title"
                  style={{
                    cursor: "pointer",
                    marginBottom: "12px",
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "29px",
                    fontSize: "24px",
                    display: " inline-flex",
                    alignItems: "center"
                  }}
                >
                  <img
                    src={imgFile}
                    height="15px"
                    style={{
                      marginRight: "12px",
                      width: "20.16px",
                      height: "24px"
                    }}
                  />
                  {ele.attributes.faq_title}
                </Typography>

                <Typography
                  className="contentArea modal-scrollbar"
                  paragraph={true}
                  style={{
                    color: "rgba(0,0,0,.6)",
                    width: "100%",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "30px"
                  }}
                  dangerouslySetInnerHTML={{
                    __html: ele.attributes.faq_content
                  }}
                />
              </Box>
            ))}
          </Box>
        </Container>
        <Footer />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
