
// Customizable Area Start
import React from 'react';
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { RouteComponentProps } from 'react-router-dom';
import { PublicClientApplication } from "@azure/msal-browser";
import { Client } from '@microsoft/microsoft-graph-client';
import { addMonths, subMonths } from "date-fns";
import moment from "moment";
//@ts-ignore
import Cookies from 'js-cookie';
export const configJSON = require("../../src/config");
export interface Props extends RouteComponentProps { }
interface NewObject {
    "@odata.etag": string;
    id: string;
    subject: string;
    bodyPreview: string;
    body: {
        contentType: string;
        content: string;
    };
    start: {
        dateTime: string;
        timeZone: string;
    };
    end: {
        dateTime: string;
        timeZone: string;
    };
    location: {
        displayName: string;
        locationType: string;
        uniqueIdType: string;
        // "address": {},
        // "coordinates": {}
    };
    // "attendees": [],
    organizer: {
        emailAddress: {
            name: string;
            address: string;
        };
    };

}
interface S {
    showCalendar: boolean,
    value: Date,
    error: any;
    isAuthenticated: boolean;
    homeAccountId: string;
    events: NewObject[];
    isLoggedIn: boolean;
}

interface SS {
}


export default class CalendarPageController extends BlockComponent<
    Props,
    S, SS
> {
    // Customizable Area Start
    publicClientApplication: any;
    authConfig = {
        clientId: `${process.env.REACT_APP_OUTLOOK_CLIENT_ID_FORNTEND}`,
        redirectUri: `${window.location.origin}/operation-manager/calendar`,
        authority: `${process.env.REACT_APP_OUTLOOK_AUTHORITY_FORNTEND}`,
        scopes: ['user.read', 'calendars.read'],
        postLogoutRedirectUri: `${window.location.origin}/operation-manager/calendar`
    };
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.state = {
            showCalendar: false,
            value: new Date(),
            error: null,
            isAuthenticated: false,
            homeAccountId: "",
            events: [],
            isLoggedIn: false
        }
        this.login = this.login.bind(this)
    }
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.publicClientApplication = new PublicClientApplication({
            auth: {
                clientId: this.authConfig.clientId,
                authority: this.authConfig.authority,
                redirectUri: this.authConfig.redirectUri,
                postLogoutRedirectUri: this.authConfig.postLogoutRedirectUri
            },
            cache: {
                cacheLocation: "sessionStorage",
                storeAuthStateInCookie: false,
            }
        })

        let outlookToken = Cookies.get("outlook_access_token")
        if (outlookToken)
            this.fetchOutLookEvents(outlookToken);
        // Customizable Area End
    }

    async fetchOutLookEvents(accessToken: any) {

        const client = Client.init({
            authProvider: (done) => {
                done(null, accessToken);
            },
        });

        await client.api('/me/events')
            .header('Prefer', 'outlook.timezone="India Standard Time"')
            .select('subject,body,bodyPreview,organizer,attendees,start,end,location')
            .get().then((response) => {
                if (response)
                    this.setState({ events: response.value })
            }).catch((err) => {
                console.log('error in fetching the events from outlook', err)
            })
    }

    async login() {
        try {
            const authResult = await this.publicClientApplication.loginPopup({
                scopes: this.authConfig.scopes,
                prompt: "select_account"
            })
            const accessToken = authResult.accessToken;
            if (accessToken) {
                this.setState({ isAuthenticated: true, homeAccountId: authResult.account.homeAccountId })
                const currentTime = new Date().getTime();
                const expiryTime = new Date(authResult.expiresOn).getTime();
                const duration = expiryTime - currentTime;
                this.setCookiesSession("outlook_access_token", accessToken, duration);
                this.fetchOutLookEvents(accessToken)
            }
        } catch (err) {
            this.setState({ isAuthenticated: false, error: err })
        }
    }

    setCookiesSession = (cookieName: string, accessToken: any, expireTime: number) => {
        const secondsInMinute = expireTime / 60;
        const in120Minutes = secondsInMinute / 1000;
        const inDays = in120Minutes / 1440;
        Cookies.set(cookieName, accessToken, {
            expires: inDays,
        });
        this.setState({ isLoggedIn: true })
        setTimeout(() => {
            this.setState({ isLoggedIn: false })
        }, expireTime)
    }

    async logout() {
        const logoutRequest = {
            account: this.publicClientApplication.getAccountByHomeId(this.state.homeAccountId),
            mainWindowRedirectUri: `${window.location.origin}/operation-manager/calendar`,
        };
        Cookies.remove("outlook_access_token")
        await this.publicClientApplication.logoutPopup(logoutRequest)
    }

    backwardMonth = () => {
        let subMonth = subMonths(this.state.value, 1)
        this.setState({ value: subMonth })
        localStorage.setItem('value', String(subMonth))
    }

    forwardMonth = () => {
        let addMonth = addMonths(this.state.value, 1)
        this.setState({ value: addMonth })
        localStorage.setItem('value', String(addMonth))
    }

    monthClickHandler = (event: Date) => {
        this.setState({ value: event })
        localStorage.setItem('value', String(event))
    }

    onChangeHandler = (event: Date) => {
        const { history } = this.props;
        localStorage.setItem('value', String(event))
        this.setState({ value: event, showCalendar: false });
        history.push({ pathname: "/operation-manager/calendar/available-appointment" })
    }

    navigationHandler = (event: any) => {
        this.setState({ value: (event.action === 'drillUp' || event.action === 'drillDown') ? event.value : event.activeStartDate });
        localStorage.setItem('value', String(event.value))
    }

    jumpToday = () => {
        this.setState({ value: new Date(), showCalendar: false })
        localStorage.setItem('value', String(new Date()))
    }

    getEvents = ({ events, date }: any) => {
        let newArr: string[] = [];
        events && events.length > 0 && events.map((item: any) => {
            const eventDate = moment(item.start.dateTime).format("DD/MM/YYYY")
            const startTime = moment(item.start.dateTime).format("HH:mm")
            const endTime = moment(item.end.dateTime).format("HH:mm")
            const calDate = moment(date).format("DD/MM/YYYY")
            if (eventDate === calDate) {
                newArr.push(`${startTime} - ${endTime}`)
            }
        }
        )
        return newArr;
    }

    getDate = ({ events, date }: any) => {
        const getEventDate = this.getEvents({ events, date })
        return (
            <>
                <p>{moment(date).format("D")}</p>
                {getEventDate && getEventDate.length > 0 && getEventDate.slice(0, 2).map((item: any) => {
                    if (item)
                        return (<p className="eventDate">{item}</p>)
                })}
            </>
        )
    }
    // Customizable Area End
}
