// Customizable Area Start
import React from "react";
import "./BackGroundSteps3.css"
import { closearrow } from "./assets";
import { Grid } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import PaymentFormController, { Props } from "./PaymentFormController.web";
import PaymentThanks from "./PaymentThanks.web";
import { CardCvcElement, CardExpiryElement, CardNumberElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { warning } from "../../ReviewAndApproval/src/assets";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_FORNTEND}`);
class BackGroundCheckPaymentForm extends PaymentFormController {
    constructor(props: Props) {
        super(props);

    }


renderWarning = (error:string) => {
  return(  <>
  {error?<div className="da">
        <img src={warning} alt="" />
        <p className="err ml10">{error}</p>
    </div>:""}
  </>)
};



    render() {
       
        const { isAddressConfirmed, cardNumberError, expirationError, cvcError, stripeInfoError, additional_card_info_Error } = this.state
        return (
            <>{this.state.backgrounPaid || (this.state.isStripeError && this.state.stripeErrorMessage) ? (
                <PaymentThanks
                  navigation={this.props.navigation}
                  id={""}
                  history={undefined}
                  handleChange={this.props.handleChange}
                  handleNext={undefined}
                  errorMessage={this.state.stripeErrorMessage}
                  handleBack={this.props.handleBack}
                />):
                <div>
                    <Elements stripe={stripePromise}>
                        <ElementsConsumer>
                            {({ stripe, elements }) => (
                                <>
                                    <Grid
                                        container
                                        spacing={2}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid item lg={8} className="widthFull">
                                            <div className="bgTitleheading">
                                                <div className="mrTitle">
                                                    <img
                                                        src={closearrow}
                                                        onClick={() => this.props.handleBack()}
                                                        className="closeArrowStyle"
                                                    />
                                                </div>
                                                <h3 className="bgheading">Pay</h3>
                                            </div>
                                            <div className="payement-heading">
                                                <h5>Total Payment :</h5>
                                                <h5 className="font-bold">${this.state.getPrice}</h5>
                                            </div>
                                            <form >
                                                <div className='inputContainer'>
                                                    <div >
                                                        <label className="labelStyle" htmlFor="">
                                                            Name on Card                                                         <span className="colorForError">*</span>
                                                        </label>

                                                    </div>
                                                    <input
                                                        className='inputField'
                                                        type="text"
                                                        id="approvedRateType"
                                                        placeholder="Please Type..."
                                                        name="card_name"
                                                        value={this.state.stripeInfo.card_name}
                                                        onChange={event => this.handlePrimaryStripeInfo(event)}
                                                        data-testid="card_name"
                                                        autoComplete="off"

                                                    />
                                                {this.renderWarning(stripeInfoError.card_name)}

                                                </div>
                                                <div className='inputContainer'>
                                                    <div >
                                                        <label className="labelStyle" htmlFor="">
                                                            Card Number                                                         <span className="colorForError">*</span>
                                                        </label>

                                                    </div>

                                                    <CardNumberElement onChange={(event) => this.handleCardNumberChange(event)} />
                                                    {this.renderWarning(cardNumberError)}

                                                </div>
                                                <div className='inputContainer'>
                                                    <div >
                                                        <label className="labelStyle" htmlFor="">
                                                            Expiry Date                                                         <span className="colorForError">*</span>
                                                        </label>

                                                    </div>

                                                    <CardExpiryElement onChange={(event) => this.handleExpirationChange(event)} />
                                                    {this.renderWarning(expirationError)}
                                                </div>
                                                <div className='inputContainer'>
                                                    <div >
                                                        <label className="labelStyle" htmlFor="">
                                                            CVC/CVV                                                         <span className="colorForError">*</span>
                                                        </label>

                                                    </div>

                                                    <CardCvcElement onChange={(event) => this.handleCvcChange(event)} />
                                                    {this.renderWarning(cvcError)}
                                                </div>
                                                <div className='inputContainer'>
                                                    <div >
                                                        <label className="labelStyle" htmlFor="">
                                                            Issue Date
                                                        </label>

                                                    </div>
                                                    <div className="date">
                                                        <input
                                                            type="date"
                                                            name="issue_date"
                                                            id="issue_date"
                                                            data-testid="issue_date"
                                                            placeholder="Please Type..."
                                                            value={this.state.stripeInfo.issue_date}
                                                            onChange={event => this.handlePrimaryStripeInfo(event)}
                                                        />
                                                    </div>

                                                </div>
                                                <div className='inputContainer'>
                                                    <div>
                                                        <label className="labelStyle" htmlFor="adminManual">
                                                            Is the billing address same as application address?                                                         <span className="colorForError">*</span>
                                                        </label>
                                                        <div className='adminManualInputContainer'>
                                                            <div className='adminManualInputContainerInner addressconf yesPayment'>
                                                                <input
                                                                    id="yes"
                                                                    type="checkbox"
                                                                    name=""
                                                                    autoComplete="off"
                                                                    checked={isAddressConfirmed}
                                                                    data-testid="check_1"
                                                                    onChange={() => this.handleCheckboxChanges(true)}
                                                                />
                                                                <label htmlFor="yes" className='m10'>Yes</label>
                                                            </div>
                                                            <div className='adminManualInputContainerInner addressconf'>
                                                                <input
                                                                    type="checkbox"
                                                                    id="no"
                                                                    name=""
                                                                    checked={isAddressConfirmed === false ? true : false}
                                                                    onChange={() => this.handleCheckboxChanges(false)}
                                                                    data-testid="check_2"
                                                                />

                                                                <label htmlFor="no" className='m10'>No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {!isAddressConfirmed && <>
                                                    <div className='inputContainer'>
                                                        <div >
                                                            <label className="labelStyle" htmlFor="approvedRateType">
                                                                Address                                                             <span className="colorForError">*</span>
                                                            </label>

                                                        </div>
                                                        <input
                                                            className='inputField'
                                                            type="text"
                                                            id="approvedRateType"
                                                            placeholder="Please Type..."
                                                            name="address"
                                                            data-testid="address_field"
                                                            autoComplete="off"
                                                            value={this.state.additional_card_info.address}
                                                            onChange={event => this.handleSecondaryStripeInfo(event)}

                                                        />
                                                        {this.renderWarning(additional_card_info_Error.address)}
                                                    </div>
                                                    <Grid container spacing={2}>
                                                        <Grid item md={6} xs={12}>
                                                            <div>
                                                                <label className="labelHeading" htmlFor="fname">
                                                                    Suburb                                                                 <span className="redSign">*</span>
                                                                </label>

                                                            </div>
                                                            <input
                                                                type="text"
                                                                id="fname"
                                                                placeholder="Please Type..."
                                                                name="suburb"
                                                                data-testid="suburb_field"
                                                                autoComplete="off"
                                                                value={this.state.additional_card_info.suburb}
                                                                onChange={event => this.handleSecondaryStripeInfo(event)}

                                                            />
                                                            {this.renderWarning(additional_card_info_Error.suburb)}

                                                        </Grid>
                                                        <Grid item md={6} xs={12}>
                                                            <div>
                                                                <label className="labelHeading" htmlFor="sname">
                                                                    State                                                                 <span className="redSign">*</span>
                                                                </label>

                                                            </div>

                                                            <input
                                                                type="text"
                                                                id="state"
                                                                placeholder="Please Type..."
                                                                name="state"
                                                                data-testid="stateInput"
                                                                autoComplete="off"
                                                                value={this.state.additional_card_info.state}
                                                                onChange={event => this.handleSecondaryStripeInfo(event)}

                                                            />
                                                            {this.renderWarning(additional_card_info_Error.state )}

                                                        </Grid>
                                                        <Grid item md={6} xs={12}>
                                                            <div>
                                                                <label className="labelHeading" htmlFor="passcode">
                                                                    Postcode                                                                 <span className="redSign">*</span>
                                                                </label>

                                                            </div>
                                                            <input
                                                                type="text"
                                                                id="passcode"
                                                                placeholder="Please Type..."
                                                                name="pass_code"
                                                                data-testid="passcodeInput"
                                                                autoComplete="off"
                                                                value={this.state.additional_card_info.pass_code}
                                                                onChange={event => this.handleSecondaryStripeInfo(event)}

                                                            />

                                                            {this.renderWarning(additional_card_info_Error.pass_code )}
                                                        </Grid>
                                                        <Grid item md={6} xs={12}>
                                                            <div>
                                                                <label className="labelHeading" htmlFor="country">
                                                                    Country                                                                 <span className="redSign">*</span>
                                                                </label>

                                                            </div>


                                                            <input
                                                                type="text"
                                                                id="country"
                                                                placeholder="Please Type..."
                                                                name="country"
                                                                data-testid="countryInput"
                                                                autoComplete="off"
                                                                value={this.state.additional_card_info.country}
                                                                onChange={event => this.handleSecondaryStripeInfo(event)}

                                                            />
                                                            {this.renderWarning(additional_card_info_Error.country )}
                                                        </Grid>
                                                    </Grid>
                                                </>}
                                                <div
                                                    className="flexEnd pt40 btn-static-div"
                                                >

                                                    <button
                                                        data-testid="submit-btn"
                                                        className="btn-green-full-opacity da"
                                                        onClick={(e) => this.handleSubmit(e, stripe, elements)}
                                                    >
                                                        Next
                                                        <span
                                                            className="ml56">
                                                            <NavigateNextIcon />
                                                        </span>
                                                    </button>
                                                </div>
                                            </form>

                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </ElementsConsumer>
                    </Elements>

                </div>
            }</>


        );
    }
}

export default BackGroundCheckPaymentForm;
// Customizable Area End