
// Customizable Area Start
import moment from "moment";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

export const configJSON = require("../../src/config");

export interface Props {
    isShowOpManagerAvailability: boolean
    userDetails: any,
    selectedManager: string,
    handleClose: () => void,
    classes: any
}
interface S {
    timeSlots: any,
    managerAvailability: any,
    weekStartDate: string,
    weekEndDate: string,
    weekDates: any
}
interface SS {
    id: any;
}
export default class ReceivingManagerAvailabilityController extends BlockComponent<Props, S, SS>  {
    // Customizable Area Start
    getAllTimeSlotsStatusId: string = "";
    checkScheduleApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIRequestMethodMessage),
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage)

        ];

        this.state = {
            timeSlots: [],
            managerAvailability: [],
            weekStartDate: '',
            weekEndDate: "",
            weekDates: []
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (this.getAllTimeSlotsStatusId === apiRequestCallId) {
                if (responseJson && responseJson.data) {
                    this.setState({ timeSlots: responseJson.data })
                    this.checkSchedule()
                }
            }
            if (this.checkScheduleApiCallId === apiRequestCallId) {
                if (responseJson && responseJson.data) {
                    this.setState({ managerAvailability: responseJson })
                }
            }
        }
        // Customizable Area End
    }


    commonRunEngineFunction = (urlEndPoint: any, methodType: string, status: any) => {
        const header = {
            "Content-Type": "application/json",
            token: window.localStorage.getItem("token"),
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const statusMapping = {
            getOpManagers: "getOpmanagersApiCallId",
            checkSchedule: "checkScheduleApiCallId",
            getTimeSlots: "getAllTimeSlotsStatusId"
        };
        //@ts-ignore
        const propertyName = statusMapping[status];
        //@ts-ignore
        this[propertyName] = getValidationsMsg.messageId;


        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            urlEndPoint
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methodType
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    weekCalculator = (start: any) => {
        const weekDates = [];
        const currentDate = new Date(start);
        const sundayOffset = (currentDate.getDay() + 7 - 0) % 7;
        currentDate.setDate(currentDate.getDate() - sundayOffset);

        for (let i = 0; i < 7; i++) {
            let date = moment(currentDate).format("DD/MM/YYYY")
            const dayOfWeek = currentDate.toLocaleString("default", {
                weekday: "long",
            });
            weekDates.push({ date, dayOfWeek });
            currentDate.setDate(currentDate.getDate() + 1);
        }
        this.setState({ weekDates: weekDates, weekStartDate: weekDates[0].date, weekEndDate: weekDates[weekDates.length - 1].date })
        return weekDates
    }


    checkSchedule = () => {
        const { start } = this.props.userDetails?.attributes?.appointments?.data?.attributes ?? {};
        const weekDates = this.weekCalculator(start)
        const urlEndPoint = `${configJSON.checkCalendar}?from=${weekDates[0].date}&to=${weekDates[weekDates.length - 1].date}&manager_id=${this.props.selectedManager}`
        this.commonRunEngineFunction(urlEndPoint, configJSON.validationApiMethodType, 'checkSchedule')
    }

    handleNextWeek = () => {
        const nextWeekStartDate = moment(this.state.weekEndDate, "DD/MM/YYYY").toDate()
        nextWeekStartDate.setDate(nextWeekStartDate.getDate() + 1);
        const weekDates = this.weekCalculator(nextWeekStartDate)
        const urlEndPoint = `${configJSON.checkCalendar}?from=${weekDates[0].date}&to=${weekDates[weekDates.length - 1].date}&manager_id=${this.props.selectedManager}`
        this.commonRunEngineFunction(urlEndPoint, configJSON.validationApiMethodType, 'checkSchedule')
    };

    handlePreviousWeek = () => {
        const nextWeekStartDate = moment(this.state.weekEndDate, "DD/MM/YYYY").toDate()
        nextWeekStartDate.setDate(nextWeekStartDate.getDate() - 7);
        const weekDates = this.weekCalculator(nextWeekStartDate)
        const urlEndPoint = `${configJSON.checkCalendar}?from=${weekDates[0].date}&to=${weekDates[weekDates.length - 1].date}&manager_id=${this.props.selectedManager}`
        this.commonRunEngineFunction(urlEndPoint, configJSON.validationApiMethodType, 'checkSchedule')
    };

    getTimeSlots = () => {
        this.commonRunEngineFunction(configJSON.GetAllTimeSlots, configJSON.validationApiMethodType, 'getTimeSlots')
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getTimeSlots()
    }

    // Customizable Area End
}
