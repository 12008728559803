// Customizable Area Start
import React from "react";
import { Button, Container, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { australiaflag, closearrow, warning } from "./assets";
import BankDetails from "./BankDetails.web";
import "./PersonalDetails.css";
import WorkExperienceController, { Props } from "./WorkExprienceController.web";

class WorkExperience extends WorkExperienceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderEmergecySection = () => {
    return (
      <>
        <Grid item xs={12}>
          <p className="fs20 formHeading">Emergency Contact :</p>
          <div>
            <label className="labelHeading" htmlFor="name">
              Name
            </label>
            <sup>*</sup>
          </div>
          <input
            type="text"
            id="name"
            placeholder="Type here..."
            name="name"
            autoComplete="off"
            value={this.state.data.name}
            data-testid="nameInputField"
            onChange={event => this.handleInputChange(event)}
          />
          {this.state.errors.name && (
            <div className="da">
              <img src={warning} alt="" />
              <p className="err ml10">{this.state.errors.name}</p>
            </div>
          )}
        </Grid>
        <Grid item sm={6} xs={12}>
          <div>
            <label className="labelHeading" htmlFor="relation">
              Relationship
            </label>
            <sup>*</sup>
          </div>
          <input
            type="text"
            id="relation"
            placeholder="Type here..."
            name="relationship"
            autoComplete="off"
            value={this.state.data.relationship}
            data-testid="relationshipInputField"
            onChange={event => this.handleInputChange(event)}
          />
          {this.state.errors.relationship && (
            <div className="da">
              <img src={warning} alt="" />
              <p className="err ml10">{this.state.errors.relationship}</p>
            </div>
          )}
        </Grid>
        <Grid item sm={6} xs={12}>
          <div>
            <label className="labelHeading" htmlFor="mobile">
              Aus Mobile number
            </label>
            <sup>*</sup>
          </div>
          <section className="dFlex">
            <div className="aus-mob">
              <img src={australiaflag} alt="" className="australiaflag" />
            </div>
            <input
              type="number"
              id="mobile"
              placeholder="04xx xxx xxx"
              name="phone_number"
              data-testid="mobileInputField"
              autoComplete="off"
              value={this.state.data.phone_number}
              onChange={event => this.handleInputChange(event)}
            />
          </section>
          {this.state.errors.phone_number && (
            <div className="da">
              <img src={warning} alt="" />
              <p className="err ml10">{this.state.errors.phone_number}</p>
            </div>
          )}
        </Grid>

        <Grid item sm={6} xs={12}>
          <div>
            <label className="labelHeading" htmlFor="address">
              Address
            </label>
            <sup>*</sup>
          </div>
          <input
            type="text"
            id="address"
            placeholder="Type here..."
            name="address"
            autoComplete="off"
            value={this.state.data.address}
            data-testid="addressInputField"
            onChange={event => this.handleInputChange(event)}
          />

          {this.state.errors.address && (
            <div className="da">
              <img src={warning} alt="" />
              <p className="err ml10">{this.state.errors.address}</p>
            </div>
          )}
        </Grid>
        <Grid item sm={6} xs={12}>
          <div>
            <label className="labelHeading" htmlFor="postcode">
              Postcode
            </label>
            <sup>*</sup>
          </div>
          <input
            type="number"
            id="postcode"
            placeholder="Type here..."
            data-testid="postcode"
            name="postcode"
            autoComplete="off"
            value={this.state.data.postcode}
            onChange={event => this.handleInputChange(event)}
          />
          {this.state.errors.postcode && (
            <div className="da">
              <img src={warning} alt="" />
              <p className="err ml10">{this.state.errors.postcode}</p>
            </div>
          )}
        </Grid>
      </>
    );
  };

  showingError = (error: any) => {
    return (error && (
        <div className="da">
            <img src={warning} alt="" />
            <p className="err ml10">{error}</p>
        </div>
    ))
}
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.state.validateNextPage === false ? (
        <div style={{ maxWidth: "770px", padding: "0px 30px", margin: "0 auto"}}>
          <div className="bgTitleheading">
            <div className="mrTitle">
              <img
                src={closearrow}
                onClick={() => this.props.handleBack()}
                className="closeArrowStyle"
              />
            </div>
            <h3 className="bgheading">Driver Application</h3>
          </div>
          </div>
        ) : (
          <div style={{ maxWidth: "770px", padding: "0px 30px", margin: "0 auto" }}>
          <div className="bgTitleheading">
            <div className="mrTitle">
              <img
                src={closearrow}
                onClick={() => this.setState({ validateNextPage: false })}
                className="closeArrowStyle"
              />
            </div>
            <h3 className="bgheading">Driver Application</h3>
          </div>
          </div>
        )}

        <section className="bgGrey">
          <Container>
            {this.state.validateNextPage === false ? (
              <div>
                <Grid className="scrollContent">
                  <Grid>
                    <p className="fs20 mt0 formHeading">Work Experience:</p>
                    <Grid container spacing={2}>
                      {this.state.addresses?.map((address: any, index: any) => (
                        <Grid item xs={12} key={index}>

                          <Grid item xs={12}>
                            <label
                              className="labelHeading"
                              htmlFor={`company_name-${index}`}
                            >
                              Company Name
                            </label>
                            <sup>*</sup>
                            <input
                              type="text"
                              id={`company_name-${index}`}
                              value={address.company_name}
                              placeholder="Type here..."
                              data-testid="company_nameInputField"
                              onChange={e =>
                                this.handleWorkExprienceChange(
                                  e,
                                  index,
                                  "company_name"
                                )
                              }
                            />
                            <br />
                            {this.state.formErrors[`${index}-company_name`] && (
                              <div className="da">
                                <img src={warning} alt="" />
                                <p className="err ml10">
                                  {
                                    this.state.formErrors[
                                    `${index}-company_name`
                                    ]
                                  }
                                </p>
                              </div>
                            )}
                          </Grid>

                          <Grid container style={{ margin: "10px 0" }}>
                            <Grid item sm={6} xs={12}>
                              <div>
                                <label
                                  className="labelHeading"
                                  htmlFor={`start_date-${index}`}
                                >
                                  From
                                </label>
                                <sup>*</sup>
                              </div>
                              <div className="date">
                                <input
                                  style={{ width: 250 }}
                                  type="date"
                                  id={`start_date-${index}`}
                                  value={address.start_date}
                                  autoComplete="off"
                                  // onKeyDown={(e) => e.preventDefault()}
                                  // max={new Date().toISOString().split("T")[0]}
                                  data-testid="start_dateInputField"
                                  onChange={e =>
                                    this.handleWorkExprienceChange(
                                      e,
                                      index,
                                      "start_date"
                                    )
                                  }
                                />
                                {this.showingError(this.state.addressesError[index].start_date)}
                              </div>
                              {this.state.formErrors[`${index}-start_date`] && (
                                <div className="da">
                                  <img src={warning} alt="" />
                                  <p className="err ml10">
                                    {
                                      this.state.formErrors[
                                      `${index}-start_date`
                                      ]
                                    }
                                  </p>
                                </div>
                              )}
                            </Grid>

                            <Grid item sm={6} xs={12}>
                              <div>
                                <label className="labelHeading"
                                  htmlFor={`end_date-${index}`}>
                                  To
                                </label>
                                <sup>*</sup>
                              </div>
                              <div className="date">
                                <input
                                  style={{ width: 250 }}
                                  type="date"
                                  autoComplete="off"
                                  id={`end_date-${index}`}
                                  value={address.end_date}
                                  // min={address.start_date}
                                  // max={new Date().toISOString().split("T")[0]}
                                  // onKeyDown={(e) => e.preventDefault()}
                                  data-testid="end_dateInputField"
                                  onChange={e =>
                                    this.handleWorkExprienceChange(
                                      e,
                                      index,
                                      "end_date"
                                    )
                                  }
                                />
                                {this.showingError(this.state.addressesError[index].end_date)}
                              </div>
                              {this.state.formErrors[`${index}-end_date`] && (
                                <div className="da">
                                  <img src={warning} alt="" />
                                  <p className="err ml10">
                                    {this.state.formErrors[`${index}-end_date`]}
                                  </p>
                                </div>
                              )}
                            </Grid>
                          </Grid>

                          <Grid container >
                            <Grid item sm={6} xs={10}>
                              <label
                                className="labelHeading"
                                htmlFor={`reason_for_leaving-${index}`}
                              >
                                Reason for Leaving
                              </label>
                              <sup>*</sup>
                              <input
                                type="text"
                                placeholder="Type here..."
                                id={`reason_for_leaving-${index}`}
                                value={address.reason_for_leaving}
                                data-testid="reason_for_leavingInputField"
                                onChange={e =>
                                  this.handleWorkExprienceChange(
                                    e,
                                    index,
                                    "reason_for_leaving"
                                  )
                                }
                              />

                              {this.state.formErrors[
                                `${index}-reason_for_leaving`
                              ] && (
                                  <div className="da">
                                    <img src={warning} alt="" />
                                    <p className="err ml10">
                                      {
                                        this.state.formErrors[
                                        `${index}-reason_for_leaving`
                                        ]
                                      }
                                    </p>
                                  </div>
                                )}
                            </Grid>

                            <Grid item sm={6} xs={2}>
                              <div className="delete-work-exp">
                                {index > 0 &&
                                  <IconButton aria-label="delete"
                                    onClick={() => this.handleDeleteWorkExp(address.id)}
                                  >
                                    <DeleteOutlineOutlinedIcon fontSize="large" style={{ color: "red" }} />
                                  </IconButton>
                                }

                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}

                      <div className="add_more_button_align">
                        <Button
                          className="add_more_btn"
                          onClick={this.addmorefiled}
                          style={{
                            fontFamily: "Lexend",
                            background: "white",
                            color: "#83C247",
                            textTransform: "none"
                          }}
                          variant="contained"
                          startIcon={<AddIcon />}
                        >
                          Add More
                        </Button>
                      </div>
                      {this.renderEmergecySection()}
                    </Grid>
                  </Grid>
                </Grid>
                <div className="flexEnd pt40 btn-static-div">
                  <button
                    type="button"
                    className="btn-transparent mr15"
                    onClick={() =>
                      this.handleWorkEperienceSaveandExitForm("save")
                    }
                    data-testid="saveExitId"
                  >
                    Save and Exit
                  </button>

                  <button
                    type="button"
                    className={
                      this.state.validationCheck === false
                        ? "btn-green da"
                        : "btn-green-full-opacity da"
                    }
                    disabled={this.state.validationCheck ? false : true}
                    data-testid="nextId"
                    onClick={() =>
                      this.handleWorkEperienceSaveandNextForm("next")
                    }
                  >
                    Next
                    <span className="ml56">
                      <NavigateNextIcon />
                    </span>
                  </button>
                </div>
              </div>
            ) : null}
            {this.state.validateNextPage === true ? (
              <BankDetails
                handleBack={this.props.handleBack}
                handleNext={this.props.handleChange}
                navigation={this.props.navigation}
                id={""}
                history={undefined}
                handleChange={this.props.handleChange}
              />
            ) : null}
          </Container>
        </section>
      </>
      // Customizable Area End
    );
  }
}
export default WorkExperience;
// Customizable Area End
