import React from 'react'
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "./css/askClientRescheduleModal.css"
import { withStyles } from '@material-ui/core/styles';
import AskClientRescheduleModalController, { Props } from '../Controller/AskClientRescheduleModalController';

const styles = (theme: any) => ({
    dialogTitle: {
        "& .MuiTypography-h6": {
            fontSize: "1.5rem"
        }
    },
    dialogContent: {
        "& .MuiDialogContentText-root": {
            fontSize: "1.125rem"
        }
    },
    dialogActionBtns: {
        borderTop: "1px solid #ccc",
        padding: "20px 0",
        marginTop: "24px",
    }
})
class AskClientToReschedule extends AskClientRescheduleModalController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        const { isOpenRescheduleConfimationModal, closeAskToRescheduleConfirmationModal, classes } = this.props

        return (
            <div>
                <Dialog
                    open={isOpenRescheduleConfimationModal}
                    // onClose={() => closeAskToRescheduleConfirmationModal()}
                    aria-labelledby="draggable-dialog-title"
                    PaperProps={{
                        style: {
                            padding: "16px 20px 0",
                            borderRadius: 0,
                            border: "2px solid #494949",
                        },
                    }}
                >
                    <DialogTitle className={classes.dialogTitle} style={{ cursor: "move", marginBottom: 24 }} id="draggable-dialog-title">
                        Ask client to Reschedule?
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <DialogContentText>
                            Are you sure you want to ask client to Reschedule his/her induction?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={classes.dialogActionBtns}>
                        <button data-testid="cancelAskScheduleBtn" className='cancelAskSchedule' autoFocus onClick={() => closeAskToRescheduleConfirmationModal()} color="primary">
                            Cancel
                        </button>
                        <button data-testid="confirmAskScheduleBtn" className='confirmAskSchedule' onClick={() => this.confirmAskForReschedule()}>
                            Confirm
                        </button>
                    </DialogActions>
                </Dialog>
            </div >
        )
    }
}
export { AskClientToReschedule }
export default withStyles(styles, { withTheme: true })(AskClientToReschedule)

