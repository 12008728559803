// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
 
}
interface S {
  token: string;
  errorMsg: string;
  loading: boolean;
  showDropdown: boolean;
  date: string;
  getUrl: string;
  viewContract: string;
  apiLoader: boolean;
  applicationStatus: any,
  appintmentID: string,
  appointmentChange: string,
  apointmentTimeAndDate: any
 
}
interface SS {
  id: any;
}
export default class DashboardController extends BlockComponent<Props, S, SS> {
  
  getApplicationStatusId: string = "";
  profileDataID: string = "";
  cancelAppointmentCallID: string = ""
  urlApiCallId: string = "";
  viewApiCallId: string = "";
 

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
   
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      errorMsg: "",
      token: "",
      loading: false,
      showDropdown: false,
      date: "",
      getUrl: '',
      viewContract: '',
      apiLoader: false,
      applicationStatus: {},
      appintmentID: '',
      appointmentChange: '',
      apointmentTimeAndDate: {
        time: "",
        date: "",
        location: ""
      }
    };
   
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getApplicationStatus()
    this.getProfileData()
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
   
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  handleURLApiCall = () => {
    this.setState({ apiLoader: true })
    const userId=localStorage.getItem("userId")
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")      
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.urlApiCallId = requestMessage.messageId;
    // Set Method Type
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    // Set EndPoints
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.docusignGetUrl}`
    );
    // set Headers
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    // set body
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      null
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  viewdocURLApiCall = () => {
    this.setState({ apiLoader: true })
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.viewApiCallId = requestMessage.messageId;
    // Set Method Type
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    // Set EndPoints
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewDocusign
    );
    // set Headers
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    // set body
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      null
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async receive(from: string, message: Message) {
    const messageHandlers = {
      [getName(MessageEnum.RestAPIResponceMessage)]: () => {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (this.getApplicationStatusId === apiRequestCallId) {
          if (responseJson) {
            this.setState({ applicationStatus: responseJson });
          }
        }
        this.urlApiResponse(apiRequestCallId, responseJson)
        this.viewApiResponse(apiRequestCallId, responseJson)
      }
    };

    const handler = messageHandlers[message.id];
    if (handler) {
      handler();
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJsonApi = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponseApi = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId == this.profileDataID) {
      this.handleProfileDataID(responseJsonApi, errorReponseApi);
    }
    if (apiRequestCallId == this.cancelAppointmentCallID) {
      this.handleCancelBookRes(responseJsonApi, errorReponseApi);
    }


  }

  handleProfileDataID = (responseJson: any, errorReponse: any) => {

    if (responseJson && responseJson?.data) {
      if (responseJson?.data?.attributes?.appointments.data !== null) {
        const obj = {
          time: responseJson?.data?.attributes?.appointments?.data?.attributes?.slot,
          date: responseJson?.data?.attributes?.appointments?.data?.attributes?.start,
          location: responseJson?.data?.attributes?.appointments?.data?.attributes?.location ?? ""
        }
        this.setState({
          appintmentID: responseJson?.data?.attributes?.appointments?.data?.id,
          apointmentTimeAndDate: obj
        })
      }
    } else {
      this.parseApiCatchErrorResponse(errorReponse);
    }

  }

  handleCancelBookRes = (responseJson: any, errorReponse: any) => {

    if (responseJson && responseJson?.data) {
      if (this.state.appointmentChange === "change") {
        this.naviageToAppointmentPage()
      }
      else {
        this.getApplicationStatus()
      }
      this.getApplicationStatus()
    } else {
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  urlApiResponse = (apiRequestCallId: any, responseJson: any) => {
    if (this.urlApiCallId === apiRequestCallId) {
      if (responseJson) {
        this.setState({ loading: false })
        window.open(responseJson.signing_url, "Docusign.com", 'noopener');
      }
    }
  }

  viewApiResponse = (apiRequestCallId: any, responseJson: any) => {
    if (this.viewApiCallId === apiRequestCallId) {
      if (responseJson) {
        this.setState({ loading: false })
        window.open(responseJson.view_url, "Docusign.com", 'noopener');
      }
    }
  }

  showDropdownHandler = () => {
    this.setState({
      showDropdown: !this.state.showDropdown
    });
  };

  resumeClickHandler = () => {
    this.props.navigation.navigate("PersonalDetails");
  };


  goToHomescreen = () => {
    window.location.href = "/driver/dashboard"
  };

  handleLogout = () => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('userId');
    window.localStorage.removeItem('email');
    window.localStorage.removeItem('userType');
    window.localStorage.removeItem('password');
    window.localStorage.removeItem('sKey');
    this.props.navigation.navigate("LandingPage");
  }

  naviageToSettingsPage = () => {
    this.props.navigation.navigate('Settings')
  }
  naviageToAppointmentPage = () => {
    this.props.navigation.navigate('Appointments')
  }
  getProfileData() {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    let userId = localStorage.getItem('userId')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.profileDataID = requestMessage.messageId;
    // Set Method Type
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileDataApiEndPoint + userId
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // cancel appointment api
  handleCancelAppointmentApi = (value: string) => {
    this.setState({ appointmentChange: value })
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cancelAppointmentCallID = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiPUTMethodType
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.cancelAppointmentEndPoint}/${this.state.appintmentID}/cancel`
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getApplicationStatus = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getApplicationStatusId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getApplicationStatusApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

}
// Customizable Area End
