import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const Colors = {
  inputLabel: "rgba(31, 31, 34, 0.6)",
  inputTextColor: "rgb(31, 31, 34)",
  borderGrey: "rgba(28, 28, 30, 0.3)",
  borderYellow: "rgb(205, 149, 12)",
  white: "#FFFFFF",
  modalBg: "rgb(243, 243, 243)",
};
export const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const firstNameRegex = /^[A-Za-z]{2,64}$/i;
export const requiredErr = "This Field is Required";
export const phoneNumRegex = /^\d\d{9}$/;
export const suburbRegex = /^[A-Za-z ]{2,50}$/;
export const postcodeRegex = /^[0-9]{4}$/;
export const abnRegex = /^[0-9]{11}$/;
export const acnRegex = /^[0-9]{9}$/;
export const addressRegex = /^[a-zA-Z0-9\s,'-]*$/;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  handleChange: any;
  handleNext: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  getSellerDetailsMessageId: any;
  postSellerDetailsMessageId: any;
  sellerID: any;
  shopName: string;
  address: string;
  gstin: string;
  selectedServices: number[];
  showSuccessModal: boolean;
  token: string;
  lat: any;
  long: any;
  data: any;
  errors: any;
  [key: string]: any;
  stateList: any;
  personalDetailSeconddata: any;
  personalDetailSecondErrors: any;
  validationCheck: boolean;
  personalDetailFirstBtnResponse: string;
  showPersoanlDetailForm: boolean;
  businessType: string;
  textAreaOpen: any;
  activeStep: any;
  hearAboutUsLst: any;
  OtherId: string;
  maxYear: string;
  minYear: string;
  hearType: string;
  cbdId: string;
  searchRequired: boolean;
  userType: any
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class CustomformController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  personalDetailsId: string = "";
  additionalDetailsId: string = "";
  stateListDataID: string = "";
  hearAboutUsDetailsID: string = "";
  profileDataID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      getSellerDetailsMessageId: "",
      postSellerDetailsMessageId: "",
      sellerID: "",
      shopName: "",
      address: "",
      gstin: "",
      selectedServices: [],
      token: "",
      showSuccessModal: false,
      lat: 0,
      long: 0,
      stateList: [],
      userType: "driver",
      data: {
        first_name: "",
        last_name: "",
        gender: "",
        date_of_birth: "",
        address: "",
        suburb: "",
        state: "",
        postcode: "",
        full_phone_number: "",
        email: "",
      },
      // State for showing the error of personal detail 1st-form
      errors: {
        first_name: "",
        last_name: "",
        gender: "",
        date_of_birth: "",
        address: "",
        suburb: "",
        state: "",
        postcode: "",
        full_phone_number: "",
        email: "",
      },
      // State for showing the error of personal detail 2nd-form
      personalDetailSeconddata: {
        australian_citizen: "",
        business_type: "",
        ABN_number: "",
        ACN_number: "",
        company: "",
        hear: "",
        search_description: "",
      },
      // State for showing the error of personal detail 2nd-form
      personalDetailSecondErrors: {
        australian_citizen: "",
        business_type: "",
        ABN_number: "",
        ACN_number: "",
        company: "",
        hear: "",
        search_description: ""
      },
      // State for handling next button enable disable of personal detail 1st-form
      validationCheck: false,
      personalDetailFirstBtnResponse: "",
      showPersoanlDetailForm: false,
      textAreaOpen: "",
      businessType: "",
      activeStep: 0,
      hearAboutUsLst: [],
      OtherId: "",
      maxYear: "",
      minYear: "",
      hearType: "",
      cbdId: "",
      searchRequired: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.handlePostSellerDetailsMessageId(apiRequestCallId, responseJson)
        this.handleGetSellerDetailsMessageId(apiRequestCallId, responseJson)
        this.handleStateListDataID(apiRequestCallId, responseJson)
        this.handlePersonalDetailsId(apiRequestCallId, responseJson)
        this.handleAdditionalDetailsId(apiRequestCallId, responseJson)
        this.handleHearAboutUsDetailsID(apiRequestCallId, responseJson)
        this.handleProfileDataID(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start


  handlePostSellerDetailsMessageId = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.state.postSellerDetailsMessageId) {
      this.showModal();
    }
  }

  handleGetSellerDetailsMessageId = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.state.getSellerDetailsMessageId) {

      if (responseJson.data && responseJson.data.attributes) {

        const {
          wholesaler = false,
          retailer = false,
          manufacturer = false,
          hallmarking_center = false,
        } = responseJson.data.attributes;

        const isWholesalerSelected = wholesaler;
        const isRetailerSelected = retailer;
        const isManufacturerSelected = manufacturer;
        const isHallmarking_centerSelected = hallmarking_center;

        const services = [
          { name: 'Wholesaler', selected: isWholesalerSelected, value: 1 },
          { name: 'Manufacturer', selected: isManufacturerSelected, value: 2 },
          { name: 'Hallmarking Center', selected: isHallmarking_centerSelected, value: 3 },
          { name: 'Retailer', selected: isRetailerSelected, value: 4 }
        ];

        const selectedServices = services.filter(service => service.selected).map(service => service.value);

        this.setState({
          sellerID: responseJson.data.id,
          shopName: responseJson.data.attributes.firm_name
            ? responseJson.data.attributes.firm_name
            : "",
          address: responseJson.data.attributes.location
            ? responseJson.data.attributes.location
            : "",
          gstin: responseJson.data.attributes.gstin_number
            ? responseJson.data.attributes.gstin_number
            : "",
          selectedServices: selectedServices,
        });

      }
    }

  }

  handleStateListDataID = (apiRequestCallId: any, responseJson: any) => {
    if (this.stateListDataID === apiRequestCallId) {

      this.setState({
        stateList: responseJson.data,
      });

    }
  }


  handlePersonalDetailsId = (apiRequestCallId: any, responseJson: any) => {
    if (this.personalDetailsId === apiRequestCallId) {
      if (responseJson.data && responseJson.meta.message == "personal details updated sucessfully.") {
        if (this.state.personalDetailFirstBtnResponse === "save") {
          let userId = responseJson.data.id
          window.localStorage.setItem("userId", userId);
          this.props.navigation.navigate("Dashboard")
        } else {
          this.setState({
            showPersoanlDetailForm: true,
            validationCheck: false,
          });
        }
      }
    }
  }

  handleAdditionalDetailsId = (apiRequestCallId: any, responseJson: any) => {
    if (this.additionalDetailsId === apiRequestCallId) {
      if (responseJson.data) {
        if (this.state.personalDetailFirstBtnResponse === "save") {
          this.props.navigation.navigate("Dashboard")
        } else {
          this.setState({
            validationCheck: false,
          });
          this.props.handleNext()
        }

      }
    }
  }

  handleHearAboutUsDetailsID = (apiRequestCallId: any, responseJson: any) => {
    if (this.hearAboutUsDetailsID === apiRequestCallId) {

      if (responseJson.length > 0) {
        let id = ""
        let cbdId = ""
        responseJson.map((item: any) => {
          if (item?.title == "Other") {
            id = item?.id
          }
          else if (item?.title == "Referral from CBD Express colleague") {
            cbdId = item?.id
          }


        })
        this.setState({ OtherId: id, cbdId })
      }
      this.setState({ hearAboutUsLst: responseJson });

    }
  }

  handleProfileDataID = (apiRequestCallId: any, responseJson: any) => {
    if (this.profileDataID === apiRequestCallId) {
      if (responseJson.data) {
        this.setState({ userType: responseJson?.data?.attributes?.user_type })
        this.handleStateList(responseJson)
        this.handleHearAboutList(responseJson)


      }
    }
  }


  handleStateList = (responseJson: any) => {
    let {
      first_name,
      last_name,
      gender,
      date_of_birth,
      address,
      suburb,
      state_name,
      postcode,
      full_phone_number,
      email,
    } = responseJson.data.attributes

    this.state.stateList.map((item: any) => {
      if (item.attributes.state_name == state_name) {
        state_name = item.id
      }
    })

    this.setState({
      data: {
        first_name,
        last_name,
        gender,
        date_of_birth,
        address,
        suburb,
        state: state_name,
        postcode: postcode ? postcode.toString() : "",
        full_phone_number,
        email,
      },
    })

  }

  handleHearAboutList = (responseJson: any) => {
    let {
      australian_citizen,
      business_type,
      ABN_number,
      ACN_number,
      company_name,
      how_do_you_hear_about_us,
      search_description,
    } = responseJson.data.attributes


    if (this.state.hearAboutUsLst.length > 0) {
      this.state.hearAboutUsLst.map((item: any) => {
        if (item.title == how_do_you_hear_about_us) {
          how_do_you_hear_about_us = item.id
        }
      })
    }
    this.setState({

      personalDetailSeconddata: {
        australian_citizen: australian_citizen + "",
        business_type,
        ABN_number,
        ACN_number,
        company: company_name,
        hear: how_do_you_hear_about_us,
        search_description,
      },
      businessType: business_type,
      validationCheck: true
    })

  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getStateListData();
    this.getHearAboutUsList();
    let _max = `${new Date().getFullYear() - 18}-01-01`
    let _min = `${new Date().getFullYear() - 90}-01-01`
    this.setState({
      maxYear: _max,
      minYear: _min
    })
    this.getProfileData()



  }

  // *******1st Form Part Start
  // Validate function for handling error of personal detail 1st-form
  validate = (name: any, value: any) => {

    switch (name) {
      case "gender":
        return this.defaultValidations(value)
      case "address":
        return this.validateAddress(value)
      case "suburb":
        return this.validateSuburb(value)
      case "state":
        return this.defaultValidations(value)
      case "date_of_birth":
        return this.validateDate(value)
      case "last_name":
        return this.validateLastName(value)
      case "first_name":
        return this.validateFirstName(value)
      case "postcode":
        return this.validatePostCode(value)
      case "full_phone_number":
        return this.validatePhoneNumber(value)

      case "email":
        return this.validateEmail(value)

      default: {
        return "";
      }
    }
  };
  // end

  defaultValidations = (value: any) => {
    if (!value || value === "") {
      return "This Field is Required";
    } else {
      return "";
    }
  }

  // To take input from the user of personal details forms 1st-Form
  handleInputChange = (e: any) => {
    this.setState({
      errors: {
        ...this.state.errors,
        [e.target.name]: this.validate(e.target.name, e.target.value),
      },
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });

    // Functionality to enable disable next button for the personal details first form
    const { data } = this.state;
    Object.keys(data).forEach((name) => {
      const error = this.validate(name, data[name]);
      if (error) {
        // @ts-ignore
        this.setState({ validationCheck: false });
      } else {
        this.setState({ validationCheck: true });
      }
    });
  };
  // end

  // Form submit function of personal details forms 1st-Form
  handleSubmitPersonalDetailFirstForm = (btnResponse: string) => {
    this.setState({ personalDetailFirstBtnResponse: btnResponse });

    if (btnResponse == "next") {
      const { data } = this.state;
      // e.preventDefault();
      let validationErrors = {};
      Object.keys(data).forEach((name) => {
        const error = this.validate(name, data[name]);
        if (error && error.length > 0) {
          // @ts-ignore
          validationErrors[name] = error;
        }
      });
      if (Object.keys(validationErrors).length > 0) {
        this.setState({ errors: validationErrors });
        return;
      }
    }
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestBody = {
      data: {
        first_name: this.state.data.first_name,
        last_name: this.state.data.last_name,
        gender: this.state.data.gender,
        date_of_birth: this.state.data.date_of_birth,
        address: this.state.data.address,
        suburb: this.state.data.suburb,
        state_id: this.state.data.state,
        postcode: this.state.data.postcode,
        full_phone_number: this.state.data.full_phone_number,
        email: this.state.data.email,
      },
    };



    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.personalDetailsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PersonalDetailsApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PutMethodType
    );

    // pass request parametres
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };
  // *******1st Form Part End

  // Validate function for handling error of personal detail 2nd-form
  isRequired = (value: any): string => {
    return (!value || value === "") ? requiredErr : "";
  }

  validateABN = (value: any): string => {
    let validateABNResult: string;
    if (!value || value === "") {
      validateABNResult = requiredErr;
    } else if (!value.match(abnRegex)) {
      validateABNResult = "Enter a valid ABN number of 11 digits.";
    } else {
      validateABNResult = "";
    }
    return validateABNResult;
  };


  validateACN = (value: any): string => {
    if (this.state.businessType !== "company") {
      return "";
    }
    if (!value || value === "") {
      return requiredErr;
    }
    if (!value.match(acnRegex)) {
      return "Enter a valid ACN number of 9 digits.";
    }
    return "";
  };

  validateCompany = (value: any): string => {
    if (this.state.businessType !== "company") {
      return "";
    }
    return this.isRequired(value);
  };

  validateSecondStep = (name: any, value: any) => {
    switch (name) {
      case "australian_citizen":
      case "hear":
        return this.isRequired(value);
      case "business_type":
        return this.isRequired(value);
      case "search_description":
        return (this.state.personalDetailSeconddata.hear == this.state.OtherId || this.state.personalDetailSeconddata.hear == this.state.cbdId) ? this.isRequired(value) : "";
      case "ABN_number":
        return this.validateABN(value);
      case "ACN_number":
        return this.validateACN(value);
      case "company":
        return this.validateCompany(value);
      default:
        return "";
    }
  };



  // To take input from the user of personal details forms 2nd-Form
  handleInputChangeSecondForm = (e: any) => {
    if (e.target.value === "sole_trader") {
      this.setState({
        businessType: "sole_trader", personalDetailSecondErrors: {
          ...this.state.personalDetailSecondErrors,
          ACN_number: ''
        }
      });
    }
    if (e.target.value === "company") {
      this.setState({ businessType: "company" });
    }
    if (e.target.name == "hear") {
      this.setState({
        personalDetailSecondErrors: {
          ...this.state.personalDetailSecondErrors,
          search_description: ''
        },
        personalDetailSeconddata: {
          ...this.state.personalDetailSeconddata,
          hear: e.target.value
        },
      })
    }

    this.setState({
      personalDetailSecondErrors: {
        ...this.state.personalDetailSecondErrors,
        [e.target.name]: this.validateSecondStep(e.target.name, e.target.value),
      },
      personalDetailSeconddata: {
        ...this.state.personalDetailSeconddata,
        [e.target.name]: e.target.value,
      },
    }, () => {
      // Functionality to enable disable next button for the personal details first form
      const { personalDetailSeconddata } = this.state;
      Object.keys(personalDetailSeconddata).forEach((name) => {
        const error = this.validateSecondStep(name, personalDetailSeconddata[name]);
        if (error) {
          // @ts-ignore
          this.setState({ validationCheck: false });
        } else {
          this.setState({ validationCheck: true });
        }
      });
    });




  };

  // Form submit function of personal details forms 2nd-Form
  handleSubmitPersonalDetailSecondForm = (btnResponse: string) => {
    this.setState({ personalDetailFirstBtnResponse: btnResponse });

    if (btnResponse == "next") {
      const { personalDetailSeconddata } = this.state;
      let validationErrorsSecond = {};
      Object.keys(personalDetailSeconddata).forEach((name) => {
        const error = this.validateSecondStep(
          name,
          personalDetailSeconddata[name]
        );
        if (error && error.length > 0) {
          // @ts-ignore
          validationErrorsSecond[name] = error;
        }
      });
      if (Object.keys(validationErrorsSecond).length > 0) {
        this.setState({ personalDetailSecondErrors: validationErrorsSecond });
        return;
      }
    }

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestBody = {
      data: {
        australian_citizen: this.state.personalDetailSeconddata
          .australian_citizen,
        business_type: this.state.personalDetailSeconddata.business_type,
        ABN_number: this.state.personalDetailSeconddata.ABN_number,
        ACN_number: this.state.personalDetailSeconddata.ACN_number,
        company: this.state.personalDetailSeconddata.company,
        search_from_id: this.state.personalDetailSeconddata.hear,
        search_description: this.state.personalDetailSeconddata
          .search_description,
      },
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.additionalDetailsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AdditionalDetailsApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PutMethodType
    );

    // pass request parametres
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  labelShopName: string = "Name of Shop";
  labelAddress: string = "Address";
  labelGstin: string = "GSTIN";
  labelAbout: string = "About Us";
  labelServiceProvided: string = "Services Provided";
  labelDealsIn: string = "Deals in";
  labelShopPhotos: string = "Shop Photos(Min 1 - Max 8)";
  labelVisitingCard: string = "Upload Visiting Card";
  btnLabel: string = "Submit";
  btnContinueLabel: string = "Continue";

  services = [
    { label: "Wholesaler", value: 1 },
    { label: "Manufacturer", value: 2 },
    { label: "Hallmarker", value: 3 },
    { label: "Retailer", value: 4 },
  ];

  getSellerDetail = async (token: string) => {
    const header = {
      "Content-Type": configJSON.sellerDetailsApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      getSellerDetailsMessageId: requestMessage.messageId,
    });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sellersAPIEndPoint + "/1"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSellersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  submitSellerDetails = async () => {
    let token = this.state.token;

    if (!token || token.length === 0) {
      return;
    }
    if (this.state.shopName.trim() === "") {
      return;
    }

    const header = {
      "Content-Type": configJSON.sellerDetailsApiContentType,
    };

    let isWholesalerSelected = false;
    let isRetailerSelected = false;
    let isManufacturerSelected = false;
    let isHallmarking_centerSelected = false;

    this.state.selectedServices.forEach((value) => {
      switch (value) {
        case 1:
          isWholesalerSelected = true;
          break;
        case 2:
          isManufacturerSelected = true;
          break;
        case 3:
          isHallmarking_centerSelected = true;
          break;
        case 4:
          isRetailerSelected = true;
          break;
      }
    });

    const httpBody = {
      token: token,
      seller_account: {
        firm_name: this.state.shopName,
        location: this.state.address,
        gstin_number: this.state.gstin,
        wholesaler: isWholesalerSelected,
        retailer: isRetailerSelected,
        manufacturer: isManufacturerSelected,
        hallmarking_center: isHallmarking_centerSelected,
        lat: this.state.lat,
        long: this.state.long,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      postSellerDetailsMessageId: requestMessage.messageId,
    });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.sellerID.length > 1
        ? configJSON.sellersAPIEndPoint + "/" + this.state.sellerID
        : configJSON.sellersAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.sellerID.length > 1
        ? configJSON.sellerDetailsAPIMethodPUT
        : configJSON.sellerDetailsAPIMethodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onChangeInput = (name: string, value: any) => {
    // @ts-ignore
    this.setState({ [name]: value });
  };

  txtInputProps = (key: string) => ({
    // @ts-ignore
    value: this.state[key],
    onChangeText: (text: string) => {
      this.onChangeInput(key, text);
    },
  });

  inputLabelProps = {
    fontSize: 14,
    color: Colors.inputLabel,
    lineHeight: 16,
  };

  onServiceSelected = (serviceId: number) => {
    if (!this.state.selectedServices.includes(serviceId)) {
      this.setState({
        selectedServices: this.state.selectedServices.concat(serviceId),
      });
    }
  };

  onServiceUnSelected = (serviceId: number) => {
    this.setState({
      selectedServices: this.state.selectedServices.filter(
        (selectedService) => selectedService !== serviceId
      ),
    });
  };

  showModal = () => {
    this.setState({ showSuccessModal: true });
  };

  hideModal = () => {
    this.setState({ showSuccessModal: false }, () => { });
  };

  // State List Function
  getStateListData() {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.stateListDataID = requestMessage.messageId;
    // Set Method Type
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetMethodType
    );
    // Set EndPoints
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.StateListDataApiEndPoint
    );
    // set Headers
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Hear about us list
  getHearAboutUsList() {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.hearAboutUsDetailsID = requestMessage.messageId;
    // Set Method Type
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetMethodType
    );
    // Set EndPoints
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.HearAboutUsApiEndPoint
    );
    // set Headers
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  //validationFunction changes for optimization
  validateDate = (value: any) => {
    let cyear = new Date().getFullYear();
    let year = new Date(value).getFullYear();

    if (!value || value.trim() === "") {
      return requiredErr;
    } else if ((cyear - year) < 18) {
      return "Enter a valid date.";
    } else {
      return "";
    }
  }
  validateLastName = (value: any) => {
    if (!value || value.trim() === "") {
      return requiredErr;
    } else if (!value.match(firstNameRegex)) {
      return "Enter a valid surname.";
    } else {
      return "";
    }
  }
  validatePostCode = (value: any) => {
    if (!value || value === "") {
      return requiredErr;
    } else if (!value.match(postcodeRegex)) {
      return "Enter a valid postcode of 4 digits.";
    } else {
      return "";
    }
  }
  validatePhoneNumber = (value: any) => {
    if (!value || value.trim() === "") {
      return requiredErr;
    } else if (!value.match(phoneNumRegex)) {
      return "Enter a valid mobile number.";
    } else {
      return "";
    }
  }
  validateEmail = (value: any) => {
    if (!value) {
      return "Email is Required";
    } else if (
      !value.match(emailRegex)
    ) {
      return "Enter a valid email address";
    } else {
      return "";
    }
  }
  validateFirstName = (value: any) => {
    if (!value || value.trim() === "") {
      return requiredErr;
    } else if (!value.match(firstNameRegex)) {
      return "Enter a valid first name.";
    } else {
      return "";
    }
  }
  validateSuburb = (value: any) => {
    if (!value || value.trim() === "") {
      return requiredErr;
    } else if (!value.match(suburbRegex)) {
      return "Enter a valid suburb.";
    } else {
      return "";
    }
  }
  validateAddress = (value: any) => {
    if (!value || value === "") {
      return requiredErr;
    } else if (!addressRegex.test(value)) {
      return "Enter a valid address.";
    } else {
      return "";
    }
  }
  validateOther = (value: any) => {
    if (this.state.personalDetailSeconddata.hear == this.state.OtherId || this.state.personalDetailSeconddata.hear == this.state.cbdId) {
      if (!value || value === "") {
        return requiredErr;
      } else {
        return "";
      }
    }
  }
  getProfileData() {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    let userId = localStorage.getItem('userId')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.profileDataID = requestMessage.messageId;
    // Set Method Type
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetMethodType
    );
    // Set EndPoints
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileDataApiEndPoint + userId
    );
    // set Headers
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }





  // Customizable Area End
}
