// Customizable Area Start
import React from "react";
import { TopbarContex } from "./TopbarContext";
import TopBarContextController, { Props } from "./TopbarContextController";

class TopbarContexProvider extends TopBarContextController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <TopbarContex.Provider value={{ ...this.state, ...this }}>
        {this.props.children}
      </TopbarContex.Provider>
    );
  }
}

export default TopbarContexProvider;
// Customizable Area End
