import React from "react";
// Customizable Area Start
import CustomformController, { Props } from "./CustomformController";
import { Container, Grid } from "@material-ui/core";
import "./PersonalDetails.css";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { warning } from "./assets";

const checkBoxBorder = (condition: any, margin: any) => {
  return condition
    ? "div-checkbox mr15 green-border"
    : `div-checkbox ${margin}`;
};

const RadioInput = ({ id, name, value, checked, onChange, label }: any) => {
  return (
    <div className={checkBoxBorder(checked, "mr15")}>
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        data-testid={`${value}${name}`}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};
// Customizable Area End
export default class PersonalDetailsSecondForm extends CustomformController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderedPersonalDetailSecondErrors = (personalDetailSecondErrorData: any) => {
    return (
      personalDetailSecondErrorData && (
        <div className="da">
          <img src={warning} alt="" />
          <p className="err ml10">{personalDetailSecondErrorData}</p>
        </div>
      )
    );
  };

  renderDetails = () => {
    return (
      <>
        <p className="labelHeading">
          Are you an Australian citizen ?
          <sup>*</sup>
        </p>
        <div className="df">
          <RadioInput
            id="yes"
            name="australian_citizen"
            value="true"
            checked={
              this.state.personalDetailSeconddata.australian_citizen === "true"
            }
            onChange={(event: any) => this.handleInputChangeSecondForm(event)}
            label="Yes"
            data-testid="yesAustralian"
          />
          <RadioInput
            id="no"
            name="australian_citizen"
            value="false"
            checked={
              this.state.personalDetailSeconddata.australian_citizen === "false"
            }
            onChange={(event: any) => this.handleInputChangeSecondForm(event)}
            label="No"
            data-testid="noAustralian"
          />
        </div>
        {this.renderedPersonalDetailSecondErrors(
          this.state.personalDetailSecondErrors.australian_citizen
        )}
        <p className="labelHeading">
          Business Type<sup>*</sup>
        </p>
        <div className="df mb10">
          <RadioInput
            id="sole"
            name="business_type"
            value="sole_trader"
            checked={this.state.businessType === "sole_trader"}
            onChange={(event: any) => this.handleInputChangeSecondForm(event)}
            label="Sole Trader"
            data-testid="solebusinessType"
          />
          <RadioInput
            id="company"
            name="business_type"
            value="company"
            checked={this.state.businessType === "company"}
            onChange={(event: any) => this.handleInputChangeSecondForm(event)}
            label="Company"
            data-testid="companybusinessType"
          />
        </div>
        {this.renderedPersonalDetailSecondErrors(
          this.state.personalDetailSecondErrors.business_type
        )}
      </>
    );
  };
  showABNField = () => {
    return (
      <>
        <div>
          <label className="labelHeading" htmlFor="abn">
            ABN Number
          </label>
          <sup>*</sup>
        </div>
        <input
          type="text"
          name="ABN_number"
          id="abn"
          placeholder="Type..."
          autoComplete="off"
          data-testid="abn"
          value={this.state.personalDetailSeconddata.ABN_number}
          onChange={event => this.handleInputChangeSecondForm(event)}
        />
        {this.renderedPersonalDetailSecondErrors(
          this.state.personalDetailSecondErrors.ABN_number
        )}
        <div className="daf">
          <p className="colorGrey follow-link">
            Follow this link to get your ABN number :
          </p>
          <a href="https://abr.business.gov.au/" target="_blank">
            https://abr.business.gov.au/
          </a>
        </div>
      </>
    );
  };
  showCompanyField = () => {
    return (
      <div className="mb10">
        <div className="mt10">
          <label htmlFor="companyname" className="colorGrey labelHeading">
            Company Name
          </label>
          <sup>*</sup>
        </div>
        <input
          type="text"
          id="companyname"
          name="company"
          placeholder="Type..."
          autoComplete="off"
          data-testid="companyname"
          value={this.state.personalDetailSeconddata.company}
          onChange={event => this.handleInputChangeSecondForm(event)}
        />
        {this.state.personalDetailSecondErrors.company && (
          <div className="da">
            <img src={warning} alt="" />
            <p className="err ml10">
              {this.state.personalDetailSecondErrors.company}
            </p>
          </div>
        )}

        <div>
          <label className="labelHeading" htmlFor="acn">
            ACN Number
          </label>
          <sup>*</sup>
        </div>
        <input
          className="acn"
          type="text"
          name="ACN_number"
          id="acn"
          data-testid="acn"
          placeholder="Type..."
          autoComplete="off"
          value={this.state.personalDetailSeconddata.ACN_number}
          onChange={event => this.handleInputChangeSecondForm(event)}
        />
        {this.renderedPersonalDetailSecondErrors(
          this.state.personalDetailSecondErrors.ACN_number
        )}
      </div>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start

    const {
      personalDetailSeconddata,
      personalDetailSecondErrors,
      validationCheck,
      businessType,
      hearAboutUsLst,
      OtherId
    } = this.state;
    const renderDetails = () => {
      return (
        <>
          <p className="labelHeading">
            Are you an Australian citizen ?
            <sup>*</sup>
          </p>
          <div className="df">
            <div
              className={checkBoxBorder(
                personalDetailSeconddata.australian_citizen == "true",
                "mr15"
              )}
            >
              <input
                type="radio"
                checked={personalDetailSeconddata.australian_citizen == "true"}
                id="yes"
                name="australian_citizen"
                value="true"
                data-testid="yesAustralian"
                onChange={event => this.handleInputChangeSecondForm(event)}
              />
              <label htmlFor="yes">Yes</label>
            </div>
            <div
              className={checkBoxBorder(
                personalDetailSeconddata.australian_citizen == "false",
                null
              )}
            >
              <input
                checked={personalDetailSeconddata.australian_citizen == "false"}
                type="radio"
                id="no"
                name="australian_citizen"
                data-testid="noAustralian"
                value="false"
                onChange={event => this.handleInputChangeSecondForm(event)}
              />
              <label htmlFor="no">No</label>
            </div>
          </div>
          {this.renderedPersonalDetailSecondErrors(
            personalDetailSecondErrors.australian_citizen
          )}
          <p className="labelHeading">
            Business Type<sup>*</sup>
          </p>
          <div className="df mb10">
            <div
              className={checkBoxBorder(businessType == "sole_trader", "mr15")}
            >
              <input
                checked={businessType == "sole_trader"}
                type="radio"
                id="sole"
                name="business_type"
                value="sole_trader"
                data-testid="solebusinessType"
                onChange={event => this.handleInputChangeSecondForm(event)}
              />
              <label htmlFor="sole">Sole Trader</label>
            </div>
            <div className={checkBoxBorder(businessType == "company", "mr15")}>
              <input
                checked={businessType == "company"}
                type="radio"
                id="company"
                name="business_type"
                value="company"
                data-testid="companybusinessType"
                onChange={event => this.handleInputChangeSecondForm(event)}
              />
              <label htmlFor="company">Company</label>
            </div>
          </div>
          {this.renderedPersonalDetailSecondErrors(
            personalDetailSecondErrors.business_type
          )}
        </>
      );
    };

    // Customizable Area End
    return (
      // Customizable Area Start
      <section className="bgGrey">
        <Container>
          <section className="personal-detail">
            <section className="citizen-ship">
              <Grid className="scrollContent">
                <Grid item lg={8}>
                  <p className="formHeading mt0 mb10">Personal Details :</p>
                  {renderDetails()}
                  <section>
                    {businessType === "company" && this.showCompanyField()}
                    {(businessType === "sole_trader" ||
                      businessType === "company") &&
                      this.showABNField()}
                  </section>
                  <div>
                    <label htmlFor="hear" className="colorGrey labelHeading">
                      How did you hear about us?
                    </label>
                    <sup>*</sup>
                  </div>
                  <div className="personal-detail mb20">
                    <select
                      data-testid="hearComp"
                      name="hear"
                      id="hear"
                      value={personalDetailSeconddata.hear}
                      onChange={event =>
                        this.handleInputChangeSecondForm(event)
                      }
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      {hearAboutUsLst.length > 0
                        ? hearAboutUsLst.map((item: any) => (
                            <option key={item.id} value={item.id}>
                              {item.title}
                            </option>
                          ))
                        : ""}
                    </select>
                    {this.renderedPersonalDetailSecondErrors(
                      personalDetailSecondErrors.hear
                    )}
                  </div>
                  {personalDetailSeconddata.hear == OtherId && (
                    <div>
                      <textarea
                        rows={3}
                        className="w-100"
                        placeholder="Type here..."
                        name="search_description"
                        value={personalDetailSeconddata.search_description}
                        onChange={event =>
                          this.handleInputChangeSecondForm(event)
                        }
                      />
                      {this.renderedPersonalDetailSecondErrors(
                        personalDetailSecondErrors.search_description
                      )}
                    </div>
                  )}
                  {personalDetailSeconddata.hear == this.state.cbdId && (
                    <div className="mb15 mt10">
                      <div>
                        <label
                          htmlFor="colleaguename"
                          className="colorGrey labelHeading"
                        >
                          Enter Colleague name
                        </label>
                        <sup>*</sup>
                      </div>
                      <input
                        type="text"
                        placeholder="Type here..."
                        autoComplete="off"
                        name="search_description"
                        value={personalDetailSeconddata.search_description}
                        onChange={event =>
                          this.handleInputChangeSecondForm(event)
                        }
                      />
                      {this.renderedPersonalDetailSecondErrors(
                        personalDetailSecondErrors.search_description
                      )}
                    </div>
                  )}
                </Grid>
              </Grid>
              <div className="flexEnd pt40 btn-static-div">
                <button
                  className="btn-transparent mr15"
                  onClick={() =>
                    this.handleSubmitPersonalDetailSecondForm("save")
                  }
                  data-testid="saveExitSecondForm"
                >
                  Save and Exit
                </button>
                <button
                  className={
                    validationCheck === false
                      ? "btn-green da"
                      : "btn-green-full-opacity da"
                  }
                  disabled={this.state.validationCheck ? false : true}
                  data-testid="nextSecondForm"
                  onClick={() =>
                    this.handleSubmitPersonalDetailSecondForm("next")
                  }
                >
                  Next
                  <span className="ml56">
                    <NavigateNextIcon />
                  </span>
                </button>
              </div>
            </section>
          </section>
        </Container>
      </section>
      // Customizable Area End
    );
  }
}
