// Customizable Area Start
import React from 'react';
import Popover from '@material-ui/core/Popover';
import moment from 'moment';

interface Iprops {
    data: any;
    slotBookingApi: any;
    isNotPermitBook: boolean
}
const CustomPopover = ({ data, slotBookingApi, isNotPermitBook }: Iprops) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: any) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleManageBookSlotApi = () => {

        const date = moment(data.start).format('DD-MM-YYYY');
        console.log("formated date", date)
        const value = {
            id: data.slotId,
            dateValue: date
        }
        slotBookingApi(value)
    }
    return (
        <div
            className='green-slot-btn'>
            <p aria-describedby={id}
                onClick={handleClick}
            >
                {moment(data.start, 'h:mm A').format('h:mm')} - {moment(data.end, 'h:mm A').format('h:mm')}
            </p>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}

                PaperProps={{
                    style: {
                        backgroundColor: '#fff',
                        borderRadius: '4px',
                        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
                        zIndex: 1,
                    },
                }}
            >

                {isNotPermitBook ?
                    <div className='full-container'>
                        <p>Sorry,You already booked your slots</p>
                    </div>
                    : <div className='full-container'>
                        <div className="popperContainer">
                            <div className="green-circle"></div>
                            <div>
                                <span className="popper-slotLabel">{moment(data.start).format('LT')} - {moment(data.end).format('LT')}</span>
                            </div>
                        </div>
                        <button
                            onClick={() => handleManageBookSlotApi()}
                            className="book-slot-blackBtn"
                        >
                            Book Slot
                        </button>
                    </div>}

                <div
                    style={{
                        position: 'absolute',
                        top: '-20px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '20px',
                        height: '20px',
                        zIndex: 2,
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            content: '',
                            top: '0',
                            left: '50%',
                            transform: 'translateX(-50%) translateY(-50%) rotate(45deg)',
                            width: '14px',
                            height: '14px',
                            backgroundColor: '#fff',
                            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
                        }}
                    ></div>
                </div>
            </Popover>
        </div>
    );
};

export default CustomPopover;
  // Customizable Area End
