import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
	getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
	navigation: any;
	id: string;
	// Customizable Area Start
	user: any;
	// Customizable Area End
}

interface S {
	// Customizable Area Start
	isEdit: boolean;
	showSnackBar: boolean,
	pwNotFound: boolean;
	new_password: string;
	confirm_password: string;
	current_password: string;
	showPassword: number[];
	initialValues: any;
	// Customizable Area End
}

interface SS {
	id: any;
	// Customizable Area Start
	// Customizable Area End
}

export default class SettingsController extends BlockComponent<
	Props,
	S,
	SS
> {
	// Customizable Area Start
	changePasswordCallId: any;
	// Customizable Area End

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		// Customizable Area Start
		this.subScribedMessages = [
			// Customizable Area Start
			getName(MessageEnum.RestAPIRequestMethodMessage),
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			getName(MessageEnum.RestAPIRequestMessage),
			getName(MessageEnum.RestAPIResponceMessage)
			// Customizable Area End
		];
		// Customizable Area End

		this.state = {
			// Customizable Area Start
			isEdit: false,
			pwNotFound: false,
			new_password: "",
			confirm_password: "",
			current_password: "",
			showPassword: [],
			initialValues: {
				email: "",
				current_password: "",
				new_password: "",
				confirm_password: "",
			},
			showSnackBar: false,

			// Customizable Area End
		};
		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

		// Customizable Area Start
		// Customizable Area End
	}

	async componentDidMount(): Promise<void> {
		this.getUserData();
	}
	handleCloseSnackBar = () => {
		this.setState({ showSnackBar: false });
	}

	handleSettingBackBtn = () => {
		if (this.state.isEdit) {
			this.setState({ isEdit: false })
		} else if (this.state.pwNotFound) {
			this.setState({ isEdit: true, pwNotFound: false })
		}
		else {
			window.history.back()
		}
	}
	getUserData = async () => {
		let getCurrentUserEmail = localStorage.getItem('email')
		const values = {
			...this.state.initialValues,
			email: getCurrentUserEmail,
		}
		this.setState({ initialValues: values })
	}

	async receive(from: string, message: Message) {
		// Customizable Area Start
		runEngine.debugLog("Message Recived", message);

		if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
			const apiRequestCallId = message.getData(
				getName(MessageEnum.RestAPIResponceDataMessage)
			);

			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);

			let errorResponse = message.getData(
				getName(MessageEnum.RestAPIResponceErrorMessage)
			);

			if (this.changePasswordCallId === apiRequestCallId) {
				if (responseJson && responseJson.errors) {
					this.setState({ pwNotFound: true, isEdit: false })
				} else if (responseJson && responseJson.meta) {
					this.setState({ isEdit: !this.state.isEdit, new_password: "", confirm_password: "", current_password: "", showSnackBar: true });
				}
			}
		}
		// Customizable Area End
	}

	// Customizable Area Start
	handleShowPassword(value: any) {
		this.setState({ showPassword: this.state.showPassword.concat(value) });
	}

	handleHidePassword() {
		this.setState({ showPassword: [] });
	}

	changePassword() {
		const header = {
			"Content-Type": configJSON.validationApiContentType,
			token: localStorage.getItem("token")
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.changePasswordCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.changePasswordApiEndPoint
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		const data = {
			current_password: this.state.current_password,
			new_password: this.state.new_password,
			password_confirmation: this.state.confirm_password
		};

		const httpBody = {
			data: data
		};

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.examplePutApiMethod
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	}
	// Customizable Area End
}