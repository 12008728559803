import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import Cryptr from 'cryptr';
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  location: any;
  match: any;
  signupState: boolean;
  handleSignupState: () => void;
  handleFPassState: () => void;
  loginstate: boolean;
  handleLoginState: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  redirect: boolean;
  redirectUserDashboard: boolean;
  confirmPassword: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  loginModal: boolean;
  otpModal: boolean;
  signupModal: boolean;
  termsConditionsModal: boolean;
  privacyPolicyModal: boolean;
  passwordDoNotMatch: boolean;
  checkBoxTick: boolean;
  otpState: string;
  loginResponse: {};
  forgetPassword: boolean;
  otp: string;
  created_by: string;
  passToken: string;
  emailnotfound: boolean;
  termsOfServices: any[],
  privacyPolicies: any[],
  rentAVechile: any[],
  errorMessage: string;
  otpErrorMassage: string;
  otpSuccessMassage: string;
  isShowSignUpNewPassword: boolean,
  isShowSignUpConfirmPassword: boolean,
  isShowSignInPassword: boolean,
  isShowConfirmPasswordModalNewPassword: boolean,
  isShowConfirmPasswordModalNewPasswordConfirm: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  apiLoginCallId: string = "";
  emailReg: RegExp;
  validationApiCallId: string = "";
  termsOfServicesApiCallId: string = "";
  policyApiCallId: string = "";
  labelTitle: string = "";
  changePasswordCallId: any;
  rentAVechile: string = "";
  resendOtpCallId: string = "";
  otpConfirmationCallId: string = "";
  profileDataID: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      passwordDoNotMatch: false,
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      loginModal: false,
      checkBoxTick: false,
      otpState: "",
      otpModal: false,
      signupModal: false,
      loginResponse: {},
      forgetPassword: false,
      otp: "",
      created_by: "",
      termsConditionsModal: false,
      privacyPolicyModal: false,
      passToken: "",
      emailnotfound: false,
      termsOfServices: [],
      privacyPolicies: [],
      redirect: false,
      redirectUserDashboard: false,
      rentAVechile: [],
      errorMessage: "",
      otpErrorMassage: "",
      otpSuccessMassage: "",
      isShowSignUpNewPassword: false,
      isShowSignUpConfirmPassword: false,
      isShowSignInPassword: false,
      isShowConfirmPasswordModalNewPassword: false,
      isShowConfirmPasswordModalNewPasswordConfirm: false
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.callGetValidationApi();
    this.getTermsOfServices();
    this.getPrivacyPolicies();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    const token = window.location.search.includes('token');

    {
      token ? this.setState({
        passwordDoNotMatch: true
      }) :
        this.setState({
          passwordDoNotMatch: false
        })
    }
    // Customizable Area End
  }

  // Customizable Area Start



  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  visibilitySetter = (key: keyof S, password: boolean) => {
    this.setState({ ...this.state, [key]: !password })
  }

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };



  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  handleLoginResponse = (responseJson: any) => {
    console.log("handleLoginResponse"); 
    if (responseJson && responseJson.meta) {
      
      window.localStorage.setItem("token", responseJson.meta.token);
      window.localStorage.setItem("userId", responseJson?.user?.data?.id);
      if (window.location.search.includes('redirected_from=manager')) {
        window.localStorage.setItem('redirected_from', 'manager')
      } else {
        window.localStorage.removeItem('redirected_from');
      }

      if (window.localStorage.redirected_from == "manager") {
        this.setState({ otpModal: false });
        let userId = responseJson.user.data.id
        window.localStorage.setItem("userId", userId);
        this.setState({ redirect: true });
      } else {
        window.localStorage.removeItem('redirected_from');
        this.setState({ otpModal: true });
      }
      this.props.handleSignupState();
    }
    else if (responseJson && responseJson.errors) {
      this.setState({ errorMessage: responseJson.errors }, () => {
        this.removeToast()
      })
    }
}


  handleResendOtpResponse = (responseJson: any) => {
    console.log("handleResendOtpResponse");
    
    if (responseJson && responseJson.meta) {
      
      console.log(responseJson);
      localStorage.setItem("token", responseJson.meta.token)
      this.setState({ otpSuccessMassage: "OTP has been send successfully",otpState:"" }, () => {
        this.removeToast()
      })
    }
    else if (responseJson && responseJson.errors) {
      console.log("Not");
    }
  }

  handleOtpConfirmResponse = (responseJson: any) => {
    const cryptr = new Cryptr('x9o7H20Pm34Tv');
    if (responseJson && responseJson.meta) {
      const user_type = responseJson?.data?.attributes?.user_type;
      window.localStorage.setItem("userType", user_type);
      if (user_type) {
        // Encode the user_type String
        let encodedStringBtoA = cryptr.encrypt(user_type);
        window.localStorage.setItem("sKey", encodedStringBtoA);
      } else {
        // Handle the case where user_type is not available
        console.error("user_type is missing in the responseJson");
      }
      let userId = responseJson.data.id
      window.localStorage.setItem("userId", userId);
      this.setState({ otpSuccessMassage: responseJson.meta.message }, () => {
        this.removeToast()
      })
      this.setState({ redirect: true })
    }
    else if (responseJson && responseJson.errors) {
      this.setState({ otpErrorMassage: responseJson.errors[0].otp }, () => {
        this.removeToast()
      })

      this.setState({ otpErrorMassage: responseJson.errors[0].otp }, () => {
        this.removeToast()
      })

    }
  }

  handleChangePasswordResponse = (responseJson: any) => {
    if (responseJson && responseJson.meta) {
      this.setState({ passwordDoNotMatch: false });
    }
    else if (responseJson && responseJson.errors) {
      this.setState({ errorMessage: responseJson.errors[0].token }, () => {
        this.removeToast()
      })
    }
  }

  handleTermsServicesResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ termsOfServices: responseJson.data })
    }
  }

  handlePrivacyPolicyResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ privacyPolicies: responseJson.data });
    }
  }

  handleApiLoginResponse = (responseJson: any) => {
    const cryptr = new Cryptr('x9o7H20Pm34Tv');
    // Encode the String
    
    
    if (responseJson && !responseJson.errors) {
      let encodedStringBtoA = cryptr?.encrypt(responseJson?.user?.data?.attributes.user_type);
      window.localStorage.setItem('email', responseJson.user.data.attributes.email);
      window.localStorage.setItem("token", responseJson.meta.token);
      window.localStorage.setItem("userId", responseJson?.user?.data?.id);
      window.localStorage.setItem("userType", responseJson?.user?.data?.attributes.user_type);
      window.localStorage.setItem("sKey", encodedStringBtoA);
      this.checkOperationManger(responseJson);
    } else if (responseJson.errors === "invalid password") {
      this.setState({ errorMessage: "Please provide a valid password." });
      this.removeToast();
    } else {
      this.setState({ emailnotfound: true });
    }
  };

  handleProfileDataResponse = (responseJson: any) => {
    if (responseJson) {
      const lastStepCount = responseJson.data.attributes.last_application_step;
      if (lastStepCount) {
        this.setState({ redirectUserDashboard: true });
      }
      else {
        this.setState({ redirect: true });
      }
    }
  };


  handleApiResponse = (message: any) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    const handleApiResponseMap = {
      [this.apiEmailLoginCallId]: this.handleLoginResponse,
      [this.changePasswordCallId]: this.handleChangePasswordResponse,
      [this.resendOtpCallId]: this.handleResendOtpResponse,
      [this.otpConfirmationCallId]: this.handleOtpConfirmResponse,
      [this.termsOfServicesApiCallId]: this.handleTermsServicesResponse,
      [this.policyApiCallId]: this.handlePrivacyPolicyResponse,
      [this.apiLoginCallId]: this.handleApiLoginResponse,
      [this.profileDataID]: this.handleProfileDataResponse,
    };

    const handleResponse = handleApiResponseMap[apiRequestCallId]
    if (handleResponse) {
      handleResponse(responseJson);
    }
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // Customizable Area Start
      this.handleApiResponse(message);
      // Customizable Area End
    }
  }

  // Customizable Area Start



  handleChangePassword(values: any) {

    const Token = new URLSearchParams(window.location.search).get('token')


    this.setState({
      password: values?.password,
      confirmPassword: values?.confirm_password,
    });
    window.localStorage.setItem("password", this.state.password)

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changePasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const data = {
      new_password: values?.password ? values?.password : "",
      confirm_password: values?.password ? values?.password : "",
      // token: this.state.passToken ? this.state.passToken : "",
      token: Token ? Token : "",
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  goToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    // for signup

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
      password_confirmation: this.state.confirmPassword,
      user_type: "driver",
      redirected_from: window.localStorage.getItem('redirected_from')
    };
    // window.localStorage.setItem("password", this.state.password)

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  doLogIn(): boolean {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_login/login`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getTermsOfServices() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.termsOfServicesApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetTermsOfServices
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getPrivacyPolicies() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.policyApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetPrivacyPolicy
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleOtpApiCall = () => {
    // otp confirm
    console.log("I am calling");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const attr: any = {
      token: localStorage.getItem("token"),
      otp_code: this.state.otpState,
    }
    const httpBody = {
      data: attr

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.otpConfirmationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_forgot_password/otp_confirmation"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleResendOTP = () => {
    // resend otp
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const httpBody = {
      email: this.state.email,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resendOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/resend_verification_link"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  removeToast = () => {
    window.setTimeout(() => {
      this.setState({ errorMessage: "", otpErrorMassage: "", otpSuccessMassage: "" })
    }, 6000)
  }

  checkOperationManger = (userData: any) => {
    if (userData.user) {
      const userRole = userData.user.data.attributes.user_type
      if (userRole === "operation_manager") {
        window.location.href = "/operation-manager/driver-applications"
      } else {
        this.getProfileData()
      }
    }

  }


  getProfileData() {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    let userId = localStorage.getItem('userId')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.profileDataID = requestMessage.messageId;
    // Set Method Type
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    // Set EndPoints
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileDataApiEndPoint + userId
    );
    // set Headers
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };


  handleMouseDownPasswordSignUp = (event: any) => {
    event.preventDefault();
  };

  // Customizable Area End
}
