// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import App from './App';
import registerServiceWorker from '../../components/src/registerServiceWorker';
import { store } from "../../framework/src/redux/store";

ReactDOM.render(
  // <Provider store={store}>
    <Router>
      <App />
    </Router>
  // </Provider>
  ,
  document.getElementById('root')
);
registerServiceWorker();
