// Customizable Area Start
import React, { } from 'react';
import { Link, withRouter } from "react-router-dom";
import Popup from 'reactjs-popup';
import {
    Button,
    MenuItem,
    Typography,
    Toolbar,
    Box,
    IconButton

} from "@material-ui/core";
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { call, logo, logout, profile, robot } from '../../blocks/customform/src/assets';
import "../../web/src/App.css"
import { useTopbar } from '../../blocks/user-profile-basic/src/TopbarContext';
import { avatar } from '../../blocks/forgot-password/src/assets';
import { SettingsIC } from '../../blocks/ReviewAndApproval/src/assets';
import { withStyles } from '@material-ui/core/styles';
import { notification } from '../../blocks/dashboard/src/assets';



const StyledMenu = withStyles({
    paper: {
        border: '1px solid #979797',
        borderRadius: "8px",
        filter: " drop-shadow(0px 2px 29px rgba(0, 0, 0, 0.0701759))",
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {

    },
}))(MenuItem);



const DashboardHeader = ({ history, location }: any) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [userType, setUserType] = React.useState<any>(localStorage.getItem("userType"))
    const { changeSignupState } = useTopbar()
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        history.push("/")
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('userId');
        window.localStorage.removeItem('email');
        window.localStorage.removeItem('userType');
        window.localStorage.removeItem('password');
        window.localStorage.removeItem('sKey');
        
    }

    const handleLog = () => {
        history.push("/")
        changeSignupState()
    }

    const naviageToSettingsPage = () => {
        setAnchorEl(null);
        history.push("/driver/settings")
    }

    const handleOpmanagerNavigate = () => {
        if (userType === "operation_manager") {
            window.location.href = "/operation-manager/driver-applications"
        }
        else if (userType === "driver") {
            window.location.href = "/driver/dashboard"
        }
        else{
            window.location.href = "/"
        }
    }

    return (
        <div className="headerSection">
            <div className="container">
                <Toolbar disableGutters style={{ justifyContent: "space-between", padding: '0', background: "white" }}>
                    <Box style={{ display: "flex" }}>

                        <Typography>
                            <img src={avatar} alt="avatar"
                                className="avatar"
                                style={{
                                    width: "60px",
                                    height: "auto", cursor: "pointer"
                                }}
                                onClick={handleOpmanagerNavigate}
                            />
                        </Typography>

                        <Typography>
                            <img src={logo}
                                alt="logo"
                                className="avatarlogo"
                                style={{ margin: "20px", cursor: "pointer" }}
                                onClick={handleOpmanagerNavigate}
                            />

                        </Typography>

                    </Box>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                        {localStorage.getItem("userType") === "operation_manager" ?
                            <img src={notification} height={32} width={29} alt="" style={{ marginRight: "58px", display: 'none' }} />
                            : <IconButton color="primary">
                                <Box className="callUs">
                                    <Box className="myDIV">
                                        <img className="callIcon" src={call} alt="call" />
                                    </Box>
                                    <Box className="hide">
                                        +61 87399 29879
                                    </Box>
                                </Box>
                            </IconButton>}

                        {localStorage.getItem("userType") === null ?
                            <Button className="mNone commonButton" variant="contained" onClick={handleLog}>
                                Drive with us
                            </Button>
                            : <Box>
                                <Button
                                    aria-haspopup="true"
                                    id="profile"
                                    aria-controls={open ? 'fade-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    <img src={profile} />
                                </Button>

                                <StyledMenu
                                    id="fade-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'fade-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}

                                >
                                    {localStorage.getItem("userType") === "driver"
                                        && <MenuItem onClick={naviageToSettingsPage}>
                                            <img
                                                src={SettingsIC}
                                                height={20}
                                                width={20}
                                                className="mr-4"
                                                style={{ marginRight: "15px" }}
                                            />
                                            Settings
                                        </MenuItem>
                                    }
                                    <MenuItem onClick={handleLogout}>
                                        <img
                                            src={logout}
                                            height={20}
                                            width={20}
                                            className="mr-4"
                                            style={{ marginRight: "15px" }}
                                        />
                                        Logout
                                    </MenuItem>
                                </StyledMenu>
                            </Box>
                        }
                    </Box>
                </Toolbar>
            </div>
        </div>
    );
};
// Customizable Area End
export default withRouter(DashboardHeader);

