import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, {
	getName,
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import StorageProvider from '../../framework/src/StorageProvider.web'
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
	navigation: any;
	id: string;
	// Customizable Area Start
	setUserData: (data: any) => any;
	// Customizable Area End
}

interface S {
	// Customizable Area Start
	// Customizable Area End
}

interface SS {
	id: any;
	// Customizable Area Start
	// Customizable Area End
}

export default class AppController extends BlockComponent<
	Props,
	S,
	SS
> {
	// Customizable Area Start
	userApiCallId: string = "";
	token: any;
	userId: any;
	// Customizable Area End

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		this.subScribedMessages = [
			getName(MessageEnum.RestAPIRequestMethodMessage),
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			getName(MessageEnum.RestAPIRequestMessage),
			getName(MessageEnum.RestAPIResponceMessage),
			// Customizable Area Start
			// Customizable Area End
		];

		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

		// Customizable Area Start
		this.token = window.localStorage.getItem('token');
		this.userId = window.localStorage.getItem('userId');
		if (this.token && this.userId) this.getUserData();
		// Customizable Area End
	}

	async receive(from: string, message: Message) {
		runEngine.debugLog("Message Recived", message);

		if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
			const apiRequestCallId = message.getData(
				getName(MessageEnum.RestAPIResponceDataMessage)
			);

			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);

			let errorResponse = message.getData(
				getName(MessageEnum.RestAPIResponceErrorMessage)
			);
			if (this.userApiCallId === apiRequestCallId) {
				if (responseJson && responseJson.data) {
					const userObj = {
						name: responseJson.data.attributes.first_name + responseJson.data.attributes.last_name,
						state: responseJson.data.attributes.state_name
					}
					localStorage.setItem('current_user', JSON.stringify(userObj))
				} else {
					console.log(errorResponse);
				}
			}
		}
		// Customizable Area Start
		// Customizable Area End
	}

	getUserData() {
		const headers = {
			"Content-Type": configJSON.validationApiContentType,
			token: this.token
		};

		const getValidationsMsg = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.userApiCallId = getValidationsMsg.messageId;

		getValidationsMsg.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.showUserProfile}/${this.userId}`
		);

		getValidationsMsg.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(headers)
		);

		getValidationsMsg.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.validationApiMethodType
		);
		runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
		return true;
	}
	// Customizable Area Start
	// Customizable Area End
}