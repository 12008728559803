// Customizable Area Start
import React from "react";
import { closearrow, sucessrobot } from "./assets";
import { Grid } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import PaymentFormController from "./PaymentFormController.web";

class BackGroudCheckComplete extends PaymentFormController {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <>
        <div>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item lg={8}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <div className="bgTitleheading">
                  <img
                    src={closearrow}
                    onClick={this.props.handleBack}
                    className="closeArrowStyle"
                  />
                  <h3 className="bgheading">Background Check</h3>
                </div>

              </div>
              <div className="thanksgiving pt40">
                <img src={sucessrobot} className="robot" />
                <h4>Background check complete</h4>
                <p>Please click next to start with document check</p>
              </div>
              <div>
                <div className="flexEnd pt40 btn-static-div">
                  <button
                    className="btn-transparent mr15"
                    onClick={this.handleSaveAndExist}
                  >
                    Save and Exit
                  </button>
                  <button
                    className="btn-green-full-opacity da"
                    onClick={this.handleFinishBeCheckApiCall}
                  >
                    Finish
                    <span className="ml56">
                      <NavigateNextIcon />
                    </span>
                  </button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

export default BackGroudCheckComplete;
// Customizable Area End
