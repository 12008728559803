// Customizable Area Start
import React, { Component, ComponentType } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import DashboardSidebar from './Layout/DashboardSidebar.web';
import DriverApplications from './Screens/DriverApplications.web';
import CalenderPage from './Screens/CalenderPage.web';
import SettingsPage from './Screens/SettingsPage.web';
import NewApplications from './Screens/NewApplications.web';
import ViewApplications from './Screens/ViewApplications.web';
import ViewCertificateApplications from './Screens/ViewCertificateApplications.web';
import AppointmentSlots from './Screens/AppointmentSlots.web';
import ContractInformation from './Screens/ContractInformation.web';
import EditDriverDetails from './Screens/EditDriverDetails.web';

// Define a type for the WrappedComponent
type WrappedComponentProps = {
    url: string;
};

// Higher-Order Component for Role-Based Routing
const withRoleBasedRouting = (WrappedComponent: ComponentType<WrappedComponentProps>) => {
    return class extends Component {
        render() {
            const url = '/operation-manager';
            const userType: string | null = localStorage.getItem('userType');
            if (userType === 'operation_manager') {
                return <WrappedComponent url={url} />;
            } else if (userType === 'driver') {
                return <Redirect to="/driver/driver-details" />;
            } else {
                return <Redirect to="/" />;
            }
        }
    };
};

class OperationManager extends Component<WrappedComponentProps> {
    render() {
        return (
            <Router>
                <div className='maincalender-box' style={{ background: '#F9F9F9', display: 'flex' }}>
                    <DashboardSidebar />
                    <div style={{ flex: '1' }}>
                        <Switch>
                            <Route exact path={`${this.props.url}/driver-applications`} component={DriverApplications} />
                            <Route exact path={`${this.props.url}/calendar`} component={CalenderPage} />
                            <Route exact path={`${this.props.url}/calendar/available-appointment`} component={AppointmentSlots} />
                            <Route exact path={`${this.props.url}/viewApplications`} component={ViewApplications} />
                            <Route exact path={`${this.props.url}/viewCertificate`} component={ViewCertificateApplications} />
                            <Route exact path={`${this.props.url}/edit`} component={EditDriverDetails} />
                            <Route exact path={`${this.props.url}/settings`} component={SettingsPage} />
                            <Route exact path={`${this.props.url}/new-application`} component={NewApplications} />
                            <Route exact path={`${this.props.url}/contractInformation`} component={ContractInformation} />
                        </Switch>
                    </div>
                </div>
            </Router>
        );
    }
}

const OperationManagerWithRole = withRoleBasedRouting(OperationManager);

export default OperationManagerWithRole;
// Customizable Area End