import React from "react";
// Customizable Area Start
import { Formik } from "formik";
import OTPInput from "react-otp-input";
import * as Yup from "yup";
import {
  Box,
  Button,
  Typography,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@material-ui/core";
import { createTheme, withStyles } from "@material-ui/core/styles";
import { Link, Redirect } from "react-router-dom";
import { styled } from "@material-ui/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CheckboxProps } from "@material-ui/core/Checkbox";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import "./EmailAccountLogin.css";
import { Arrow, Triangle } from "./assets";
// Customizable Area End
import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

// Customizable Area Start
const StyledDriveButton = styled(Button)({
  color: "white",
  borderColor: "#80c342",
  padding: "16px 34px",
  marginBottom: "60px",
  borderRadius: "5px",
  fontFamily: "Lexend",
  fontSize: "16px",
  fontWeight: 400,
  textTransform: "none"
});

const StyledCreateAccountButton = styled(Button)({
  color: "white",
  background: "#80c342",
  width: "100%",
  borderRadius: "5px",
  fontFamily: "Lexend",
  fontSize: "16px",
  fontWeight: 400,
  textTransform: "none",
  padding: "20px 0",
  lineHeight: "18px"

});

const StyledContinueButton = styled(Button)({
  color: "white",
  background: "#80c342",
  width: "100%",
  borderRadius: "5px",
  fontFamily: "Lexend",
  fontSize: "16px",
  fontWeight: 400,
  textTransform: "none",
  padding: "20px 0",
  lineHeight: "18px"

});

const StyledLoginButton = styled(Button)({
  color: "white",
  background: "#80c342",
  width: "100%",
  borderRadius: "5px",
  fontFamily: "Lexend",
  fontSize: "16px",
  fontWeight: 400,
  textTransform: "none",
  padding: "20px 50px",
  lineHeight: "18px"


});

const StyledResetPasswordButton = styled(Button)({
  color: "white",
  background: "#80c342",
  width: "100%",
  borderRadius: "5px",
  fontFamily: "Lexend",
  fontSize: "16px",
  fontWeight: 400,
  textTransform: "none",
  padding: "20px 50px",
  lineHeight: "18px",

  // @ts-ignore
  "&:disabled": {
    backgroundColor: "#c0e1a1 !important",
    pointerEvents: "none !important",
    cursor: "not-allowed !important",
    color: "white",
  },
});

const passwordValidationSchema = Yup.object({
  password: Yup.string()
    .required("Password is a required field")
    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Password must match")
    .required("Password do not match"),
});

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Email is invalid")
    .required("Email is a required field"),
  password: Yup.string()
    .required("Password is a required field")
    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/),
});

const signupvalidationSchema = Yup.object({
  email: Yup.string()
    .email("Email is invalid")
    .required("Email is a required field"),
  password: Yup.string()
    .required("Password is a required field")
    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Password must match")
    .required("Password do not match"),
});

const GreenCheckbox = withStyles({
  root: {
    height: "16px",
    width: "16px",
    borderRadius: "4px",
    color: "#80C343",
    "&$checked": {
      color: "#80C343",
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

// Customizable Area Start


export const handlePasswordBoolean = (formikprops: any) => {
  return Boolean(formikprops.touched.password && formikprops.errors.password)
}
export const handleEmailBoolean = (formikprops: any) => {
  return Boolean(formikprops.touched.email && formikprops.errors.email)
}
export const handleConfirmPasswordBoolean = (formikprops: any) => {
  return Boolean(formikprops.touched.confirm_password && formikprops.errors.confirm_password)

}

export const conditionCheck = (value: boolean) => {
  if (value === true) {
    return "text"
  }
  else
    return "password"

}

export const ConfirmPasswordForm = ({
  formikprops,
  isShowConfirmPasswordModalNewPassword,
  isShowConfirmPasswordModalNewPasswordConfirm,
  visibilitySetter,
  handleMouseDownPassword
}: {
  formikprops: any,
  isShowConfirmPasswordModalNewPassword: boolean,
  isShowConfirmPasswordModalNewPasswordConfirm: boolean,
  visibilitySetter: any;
  handleMouseDownPassword: any
}) => {
  return (
    <form
      style={{ margin: "0" }}
      onSubmit={formikprops.handleSubmit}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px"
        }}
      >
        <div>
          <label htmlFor="password"
            className="LebelText TextfontNormal"
          >
            New Password
          </label>
          <TextField
            onChange={formikprops.handleChange}
            onBlur={formikprops.handleBlur}
            error={handlePasswordBoolean(formikprops)}
            helperText={
              formikprops.touched.password &&
              Boolean(formikprops.errors.password) &&
              // "Invalid Password"
              "Password must contain 8 characters with 1 uppercase 1 lowercase 1 numeric & 1 special character"
            }
            value={formikprops.values.password}
            id="password"
            placeholder="Create Password"
            type={isShowConfirmPasswordModalNewPassword ? 'text' : 'password'}
            name="password"
            data-testid="password-input"
            fullWidth
            variant="filled"
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => visibilitySetter("isShowConfirmPasswordModalNewPassword", isShowConfirmPasswordModalNewPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {isShowConfirmPasswordModalNewPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                fontFamily: "Lexend",
                fontWeight: 300,
                fontSize: "14px",
                border: handlePasswordBoolean(formikprops) ? "1px solid red" : "none",
              },
            }}
          />
        </div>

        <div>
          <label
            htmlFor="name"
            className="LebelText TextfontNormal"
          >
            Confirm Password
          </label>
          <TextField
            value={formikprops.values.confirm_password}
            onChange={formikprops.handleChange}
            onBlur={formikprops.handleBlur}
            error={handleConfirmPasswordBoolean(formikprops)}
            helperText={
              formikprops.touched.confirm_password &&
              Boolean(formikprops.errors.confirm_password) &&
              String(formikprops.errors.confirm_password)
            }
            id="name"
            placeholder="Re-enter password"
            type={isShowConfirmPasswordModalNewPasswordConfirm ? 'text' : 'password'}
            name="confirm_password"
            data-testid="confirm-password-input"
            fullWidth
            variant="filled"
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => visibilitySetter("isShowConfirmPasswordModalNewPasswordConfirm", isShowConfirmPasswordModalNewPasswordConfirm)}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {isShowConfirmPasswordModalNewPasswordConfirm ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                fontFamily: "Lexend",
                fontWeight: 300,
                fontSize: "14px",
                border: handleConfirmPasswordBoolean(formikprops) ? "1px solid red" : "none",
              },
            }}

          />
        </div>
      </div>

      {/* <DialogActions className="email-dialog-actions">
       
      </DialogActions> */}
      <div
        style={{
          marginTop: "40px",
        }}
      >
        <StyledResetPasswordButton
          variant="contained"
          type="submit"
          data-testid="reset-password-btn"
          disabled={
            formikprops.errors.password ||
              formikprops.errors.confirm_password ||
              formikprops.values.password === "" ||
              formikprops.values.confirm_password === ""
              ? true
              : false
          }
        >
          Reset Password
        </StyledResetPasswordButton>
      </div>
    </form >
  )
}
// Customizable Area End

// Customizable Area Start
export const CheckboxLabel = ({ handleOpen, handleSignupClose }: { handleOpen: any, handleSignupClose: any }) => (
  <Typography
    style={{
      fontFamily: "Lexend",
      fontWeight: 300,
      color: "#BEBEBE",
      lineHeight: "17.5px",
    }}
    className="checkboxLabel" component="span">
    By continuing you indicate that you agree to the{" "}
    <Link
      to="#"
      className="termsOfServices"
      onClick={() => {
        handleOpen("termsConditionsModal")
        // handleSignupClose()
      }}
      id="terms-services-btn"
    >
      Terms of services
    </Link>{" "}
    and{" "}
    <Link
      to="#"
      className="termsOfServices"
      onClick={() => {
        // handleSignupClose()
        handleOpen("privacyPolicyModal")
      }}
      id="privacy-policy-btn"
    >
      Privacy Policy
    </Link>
  </Typography>
);
// Customizable Area End

// Customizable Area Start
export const SignupScreenForm = ({
  formikprops,
  checkBoxTick,
  setCheckboxTick,
  handleOpen,
  handleSignupState,
  errorMsg,
  isShowSignUpNewPassword,
  isShowSignUpConfirmPassword,
  visibilitySetter,
  handleMouseDownPassword,

}: {
  formikprops: any,
  checkBoxTick: boolean,
  setCheckboxTick: () => void,
  handleOpen: (item: string) => void,
  handleSignupState: () => void,
  errorMsg: any;
  isShowSignUpNewPassword: boolean;
  isShowSignUpConfirmPassword: boolean;
  visibilitySetter: any
  handleMouseDownPassword: any
}) => {


  const handleBorder = (value1: any, value2: any) => {
    if (value1 && value2) {
      return "1px solid red"
    }
    else {
      return "none"
    }
  }



  const handleDisable = () => {


    if (handleConditionCheck(formikprops.values.email, handleEmailBoolean(formikprops)) || handleConditionCheck(formikprops.values.password, handlePasswordBoolean(formikprops)) || handleConditionCheck(formikprops.values.confirm_password, handleConfirmPasswordBoolean(formikprops)) || !checkBoxTick) {
      return true
    }

  }

  const handleConditionCheck = (value1: any, value2: boolean) => {
    if (value1 === '' || value2 === true) {
      return true
    }
  }

  return (
    <form onSubmit={formikprops.handleSubmit}>
      <DialogContent className="sign-up-screen">
        <label htmlFor="email" style={{ fontFamily: "Lexend", fontWeight: 300, fontSize: "16px" }} >Email</label>
        <TextField
          onChange={formikprops.handleChange}
          onBlur={formikprops.handleBlur}
          error={handleEmailBoolean(formikprops)}
          helperText={
            formikprops.touched.email &&
            Boolean(formikprops.errors.email) &&
            String(formikprops.errors.email)
          }
          value={formikprops.values.email}
          data-testid="email"
          placeholder="Enter Email"
          type="email"
          name="email"
          fullWidth
          variant="filled"
          style={{ fontFamily: "Lexend", fontWeight: 300 }}
          InputProps={{
            disableUnderline: true,
          }}
          inputProps={{
            style: {
              padding: 15,
              fontFamily: "Lexend",
              fontWeight: 300,
              fontSize: "14px",
              border: handleBorder(Boolean(formikprops.touched.email), Boolean(formikprops.errors.email))

            },
          }}
        />
      </DialogContent>

      <DialogContent>
        <label htmlFor="password" style={{ fontFamily: "Lexend", fontWeight: 300, fontSize: "16px" }}>Password</label>
        <TextField
          onChange={formikprops.handleChange}
          onBlur={formikprops.handleBlur}
          error={handlePasswordBoolean(formikprops)}
          helperText={
            formikprops.touched.password &&
            Boolean(formikprops.errors.password) &&
            // "Invalid Password"
            "Password must contain 8 characters with 1 uppercase 1 lowercase 1 numeric & 1 special character"
          }
          value={formikprops.values.password}
          data-testid="password"
          placeholder="Create Password"
          type={conditionCheck(isShowSignUpNewPassword)}
          name="password"
          fullWidth
          variant="filled"
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => visibilitySetter("isShowSignUpNewPassword", isShowSignUpNewPassword)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {isShowSignUpNewPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                </IconButton>
              </InputAdornment>
            ),
            style: {
              fontFamily: "Lexend",
              fontWeight: 300,
              fontSize: "14px",
              border: handleBorder(Boolean(formikprops.touched.password), Boolean(formikprops.errors.password))
            },
          }}

          style={{ fontFamily: "Lexend", fontWeight: 300 }}
        />
      </DialogContent>

      <DialogContent>
        <label htmlFor="name" style={{ fontFamily: "Lexend", fontWeight: 300, fontSize: "16px" }}>Confirm Password</label>
        <TextField
          // id="signup-confirm-password"
          value={formikprops.values.confirm_password}
          onChange={formikprops.handleChange}
          onBlur={formikprops.handleBlur}
          error={handleConfirmPasswordBoolean(formikprops)}
          helperText={
            formikprops.touched.confirm_password &&
            Boolean(formikprops.errors.confirm_password) &&
            String(formikprops.errors.confirm_password)
          }
          id="name"
          placeholder="Re-enter password"
          type={conditionCheck(isShowSignUpConfirmPassword)}
          name="confirm_password"
          data-testid="confirm_password"
          fullWidth
          variant="filled"
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => visibilitySetter("isShowSignUpConfirmPassword", isShowSignUpConfirmPassword)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {isShowSignUpConfirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                </IconButton>
              </InputAdornment>
            ),
            style: {
              fontFamily: "Lexend",
              fontWeight: 300,
              fontSize: "14px",
              border: handleBorder(Boolean(formikprops.touched.confirm_password), Boolean(formikprops.errors.confirm_password))
            },
          }}

          style={{ fontFamily: "Lexend", fontWeight: 300 }}
        />
      </DialogContent>

      <FormControlLabel
        style={{ margin: "15px 0 0 0" }}
        data-test-id="sign-up-check-box-label"
        control={
          <GreenCheckbox
            checked={checkBoxTick}
            onChange={setCheckboxTick}
            name="checked"
          />
        }
        label={<CheckboxLabel handleOpen={handleOpen} handleSignupClose={handleSignupState} />}
      />

      <DialogActions className="loginActions" style={{ padding: "0" }}>
        <StyledCreateAccountButton
          data-testid="create-account-btn"
          variant="contained"
          type="submit"
          disabled={
            handleDisable()
          }
          style={{
            fontSize: "16px",
            fontFamily: "Lexend",
            marginTop: "32px",
          }}
        >
          Create Account
        </StyledCreateAccountButton>
        {errorMsg && <p className="err ml10">{errorMsg}.Please Login</p>
        }
      </DialogActions>
    </form>
  )
}


// Customizable Area End

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start


  handleOpen = (stateName: string) => {
    this.setState((prev) => ({
      ...prev,
      [stateName]: true,
    }));
  };

  handleClose = (stateName: string) => {
    this.setState((prev) => ({
      ...prev,
      [stateName]: false,
    }));
  };

  conditionalRedirection = () => {
    if (this.state.redirect) {
      return <Redirect to="/driver/driver-details" />
    } else if (this.state.redirectUserDashboard) {
      return <Redirect to="/driver/dashboard" />
    }
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const token: string | null = localStorage.getItem('userType');
    const isLoggedIn: boolean = !!token;

    const style: any = {
      modalBox: {
        margin: "0 44px",
        padding: "15px",
        height: "444px",
        maxHeight: "525px",
        overflowY: "auto",
        border: " 1px solid #E2E2E2",
        borderRadius: "5px",
      },
      button: {
        color: "white",
        background: "#80c342",
        margin: "16px 44px 24px 0",
        borderRadius: "5px",
        padding: "20px 58px",
        fontFamily: "Lexend",
        fontSize: "16px",
        fontWeight: 400,
        textTransform: "none",
        lineHeight: "18px"

      },
      buttonBox: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end"
      }
    };
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
        {!isLoggedIn && (
          <StyledDriveButton
            id="email-driver-login-btn"
            variant="outlined"
            color="primary"
            onClick={() => {
              this.props?.handleLoginState?.();
            }}
          >
            Login
          </StyledDriveButton>
        )}

        {/* sign up */}
        <Dialog
          id="signupscreen-test"
          open={this.props.signupState}
          onClose={() => {
            this.props.handleSignupState();
          }}
          aria-labelledby="form-dialog-title"
        >
          <Box className="loginModalContainer"

          >
            {/* <DialogTitle id="form-dialog-title" className="loginTitle"
            style={{
              marginBottom:"32px"
            }}
            >
             Sign Up
            </DialogTitle> */}

            <p
              className="loginTitle"
              id="form-dialog-title"

            >
              Sign Up
            </p>
            <Formik
              data-testid="user-details-submit-btn"
              initialValues={{
                email: "",
                password: "",
                confirm_password: "",
              }}
              onSubmit={(values) => {
                this.setState(
                  {
                    email: values?.email,
                    password: values?.password,
                    confirmPassword: values?.confirm_password,
                  },
                  () => {
                    this.doEmailLogIn();
                  }
                );
              }}
              validationSchema={signupvalidationSchema}
            >
              {(formikprops) => (
                <SignupScreenForm
                  formikprops={formikprops}
                  handleOpen={this.handleOpen}
                  handleSignupState={this.props.handleSignupState}
                  checkBoxTick={this.state.checkBoxTick}
                  errorMsg={this.state.errorMessage}
                  setCheckboxTick={() => {
                    this.setState({
                      checkBoxTick: !this.state.checkBoxTick,
                    })
                  }}
                  isShowSignUpNewPassword={this.state.isShowSignUpNewPassword}
                  isShowSignUpConfirmPassword={this.state.isShowSignUpConfirmPassword}
                  visibilitySetter={this.visibilitySetter}
                  handleMouseDownPassword={this.handleMouseDownPasswordSignUp}

                />
              )}
            </Formik>

            <Typography className="loginLinks"
              style={{
                fontFamily: "Lexend",
                fontWeight: 300,
                fontSize: "16px",
                color: "#949494"
              }}
            >
              Already have an account?
              <Link
                id="link-to-login"
                to="/"
                className="linkColor"
                onClick={() => {
                  this.props.handleSignupState();
                  this.props.handleLoginState();
                }}
              >
                Login
              </Link>
            </Typography>
          </Box>
        </Dialog>

        {/* Email not found screen */}
        <Dialog
          id="on-close-modal"
          open={this.state.emailnotfound}
          onClose={() => {
            this.handleClose("emailnotfound");
          }}
          aria-labelledby="form-dialog-title"
        >
          <img src={Arrow} className="email-not-found-arrow" id="emailnotfoundarrow" onClick={() => { this.handleClose("emailnotfound"); }} />
          <Box
            className=""
            style={{
              padding: "64px 100px"
            }}
          >
            <Box className="email-container-box">
              <img src={Triangle} className="triangle" />
            </Box>
            <DialogTitle id="form-dialog-title" className="email-form">
              <Typography className="email-not-found">
                Email Not Found
              </Typography>
            </DialogTitle>
            <DialogContent>
              <p className="email-form-text">
                Entered email address is invalid, Please click the link <br />
                below to create an account to start using our platform
              </p>
            </DialogContent>
            <DialogActions className="email-dialog-actions">

              <StyledLoginButton variant="contained" type="submit"
                style={{
                  width: "100%",
                  textTransform: "none",
                  marginTop: "-20px"
                }}
                data-testid="signupButton"
                onClick={() => {

                  this.handleClose("emailnotfound");

                  this.props.handleSignupState();

                  this.props.handleLoginState()
                }}
              >
                Please Sign Up
              </StyledLoginButton>
              <Button
                style={{
                  color: "#a7a7a7",
                  marginTop: "10px",
                  textTransform: "none"
                }}
                variant="text"
                data-testid="backToLogin"
                onClick={() => {
                  this.handleClose("emailnotfound");

                }}
              >
                Back to Login
              </Button>
            </DialogActions>
          </Box>
        </Dialog>

        {/* email otp screen */}
        <Dialog
          open={this.state.otpModal}
          data-testid="otpModal"
          onClose={() => this.handleClose("otpModal")}
          aria-labelledby="form-dialog-title"
        >
          <Box className="loginModalContainer">
            <h1 className="text-center loginModalHeading">Email Verification</h1>
            <h5 className="login-otp ">One Time Passcode(OTP)</h5>
            <p className="text-center otp-text1 TextfontNormal">
              Enter the 4 digit code sent to you at : <br /> ({this.state.email}){" "}
            </p>
            <OTPInput
              className="opt-box-container"
              containerStyle={{
                justifyContent: "center"
              }}
              data-test-id="otpstate"
              onChange={(e: any) => this.setState({ otpState: e })}
              numInputs={4}
              value={this.state.otpState}
              placeholder="0000"
              inputStyle={{
                height: "3.5rem",
                width: "3.5rem",
                margin: 20,
                // backgroundColor: "#DEDEDE52 !important",
                border: "none",
                borderRadius: "5px",
                fontWeight: "700",
                fontSize: "1.3rem",
                color: "black",
              }}
              shouldAutoFocus={true}
              isInputNum={true}
            />
            <p className="text-center otp-text1 TextfontNormal">
              Didn't receive OTP?{" "}
              <Link to="#"
                className="linkColor"
                data-testid="resendOtp"
                onClick={() => this.handleResendOTP()}
              >
                Resend
              </Link>
            </p>
            <DialogActions className="email-dialog-actions">
              <StyledContinueButton
                variant="contained"
                id="handleotp"
                onClick={() => {
                  if (this.state.otpState.length === 4) this.handleOtpApiCall();
                  else alert("Invalid OTP")
                }}
              >
                Continue
              </StyledContinueButton>
              <Button
                variant="text"
                onClick={() => {
                  this.handleOpen("loginModal");
                  this.handleClose("otpModal");
                }}
                style={{
                  fontFamily: "Lexend",
                  fontWeight: 300,
                  fontSize: "16px",
                  color: "#949494",
                  textTransform: "none"
                }}
                id="login"
              >
                Back to login
              </Button>
            </DialogActions>

            {this.state.otpErrorMassage && <p className="err ml10">{this.state.otpErrorMassage}</p>}
            {this.state.otpSuccessMassage && <p className="success ml10">{this.state.otpSuccessMassage}</p>}
          </Box>
        </Dialog>

        {/* login screen */}
        <Dialog
          id="loginModal"
          open={this.props.loginstate}
          onClose={() => {
            this.props.handleLoginState();
          }}
          aria-labelledby="form-dialog-title"
        >
          <Box className="loginModalContainer">
            <p className="loginModalHeading">Login</p>
            <Formik
              data-test-id="loginForm"
              initialValues={{
                email: "",
                password: "",
              }}
              onSubmit={(values) => {
                this.setState(
                  {
                    email: values?.email,
                    password: values?.password,
                  },
                  () => {
                    this.doLogIn();
                  }
                );
              }}
              validationSchema={validationSchema}
            >
              {(formikprops) => (
                <form data-testid="loginFromSubmit" onSubmit={formikprops.handleSubmit}>
                  <DialogContent>
                    <label htmlFor="email"
                      style={{
                        fontFamily: "Lexend",
                        fontWeight: 300,
                        fontSize: "16px"
                      }}
                    >
                      Email
                    </label>
                    <TextField
                      onChange={formikprops.handleChange}
                      onBlur={formikprops.handleBlur}
                      error={handleEmailBoolean(formikprops)}
                      helperText={
                        formikprops.touched.email &&
                        Boolean(formikprops.errors.email) &&
                        String(formikprops.errors.email)
                      }

                      id="email"
                      placeholder="Enter Email"
                      type="email"
                      fullWidth
                      variant="filled"
                      name="email"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      inputProps={{
                        style: {
                          fontFamily: "Lexend",
                          fontWeight: 300,
                          fontSize: "14px",
                          border: handleEmailBoolean(formikprops) ? "1px solid red" : "none",
                        },
                      }}
                    />
                  </DialogContent>

                  <DialogContent>
                    <label htmlFor="password"
                      style={{
                        fontFamily: "Lexend",
                        fontWeight: 300,
                        fontSize: "16px"
                      }}
                    >Password</label>
                    <TextField
                      onChange={formikprops.handleChange}
                      onBlur={formikprops.handleBlur}
                      error={handlePasswordBoolean(formikprops)}
                      helperText={
                        formikprops.touched.password && Boolean(formikprops.errors.password) &&
                        // "Invalid Password"
                        "Incorrect password"
                      }
                      value={formikprops.values.password}
                      id="password"
                      placeholder="Enter Password"
                      type={this.state.isShowSignInPassword ? 'text' : 'password'}
                      fullWidth
                      variant="filled"
                      name="password"
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => this.visibilitySetter("isShowSignInPassword", this.state.isShowSignInPassword)}
                              onMouseDown={this.handleMouseDownPassword}
                              edge="end"
                            >
                              {this.state.isShowSignInPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                        style: {
                          fontFamily: "Lexend",
                          fontWeight: 300,
                          fontSize: "14px",
                          border: handlePasswordBoolean(formikprops) ? "1px solid red" : "none",
                        },
                      }}

                    />
                    <div style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}>
                      <p
                        id="link-to-login"
                        className="linkColor"
                        onClick={() => {
                          this.props.handleFPassState();
                          this.props.handleLoginState();
                        }}
                        style={{
                          cursor: "pointer",
                          fontFamily: "Lexend",
                          fontSize: "16px",
                          fontWeight: 500,
                          color: "rgba(0, 0, 0, 0.5)",
                          textDecoration: "none",
                          margin: "10px 0 0 0"

                        }}
                      >
                        Forgot your password?
                      </p>
                    </div>

                  </DialogContent>

                  <DialogActions className="email-dialog-actions"
                    style={{
                      padding: "0"
                    }}
                  >
                    <StyledLoginButton
                      variant="contained"
                      disabled={this.state.errorMessage != ""}
                      type="submit"

                      style={{
                        fontSize: "16px",
                        fontFamily: "Lexend",
                        marginTop: "2px",

                      }}
                    >

                      Login
                    </StyledLoginButton>
                    {this.state.errorMessage && <p className="err ml10">{this.state.errorMessage}</p>
                    }

                    <Button
                      variant="text"
                      style={{
                        fontSize: "16px",
                        fontFamily: "Lexend",
                        marginTop: "12px",
                        fontWeight: 300,
                        color: "rgba(0, 0, 0, 0.5)",
                        textTransform: "none"
                      }}
                      onClick={() => {
                        this.props.handleSignupState();
                        this.props.handleLoginState();
                      }}
                    >
                      Sign Up
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </Box>
        </Dialog>

        {/* Create new password */}
        <Dialog
          id="newpassword"
          open={this.state.passwordDoNotMatch}
          onClose={() => {
            this.handleClose("passwordDoNotMatch");
          }}
          aria-labelledby="form-dialog-title"
        >
          <img
            id="imgclick"
            onClick={() => {
              this.handleClose("passwordDoNotMatch");
            }}
            src={Arrow}
            className="arrow"
          />
          <Box className="loginModalContainer">
            <p
              id="form-dialog-title"
              className="login-heading headingFontBold"
              style={{ margin: "0 0 40px 0" }}
            >
              Create new password
            </p>
            <Formik
              data-test-id="on-login-driver"
              initialValues={{
                password: "",
                confirm_password: "",
              }}
              // @ts-ignore
              onSubmit={(values) => this.handleChangePassword(values)}
              validationSchema={passwordValidationSchema}
            >
              {/* @ts-ignore */}
              {(formikprops) => (
                <ConfirmPasswordForm
                  formikprops={formikprops}
                  isShowConfirmPasswordModalNewPassword={this.state.isShowConfirmPasswordModalNewPassword}
                  isShowConfirmPasswordModalNewPasswordConfirm={this.state.isShowConfirmPasswordModalNewPasswordConfirm}
                  visibilitySetter={this.visibilitySetter}
                  handleMouseDownPassword={this.handleMouseDownPasswordSignUp}
                />
              )}
            </Formik>
            {this.state.errorMessage && <p className="err ml10">{this.state.errorMessage}</p>}
          </Box>
        </Dialog>

        {/* terms and conditions screen */}
        <Dialog //
          open={this.state.termsConditionsModal}
          id="terms"
          onClose={() => {
            this.handleClose("termsConditionsModal");
          }}
          aria-labelledby="form-dialog-title"
          style={{ fontFamily: "Lexend" }}
          data-testid="terms-services-content"
        >
          <Box style={{ padding: "41px 44px 0 44px" }}>
            <DialogTitle id="form-dialog-title">
              <p
                className="headingFontBold term-and-policy-header"
                style={{ fontWeight: 600 }}
              >
                Terms of services
              </p>
              <p className="TextfontNormal term-and-policy-sub-header">
                Your Agreement
              </p>
            </DialogTitle>
          </Box>
          <Box style={style.modalBox} className="modal-scrollbar">

            {this.state.termsOfServices.map((service: any, index: any) => (
              <div key={index} >
                {service.attributes.accepted && (
                  <Typography data-testid="terms-services-data" dangerouslySetInnerHTML={{ __html: service.attributes.ts_content }} />
                )}
              </div>
            )
            )}
          </Box>

          <Box style={style.buttonBox}>
            <Button
              id="tAndC"
              variant="contained"
              style={style.button}
              onClick={() => {
                // this.props.handleSignupState();
                this.handleClose("termsConditionsModal")
              }}
            >
              Continue
            </Button>
          </Box>


        </Dialog>

        {/* privacy policy screeen */}
        <Dialog
          open={this.state.privacyPolicyModal}

          onClose={() => this.handleClose("privacyPolicyModal")}
          aria-labelledby="form-dialog-title"
          style={{ fontFamily: "Lexend" }}
          data-testid="privacy-policy-content"
        >
          <Box style={{ padding: "42px 44px 0 44px" }}>
            <DialogTitle id="form-dialog-title">

              <p className="headingFontBold term-and-policy-header"
                style={{ fontWeight: 600 }}
              >
                Privacy Policy
              </p>
              <p className="TextfontNormal term-and-policy-sub-header">
                Your Agreement
              </p>
            </DialogTitle>
          </Box>

          <Box style={style.modalBox} className="modal-scrollbar">
            {this.state.privacyPolicies?.map((policy: any, index: any) => (
              <div key={index}>
                {policy.attributes.accepted && (
                  <Typography data-testid="privacy-policy-data" dangerouslySetInnerHTML={{ __html: policy.attributes.policy_content }} />
                )}
              </div>
            ))}
          </Box>

          <Box style={style.buttonBox}>
            <Button
              variant="contained"
              style={style.button}
              id="continuebuttonclick"
              onClick={() => {
                // this.props.handleSignupState();
                this.handleClose("privacyPolicyModal")
              }}
            >
              Continue
            </Button>
          </Box>
        </Dialog>
        {this.conditionalRedirection()}
      </>
      // Customizable Area End
    );
  }
}
