
// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

export const configJSON = require("../../src/config");

export interface Props {
    closeAskToRescheduleConfirmationModal: () => void,
    isOpenRescheduleConfimationModal: boolean,
    userDetails: any,
    classes: any
}
interface S { }
interface SS {
    id: any;
}
export default class AskClientRescheduleModalController extends BlockComponent<Props, S, SS>  {
    // Customizable Area Start
    confirmAskForRescheduleStatusId: string = ""
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIRequestMethodMessage),
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage)

        ];

        this.state = {};
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (this.confirmAskForRescheduleStatusId === apiRequestCallId) {
                if (responseJson && responseJson.data) {
                    debugger
                    this.props.closeAskToRescheduleConfirmationModal()
                }
            }
        }
        // Customizable Area End
    }


    commonRunEngineFunction = (urlEndPoint: any, methodType: string, status: any, body: any) => {
        const header = {
            "Content-Type": "application/json",
            token: window.localStorage.getItem("token"),
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const statusMapping = {
            confirmAskForReschedule: "confirmAskForRescheduleStatusId",
        };
        //@ts-ignore
        const propertyName = statusMapping[status];
        //@ts-ignore
        this[propertyName] = getValidationsMsg.messageId;


        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            urlEndPoint
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methodType
        );
        if (body) {
            getValidationsMsg.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
        }

        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }


    confirmAskForReschedule = () => {
        const body = {
            "driver_id": this.props.userDetails.id,
            "status": "pending_induction",
            "rescheduled": true
        }

        this.commonRunEngineFunction(configJSON.confirmAssignmentEndPoint, configJSON.examplePutAPiMethod, 'confirmAskForReschedule', body)
    }

    // Customizable Area End
}
