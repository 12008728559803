// Customizable Area Start
import React, { Component } from 'react';
import { Container, Grid, Tooltip } from "@material-ui/core";
import { Theme, withStyles } from '@material-ui/core/styles';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { infoIcon, warning } from "./assets";

import "./PersonalDetails.css";
import WorkExperienceController, { Props } from "./WorkExprienceController.web";
// Customizable Area End
class BankDetails extends WorkExperienceController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start

            <section className="bgGrey" >
                <Container>
                    <section className="personal-detail">
                        <div>
                            <Grid className="scrollContent">
                                <Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <p className="fs20 formHeading">Bank Details :</p>
                                            <div>
                                                <label className="labelHeading" htmlFor="aname">Account Name</label>
                                                <sup>*</sup>
                                            </div>
                                            <input
                                                type="text"
                                                id="account_name"
                                                placeholder="Type here..."
                                                name="account_name"
                                                autoComplete="off"
                                                value={this.state.data.account_name}
                                                data-testid="account_nameInputField"
                                                onChange={event => this.handleInputChange(event)}
                                            />

                                            {this.state.errors.account_name && (
                                                <div className="da">
                                                    <img src={warning} alt="" />
                                                    <p className="err ml10">
                                                        {this.state.errors.account_name}
                                                    </p>
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div>
                                                <label className="labelHeading" htmlFor="bsb">BSB Number</label>
                                                <sup>*</sup>
                                            </div>
                                            <input
                                                type="text"
                                                id="BSB_number"
                                                placeholder="Type here..."
                                                name="BSB_number"
                                                autoComplete="off"
                                                value={this.state.data.BSB_number}
                                                data-testid="BSB_numberInputField"
                                                onChange={event => this.handleInputChange(event)}
                                            />
                                            {this.state.errors.BSB_number && (
                                                <div className="da">
                                                    <img src={warning} alt="" />
                                                    <p className="err ml10">
                                                        {this.state.errors.BSB_number}
                                                    </p>
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div>
                                                <label className="labelHeading" htmlFor="account">Account Number</label>
                                                <sup>*</sup>
                                            </div>
                                            <input
                                                type="text"
                                                id="account"
                                                placeholder="Type here..."
                                                name="account_number"
                                                autoComplete="off"
                                                value={this.state.data.account_number}
                                                data-testid="account_numberInputField"
                                                onChange={event => this.handleInputChange(event)}
                                            />
                                            {this.state.errors.account_number && (
                                                <div className="da">
                                                    <img src={warning} alt="" />
                                                    <p className="err ml10">
                                                        {this.state.errors.account_number}
                                                    </p>
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div>
                                                <label className="labelHeading" htmlFor="bank">Bank Name</label>
                                                <sup>*</sup>
                                            </div>
                                            <input
                                                type="text"
                                                id="bank"
                                                placeholder="Type here..."
                                                name="bank_name"
                                                autoComplete="off"
                                                value={this.state.data.bank_name}
                                                data-testid="bank_nameInputField"
                                                onChange={event => this.handleInputChange(event)}
                                            />
                                            {this.state.errors.bank_name && (
                                                <div className="da">
                                                    <img src={warning} alt="" />
                                                    <p className="err ml10">
                                                        {this.state.errors.bank_name}
                                                    </p>
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {/* <div className="da regis"> */}
                                            <label className="labelHeading">Are you GST Registered :<sup>*</sup> </label>
                                            {/* <sup>*</sup> */}

                                            {/* </div> */}
                                            <div style={{ marginTop: "16" }} className="df">
                                                <div className={this.state.data.GST_registered == "true" ? "div-checkbox mr15 green-border" : "div-checkbox mr15"}>

                                                    <input
                                                        checked={this.state.data.GST_registered == "true"}
                                                        type="radio"
                                                        id="yes"
                                                        name="GST_registered"
                                                        value="true"
                                                        data-testid="yesGST_registeredCheckBox"

                                                        onChange={event =>
                                                            this.handleGSTRegistered(
                                                                event,
                                                                "true",
                                                                "GST_registered"
                                                            )
                                                        }
                                                    />
                                                    <label htmlFor="yes">Yes</label>
                                                </div>
                                                <div className={this.state.data.GST_registered == "false" ? "div-checkbox mr15 green-border" : "div-checkbox mr15"}>

                                                    <input
                                                        checked={this.state.data.GST_registered == "false"}
                                                        type="radio"
                                                        id="no"
                                                        name="GST_registered"
                                                        data-testid="noGST_registeredCheckBox"
                                                        value="false"

                                                        onChange={event =>
                                                            this.handleGSTRegistered(
                                                                event,
                                                                "false",
                                                                "GST_registered"
                                                            )
                                                        }
                                                    />
                                                    <label htmlFor="no">No</label>
                                                </div>
                                            </div>
                                            {this.state.errors.GST_registered && (
                                                <div className="da">
                                                    <img src={warning} alt="" />
                                                    <p className="err ml10">
                                                        {this.state.errors.GST_registered}
                                                    </p>
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} style={{ marginTop: "10px" }}>
                                            <label className="labelHeading">
                                                Do you have a pre-existing injury or medical
                                                condition/disability that would affect your
                                            </label>

                                            <div>
                                                <label className="labelHeading">ability to do this work? <sup>*</sup><LightTooltip
                                                    // style={{ maxWD idth: "700px" }}
                                                    arrow
                                                    placement="top"
                                                    title='"if yes please provide detail of the injury or condition(s), and provide information
about any adjustments you require to perform the role of the contract (if any)"'
                                                >
                                                    <img src={infoIcon} style={{ height: "16px", width: "auto", marginLeft: "10px" }} />
                                                </LightTooltip></label>
                                            </div>

                                            <div style={{ marginTop: "16" }} className="df">
                                                <div className={this.state.data.medical_condition === "true" ? "div-checkbox mr15 green-border" : "div-checkbox mr15"}>
                                                    {/* istanbul ignore  */}
                                                    <input
                                                        checked={this.state.data.medical_condition === "true"}
                                                        type="radio"
                                                        id="yes"
                                                        name="medical_condition"
                                                        value={this.state.data.medical_condition}
                                                        data-testid="medical_conditionCheckBox"
                                                        onChange={event =>
                                                            this.handleMedicalCondition(
                                                                event,
                                                                "true",
                                                                "medical_condition"
                                                            )
                                                        }
                                                    />
                                                    <label htmlFor="M_yes">Yes</label>
                                                </div>
                                                <div className={this.state.data.medical_condition === "false" ? "div-checkbox mr15 green-border" : "div-checkbox mr15"}>

                                                    <input
                                                        checked={this.state.data.medical_condition === "false"}
                                                        type="radio"
                                                        id="no"
                                                        name="medical_condition"
                                                        data-testid="nomedical_conditionCheckBox"
                                                        value={this.state.data.medical_condition}
                                                        onChange={event =>
                                                            this.handleMedicalCondition(
                                                                event,
                                                                "false",
                                                                "medical_condition"
                                                            )
                                                        }
                                                    />
                                                    <label htmlFor="M_no">No</label>
                                                </div>
                                            </div>
                                            {this.state.errors.medical_condition && (
                                                <div className="da">
                                                    <img src={warning} alt="" />
                                                    <p className="err ml10">
                                                        {this.state.errors.medical_condition}
                                                    </p>
                                                </div>
                                            )}
                                        </Grid>
                                        {this.state.data.medical_condition === "true" ?
                                            <Grid item xs={12}>
                                                <div>
                                                    <label className="labelHeading" htmlFor="bank">Please Explain</label>
                                                    <sup>*</sup>
                                                </div>
                                                <input
                                                    type="text"
                                                    id="bank"
                                                    placeholder="Type here..."
                                                    name="medical_description"
                                                    data-testid="medical_descriptionInputField"
                                                    autoComplete="off"
                                                    value={this.state.data.medical_description}
                                                    onChange={event => this.handleInputChange(event)}
                                                />
                                                {this.state.errors.medical_description && (
                                                    <div className="da">
                                                        <img src={warning} alt="" />
                                                        <p className="err ml10">
                                                            {this.state.errors.medical_description}
                                                        </p>
                                                    </div>
                                                )}
                                            </Grid>
                                            : ""}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <div className="flexEnd pt40 btn-static-div">
                                <button
                                    type="button"
                                    className="btn-transparent mr15"
                                    onClick={() => this.handleBankDetailsSaveandExitForm("save")}
                                    data-testid="saveExitId"
                                >
                                    Save and Exit
                                </button>
                                <button type="button"
                                    className="btn-green-full-opacity da"
                                    data-testid="nextId"
                                    onClick={() => this.handleBankDetailsSaveandNextForm("next")}
                                >
                                    Next
                                    <span className="ml56">
                                        <NavigateNextIcon />
                                    </span>
                                </button>
                            </div>
                        </div>

                    </section>
                </Container>
            </section >
            // Customizable Area End
        );
    }
}
// Customizable Area Start

const LightTooltip: any = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        padding: "25px",
        maxWidth: "500"
    },
    arrow: {
        color: theme.palette.common.white,
    },
}))(Tooltip);
// Customizable Area End


export default BankDetails;