import React from "react";
// Customizable Area Start
import { Container, Box, Typography, Grid } from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import DashboardController, { Props } from "./DashboardController";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { logo, avatar, profile, bell, setting, logout, calendar } from "./assets";
import "./DashboardStyles.css";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Lexend",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export const StatusText = ({ status, children }: any) => {
  const removeUnderscoreAndCapitalize = (string: string) => {
    if (string === "draft") {
      return "Application draft"
    } else if (string === "contract_signature_req") {
      return "Contract signature required"
    } else {
      string = string.replace(/_/g, ' ');
      string = string.charAt(0).toUpperCase() + string.slice(1);
      return string;
    }
  }
  return <Typography style={withStyle.detailContent}>
    <span style={{ color: "#FFFFFF" }}>{removeUnderscoreAndCapitalize(status)}</span>
    {children && ` - ${children}`}
  </Typography>
}

export const DefaultStatus = () => null;

export const RebookLink = () => (
  <>
    <p style={withStyle.detailContent}>
      To continue your application,{" "}
    </p>
    <Link to="/Appointments" style={{ textDecoration: 'none', color: "#52f152", cursor: 'pointer' }}>
      please re-book another date
    </Link>
  </>
);

// Customizable Area Ends
class Dashboard extends DashboardController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }

  getStatus = (status: string) => {
    const statusMap: any = {
      draft: <StatusText status={status}>Your application is still unfinished. Please click resume to continue.</StatusText>,
      pending_approval: (
        <StatusText status={status}>Your application is being reviewed by the team. Please wait for further contact.</StatusText>
      ),
      pending_induction: (
        <StatusText status={status}>Your application has been accepted. Please book your induction meeting.</StatusText>
      ),
      induction_booked: (
        <StatusText status={status}>
          {`Your induction slot has been booked for ${this.state.apointmentTimeAndDate.time} ${this.state.apointmentTimeAndDate.date?.split(" ")[0]} in the "${this.state.apointmentTimeAndDate?.location}".`}
        </StatusText>
      ),
      cancelled_slot: (
        <>
          <StatusText status={status}>
            Your induction slot has been cancelled.
          </StatusText>
          <RebookLink />
        </>
      ),
      contract_signature_req: (
        <StatusText status={status}>Your induction was a success. Please sign your contract.</StatusText>
      ),
      background_checks_required: (
        <StatusText status={status}>Your application has been verified . Please click resume to continue your background check process.</StatusText>
      ),
      background_checks_completed: (
        <StatusText status={status}>Your background check process is successfully completed. Please wait for further information</StatusText>
      ),

      documents_requested: 
        <StatusText status={status}> Upload documents is in-completed. Please click resume to continue.</StatusText>
      ,
      contract_signed: <StatusText status={status} />,
      application_rejected: (
        <>
          <StatusText status={status}>
            Your application was rejected by the op-manager.
          </StatusText>
          <p style={withStyle.detailContent}> Please contact support for more information.</p>
        </>
      ),
      default: <DefaultStatus />,
    };
    return statusMap[status] || statusMap.default;
  }

  renderStatus = (status: string): React.ReactNode => {
    const Component = this.getStatus(status)
    return Component;
  };

  renderConditionalButtons = (status: any) => {
    const buttonMapping: any = {
      draft: (
        <Button
          data-testid="resumeBtn"
          className="resumeButton app_draft_btn"
          onClick={this.resumeClickHandler}
        >
          Resume
        </Button>
      ),
      pending_approval: (
        <Button
          data-testId="pendings_approval_resume"
          className="resumeButton pending_approval_btn"
          disabled
        >
          Resume
        </Button>
      ),
      documents_requested: (
        <Button
          data-testId="pendings_approval_resume"
          className="resumeButton pending_approval_btn"
          onClick={this.resumeClickHandler}
        >
          Resume
        </Button>
      ),
      background_checks_required: (
        <Button
          data-testid="resumeBtn"
          className="resumeButton app_draft_btn"
          onClick={this.resumeClickHandler}
        >
          Resume
        </Button>
      ),
      background_checks_completed: (
        <Button
          data-testId="pendings_approval_resume"
          className="resumeButton pending_approval_btn"
          disabled
        >
          Resume
        </Button>
      ),
      pending_induction: (
        <Button
          variant="outlined"
          className="uploadButton btn-outline-none"
          component="span"
          onClick={() => this.naviageToAppointmentPage()}
        >
          Select induction slot
          <img src={calendar} alt="calendar" />
        </Button>
      ),
      cancelled_slot: (
        <Button
          variant="outlined"
          className="uploadButton btn-outline-none"
          component="span"
          onClick={() => this.naviageToAppointmentPage()}
        >
          Select induction slot
          <img src={calendar} alt="calendar" />
        </Button>
      ),
      induction_booked: (
        <>
          <Button
            data-testId="induction_booked_change_btn"
            variant="outlined"
            className="resumeButton"
            onClick={() => this.handleCancelAppointmentApi('change')}
          >
            Change
          </Button>
          <Box>
            <Button
              onClick={() => this.handleCancelAppointmentApi('cancle')}
              className="uploadButton ml-15 cancel-btn"
              component="span"
            >
              Cancel
            </Button>
          </Box>
        </>
      ),
      contract_signature_req: (
        <Button
          variant="outlined"
          className="uploadButton sign-Contract"
          data-testid="sign-Contract"
          component="span"
          onClick={this.handleURLApiCall}
        >
          Sign Contract
        </Button>
      ),
      contract_signed: (
        <Button
          variant="outlined"
          className="uploadButton sign-Contract"
          component="span"
          onClick={this.viewdocURLApiCall}
        >
          View Contract
        </Button>
      ),
    };

    return buttonMapping[status] || null;
  };

  render() {
    const { applicationStatus } = this.state
    return (

      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box style={withStyle.firstBox} className="MainBox">
              <Container className="containerBox" style={withStyle.containerPadding} >
                <Box style={withStyle.navbarBox}>
                  <Box
                    data-testId="logoContainer"
                    style={withStyle.flexCenter}
                    onClick={() => this.goToHomescreen()}>
                    <img
                      src={avatar}
                      alt="avatar"
                      style={withStyle.avatar}
                    ></img>
                    <img src={logo} alt="logo" style={withStyle.logo} />
                  </Box>
                  <Box style={withStyle.flexCenter}>
                    <img
                      data-testId="profileImage"
                      src={profile}
                      alt="profile"
                      style={withStyle.profile}
                      onClick={() => {
                        this.showDropdownHandler();
                      }}
                    ></img>
                    {this.state.showDropdown ? (
                      <Box style={withStyle.dropdownList}>
                        <Paper
                          style={{
                            borderRadius: "8px",
                            backgroundColor: "#FFFFFF",
                          }}
                        >
                          <MenuList>
                            <MenuItem
                              data-testId="settingOption"
                              onClick={() => this.naviageToSettingsPage()}
                              data-my-value="Settings"
                            >
                              <ListItemIcon>
                                <img
                                  src={setting}
                                  alt="setting"
                                  style={{ padding: "10px" }}
                                ></img>
                              </ListItemIcon> Settings
                            </MenuItem>
                            <hr style={withStyle.horizontalLine}></hr>
                            <MenuItem
                              data-testId="logoutOption"
                              onClick={() => this.handleLogout()}
                              data-my-value="Logout"
                            >
                              <ListItemIcon>
                                <img
                                  src={logout}
                                  alt="logout"
                                  style={{ padding: "10px" }}
                                ></img>
                              </ListItemIcon>
                              Logout
                            </MenuItem>
                          </MenuList>
                        </Paper>
                      </Box>
                    ) : null}
                  </Box>
                </Box>
                <Box className="backMaindashboard" style={withStyle.contentBox}>
                  <Typography style={withStyle.titleContent}>
                    Welcome Back !
                  </Typography>
                  {applicationStatus && applicationStatus.data && this.renderStatus(applicationStatus.data.status)}
                </Box>
              </Container>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <Container className="containerBox" style={withStyle.containerPadding}>
              <Box style={withStyle.flexBox}>
                {applicationStatus && applicationStatus.data && this.renderConditionalButtons(applicationStatus.data.status)}
              </Box>
            </Container>
          </Grid>
        </Grid >
      </ThemeProvider >

    );
  }
  // Customizable Area Ends
}

export default Dashboard;
// Customizable Area Start
const withStyle: any = {
  containerPadding: {
    padding: "0px 10px",
    maxWidth: "96%",
  },
  containerHeader: {
    padding: "0px 10px",
    width: '90%',
    margin: '0 auto'
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  avatar: {
    width: "60px",
    height: "128px",
    cursor: "pointer"
  },
  logo: {
    width: "174px",
    height: "61px",
    cursor: "pointer"
  },
  notification: {
    width: "48px",
    height: "48px",
    marginRight: "50px",
    cursor: "pointer"
  },

  navbarBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexwrap: "wrap",
  },
  profile: {
    width: "48px",
    height: "48px",
    cursor: "pointer"
  },
  titleContent: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    // fontSize: "48px",
    fontSize: "3rem",
    lineHeight: "59px",
    display: "flex",
    alignItems: "center",
    // textAlign: "start",
    color: "#FFFFFF",
    paddingBottom: "38px",
  },
  detailContent: {
    fontFamily: "Lexend",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: "36px",
    alignItems: "start",
    color: "#D7D7D7",
  },
  contentBox: {
    textAlign: "start",
    padding: "13rem 0rem 0rem 0rem",
  },

  flexBox: {
    display: "flex",
    justifyContent: "end",
    height: "239px",
    paddingTop: "48px",
  },
  dropdownList: {
    borderRadius: "8px",
    position: "absolute",
    top: "60px",
    left: "-136px",
    background: "#FFFFFF",
    cursor: "pointer"
  },
  settings: {
    width: "278px",
    height: "72px",
    background: "#FFFFFF",
    borderRadius: "8px 8px 0px 0px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  dropDownContent: {
    fontFamily: "Lexend !important",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "-0.3264px",
    color: "#000000",
    padding: "2px",
  },
  horizontalLine: {
    margin: "0px 23px",
    padding: "0px 72px",
    border: "1px solid #979797",
    opacity: 0.2,
  },
  logout: {
    width: "278px",
    height: "72px",
    background: "#FFFFFF",
    borderRadius: "0px 0px 8px 8px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
};
// Customizable Area Ends
