export const imgAdd = require("../assets/ic_add.png");
export const imgCancel = require("../assets/ic_cancel.png");
export const imgGstin = require("../assets/ic_gstin.png");
export const imgMapIcon = require("../assets/ic_map_icon.png");
export const imgSuccessModal = require("../assets/success_modal_image.png");
export const backIcon = require("../assets/backIcon.png");
export const rightwhitearrow = require("../assets/rightwhitearrow.png");
export const logo = require("../assets/logo.png");
export const call = require("../assets/call.png");
export const australiaflag = require("../assets/australiaflag.png");
export const downarrow = require("../assets/downarrow.png");
export const robot = require("../assets/robot.png");
export const sucessrobot = require("../assets/sucessIcon.png");
export const warning = require("../assets/warning.png");
export const closearrow = require("../assets/arrow-left.png");
export const rightarrow = require("../assets/arrow_right.png")
export const profile = require("../assets/profile.png");
export const settings = require("../assets/settings.png");
export const logout = require("../assets/logout.png");
export const infoIcon = require("../assets/infoIcon.png");
export const CalendarIcon = require("../assets/CalendarIcon.png");
export const pauseIcon = require("../assets/pauseIcon.png");
export const crossIcon = require("../assets/crossIcon.png");
export const Image = require("../assets/docCheckGalleryIcon.png");
