import React, { useState } from "react";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
	Container,
	Typography,
	Breadcrumbs,
	Link,
	Button,
	TextField, InputLabel
} from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
// Customizable Area End
import SettingsController, {
	Props
} from "./SettingsController";
import { Arrow, imgPasswordVisible, visibilityOff, pathArrow } from "../assets";
import "./Settings.css";

// Customizable Area Start
const theme = createTheme({
	palette: {
		primary: {
			main: "#fff",
			contrastText: "#fff",
		},
	},
	typography: {
		h6: {
			fontWeight: 500,
		},
		subtitle1: {
			margin: "20px 0px",
		},
	},
});
interface ProfileFormProps {
	formikprops: any,
	setEdit: (item: boolean) => void,
	isEdit: boolean,
	email: string
}

export const Alert = (props: AlertProps) => {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const ProfileForm = ({
	formikprops,
	setEdit,
	isEdit,
	email
}: ProfileFormProps) => {
	const { isValid, dirty } = formikprops
	const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	const [showNewPassword, setShowNePassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const checkIfErrorExists = (formikprops: any, field: any) => {
		return Boolean(
			formikprops.touched[field] &&
			formikprops.errors[field]
		)
	}
	const passWordVisibility = (showPassword: any) => {
		return showPassword ? imgPasswordVisible : visibilityOff
	}

	return (
		<form data-testId="editForm" onSubmit={formikprops.handleSubmit} className="driverSettingForm profileeditForm">
			<label htmlFor="email">Email</label>
			<TextField
				data-testId="emailTxtInput"
				value={email}
				id="email"
				placeholder="Enter Email"
				disabled={true}
				type="email"
				fullWidth
				variant="filled"
				name="email"
				InputProps={{
					disableUnderline: true
				}}
				inputProps={{
					style: {
						padding: 12
					}
				}}
			/>
			<label htmlFor="password">Current Password</label>

			<div className="passwordfield">
				<TextField
					data-testId="passwordTxtInput"
					onChange={formikprops.handleChange}
					onBlur={formikprops.handleBlur}
					value={formikprops.values.current_password}
					error={checkIfErrorExists(formikprops, 'current_password')}
					helperText={<ErrorMessage name="current_password" />}
					id="current_password"
					placeholder="Enter your current password"
					type={showCurrentPassword ? "text" : "password"}
					fullWidth
					variant="filled"
					name="current_password"
					InputProps={{
						disableUnderline: true
					}}
					inputProps={{
						style: {
							padding: 12,
							border: checkIfErrorExists(formikprops, 'current_password') ? ' 1px solid red' : ''
						}
					}}
				/>
				<img src={passWordVisibility(showCurrentPassword)} data-testId="hidePw1" onClick={() => setShowCurrentPassword(!showCurrentPassword)} className="passwordeyes"></img>
			</div>
			<label htmlFor="password">New Password</label>
			<div className="passwordfield">
				<TextField
					data-testId="newPWTxtInput"
					onChange={formikprops.handleChange}
					onBlur={formikprops.handleBlur}
					value={formikprops.values.new_password}
					error={checkIfErrorExists(formikprops, 'new_password')}
					helperText={<ErrorMessage name="new_password" />}
					id="new_password"
					placeholder="Enter new password"
					type={showNewPassword ? "text" : "password"}
					fullWidth
					variant="filled"
					name="new_password"
					InputProps={{
						disableUnderline: true
					}}
					inputProps={{
						style: {
							padding: 12,
							border: checkIfErrorExists(formikprops, 'new_password') ? ' 1px solid red' : ''
						}
					}}
				/>

				<img src={passWordVisibility(showNewPassword)} data-testId="hidePw2" onClick={() => setShowNePassword(!showNewPassword)} className="passwordeyes"></img>
			</div>

			<label htmlFor="password">Confirm Password</label>
			<div className="passwordfield">
				<TextField
					data-testId="confirmPWTxtInput"
					onChange={formikprops.handleChange}
					onBlur={formikprops.handleBlur}
					value={formikprops.values.confirm_password}
					error={checkIfErrorExists(formikprops, 'confirm_password')}
					helperText={<ErrorMessage name="confirm_password" />}
					id="confirm_password"
					placeholder="Enter confirm password"
					type={showConfirmPassword ? "text" : "password"}
					fullWidth
					variant="filled"
					name="confirm_password"
					InputProps={{
						disableUnderline: true
					}}
					inputProps={{
						style: {
							padding: 12,
							border: checkIfErrorExists(formikprops, 'confirm_password') ? ' 1px solid red' : ''
						}
					}}
				/>
				<img src={passWordVisibility(showConfirmPassword)} data-testId="hidePw3" onClick={() => setShowConfirmPassword(!showConfirmPassword)} className="passwordeyes"></img>
			</div>
			<div className="action-button">
				<Button data-test-id="close-button" variant="outlined" color="primary" className="close-button" onClick={() => setEdit(!isEdit)}>
					Close
				</Button>
				<Button data-test-id="save-button" type="submit" variant="contained" className={`save-button ${(!isValid || !dirty) ? 'disableBtn' : null}`} disabled={!isValid || !dirty}>
					Save
				</Button>
			</div>
		</form>
	)
}
// Customizable Area End

class Settings extends SettingsController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	// Customizable Area Start
	passwordValidationSchema = Yup.object({
		current_password: Yup.string()
			.required("Password is a required field"),
		new_password: Yup.string()
			.required("New password is a required field")
			.matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/, 'Password must contain 8 characters with 1 uppercase 1 lowercase 1 numeric & 1 special character'),
		confirm_password: Yup.string()
			.required("Confirm password is a required field")
			.oneOf([Yup.ref("new_password"), null], "Password does not match")
	});
	// Customizable Area End

	render() {
		return (
			// Customizable Area Start
			<ThemeProvider theme={theme}>
				<Container maxWidth='xl' className="settings-container" data-test-id="settings-container">
					<Snackbar open={this.state.showSnackBar} autoHideDuration={6000} onClose={this.handleCloseSnackBar}>
						<Alert onClose={this.handleCloseSnackBar} severity="success">
							Password reset successfully
						</Alert>
					</Snackbar>

					<div className="settings-section">
						<Breadcrumbs separator='' area-label='breadcrumb'>
							<Link href="/" color='inherit'>
								Home Page
							</Link>
							<img src={pathArrow} className="navigationArrow" />
							<Typography color='textPrimary'>
								Settings
							</Typography>
						</Breadcrumbs>
					</div>

					<div className="settings-row">
						<div className="rowSpace">
							<div className="rowflex" >
								<img ddata-test-id="settingBackIcon" onClick={this.handleSettingBackBtn} src={Arrow} className="arrow-button" />
								<Typography
									variant='h4'
									className="settings-text"
								>
									Settings
								</Typography>
							</div>
						</div>
						{this.state.pwNotFound ? (
							<div data-test-id="password-not-found">
								<h2>Incorrect password</h2>
								<p>Please click below button to try again</p>
								<Button
									variant="outlined"
									color="primary"
									className="go-to-settings-button"
									data-test-id="go-to-settings"
									onClick={() => this.setState({ isEdit: !this.state.isEdit, pwNotFound: false })}
								>
									Go to settings
								</Button>
							</div>
						) : (
							<>
								{this.state.isEdit ? (
									<Formik
										data-testId="settings-form"
										validationSchema={this.passwordValidationSchema}
										initialValues={this.state.initialValues}
										onSubmit={(values: any) => {
											this.setState({
												new_password: values.new_password,
												confirm_password: values.confirm_password,
												current_password: values.current_password
											});
											this.changePassword();
										}}
										className="form-container"
									>
										{(formikprops) => (
											<ProfileForm
												formikprops={formikprops}
												setEdit={(value: boolean) => this.setState({ isEdit: value })}
												isEdit={this.state.isEdit}
												email={this.state.initialValues.email}
											/>
										)}
									</Formik>
								) : (
									<form className='driverSettingForm'>
										<InputLabel className="settingsLabels">Email</InputLabel>
										<TextField
											className="email-input"
											value={this.state.initialValues?.email}
											id="email"
											placeholder="Enter Email"
											type="email"
											fullWidth
											variant="filled"
											name="email"
											disabled={true}
											data-testId="emailInput"
										/>

										<InputLabel className="settingsLabels">Password</InputLabel>
										<TextField
											className="password-input"
											value="******"
											id="password"
											placeholder="Enter Password"
											type="text"
											fullWidth
											variant="filled"
											name="password"
											disabled={true}
										/>
										<div className="editPassBtnEle">
											<Button data-testId="editProfileBtn" className="editPassBtn" onClick={() => {
												this.setState({ isEdit: !this.state.isEdit })
											}}>
												Edit password
											</Button>
										</div>
									</form>
								)}
							</>
						)}
					</div>
				</Container>
			</ThemeProvider>
			// Customizable Area End
		);
	}
}

// @ts-ignore
export default Settings;

