import React from "react";
// Customizable Area Start
import BackgroundCheckController, { Props } from "./BackgroundCheckController.web";
import { Container, Grid } from "@material-ui/core";
import "./PersonalDetails.css";
import { warning } from "./assets";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

class QuestionBank extends BackgroundCheckController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    const { questionList } = this.state;
    const renderErr = () => {
      return (
        <div style={webStyle.errorContainer}>
          <div className="da">
            <img src={warning} alt="" />
            <p className="err ml10">This field is required.</p>
          </div>
        </div>
      );
    };
    const checkBoxBorder = (value: any) => {
      let name = value ? "checkBoxcontent active" : "checkBoxcontent";
      return name;
    };
    const renderQuestionList = (questionList: any) => {
      return (
        <>
          {questionList.map((questions: any, index: any) => {
            return (
              <Grid className="questionList" key={index}>
                <h5 style={webStyle.questionHead}>
                  <span>{index + 1}.</span>
                  {questions?.attributes?.question}
                </h5>
                {questions?.isAnswered ? "" : renderErr()}
                <Grid className="questionOption">
                  <Grid className="checkboxAns">
                    {
                      questions?.attributes?.choices.map((data: any, key: any) =>
                        <div
                          key={data.id}
                          className={checkBoxBorder(
                            data.checked
                          )}
                        >
                          <input
                            type="checkbox"
                            id="1"
                            className="op1"
                            data-test-id="opt1"
                            checked={data.checked}
                            onChange={(event) => this.handleOption(event, index, key)}
                          />
                          <h6>{data.choice_title}</h6>
                        </div>
                      )
                    }
                  
                  
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </>
      );
    };
    // Customizable Area End
    return (
      // Customizable Area Start
      <section className="citizen-ship bgGrey">
        <Container>
          <Grid className="scrollContent">
            <Grid className="questionBankHeading">
              <h4 data-test-id="h1">
                Please select the most appropriate option
              </h4>
              <p style={webStyle.criteriaText}>
                There is no pass or fail on this and answers will be reviewed
                internally
              </p>
            </Grid>
            {questionList.length > 0 ? renderQuestionList(questionList) : ""}
          </Grid>
          <div className="flexEnd pt40 btn-static-div">
            <button
              className={"btn-green-full-opacity da"}
              data-testid="nextSecondForm"
              onClick={this.saveOptions}
            >
              Submit
              <span className="ml56">
                <NavigateNextIcon />
              </span>
            </button>
          </div>
        </Container>
      </section>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  optionContainer: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    border: "1px solid #f0f7ec",
    borderRadius: "4px",
    marginLeft: "10px",
    marginBottom: "15px",
  },
  inputStyle: { marginRight: "10px" },
  questionStyle: { margin: "0px" },
  questionBoxContainer: {
    background: "#fff",
    padding: "10px 15px",
    borderRadius: "4px",
    margin: "10px auto",
  },
  questionHead: {
    marginBottom: "15px",
  },
  errorContainer: {
    margin: "12px auto",
  },
  criteriaText: {
    fontFamily: "Lexend",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "36px",
  },
};
// Customizable Area End

export default QuestionBank;
// Customizable Area End
