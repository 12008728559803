// Customizable Area Start
import React, { Component } from 'react';
import { Grid } from "@material-ui/core";

export interface MyProps {
    data: any;
}
class PerDriverQuestionAns extends Component<MyProps> {

    checkBoxBorder = (value: any) => {
        let name = value ? "checkBoxcontent active" : "checkBoxcontent";
        // istanbul ignore next
        return name;
    };
    renderQuestionList = (questionList: any) => {
        // istanbul ignore next
        return (
            <>
                {questionList.map((questions: any, index: any) => {
                    // istanbul ignore next
                    return (
                        <Grid className="questionList" key={index}>
                            <h5 style={webStyle.questionHead}>
                                <span>{index + 1}.</span>
                                {questions?.attributes?.driver_qbank?.data?.attributes?.question}
                            </h5>
                            <Grid className="questionOption">
                                <Grid className="checkboxAns">
                                    {questions?.attributes?.driver_qbank?.data?.attributes?.choices.map((data: any, indx: any) =>
                                        <div
                                            key={data.id}
                                            className={this.checkBoxBorder(
                                                data.checked
                                            )}
                                        >
                                            <input
                                                type="checkbox"
                                                id="1"
                                                className="op1"
                                                data-test-id="QBopt1"
                                                checked={data.checked}

                                            />
                                            <h6>{data.choice_title}</h6>

                                        </div>
                                    )}

                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })}
            </>
        );
    };
    render() {


        const { data } = this.props
        console.log("Questions Data", data)
        return (
            <div>
                <section className="citizen-ship">
                    <Grid>
                        {data?.length > 0 ? this.renderQuestionList(data) : <p className='details-text'>--</p>}
                    </Grid>
                </section>
            </div>
        );
    }
}


const webStyle = {
    optionContainer: {
        display: "flex",
        alignItems: "center",
        padding: "10px",
        border: "1px solid #f0f7ec",
        borderRadius: "4px",
        marginLeft: "10px",
        marginBottom: "15px",
    },
    inputStyle: { marginRight: "10px" },
    questionStyle: { margin: "0px" },
    questionBoxContainer: {
        background: "#fff",
        padding: "10px 15px",
        borderRadius: "4px",
        margin: "10px auto",
    },
    questionHead: {
        marginBottom: "15px",
    },
    errorContainer: {
        margin: "12px auto",
    },
    criteriaText: {
        fontFamily: "Lexend",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "36px",
    },
};


export default PerDriverQuestionAns;
// Customizable Area End